import { TFunction } from "i18next";

export const otherData = (t: TFunction<"translation", undefined>) => {
    return {
        id: 1,
        label: `${t("otherData.label")}`,
        placeholder: "",
        name: "additional",
        desc: `${t("otherData.desc")}`,
    };
};
