import { jsPDF } from "jspdf";
import { v4 as genId } from "uuid";
import html2canvas from "html2canvas";

const sizes = {
    mm: 3.7795275591,
    cm: 37.7952755906,
    px: 1,
};

const getPdf = (canvas: HTMLCanvasElement): File => {
    const pageSize = [297, 210];
    const pageUnits = "mm";
    const sizeRatio = sizes[pageUnits];

    const img = canvas.toDataURL("image/jpeg", 1.0);
    const imageWidth = canvas.width / sizeRatio;
    const imageHeight = canvas.height / sizeRatio;

    const doc = new jsPDF("p", pageUnits, pageSize, true);
    console.log(doc.internal.scaleFactor);
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const height = (imageHeight * pageWidth) / imageWidth;
    console.log(imageHeight, pageHeight, height);
    console.log(imageWidth, pageWidth);

    let yOffset = 0;
    const yPadding = 0; /*11.7*/
    const pages = Math.ceil(imageHeight / pageHeight);
    for (let i = 0; i < pages; i++) {
        doc.addImage(
            img,
            "JPEG",
            0,
            yOffset,
            pageWidth,
            imageHeight + yPadding,
        );
        if (i + 1 != pages) doc.addPage(pageSize, "p");
        yOffset -= pageHeight - yPadding /*- (i === 0 ? 100 : 0)*/;
    }

    const blob = new Blob([doc.output("blob")], { type: "application/pdf" });
    const filename = genId();
    return new File([blob], `${filename}.pdf`);
};

export const getFile = (id: string): Promise<File> => {
    return new Promise<File>((resolve, reject) => {
        const form = document.getElementById(id);
        if (form) {
            html2canvas(form)
                .then((canvas) => {
                    resolve(getPdf(canvas));
                })
                .catch(() =>
                    reject({
                        errorId: "0003",
                        message: "Error to convert file to canvas",
                    }),
                );
        } else {
            reject({
                errorId: "0001",
                message: "The html is empty",
            });
        }
    });
};

export const getFileComponent = (id: string): Promise<File> => {
    return new Promise<File>((resolve, reject) => {
        const form = document.getElementById(id);
        console.log(form?.clientWidth);
        if (form) {
            const doc = new jsPDF("p", "px", [1122, 794], true);
            doc.html(form, { autoPaging: "text", margin: [40, 0, 40, 0] })
                .then(() => {
                    const blob = new Blob([doc.output("blob")], {
                        type: "application/pdf",
                    });
                    const filename = genId();
                    const file = new File([blob], `${filename}.pdf`);
                    console.log(URL.createObjectURL(file));
                    resolve(file);
                })
                .catch(() =>
                    reject({
                        errorId: "0003",
                        message: "Error to convert file to canvas",
                    }),
                );
        } else {
            reject({
                errorId: "0001",
                message: "The html is empty",
            });
        }
    });
};
