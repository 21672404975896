import React, { useRef } from "react";

interface IDivided extends React.InputHTMLAttributes<string> {
    disabled?: boolean;
    id?: string;
    label?: string;
    placeholder?: string;
    name: string;
    required?: boolean;
    checked?: boolean;
    elem?: string | any;
    elem_img?: string | any;
    onChange?: (arg0: any) => void;
    number?: boolean;
    minLength?: number;
    maxLength?: number;
    min?: number;
    max?: number;
    onkey?: boolean;
    multiple?: boolean;
    units?: string;
}

export const DividedInput: React.FC<IDivided> = ({
    id,
    label,
    placeholder,
    type = "text",
    name,
    elem,
    elem_img,
    onChange,
    number,
    minLength,
    maxLength,
    min,
    max,
    onkey,
    multiple,
    units,
}) => {
    // console.log(elem_img)

    const ref = useRef<HTMLInputElement>(null);
    const handleError = (e: { target: { value: string } }): void => {
        if (!e.target.value || !ref?.current?.checkValidity()) {
            ref?.current?.setAttribute("style", "border:1px solid #e53d3e");
        } else {
            ref?.current?.setAttribute("style", "border:1px solid #4b4b4b");
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode >= 65 && e.keyCode <= 185) {
            !/\D/g.test(e.key) && console.log(e, "key");
            e.preventDefault();
        }
    };

    // const removeFile = (filename) => {
    //     setFiles(files.filter(file => file.name !== filename))
    // }
    return (
        <>
            <div className="relative flex items-center mb-4 md:md-0 w-1/2">
                <div className="relative flex items-center justify-between">
                    <label
                        htmlFor={name}
                        className="text-middleGray whitespace-nowrap"
                    >
                        <span>{label}</span>
                    </label>
                </div>
                <div className="flex w-full bg-white">
                    {elem && (
                        <span className="inline-flex items-center px-3 text-sm whitespace-nowrap text-middleGray ">
                            {elem}
                        </span>
                    )}
                    {elem_img && (
                        <div className="flex items-center justify-center">
                            <img
                                src={require(`@assets/images/${elem_img}`)}
                                alt=""
                                className="w-4 h-4 mx-3 my-2 "
                            />
                        </div>
                    )}
                    <input
                        ref={ref}
                        onBlur={(e) => handleError(e)}
                        className="focus:outline-none focus:ring-secondary  focus:ring-1 border mb-1 focus:border-secondary border-[#4b4b4b] text-lg block rounded-md py-2 placeholder:opacity-50 placeholder:text-neutral w-full placeholder:text-lg p-2 text-lg bg-transparent"
                        placeholder={placeholder}
                        minLength={minLength}
                        maxLength={maxLength}
                        type={type}
                        name={name}
                        id={id}
                        min={min}
                        max={max}
                        multiple={multiple}
                        onKeyDown={(e) => onkey && handleKeyDown(e)}
                        onChange={onChange}
                        onKeyPress={(event: {
                            key: string;
                            preventDefault: () => void;
                        }) => {
                            number &&
                                !/[0-9.,]/.test(event.key) &&
                                event.preventDefault();
                        }}
                    />
                </div>

                {units && (
                    <p className="absolute right-2 px-2 text-lg text-gray flex items-center">
                        {units}
                    </p>
                )}
            </div>
        </>
    );
};
