import React from "react";
import { rightsData } from "@config/debtor";
import { DebtorMain } from "@components/debtor";
import { useTranslation } from "react-i18next";

export const RightsObligations = () => {
    const { t } = useTranslation();
    const { title, items, desc } = rightsData(t);
    return <DebtorMain title={title} items={items} desc={desc} />;
};
