import React from "react";
import { AccordionLayout } from "./AccordionLayout";

interface IData {
    id: number;
    title: string;
    desc?: string;
    element?: React.ReactNode;
}
interface IAccordion {
    data: IData[];
}

export const Accordion: React.FC<IAccordion> = ({ data }) => {
    return (
        <ul className="w-full overflow-hidden">
            {data.map((item) => {
                return (
                    <li className="w-full " key={item.id}>
                        <AccordionLayout
                            desc={item.desc}
                            title={item.title}
                            line
                            big
                        />
                    </li>
                );
            })}
        </ul>
    );
};