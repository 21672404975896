import {
    ClaimIcon1,
    ClaimIcon2,
    ClaimIcon3,
    ClaimIcon4,
    ClaimIcon5,
} from "@assets";

import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const claimantSidebarData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 2,
            icon: ClaimIcon1,
            title: `${t("claimantSidebarData.0.title")}`,
            desc: "Sissenõudja võib taotleda võlgniku suhtes sundtäitemenetluse alustamist...",
            path: AppRoute.CLAIMANT_PORTAL,
        },
        {
            id: 3,
            icon: ClaimIcon3,
            title: `${t("claimantSidebarData.1.title")}`,
            desc: "Elatise sissenõudmine on väga tundlik ja ühiskonnatähenduslik menetlus ...",
            path: AppRoute.CLAIMANT_HOTLINE,
        },
        // {
        //     id: 3,
        //     icon: ClaimIcon3,
        //     title: "Elatise infoliin",
        //     desc: "Elatise sissenõudmine on väga tundlik ja ühiskonnatähenduslik menetlus ...",
        //     path: AppRoute.CLAIMANT_HOTLINE,
        // },
        {
            id: 4,
            icon: ClaimIcon4,
            title: `${t("claimantSidebarData.2.title")}`,
            desc: "Täitekutse saamisel on võlgnik kohustatud viivitamata esitama ...",
            path: AppRoute.CLAIMANT_MAINTENANCE,
        },
        {
            id: 1,
            icon: ClaimIcon2,
            title: `${t("claimantSidebarData.3.title")}`,
            desc: "Võlgnikul on võimalus esitada kohtutäiturile maksegraafiku avaldus ...",
            path: AppRoute.CLAIMANT_FORMS,
        },
        {
            id: 5,
            icon: ClaimIcon5,
            title: `${t("claimantSidebarData.4.title")}`,
            desc: "Kohtutäituril on õigus kohustada võlgnikku esitama temale kuuluva ...",
            path: AppRoute.CLAIMANT_APPLYING,
        },
        // {
        //     id: 6,
        //     icon: ClaimIcon6,
        //     title: "Korduma kippuvad küsimused",
        //     desc: "Mida kiiremini võlgnik pärast täitmisteate saamist võla täidab...",
        //     path: AppRoute.CLAIMANT_QUESTIONS,
        // },
        // {
        //     id: 7,
        //     icon: ClaimIcon7,
        //     title: "Meie meeskond",
        //     desc: "Mida kiiremini võlgnik pärast täitmisteate saamist võla täidab...",
        //     path: AppRoute.CLAIMANT_TEAM,
        // },
    ];
};
