import React from "react";
import { formsSidebarData } from "@config/forms";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";
import { CircleArrow } from "@assets";

export const DebtorFormBase = () => {
    const { t } = useTranslation();
    const selectItemClick = (name: string, title: string) => {
        localStorage.setItem("selectMenuItem", name);
        localStorage.setItem("selectTitleItem", title);
    };
    return (
        <div className="flex flex-col lg:ml-0 mx-6">
            <p className="my-4 ml-6 mr-6 text-xl font-semibold md:my-6 md:ml-0">
                {t("formsSidebarData.0.title")}
            </p>
            <div className="flex flex-col">
                {formsSidebarData(t)[0].children.map((item) => (
                    <div key={item.id} className="flex flex-col ">
                        <li
                            onClick={() =>
                                selectItemClick(item.name, item.title)
                            }
                            className="h-[90px] cursor-pointer text-lg hover:bg-neutral hover:text-white !flex-row base-border mb-4"
                            key={item.id}
                        >
                            {item.name ? (
                                <NavHashLink
                                    className="flex items-center justify-between w-[100%]"
                                    to={`${item.path}#isTop`}
                                >
                                    <span className="text-lg w-[90%]">
                                        {item.title}
                                    </span>
                                    <CircleArrow />
                                </NavHashLink>
                            ) : (
                                <NavHashLink
                                    className="flex items-center justify-between w-[100%]"
                                    to={`${item.path}#isTop`}
                                >
                                    <span className="text-lg w-[90%]">
                                        {item.title}
                                    </span>
                                    <CircleArrow />
                                </NavHashLink>
                            )}
                        </li>
                    </div>
                ))}
            </div>
        </div>
    );
};
