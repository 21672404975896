import React, { useCallback } from "react";
import { AppRoute } from "@enums";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@components/common";

interface IFormFooter {
    pdf: string;
    title: string;
    validForm: boolean;
    setIsTopError?: (flag: boolean) => void;
    params?: Record<string, unknown>;
    onClick?: () => void;
}

export const FormFooter: React.FC<IFormFooter> = ({
    pdf,
    title,
    validForm,
    params,
    onClick,
}) => {
    const { t } = useTranslation();
    const handleSignClick = () => {
        if (onClick) onClick();
        if (validForm) localStorage.setItem("documentForSign", title);
    };

    const getParamLink = useCallback(() => {
        if (params) {
            const arr = Object.entries(params);
            arr.push(["path", pdf]);
            const s = arr.map(([key, value]) => `${key}=${value}`).join("&");
            return `?${s}`;
        }
        return `?path=${pdf}`;
    }, [params, pdf]);

    return (
        <>
            <div className="flex flex-col items-center justify-center my-8 md:flex-row">
                <Link
                    aria-disabled={!validForm}
                    to={pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full md:w-fit  md:mr-4"
                >
                    <Button
                        disabled={!validForm}
                        className="!w-full md:px-12 mb-4 mr-0 md:w-fit md:mb-0 lg:mr-12 uppercase"
                    >
                        {t("formFooter.btn_print")}
                    </Button>
                </Link>

                <Link
                    aria-disabled={!validForm}
                    target="_blank"
                    to={`${AppRoute.DIGITAL_SIGN}${getParamLink()}`}
                    className="w-full md:w-fit"
                >
                    <Button
                        disabled={!validForm}
                        outline
                        onClick={handleSignClick}
                        className="!w-full md:w-fit !px-2 md:!px-12 uppercase"
                    >
                        {t("formFooter.btn_sign")}
                    </Button>
                </Link>
            </div>
            {!validForm && (
                <p className="text-[#f70303] text-center font-semibold mb-6">
                    {t("formFooter.warning")}
                </p>
            )}
        </>
    );
};
