import React from "react";
import { Section } from "@components/components";
import { propertySaleData } from "@config/forms";
import { useTranslation } from "react-i18next";

interface ISale {
    onChange?: (arg0: any) => void;
}

export const Sale: React.FC<ISale> = ({ onChange }) => {
    const { t } = useTranslation();
    return (
        <div>
            <Section
                data={propertySaleData(t)}
                onChange={onChange}
                className="grid grid-cols-1 gap-4 md:grid-cols-2"
            />
            {/*<Awared data={propertySaleData.nb} upper base dark />*/}
        </div>
    );
};
