import React from "react";
import clsx from "clsx";
import { Input, Tooltip } from "@components/components";

interface IField {
    name: string;
    label?: string;
    type?: string;
    id?: number;
    placeholder?: string;
    number?: boolean;
    units?: string;
}
interface ISectionContainer {
    children: React.ReactNode;
    title?: string;
    field?: IField;
    dark?: boolean;
    upper?: boolean;
    onChange?: (arg0: any) => void;
    tip?: string;
}
export const SectionContainer: React.FC<ISectionContainer> = ({
    children,
    onChange,
    title,
    field,
    dark,
    upper,
    tip,
}) => {
    return (
        <>
            {title ? (
                <div className="flex flex-col mt-6">
                    {field ? (
                        <div className="flex flex-col w-full base-border mb-2">
                            <p
                                className={clsx(
                                    "mb-2",
                                    dark
                                        ? "font-semibold text-lg  text-neutral"
                                        : "text-[#333333] font-normal",
                                    upper ? "uppercase" : "",
                                )}
                            >
                                {title}
                                {tip && <Tooltip hint={tip} right={false} />}
                            </p>
                            <Input
                                className="w-1/2"
                                name={field.name}
                                type={field.type}
                                form
                                onChange={onChange}
                                units={field.units}
                                label={field.label}
                                number={field.number}
                                placeholder={field.placeholder}
                                hint={tip}
                            />
                        </div>
                    ) : (
                        <p
                            className={clsx(
                                "mb-2 text-lg flex items-center",
                                dark
                                    ? "font-semibold text-neutral"
                                    : "text-[#333333] font-semibold ",
                                upper ? "uppercase font-semibold" : "",
                            )}
                        >
                            {title}
                            {tip && <Tooltip hint={tip} right={false} />}
                        </p>
                    )}

                    {children}
                </div>
            ) : (
                <div className="flex flex-col">{children}</div>
            )}
        </>
    );
};
