import React from "react";

export const SalaryIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5239 9.75284C20.8848 5.81944 17.5438 2.89321 13.5605 2.77801C13.3711 2.77059 13.1865 2.83878 13.0475 2.96755C12.9084 3.09632 12.8262 3.27511 12.8191 3.46451V3.46451V3.52859L13.2676 10.238C13.297 10.6896 13.6853 11.0329 14.1372 11.0068L20.8649 10.5583C21.0544 10.5443 21.2306 10.4553 21.3543 10.311C21.478 10.1666 21.539 9.97894 21.5239 9.78946V9.75284Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.90151 6.76889C9.32947 6.6699 9.76741 6.88887 9.94499 7.29063C9.9915 7.38512 10.0195 7.48766 10.0274 7.59269C10.1189 8.89246 10.3111 11.7391 10.421 13.2769C10.4397 13.5539 10.5681 13.812 10.7777 13.9941C10.9872 14.1762 11.2608 14.2673 11.5377 14.2472V14.2472L17.1853 13.8993C17.4374 13.8842 17.6846 13.9739 17.8683 14.1472C18.052 14.3205 18.156 14.5621 18.1555 14.8147V14.8147C17.9266 18.225 15.4764 21.0761 12.1392 21.8151C8.80201 22.5541 5.37718 21.0041 3.72988 18.0092C3.23813 17.1472 2.92672 16.1943 2.81455 15.2083C2.7666 14.9056 2.74821 14.5991 2.75963 14.2929C2.76935 10.6509 5.32714 7.51285 8.89236 6.76889"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
