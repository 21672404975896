import { TFunction } from "i18next";

export const bailiffsListData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            name: `${t("bailiffsListData.kristina.name")}`,
            image: "user_1.png",
            image_full: "user_1_full.png",
            status: "",
            handedTo: "",
            position: `${t("bailiffsListData.kristina.position")}`,
            contacts: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.kristina.contacts.0.item")}`,
                    content: `${t(
                        "bailiffsListData.kristina.contacts.0.content",
                    )}`,
                    icon: "geo.svg",
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.kristina.contacts.1.item")}`,
                    content: "buroo.feinman@taitur.net",
                    icon: "mailbox.svg",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.kristina.contacts.2.item")}`,
                    content: "6 129 155",
                    icon: "telephone.svg",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.kristina.contacts.3.item")}`,
                    content: `${t(
                        "bailiffsListData.kristina.contacts.3.content",
                    )}`,
                    icon: "",
                },
            ],
            contacts_full: [
                {
                    id: 1,
                    item: `${t(
                        "bailiffsListData.kristina.contacts_full.0.item",
                    )}`,
                    content: `${t(
                        "bailiffsListData.kristina.contacts_full.0.content",
                    )}`,
                },
                {
                    id: 2,
                    content: `${t(
                        "bailiffsListData.kristina.contacts_full.1.content",
                    )}`,
                },
                {
                    id: 3,
                    item: `${t(
                        "bailiffsListData.kristina.contacts_full.2.item",
                    )}`,
                    content: "612 9155",
                },
                {
                    id: 4,
                    item: `${t(
                        "bailiffsListData.kristina.contacts_full.3.item",
                    )}`,
                    content: "buroo.feinman@taitur.net",
                },
                {
                    id: 5,
                    item: `${t(
                        "bailiffsListData.kristina.contacts_full.4.item",
                    )}`,
                    content: `${t(
                        "bailiffsListData.kristina.contacts_full.4.content",
                    )}`,
                },
                {
                    id: 6,
                    item: `${t(
                        "bailiffsListData.kristina.contacts_full.5.item",
                    )}`,
                    content: `${t(
                        "bailiffsListData.kristina.contacts_full.5.content",
                    )}`,
                },
                {
                    id: 7,
                    content: `${t(
                        "bailiffsListData.kristina.contacts_full.6.content",
                    )}`,
                },
                {
                    id: 8,
                    content: `${t(
                        "bailiffsListData.kristina.contacts_full.7.content",
                    )}`,
                },
                {
                    id: 9,
                    content: `${t(
                        "bailiffsListData.kristina.contacts_full.8.content",
                    )}`,
                },
                {
                    id: 10,
                    content: `${t(
                        "bailiffsListData.kristina.contacts_full.9.content",
                    )}`,
                },
                {
                    id: 11,
                    content: `${t(
                        "bailiffsListData.kristina.contacts_full.10.content",
                    )}`,
                },
            ],
            desc_1: `${t("bailiffsListData.kristina.desc_1")}`,
            desc_2: `${t("bailiffsListData.kristina.desc_2")}`,
        },
        {
            id: 2,
            name: `${t("bailiffsListData.risto.name")}`,
            image: "user_2.png",
            image_full: "user_2_full.png",
            status: "",
            handedTo: "",
            position: `${t("bailiffsListData.risto.position")}`,
            contacts: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.risto.contacts.0.item")}`,
                    content: `${t(
                        "bailiffsListData.risto.contacts.0.content",
                    )}`,
                    icon: "geo.svg",
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.risto.contacts.1.item")}`,
                    content: "buroo.sepp@taitur.net",
                    icon: "mailbox.svg",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.risto.contacts.2.item")}`,
                    content: "6 129 155",
                    icon: "telephone.svg",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.risto.contacts.3.item")}`,
                    content: `${t(
                        "bailiffsListData.risto.contacts.3.content",
                    )}`,
                    icon: "",
                },
            ],
            contacts_full: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.risto.contacts_full.0.item")}`,
                    content: `${t(
                        "bailiffsListData.risto.contacts_full.0.content",
                    )}`,
                },
                {
                    id: 9,
                    content: `${t(
                        "bailiffsListData.risto.contacts_full.1.content",
                    )}`,
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.risto.contacts_full.2.item")}`,
                    content: "612 9155",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.risto.contacts_full.3.item")}`,
                    content: "buroo.sepp@taitur.net",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.risto.contacts_full.4.item")}`,
                    content: `${t(
                        "bailiffsListData.risto.contacts_full.4.content",
                    )}`,
                },
                {
                    id: 5,
                    item: `${t("bailiffsListData.risto.contacts_full.5.item")}`,
                    content: `${t(
                        "bailiffsListData.risto.contacts_full.5.content",
                    )}`,
                },
                {
                    id: 6,
                    content: `${t(
                        "bailiffsListData.risto.contacts_full.6.content",
                    )}`,
                },
                {
                    id: 7,
                    content: `${t(
                        "bailiffsListData.risto.contacts_full.7.content",
                    )}`,
                },
                {
                    id: 8,
                    item: "",
                    content: `${t(
                        "bailiffsListData.risto.contacts_full.8.content",
                    )}`,
                },
            ],
            desc_1: `${t("bailiffsListData.risto.desc_1")}`,
            desc_2: `${t("bailiffsListData.risto.desc_2")}`,
            desc_3: `${t("bailiffsListData.risto.desc_3")}`,
        },
        {
            id: 3,
            name: `${t("bailiffsListData.hille.name")}`,
            image: "user_3.png",
            image_full: "user_3_full.png",
            status: "",
            handedTo: "",
            position: `${t("bailiffsListData.hille.position")}`,
            contacts: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.hille.contacts.0.item")}`,
                    content: `${t(
                        "bailiffsListData.hille.contacts.0.content",
                    )}`,
                    icon: "geo.svg",
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.hille.contacts.1.item")}`,
                    content: "buroo.kudu@taitur.net",
                    icon: "mailbox.svg",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.hille.contacts.2.item")}`,
                    content: "6 129 155",
                    icon: "telephone.svg",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.hille.contacts.3.item")}`,
                    content: `${t(
                        "bailiffsListData.hille.contacts.3.content",
                    )}`,
                    icon: "",
                },
            ],
            contacts_full: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.hille.contacts_full.0.item")}`,
                    content: `${t(
                        "bailiffsListData.hille.contacts_full.0.content",
                    )}`,
                },
                {
                    id: 11,
                    content: `${t(
                        "bailiffsListData.hille.contacts_full.1.content",
                    )}`,
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.hille.contacts_full.2.item")}`,
                    content: "612 9155",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.hille.contacts_full.3.item")}`,
                    content: "buroo.kudu@taitur.net",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.hille.contacts_full.4.item")}`,
                    content: `${t(
                        "bailiffsListData.hille.contacts_full.4.content",
                    )}`,
                },
                {
                    id: 5,
                    item: `${t("bailiffsListData.hille.contacts_full.5.item")}`,
                },
                {
                    id: 6,
                    content: `${t(
                        "bailiffsListData.hille.contacts_full.6.content",
                    )}`,
                },
                {
                    id: 7,
                    content: `${t(
                        "bailiffsListData.hille.contacts_full.7.content",
                    )}`,
                },
                {
                    id: 8,
                    content: `${t(
                        "bailiffsListData.hille.contacts_full.8.content",
                    )}`,
                },
                {
                    id: 9,
                    content: `${t(
                        "bailiffsListData.hille.contacts_full.9.content",
                    )}`,
                },
                {
                    id: 10,
                    content: `${t(
                        "bailiffsListData.hille.contacts_full.10.content",
                    )}`,
                },
            ],
            desc_1: `${t("bailiffsListData.hille.desc_1")}`,
            desc_2: `${t("bailiffsListData.hille.desc_2")}`,
        },
        {
            id: 4,
            name: `${t("bailiffsListData.tatjana.name")}`,
            image: "user_4.png",
            image_full: "user_4_full.png",
            status: "",
            handedTo: "",
            position: `${t("bailiffsListData.tatjana.position")}`,
            contacts: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.tatjana.contacts.0.item")}`,
                    content: `${t(
                        "bailiffsListData.tatjana.contacts.0.content",
                    )}`,
                    icon: "geo.svg",
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.tatjana.contacts.1.item")}`,
                    content: "buroo.afanasieva@taitur.net",
                    icon: "mailbox.svg",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.tatjana.contacts.2.item")}`,
                    content: "3 540 020",
                    icon: "telephone.svg",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.tatjana.contacts.3.item")}`,
                    content: `${t(
                        "bailiffsListData.tatjana.contacts.3.content",
                    )}`,
                    icon: "",
                },
            ],
            contacts_full: [
                {
                    id: 1,
                    item: `${t(
                        "bailiffsListData.tatjana.contacts_full.0.item",
                    )}`,
                    content: `${t(
                        "bailiffsListData.tatjana.contacts_full.0.content",
                    )}`,
                },
                {
                    id: 9,
                    content: `${t(
                        "bailiffsListData.tatjana.contacts_full.1.content",
                    )}`,
                },
                {
                    id: 2,
                    item: `${t(
                        "bailiffsListData.tatjana.contacts_full.2.item",
                    )}`,
                    content: "354 0020",
                },
                {
                    id: 3,
                    item: `${t(
                        "bailiffsListData.tatjana.contacts_full.3.item",
                    )}`,
                    content: "buroo.afanasieva@taitur.net",
                },
                {
                    id: 4,
                    item: `${t(
                        "bailiffsListData.tatjana.contacts_full.4.item",
                    )}`,
                    content: `${t(
                        "bailiffsListData.tatjana.contacts_full.4.content",
                    )}`,
                },
                {
                    id: 5,
                    item: `${t(
                        "bailiffsListData.tatjana.contacts_full.5.item",
                    )}`,
                    content: `${t(
                        "bailiffsListData.tatjana.contacts_full.5.content",
                    )}`,
                },
                {
                    id: 6,
                    content: `${t(
                        "bailiffsListData.tatjana.contacts_full.6.content",
                    )}`,
                },
                {
                    id: 7,
                    content: `${t(
                        "bailiffsListData.tatjana.contacts_full.7.content",
                    )}`,
                },
                {
                    id: 8,
                    content: `${t(
                        "bailiffsListData.tatjana.contacts_full.8.content",
                    )}`,
                },
            ],
            desc_1: `${t("bailiffsListData.tatjana.desc_1")}`,
            desc_2: `${t("bailiffsListData.tatjana.desc_2")}`,
        },

        // {
        //     id: 5,
        //     name: "Arvi Pink",
        //     image: "user_5.png",
        //     image_full: "user_5_full.png",
        //     status: "ametist vabastatud",
        //     handedTo: "büroo üle antud kohtutäiturile Risto Sepp",
        //     position: "Kohtutäitur",
        //     contacts: [
        //         {
        //             id: 1,
        //             item: "Büroo",
        //             content: "Rävala pst 5, Tallinn 10145",
        //             icon: "geo.svg",
        //         },
        //         {
        //             id: 2,
        //             item: "E-post",
        //             content: "buroo.feinman@taitur.net",
        //             icon: "mailbox.svg",
        //         },
        //         {
        //             id: 3,
        //             item: "Telefon",
        //             content: "6 129 155",
        //             icon: "telephone.svg",
        //         },
        //         {
        //             id: 4,
        //             item: "Region",
        //             content: "",
        //             icon: "",
        //         },
        //     ],
        //     contacts_full: [
        //         {
        //             id: 1,
        //             item: "Seb Pank:",
        //             content: "EE821010010124388015 BIC:EEUHEE2X",
        //         },
        //         { id: 2, item: "Seb Pank:", content: "" },
        //         { id: 3, item: "Seb Pank: ", content: "" },
        //         { id: 4, item: "Aadress: ", content: "" },
        //         { id: 5, item: "Tel: ", content: "" },
        //         { id: 6, item: "E-post: ", content: "" },
        //         { id: 7, item: "Vastuvõtt: ", content: "" },
        //     ],
        //     desc_1: " Arvi on erudeerituim täitur, omades pikaaegset juhtimise tausta. Juhtinud Tallinna täitevosakonda, oli pikalt Kohtutäiturite ja Pankrotihaldurite Koja ametikogu juhatuse liige. Arvi usub tulevikku läbi laste, kuuludes Tallinna Lastehaigla toetusfondi jõudsamate panustajate sekka.",
        //     desc_2: "Arvi valdab vene ja inglise keelt.",
        // },
        {
            id: 5,
            name: `${t("bailiffsListData.rocki.name")}`,
            image: "user_6.png",
            image_full: "user_6_full.png",
            status: "",
            handedTo: "",
            position: `${t("bailiffsListData.rocki.position")}`,
            contacts: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.rocki.contacts.0.item")}`,
                    content: `${t(
                        "bailiffsListData.rocki.contacts.0.content",
                    )}`,
                    icon: "geo.svg",
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.rocki.contacts.1.item")}`,
                    content: "buroo.albert@taitur.net",
                    icon: "mailbox.svg",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.rocki.contacts.2.item")}`,
                    content: "442 99 72",
                    icon: "telephone.svg",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.rocki.contacts.3.item")}`,
                    content: "Pärnu tööpiirkond",
                    icon: "",
                },
            ],
            contacts_full: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.rocki.contacts_full.0.item")}`,
                    content: `${t(
                        "bailiffsListData.rocki.contacts_full.0.content",
                    )}`,
                },
                {
                    id: 10,
                    content: `${t(
                        "bailiffsListData.rocki.contacts_full.1.content",
                    )}`,
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.rocki.contacts_full.2.item")}`,
                    content: "442 9972",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.rocki.contacts_full.3.item")}`,
                    content: "buroo.albert@taitur.net",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.rocki.contacts_full.4.item")}`,
                    content: `${t(
                        "bailiffsListData.rocki.contacts_full.4.content",
                    )}`,
                },
                {
                    id: 5,
                    item: `${t("bailiffsListData.rocki.contacts_full.5.item")}`,
                    content: `${t(
                        "bailiffsListData.rocki.contacts_full.5.content",
                    )}`,
                },
                {
                    id: 7,
                    item: `${t("bailiffsListData.rocki.contacts_full.6.item")}`,
                },
                {
                    id: 8,
                    content: `${t(
                        "bailiffsListData.rocki.contacts_full.7.content",
                    )}`,
                },
                {
                    id: 9,
                    content: `${t(
                        "bailiffsListData.rocki.contacts_full.8.content",
                    )}`,
                },
            ],
            desc_1: `${t("bailiffsListData.rocki.desc_1")}`,
            desc_2: `${t("bailiffsListData.rocki.desc_2")}`,
        },
        {
            id: 6,
            name: `${t("bailiffsListData.anne.name")}`,
            image: "user_7.png",
            image_full: "user_7_full.png",
            status: "",
            handedTo: "",
            position: `${t("bailiffsListData.anne.position")}`,
            contacts: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.anne.contacts.0.item")}`,
                    content: `${t("bailiffsListData.anne.contacts.0.content")}`,
                    icon: "geo.svg",
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.anne.contacts.1.item")}`,
                    content: "buroo.bockler@taitur.net",
                    icon: "mailbox.svg",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.anne.contacts.2.item")}`,
                    content: "742 0486",
                    icon: "telephone.svg",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.anne.contacts.3.item")}`,
                    content: "Tartu tööpiirkond",
                    icon: "",
                },
            ],
            contacts_full: [
                {
                    id: 1,
                    item: `${t("bailiffsListData.anne.contacts_full.0.item")}`,
                    content: `${t(
                        "bailiffsListData.anne.contacts_full.0.content",
                    )}`,
                },
                {
                    id: 9,
                    content: `${t(
                        "bailiffsListData.anne.contacts_full.1.content",
                    )}`,
                },
                {
                    id: 2,
                    item: `${t("bailiffsListData.anne.contacts_full.2.item")}`,
                    content: "742 0486",
                },
                {
                    id: 3,
                    item: `${t("bailiffsListData.anne.contacts_full.3.item")}`,
                    content: "buroo.bockler@taitur.net",
                },
                {
                    id: 4,
                    item: `${t("bailiffsListData.anne.contacts_full.4.item")}`,
                    content: `${t(
                        "bailiffsListData.anne.contacts_full.4.content",
                    )}`,
                },
                {
                    id: 5,
                    item: `${t("bailiffsListData.anne.contacts_full.5.item")}`,
                    content: `${t(
                        "bailiffsListData.anne.contacts_full.5.content",
                    )}`,
                },
                {
                    id: 6,
                    content: `${t(
                        "bailiffsListData.anne.contacts_full.6.content",
                    )}`,
                },
                {
                    id: 7,
                    content: `${t(
                        "bailiffsListData.anne.contacts_full.7.content",
                    )}`,
                },
                {
                    id: 8,
                    content: `${t(
                        "bailiffsListData.anne.contacts_full.8.content",
                    )}`,
                },
            ],
            desc_1: `${t("bailiffsListData.anne.desc_1")}`,
        },
    ];
};
