export const sliderData = {
    slides: [
        {
            id: 1,
            img: "bg_image.jpeg",
        },
        {
            id: 2,
            img: "bg_image2.jpeg",
        },
        {
            id: 3,
            img: "bg_image3.jpeg",
        },
    ],
};
