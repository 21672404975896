import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

interface IUser {
    img: string;
    name: string;
}
export const UserData: React.FC<IUser> = ({ img, name }) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLInputElement>(null);
    const hintRef = useRef<HTMLInputElement>(null);
    const currentDiv = ref.current;
    const hintDiv = hintRef.current;

    const removeBailiffHover = () => {
        if (currentDiv) {
            currentDiv.style.opacity = "30%";
            hintDiv?.classList.remove("hidden");
            hintDiv?.addEventListener("click", function () {
                localStorage.removeItem("bailiff");
                window.location.reload();
            });
        }
    };

    const leaveBailiffHover = () => {
        if (currentDiv) {
            currentDiv.style.opacity = "100%";
            hintDiv?.classList.add("hidden");
            hintDiv?.removeEventListener("click", function () {
                localStorage.clear();
            });
        }
    };

    // useEffect(() => {
    //     removeBailiffHover;
    //     leaveBailiffHover;
    // }, [currentDiv]);

    return (
        <div className="relative">
            <div
                onMouseOver={removeBailiffHover}
                onMouseOut={leaveBailiffHover}
                ref={ref}
                className="relative items-center hidden w-full m-4 cursor-pointer md:flex"
            >
                <img
                    src={require(`@assets/images/${img}`)}
                    alt="Bailiff"
                    className="w-10 h-10 mx-4 rounded-full "
                />
                <div className="flex flex-col justify-around cursor-pointer">
                    <p className="font-semibold">{name}</p>
                    <span className=" text-gray">
                        {t("other.userData.choose")}
                    </span>
                </div>
            </div>
            <div
                ref={hintRef}
                className="absolute hidden p-4 cursor-pointer top-1 right-[30px]"
            >
                <p
                    onMouseOver={removeBailiffHover}
                    onMouseOut={leaveBailiffHover}
                    className="text-lg font-semibold text-center text-secondary "
                >
                    {t("other.userData.deselect")}
                </p>
            </div>
        </div>
    );
};
