import React from "react";
import { Button } from "@components/common";
import { NavLink } from "react-router-dom";

export const Authorization = () => {
    return (
        <div className="flex flex-col items-center justify-center ">
            <p className="mt-12 mb-8 text-2xl font-semibold text-center">
                Sissenõudja portaal hetkel ei tööta
            </p>
            <NavLink to="/">
                <Button className="mb-16">Tagasi avalehele</Button>
            </NavLink>
        </div>
    );
};
