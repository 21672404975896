import React, { useEffect } from "react";

export function useCurrentRef(
    ref: React.RefObject<HTMLDivElement>,
    current?: unknown,
) {
    useEffect(() => {
        if (ref) {
            ref;
        }
    }, [current]);
    return ref;
}
