import React from "react";
import { Input, Tooltip } from "@components/components";
import { SectionContainer } from ".";

interface IItem {
    id: number;
    desc_1?: string;
    desc_2?: string;
    name: string;
    tip?: string;
}
interface IAssets {
    onChange?: (arg0: any) => void;
    title?: string;
    items: IItem[];
}
export const Assets: React.FC<IAssets> = ({ title, items, onChange }) => {
    return (
        <SectionContainer title={title}>
            {items.map((item) => (
                <div className="mb-4 base-border" key={item.id}>
                    <div className="flex items-center">
                        <p className="text-[#4b4b4b] text-lg">{item.desc_1}</p>
                        {item.tip && <Tooltip hint={item.tip} />}
                    </div>

                    {/*<p className="text-[#7d7c7d] mb-2 text-lg">{item.desc_2}</p>*/}

                    <Input
                        type="text"
                        name={item.name}
                        form
                        onChange={onChange}
                        className="text-lg"
                    />
                </div>
            ))}
        </SectionContainer>
    );
};
