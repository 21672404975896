import React from "react";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";

import { formsSidebarData } from "@config/";
import { CircleArrow } from "@assets";

export const MainForms = () => {
    const { t } = useTranslation();
    const selectItemClick = (name: string, title: string) => {
        localStorage.setItem("selectMenuItem", name);
        localStorage.setItem("selectTitleItem", title);
    };
    return (
        <div id="mobil1" className="flex flex-col mx-4 sm:mx-8 lg:mx-0">
            <p className="my-4 md:my-6 text-xl font-semibold">
                {t("navListData.5.content")}
            </p>
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                {formsSidebarData(t).map((item) => (
                    <div key={item.id} className="flex flex-col ">
                        <ul className="pb-4 text-lg font-semibold ">
                            {item.title}
                        </ul>
                        {item.children.map((i) =>
                            i.path.includes("debtor") ? (
                                <li
                                    onClick={() =>
                                        selectItemClick(i.name, i.title)
                                    }
                                    className="h-[90px] cursor-pointer px-4 py-1 text-lg w-fill hover:bg-neutral hover:text-white base-border mb-4 flex !flex-row justify-between items-center"
                                    key={i.id}
                                >
                                    {i.name ? (
                                        <NavHashLink
                                            to={`${i.path}#isTop`}
                                            className="flex items-center justify-between w-[100%]"
                                        >
                                            <span className="flex items-center justify-between text-lg w-[92%]">
                                                {i.title}
                                            </span>
                                            <CircleArrow />
                                        </NavHashLink>
                                    ) : (
                                        <NavHashLink
                                            to={`${i.path}#isTop`}
                                            className="flex items-center justify-between w-[100%]"
                                        >
                                            <span className="flex items-center justify-between text-lg w-[92%]">
                                                {i.title}
                                            </span>
                                            <CircleArrow />
                                        </NavHashLink>
                                    )}
                                </li>
                            ) : (
                                <li
                                    onClick={() =>
                                        selectItemClick(i.name, i.title)
                                    }
                                    className="h-[90px] flex items-center justify-between px-4 py-2 text-lg hover:bg-neutral hover:text-white base-border mb-4 flex !flex-row justify-between"
                                    key={i.id}
                                >
                                    <NavHashLink
                                        to={`${i.path}#isTop`}
                                        className="flex items-center justify-between w-[100%]"
                                    >
                                        <span className="text-lg w-[92%]">
                                            {i.title}
                                        </span>
                                        <CircleArrow />
                                    </NavHashLink>
                                </li>
                            ),
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
