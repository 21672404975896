import React from "react";
import { Textarea } from "@components/components";
import { otherData } from "@config/forms";
import { useTranslation } from "react-i18next";

interface IOther {
    onChange?: (arg0: any) => void;
}

export const Other: React.FC<IOther> = ({ onChange }) => {
    const { t } = useTranslation();
    const { placeholder, name, desc } = otherData(t);
    return (
        <div className="base-border">
            <p className="mb-2 text-lg text-[#4B4B4B] text-lg">{desc}</p>
            <Textarea
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                rows={8}
            />
        </div>
    );
};
