import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const navListData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            content: `${t("navListData.0.content")}`,
            path: AppRoute.CONTACTS,
        },
        {
            id: 2,
            content: `${t("navListData.1.content")}`,
            path: AppRoute.DEBTOR,
            children: [
                {
                    id: 1,
                    path: AppRoute.DEBTOR,
                    content: `${t("navListData.1.children.0.content")}`,
                },
                {
                    id: 2,
                    path: AppRoute.DEBTOR_RIGHTS,
                    content: `${t("navListData.1.children.1.content")}`,
                },
                {
                    id: 3,
                    path: AppRoute.DEBTOR_NOTIFICATION,
                    content: `${t("navListData.1.children.2.content")}`,
                },
                {
                    id: 4,
                    path: AppRoute.DEBTOR_NONSEIZABLE,
                    content: `${t("navListData.1.children.3.content")}`,
                },
                {
                    id: 5,
                    path: AppRoute.DEBTOR_FORMS,
                    content: `${t("navListData.1.children.4.content")}`,
                },
                {
                    id: 6,
                    path: AppRoute.DEBTOR_QUESTIONS,
                    content: `${t("navListData.1.children.5.content")}`,
                },
                // {
                //     id: 7,
                //     path: AppRoute.DEBTOR_QUESTIONS,
                //     content: `${t("navListData.1.children.6.content")}`,
                // },
                // {
                //     id: 8,
                //     path: AppRoute.DEBTOR_FORMS,
                //     content: `${t("navListData.1.children.7.content")}`,
                // },
            ],
        },
        {
            id: 3,
            content: `${t("navListData.2.content")}`,
            path: AppRoute.CLAIMANT,
            children: [
                {
                    path: AppRoute.CLAIMANT,
                    content: `${t("navListData.2.children.0.content")}`,
                },
                {
                    path: AppRoute.CLAIMANT_PORTAL,
                    content: `${t("navListData.2.children.1.content")}`,
                },
                {
                    path: AppRoute.CLAIMANT_HOTLINE,
                    content: `${t("navListData.2.children.2.content")}`,
                },
                {
                    path: AppRoute.CLAIMANT_MAINTENANCE,
                    content: `${t("navListData.2.children.3.content")}`,
                },
                {
                    path: AppRoute.CLAIMANT_FORMS,
                    content: `${t("navListData.2.children.4.content")}`,
                },
                {
                    path: AppRoute.CLAIMANT_APPLYING,
                    content: `${t("navListData.2.children.5.content")}`,
                },
                // {
                //     path: AppRoute.CLAIMANT_TEAM,
                //     content: `${t("navListData.2.children.6.content")}`,
                // },
            ],
        },
        {
            id: 4,
            content: `${t("navListData.3.content")}`,
            path: AppRoute.SERVICES,
        },

        {
            id: 5,
            content: `${t("navListData.4.content")}`,
            path: AppRoute.AUCTION,
        },
        {
            id: 6,
            content: `${t("navListData.5.content")}`,
            path: AppRoute.FORMS,
            children: [
                {
                    id: 1,
                    path: AppRoute.FORMS_DEBTOR,
                    content: `${t("navListData.5.children.0.content")}`,
                },
                {
                    id: 2,
                    path: AppRoute.FORMS_CLAIMANT,
                    content: `${t("navListData.5.children.1.content")}`,
                },
            ],
        },
        {
            id: 7,
            content: `${t("navListData.6.content")}`,
            path: AppRoute.CALCULATOR_SALARY,
            children: [
                {
                    id: 1,
                    path: AppRoute.CALCULATOR_SALARY,
                    content: `${t("navListData.6.children.0.content")}`,
                },
                // {
                //     id: 2,
                //     path: AppRoute.CALCULATOR_ASSETS,
                //     content: "Võlgniku blanketid",
                // },
            ],
        },
    ];
};
