import { TFunction } from "i18next";

export const claimantForm5Data = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("claimantForm5Data.title")}`,
        datepicker: true,
        sections: [
            {
                id: 1,
                title: `${t("claimantForm5Data.sections.0.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "claimant_name",
                        label: `${t(
                            "claimantForm5Data.sections.0.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "claimant_id",
                        label: `${t(
                            "claimantForm5Data.sections.0.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 11,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 3,
                        name: "claimant_address",
                        label: `${t(
                            "claimantForm5Data.sections.0.fields.2.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 4,
                        name: "claimant_phone",
                        label: `${t(
                            "claimantForm5Data.sections.0.fields.3.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 5,
                        name: "claimant_email",
                        label: `${t(
                            "claimantForm5Data.sections.0.fields.4.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                ],
                note: "",
                add_more: false,
                divided_fields: [],
            },
        ],
        paymentData: {
            title: `${t("claimantForm5Data.paymentData.title")}`,
            fields: [
                {
                    id: 1,
                    name: "payment_period",
                    type: "text",
                    label: `${t(
                        "claimantForm5Data.paymentData.fields.0.label",
                    )}`,
                    hint: `${t("claimantForm5Data.paymentData.fields.0.hint")}`,
                    right: false,
                },
                {
                    id: 2,
                    type: "text",
                    name: "payment_sum",
                    label: `${t(
                        "claimantForm5Data.paymentData.fields.1.label",
                    )}`,
                    hint: `${t("claimantForm5Data.paymentData.fields.1.hint")}`,
                    right: true,
                    units: "€",
                    number: true,
                },
                {
                    id: 3,
                    type: "text",
                    name: "payment_full",
                    label: `${t(
                        "claimantForm5Data.paymentData.fields.2.label",
                    )}`,
                    hint: `${t("claimantForm5Data.paymentData.fields.2.hint")}`,
                    right: false,
                    units: "€",
                    number: true,
                },
            ],
        },

        awared: {
            title: `${t("claimantForm5Data.awared.title")}`,
            points: [
                // {
                //     id: 1,
                //     content:
                //         "Kui esitate avalduse elektrooniliselt, siis tuleb dokument allkirjastada digitaalselt.",
                // },
                {
                    id: 2,
                    content: `${t(
                        "claimantForm5Data.awared.points.0.content",
                    )}`,
                    orange: `${t("claimantForm5Data.awared.points.0.orange")}`,
                    endContent: ".",
                },
                {
                    id: 3,
                    content: `${t(
                        "claimantForm5Data.awared.points.1.content",
                    )}`,
                },
            ],
        },
        agreement: {
            title: "",
            points: [
                {
                    id: 1,
                    value: `${t("claimantForm5Data.agreement.points.0.value")}`,
                    name: "agree",
                    defChecked: true,
                },
            ],
        },
    };
};
