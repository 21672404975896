import React, { useEffect, useState } from "react";
import { Input } from "@components/components";
import { salaryData } from "@config/";
import { useTranslation } from "react-i18next";

const initialState = {
    depend: "",
    salary: "",
    non_tax: "",
    discounts: "",
    debtor_income: "",
};

export const Salary = () => {
    const { t } = useTranslation();
    const minimum = "820";
    const subsistence = "338.23";
    const min = 0;
    const max = 20;
    const [resultS, setResultS] = useState({ result: "0", seizedValue: "0" });
    const [percent, setPercent] = useState(20);
    const [state, setState] = useState(initialState);
    const [netIncome, setNetIncome] = useState("");

    const handelChangeState = (
        e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        if (!e) return;
        const { name, value } = e.target;
        setState((prevState) => {
            return { ...prevState, [name]: value };
        });
    };

    const handleChangePercent = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const v = Math.max(min, Math.min(max, Number(event.target.value)));
        setPercent(v);
    };
    const handleSetNetIncome = (
        e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        if (!e) return;
        const { value } = e.target;
        setNetIncome(value);
    };

    const { depend, salary, non_tax, discounts, debtor_income } = state;

    useEffect(() => {
        const c3 = Number(minimum);
        const c4 = Number(subsistence);
        const c5 = Number(percent / 100);
        const d9 = Number(depend);
        const d10 = Number(salary);
        const d11 = Number(non_tax);
        const d12 = Number(discounts);
        const d13 = Number(debtor_income);
        if (d10 !== 0 || d11 !== 0 || d12 !== 0 || d13 !== 0) {
            const sum = d10 + d11 + d12 + d13;
            setNetIncome(sum.toString());
        }
        const d14 = Number(netIncome);
        const d15 = c3 + (d9 / 3) * c3;
        // const d16 = d14 - d15 < 0 ? 0 : d14 - d15;
        const d17 = 5 * c3;
        const d18 = d14 - (d17 + d15) < 0 ? 0 : d14 - (d17 + d15);
        const c19 = d14 < d15 + d17 ? ((d14 - d15) * 2) / 3 : (d17 * 2) / 3;
        const d19 = c19 > 0 ? c19 : 0;
        const c21 = (d14 / 3) * 2;
        const d21 = c21 > 0 ? c21 : 0;
        const d20 = d19 + d18 > d21 ? d21 : d19 + d18;
        const d22 = d21 > d20 ? d20 : d21;
        const c23 =
            d14 < d15
                ? (d14 - c4 - (d9 / 3) * c3) * c5
                : (d15 - c4 - (d9 / 3) * c3) * c5;

        const d23 = c23 < 0 ? 0 : c23;
        const v = (d22 + d23 > d21 ? d22 : d22 + d23).toFixed(2);

        const sV = (d14 <= 438 ? d14 / 3 : d14 - c3 / 2).toFixed(2);

        setResultS({ result: v, seizedValue: sV });
    }, [state, percent, netIncome]);
    const { title, calc } = salaryData(t);
    const { result, seizedValue } = resultS;
    return (
        <div className="flex flex-col justify-center pt-4 pl-6 pr-6 md:pt-6 ">
            <p className="mb-4 md:mb-6 text-xl font-semibold text-left">
                {title}
            </p>
            <div className="flex flex-col w-full p-6 border rounded-md xl:p-10 border-neutral/10">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    {/*<div className="flex flex-col ">*/}
                    {/*    <p className="text-xl font-semibold">{calc.name}</p>*/}
                    {/*    <p className="text-justify">{calc.desc}</p>*/}
                    {/*</div>*/}

                    <Input
                        defaultValue={minimum}
                        type={calc.fields[0].type}
                        label={calc.fields[0].label}
                        name={calc.fields[0].name}
                        key={calc.fields[0].id}
                        gray
                        units="€"
                        number={calc.fields[0].number}
                        disabled
                    />
                    <Input
                        defaultValue={subsistence}
                        type={calc.fields[1].type}
                        label={calc.fields[1].label}
                        name={calc.fields[1].name}
                        key={calc.fields[1].id}
                        gray
                        units="€"
                        number={calc.fields[1].number}
                        disabled
                    />
                    <Input
                        type={calc.fields[3].type}
                        label={calc.fields[3].label}
                        name={calc.fields[3].name}
                        key={calc.fields[3].id}
                        value={depend}
                        units="  "
                        number={calc.fields[3].number}
                        onChange={(e) => handelChangeState(e)}
                    />
                    <Input
                        value={percent}
                        type={calc.fields[2].type}
                        label={calc.fields[2].label}
                        name={calc.fields[2].name}
                        key={calc.fields[2].id}
                        units="%"
                        number={calc.fields[2].number}
                        onChange={handleChangePercent}
                    />
                    <Input
                        value={salary}
                        type={calc.fields[4].type}
                        name={calc.fields[4].name}
                        label={calc.fields[4].label}
                        key={calc.fields[4].id}
                        units="€"
                        number={calc.fields[4].number}
                        onChange={(e) => handelChangeState(e)}
                    />
                    <Input
                        value={non_tax}
                        type={calc.fields[5].type}
                        name={calc.fields[5].name}
                        label={calc.fields[5].label}
                        key={calc.fields[5].id}
                        units="€"
                        number={calc.fields[5].number}
                        onChange={(e) => handelChangeState(e)}
                    />
                    <Input
                        value={discounts}
                        type={calc.fields[6].type}
                        name={calc.fields[6].name}
                        label={calc.fields[6].label}
                        key={calc.fields[6].id}
                        units="€"
                        number={calc.fields[6].number}
                        onChange={(e) => handelChangeState(e)}
                    />
                    <Input
                        value={debtor_income}
                        type={calc.fields[7].type}
                        name={calc.fields[7].name}
                        label={calc.fields[7].label}
                        key={calc.fields[7].id}
                        units="€"
                        number={calc.fields[7].number}
                        onChange={(e) => handelChangeState(e)}
                    />
                    <Input
                        value={netIncome}
                        type={calc.fields[8].type}
                        name={calc.fields[8].name}
                        label={calc.fields[8].label}
                        key={calc.fields[8].id}
                        units="€"
                        number={calc.fields[8].number}
                        onChange={(e) => handleSetNetIncome(e)}
                    />
                </div>

                <div className="w-full mt-8 mb-4 border-t border-neutral/10"></div>
                <div className="bg-lightGray/5 rounded-md py-3">
                    <div className="md:flex md:justify-between w-full md:mb-4 pl-3 pr-6 pb-3 md:pb-0">
                        <p className="text-xs md:text-lg text-[#1A1A1F]/80 font-semibold">
                            {calc.sum}
                        </p>
                        <div className="flex justify-end mt-3 md:mt-0">
                            <p className="mr-2 text-xs md:text-lg font-semibold text-[#1A1A1F]/80">
                                {result}
                            </p>
                            <p className="text-xs md:text-lg text-[#1A1A1F]/80 font-semibold">
                                {" "}
                                €
                            </p>
                        </div>
                    </div>
                    <div className="h-4 w-full bg-white md:hidden" />
                    <div className="md:flex md:justify-between w-full text-[#1A1A1F]/80 pl-3 pr-6 pt-3 md:pt-0">
                        <p className="text-xs md:text-lg text-[#1A1A1F]/80 font-semibold">
                            {calc.seized}
                        </p>
                        <div className="flex justify-end mt-3 md:mt-0">
                            <p className="text-xs md:text-lg mr-2 font-semibold text-[#1A1A1F]/80">
                                {seizedValue}
                            </p>
                            <p className="text-xs md:text-lg text-[#1A1A1F]/80 font-semibold">
                                {" "}
                                €
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
