import React from "react";
import clsx from "clsx";

interface IClick {
    item?: string;
    content?: string;
    id?: number;
    icon?: string;
    full?: boolean;
    isHome?: boolean;
}

export const ClickContacts: React.FC<IClick> = ({ item, content, isHome }) => {
    return (
        <div
            className={clsx(
                "w-full justify-start",
                isHome ? "hidden sm:flex" : "flex",
            )}
        >
            {item === "Telefon:" && (
                <a
                    href={`tel:${content}`}
                    className={"flex-col flex cursor-pointer items-start"}
                >
                    <p className="pr-2 text-darkGray text-lg font-bold">
                        {item}
                    </p>
                    {content && <p className="text-lg">{content}</p>}
                </a>
            )}

            {(item === "E-post:" || item === "E-post") && (
                <a
                    href={`mailTo:${content}`}
                    className={clsx(
                        "flex-col ",
                        "flex  mb-1 cursor-pointer items-start",
                    )}
                >
                    <p className="pr-2 font-bold text-darkGray text-lg">
                        {item}
                    </p>
                    <p className="mb-1 text-lg">{content}</p>
                </a>
            )}

            {item !== "Tel:" &&
                item !== "E-post:" &&
                item !== "E-post" &&
                item !== "Telefon:" &&
                item !== "Region" && (
                    <div className={clsx("flex", "flex-col items-start")}>
                        {item && (
                            <p className="pr-2 font-bold text-darkGray text-lg mt-2">
                                {item}
                            </p>
                        )}
                        <p className="mb-1 text-lg">{content}</p>
                    </div>
                )}
        </div>
    );
};
