import React from "react";
import { SimpleFormsCard } from "@components/pages";
import { formsSidebarData } from "@config/index";
import { useTranslation } from "react-i18next";

export const DebtorForms = () => {
    const { t } = useTranslation();
    const { title, children } = formsSidebarData(t)[0];
    return <SimpleFormsCard title={title} items={children} />;
};
