import React from "react";
import { scheduleData } from "@config/forms";
import {
    Awared,
    HasData,
    Input,
    RadioGroup,
    Section,
    SectionContainer,
    Textarea,
    Tooltip,
} from "@components/components";
import { useTranslation } from "react-i18next";

interface ISchedule {
    onChange?: (arg0: any) => void;
    onCheckboxChange?: (arg0: any) => void;
    checked: boolean[];
    onRadioChange?: (arg0: any) => void;
    radioChecked: boolean[] | undefined;
    addBank?: boolean;
}

export const Schedule: React.FC<ISchedule> = ({
    onChange,
    onRadioChange,
    radioChecked,
    addBank,
}) => {
    const { t } = useTranslation();
    const {
        conditionals,
        banks,
        field,
        claimantHasRepresentative,
        debtorHasRepresentative,
        debtorHasDependent,
        sections,
        note,
        textArea,
    } = scheduleData(t);
    const { label, placeholder, name } = textArea;

    return (
        <>
            <SectionContainer
                title={conditionals.title}
                dark
                tip={conditionals.tip}
            >
                <div className="grid w-full grid-cols-1 gap-4 mb-14 md:grid-cols-2 border rounded-lg p-4 border-zinc-100 ">
                    {conditionals.fields.map((item) => (
                        <Input
                            key={item.id}
                            placeholder={item.placeholder}
                            name={item.name}
                            onChange={onChange}
                            type={item.type}
                            number={item.number}
                            label={item.label}
                            min={item.min}
                            max={item.max}
                            units={item.units}
                        />
                    ))}
                </div>
            </SectionContainer>
            <div className="flex items-center">
                <p className="mb-2 text-lg font-semibold">
                    {t("schedule.non-seizure_title")}
                </p>
                <Tooltip hint={t("schedule.non-seizure_hint")} />
            </div>

            <RadioGroup
                square
                col
                data={banks.values}
                upper={false}
                radioChecked={radioChecked}
                onRadioChange={onRadioChange}
            />

            {addBank && (
                <div className="my-4 base-bord">
                    <Input
                        placeholder={field.placeholder}
                        className="w-1/2"
                        name={field.name}
                        onChange={onChange}
                    />
                </div>
            )}
            <div className="my-6 base-border">
                <div className="flex items-center">
                    <p className="mb-2 text-lg text-[#333333] font-semibold">
                        {t("schedule.monthly-use_title")}
                    </p>
                    <Tooltip hint={t("schedule.monthly-use_hint")} />
                </div>
                <Input
                    className=" w-1/2"
                    name="limit1"
                    onChange={onChange}
                    units="€"
                    number={true}
                />
            </div>

            <HasData
                data={claimantHasRepresentative}
                radioChecked={radioChecked}
                onRadioChange={onRadioChange}
            />

            <HasData
                data={debtorHasRepresentative}
                radioChecked={radioChecked}
                onRadioChange={onRadioChange}
            />
            <HasData
                data={debtorHasDependent}
                radioChecked={radioChecked}
                onRadioChange={onRadioChange}
            />
            {Object(radioChecked).re_dependent ===
                t("scheduleData.debtorHasDependent.values.0.value") && (
                <Section
                    data={sections[0]}
                    key={sections[0].id}
                    onChange={onChange}
                />
            )}
            <div className="base-border mt-4">
                <p className="mb-2 text-lg text-[#333333] text-lg font-semibold">
                    {t("schedule.add-info_title")}
                </p>
                <Textarea
                    label={label}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    rows={8}
                />
            </div>

            {/*<CheckedContainer*/}
            {/*    onChange={onChange}*/}
            {/*    data={schedulePayments}*/}
            {/*    onCheckboxChange={onCheckboxChange}*/}
            {/*    checked={Object(checked)}*/}
            {/*    dark={true}*/}
            {/*/>*/}
            <Awared data={note} upper base dark />
        </>
    );
};
