import { TFunction } from "i18next";

export const rightsData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("rightsData.title")}`,
        desc: `${t("rightsData.desc")}`,
        items: [
            {
                id: 1,
                title: `${t("rightsData.items.0.title")}`,
                title_arrow: true,
                desc_1: `${t("rightsData.items.0.desc_1")}`,
                desc_2: `${t("rightsData.items.0.desc_2")}`,
                desc_3: `${t("rightsData.items.0.desc_3")}`,
                desc_3_bold: `${t("rightsData.items.0.desc_3_bold")}`,
                endOfDesc_3: ".",
            },
            {
                id: 2,
                title_arrow: true,
                title: `${t("rightsData.items.1.title")}`,
                desc_1: `${t("rightsData.items.1.desc_1")}`,
                desc_1_bold: `${t("rightsData.items.1.desc_1_bold")}`,
                endOfDesc_1: `${t("rightsData.items.1.endOfDesc_1")}`,
                desc_2: `${t("rightsData.items.1.desc_2")}`,
                desc_3: `${t("rightsData.items.1.desc_3")}`,
            },
        ],
    };
};
