const AppRoute = {
    ANY: "*",
    HOME: "/",

    DEBTOR: "/debtor",
    DEBTOR_NOTIFICATION: "/debtor/notification",
    DEBTOR_SCHEDULE: "/debtor/schedule",
    DEBTOR_NONSEIZABLE: "/debtor/nonseizable",
    DEBTOR_RIGHTS: "/debtor/rights",
    DEBTOR_ASSETS: "/debtor/assets",
    DEBTOR_QUESTIONS: "/debtor/questions",
    DEBTOR_FORMS: "/debtor/form",

    CLAIMANT: "/claimant",
    CLAIMANT_PORTAL: "/claimant/portal",
    CLAIMANT_INITIATION: "/claimant/initiation",
    CLAIMANT_FORMS: "/claimant/form",
    CLAIMANT_HOTLINE: "/claimant/hotline",
    CLAIMANT_MAINTENANCE: "/claimant/maintenance",
    CLAIMANT_APPLYING: "/claimant/applying",
    CLAIMANT_QUESTIONS: "/claimant/questions",
    CLAIMANT_TEAM: "/claimant/team",

    CALCULATOR: "/calculator",
    CALCULATOR_SALARY: "/calculator/salary",
    // CALCULATOR_ASSETS: "/calculator/assets",

    FORMS: "/forms",
    FORMS_CLAIMANT: "/forms/claimant",

    FORMS_CLAIMANT_FORM_1: "/forms/claimant/form_1",
    FORMS_CLAIMANT_FORM_2: "/forms/claimant/form_2",
    FORMS_CLAIMANT_FORM_3: "/forms/claimant/form_3",
    FORMS_CLAIMANT_FORM_4: "/forms/claimant/form_4",
    FORMS_CLAIMANT_FORM_5: "/forms/claimant/form_5",

    FORMS_DEBTOR: "/forms/debtor",
    FORMS_DEBTOR_FORM_5: "/forms/debtor/form_5",
    FORMS_DEBTOR_FORM_6: "/forms/debtor/form_6",

    SERVICES: "/services",

    AUCTION: "/auction",

    CONTACTS: "/contacts",

    WORK_AREA: "/work_area",

    AUTHORIZATION: "/authorization",

    PDF_DOCUMENT_1: "/pdf_1",
    PDF_DOCUMENT_2: "/pdf_2",
    PDF_DOCUMENT_3: "/pdf_3",
    PDF_DOCUMENT_4: "/pdf_4",
    PDF_DOCUMENT_5: "/pdf_5",
    PDF_DOCUMENT_6: "/pdf_6",
    PDF_DOCUMENT_7: "/pdf_7",

    DIGITAL_SIGN: "/digital_sign",
    SIGNING_PAGE: "/signing_page",

    COOKIES_INFO: "/cookies",
};
export { AppRoute };
