import { ITableForm } from "@types";
import React from "react";
import {
    BailiffData,
    CheckedList,
    DownloadList,
    SimpleTable,
} from "@components/components";
import { useTranslation } from "react-i18next";

export const TableDebtorForm5: React.FC<ITableForm> = ({ id, myRef }) => {
    const { t } = useTranslation();
    const debtorName = localStorage.getItem("debtorName");
    const debtorId = localStorage.getItem("debtorId");
    const debtorAddress = localStorage.getItem("debtorAddress");
    const debtorEmail = localStorage.getItem("debtorEmail");
    const debtorPhone = `+${localStorage.getItem(
        "pre_debtor_phone",
    )} ${localStorage.getItem("debtor_phone")}`;

    const job = localStorage.getItem("job");
    const claims = localStorage.getItem("claims");
    const balance = localStorage.getItem("balance");
    const cars = localStorage.getItem("cars");
    const tech = localStorage.getItem("tech");
    const relative = localStorage.getItem("relative");
    const donate = localStorage.getItem("donate");
    const liabilities = localStorage.getItem("liabilities");

    const point1 = localStorage.getItem("point1");
    const point2 = localStorage.getItem("point2");
    const point3 = localStorage.getItem("point3");
    const agree = localStorage.getItem("agree");

    const current_date = localStorage.getItem("currentDate");
    const case_number = localStorage.getItem("caseNumber");
    return (
        <div className="print-container a4" id={id} ref={myRef}>
            <img
                src={require(`@assets/images/logo_new.png`)}
                alt="Logo"
                className="logo_print block ml-auto lg:w-52 w-36"
            />
            <div className="printElement1">
                <BailiffData />
                <p className="mb-6 font-[500] text-center text-[16px] fontWeight">
                    {t("tableDebtorForm5.title")}
                </p>
                <div className="flex flex-col self-start mb-6">
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.current_date")}:
                        </p>
                        <p className="text-[16px]">{current_date}</p>
                    </div>
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.case_number")}:
                        </p>
                        <p className="text-[16px]">
                            {case_number === "undefined" ? "-" : case_number}
                        </p>
                    </div>
                </div>
                <SimpleTable
                    className="mb-4"
                    title={t("tableDebtorForm5.debtor_info.title")}
                    thead_1={t("tableDebtorForm5.debtor_info.thead_1")}
                    thead_2={t("tableDebtorForm5.debtor_info.thead_2")}
                    thead_3={t("tableDebtorForm5.debtor_info.thead_3")}
                    thead_4={t("tableDebtorForm5.debtor_info.thead_4")}
                    thead_5={t("tableDebtorForm5.debtor_info.thead_5")}
                    tbody_1_1={debtorName || ""}
                    tbody_2_1={debtorId || ""}
                    tbody_3_1={debtorAddress || ""}
                    tbody_4_1={debtorEmail || ""}
                    tbody_5_1={debtorPhone || ""}
                    row={1}
                />

                <p className="mb-2 font-[500] text-[16px] fontWeight">
                    {t("debtorForm5Data.assetsLiabilities.0.title")}
                </p>
                <ul className="mb-4">
                    <li className="my-1">
                        <p className="mb-1 font-[500] text-[16px] fontWeight">
                            {t(
                                "debtorForm5Data.assetsLiabilities.0.items.0.desc_1",
                            )}
                            :
                        </p>
                        <p className="h-12 text-[16px]">
                            {job === "undefined" ? "-" : job}
                        </p>
                    </li>
                    <li className="my-1">
                        <p className="mb-1 font-[500] text-[16px] fontWeight">
                            {t(
                                "debtorForm5Data.assetsLiabilities.0.items.1.desc_1",
                            )}
                            :
                        </p>
                        <p className="h-12 text-[16px]">
                            {claims === "undefined" ? "-" : claims}
                        </p>
                    </li>
                    <li className="my-1">
                        <p className="mb-1 font-[500] text-[16px] fontWeight">
                            {t(
                                "debtorForm5Data.assetsLiabilities.0.items.2.desc_1",
                            )}
                            :
                        </p>
                        <p className="h-12 text-[16px]">
                            {balance === "undefined" ? "-" : balance}
                        </p>
                    </li>
                    <li className="my-1">
                        <p className="mb-1 font-[500] text-[16px] fontWeight">
                            {t(
                                "debtorForm5Data.assetsLiabilities.1.items.0.desc_1",
                            )}
                            :
                        </p>
                        <p className="h-12 text-[16px]">
                            {cars === "undefined" ? "-" : cars}
                        </p>
                    </li>
                    <li className="my-1">
                        <p className="mb-1 font-[500] text-[16px] fontWeight">
                            {t(
                                "debtorForm5Data.assetsLiabilities.1.items.1.desc_1",
                            )}
                            :
                        </p>
                        <p className="h-12 text-[16px]">
                            {tech === "undefined" ? "-" : tech}
                        </p>
                    </li>
                    <li className="my-1">
                        <p className="mb-1 font-[500] text-[16px] fontWeight">
                            {t(
                                "debtorForm5Data.assetsLiabilities.1.items.2.desc_1",
                            )}
                            :
                        </p>
                        <p className="h-12 text-[16px]">
                            {relative === "undefined" ? "-" : relative}
                        </p>
                    </li>
                    <li className="my-1">
                        <p className="mb-1 font-[500] text-[16px] fontWeight">
                            {t(
                                "debtorForm5Data.assetsLiabilities.1.items.3.desc_1",
                            )}
                            :
                        </p>
                        <p className="h-12 text-[16px]">
                            {donate === "undefined" ? "-" : donate}
                        </p>
                    </li>
                    <li className="my-1">
                        <p className="mb-1 font-[500] text-[16px] fontWeight">
                            {t(
                                "debtorForm5Data.assetsLiabilities.1.items.4.desc_1",
                            )}
                            :
                        </p>
                        <p className="h-12 text-[16px]">
                            {liabilities === "undefined" ? "-" : liabilities}
                        </p>
                    </li>
                </ul>
                <p className="mb-2 font-[500] fontWeight">
                    {t("tableDebtorForm5.info_1")}
                </p>
                <p className="mb-4 font-[500] fontWeight">
                    {t("tableDebtorForm5.info_2")}
                </p>
                <DownloadList keyEl="downloadForm_6" className="mb-4" />
                <CheckedList
                    className="mb-4"
                    title=""
                    point_1={t("tableDebtorForm5.checkbox_1")}
                    sub_1={agree === "true"}
                />
                <CheckedList
                    className="mb-4"
                    title=""
                    point_1={t("tableDebtorForm5.checkbox_2")}
                    point_2={t("tableDebtorForm5.checkbox_3")}
                    point_3={t("tableDebtorForm5.checkbox_4")}
                    sub_1={point1 === "true"}
                    sub_2={point2 === "true"}
                    sub_3={point3 === "true"}
                />
                <h3 className="font-[500] text-[16px] text-[#1A1A1F] mb-2 fontWeight">
                    {t("sharedClaimantForm.applicant")}
                    <span className="font-normal text-[16px] text-[#1A1A1F] ml-2">
                        {debtorName === "undefined" ? "-" : debtorName}
                    </span>
                </h3>
                <h3 className="font-[500] text-[16px] text-[#1A1A1F] flex items-end fontWeight">
                    {t("sharedClaimantForm.signature")}
                    <span className="ml-2 h-[1px] w-[150px] bg-black" />
                </h3>
            </div>
        </div>
    );
};
