import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { sliderData } from "@config/home";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";

import { Slide } from ".";

export const OfferSlider = () => {
    const { slides } = sliderData;
    const { t } = useTranslation();

    const [current, setCurrent] = useState(0);

    const ref = useRef<HTMLInputElement>(null);

    const handleSlideChange = (index: number) => {
        setCurrent(Number(index));
    };

    function changeSlides(from: number, to: number) {
        let current = from;

        function go() {
            if (current == to) {
                clearInterval(timerId);
                changeSlides(0, slides.length);
            } else {
                setCurrent(current++);
            }
        }

        go();
        const timerId = setInterval(go, 5000);
    }

    useEffect(() => {
        changeSlides(0, slides.length);
    }, []);

    useEffect(() => {
        if (ref.current) {
            ref.current.classList.add("fade");
        }
    }, [current]);

    return (
        <>
            <div className="relative flex flex-col pr-0 mt-[133px] lg:mt-0 xl:pr-12 ">
                <div className="flex flex-1 w-full ">
                    <div className="absolute left-0 z-10 flex flex-col justify-center p-6 text-white mt-52 md:mt-12 md:p-6 lg:mt-6 lg:p-14 w-[400px] xl:w-[592px] lg:w-[500px] xl:left-20 lg:left-12 right-1/2 bg-neutral">
                        <h1 className="font-semibold">
                            {t("sliderData.title")}
                        </h1>
                        <div className="flex flex-col my-8 xl:flex-row pr-6">
                            <p className="mr-0 text-xl md:text-[20px] opacity-70 md:mr-2">
                                {t("sliderData.desc_1")}
                            </p>
                        </div>
                        <NavHashLink
                            smooth
                            to={`/contacts#isTop`}
                            scroll={(el) =>
                                el.scrollIntoView({
                                    behavior: "auto",
                                    block: "end",
                                })
                            }
                            className="border hover:border-white lg:mr-4 md:px-8 py-2 px-8 font-semibold uppercase rounded-md md:text-[14px] text-[16px] w-fit bg-secondary hover:bg-neutral text-white border-2 border-secondary hover:border-neutral"
                        >
                            {t("sliderData.link")}
                        </NavHashLink>
                    </div>
                    <div
                        ref={ref}
                        className="relative flex flex-col items-end w-full my-0 shrink-0 lg:h-[534px] md:h-[432px]"
                    >
                        <Slide
                            img={slides[current].img}
                            key={slides[current].id}
                        />
                        {/*{banner && <Summa handelClose={onCloseBanner} />}*/}
                    </div>
                </div>

                <div className="absolute flex flex-col items-center mt-8 -ml-8 md:flex-row xl:flex-col md:ml-36 -bottom-28 right-16 md:-bottom-10 lg:bottom-[1rem] xl:bottom-[-7px] xl:right-[63%] w-fit">
                    {slides.map((num, index) => (
                        <div
                            className={clsx(
                                "cursor-pointer w-[6px] h-[6px] rounded-full  md:mx-3 xl:mx-0 mx-0 xl:my-3 my-2  md:my-2  ",
                                index === current
                                    ? "bg-secondary scale-150 transform-gpu opacity-100"
                                    : "opacity-30 bg-neutral",
                            )}
                            onClick={() => handleSlideChange(index)}
                            key={num.id}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
