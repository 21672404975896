import React from "react";
import { useTranslation } from "react-i18next";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";

export const Breadcrumbs = () => {
    const { t } = useTranslation();
    const breadcrumbs = useBreadcrumbs(routes(t));

    return (
        <div className="flex">
            {breadcrumbs.map(({ match, breadcrumb }) => (
                <div className="flex items-center" key={match.pathname}>
                    <p className="px-1 leading-4 text-lg text-[#4b4b4b]">
                        <NavLink to={match.pathname}>{breadcrumb}</NavLink>
                    </p>
                    <p className="text-[#4b4b4b]">/</p>
                </div>
            ))}
        </div>
    );
};
