import { TFunction } from "i18next";

export const claimantForm2Data = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("claimantForm2Data.title")}`,
        datepicker: true,
        sections: [
            {
                id: 1,
                title: `${t("claimantForm2Data.sections.0.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "debtor_name",
                        label: `${t(
                            "claimantForm2Data.sections.0.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "debtor_id",
                        label: `${t(
                            "claimantForm2Data.sections.0.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 8,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 3,
                        name: "debtor_address",
                        label: `${t(
                            "claimantForm2Data.sections.0.fields.2.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 4,
                        name: "debtor_email",
                        label: `${t(
                            "claimantForm2Data.sections.0.fields.3.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 5,
                        name: "debtor_phone",
                        label: `${t(
                            "claimantForm2Data.sections.0.fields.4.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                    },
                ],
                note: "",
                add_more: false,
            },
            {
                id: 2,
                title: `${t("claimantForm2Data.sections.1.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "debtor_name_1",
                        label: `${t(
                            "claimantForm2Data.sections.1.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "debtor_id_1",
                        label: `${t(
                            "claimantForm2Data.sections.1.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 8,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                ],
                note: "",
                add_more: false,
                divided_fields: [],
            },
        ],
        download: {
            title: `${t("claimantForm2Data.download.title")}`,
            divided_fields: [
                {
                    id: 1,
                    elem: `${t(
                        "claimantForm2Data.download.divided_fields.0.elem",
                    )}`,
                    multiple: true,
                    type: "file",
                    name: "download_1",
                    placeholder: `${t(
                        "claimantForm2Data.download.divided_fields.0.placeholder",
                    )}`,
                },
            ],
        },
        points: [
            {
                id: 1,
                content: `${t("claimantForm2Data.points.0.content")}`,
            },
            {
                id: 2,
                content: `${t("claimantForm2Data.points.1.content")}`,
            },
        ],

        email: {
            title: `${t("claimantForm2Data.email.title")}`,
            placeholder: "https://",
            name: "email",
            type: "email",
        },
        agreement: {
            title: "",
            points: [
                {
                    id: 1,
                    value: `${t("claimantForm2Data.agreement.points.0.value")}`,
                    name: "agree",
                },
            ],
        },
    };
};
