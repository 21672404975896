import React from "react";
import { nonSeizableData } from "@config/debtor";
import { DebtorMain } from "@components/debtor";
import { useTranslation } from "react-i18next";

export const NonSeizable = () => {
    const { t } = useTranslation();
    const {
        title,
        desc_1,
        desc_2,
        desc_3,
        desc_4,
        endOfDesc_4,
        path_4,
        name_4,
        path_name_4,
        desc_5,
        endOfDesc_5,
        path_5,
        path_name_5,
        name_5,
        path_url_5,
    } = nonSeizableData(t);
    return (
        <DebtorMain
            title={title}
            desc_1={desc_1}
            desc_2={desc_2}
            desc_3={desc_3}
            desc_4={desc_4}
            name_4={name_4}
            endOfDesc_4={endOfDesc_4}
            path_4={path_4}
            path_name_4={path_name_4}
            desc_5={desc_5}
            endOfDesc_5={endOfDesc_5}
            path_5={path_5}
            path_name_5={path_name_5}
            name_5={name_5}
            path_url_5={path_url_5}
        />
    );
};
