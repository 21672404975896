import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { NavHashLink } from "react-router-hash-link";

interface ISimpleCard {
    key: number;
    children: React.ReactNode;
    pay?: boolean;
    path: string;
    name?: string;
}
export const SimpleCard: React.FC<ISimpleCard> = ({
    children,
    pay,
    path,
    name,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = () => {
        const localUrl = path.split("/")[1];
        navigate(`${path}`);
        localStorage.setItem("url", `/${localUrl}`);
        if (name) localStorage.setItem("selectQuestion", name);
        if (name) localStorage.setItem("selectMenuItem", name);
        scrollUp();
    };

    const scrollUp = (): void => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className="flex flex-col items-center justify-center m-2 bg-white border rounded-md border-neutral/10">
            {children}
            <div className="flex items-center justify-center flex-1 w-full py-4 bg-primary">
                {pay ? (
                    <div className="flex items-center justify-center flex-1 divide-x divide-neutral/10 ">
                        <div className="flex items-center justify-center w-1/2 px-4">
                            <Link to="/" className="font-semibold">
                                <img
                                    src={require("@assets/images/luminor.webp")}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="flex items-center justify-center w-1/2 px-4">
                            <Link to="/" className="font-semibold">
                                <img
                                    src={require("@assets/images/swedbank.webp")}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-center flex-1">
                        <NavHashLink
                            smooth
                            to={`${path}#isTop`}
                            scroll={(el) =>
                                el.scrollIntoView({
                                    behavior: "auto",
                                    block: "end",
                                })
                            }
                            className="py-2  md:px-8 py-2 px-8 font-semibold uppercase rounded-md md:text-[14px] text-[16px] w-fit bg-secondary hover:bg-neutral text-white border-2 border-secondary hover:border-neutral"
                            onClick={handleClick}
                        >
                            {t("other.bailiffFullCard.link")}
                        </NavHashLink>
                    </div>
                )}
            </div>
        </div>
    );
};
