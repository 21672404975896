import React, { useEffect, useRef, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import { useCurrentRef } from "@hooks";
import clsx from "clsx";
interface IBase {
    animate?: string;
    children: ReactNode;
    arrows?: boolean;
    pagination?: boolean;
    className?: string;
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    totalPages: number;
    totalItems?: number;
    full?: boolean;
}

export const BaseSlider: React.FC<IBase> = ({
    children,
    arrows,
    pagination,
    current,
    setCurrent,
    totalPages,
    full,
}) => {
    const ref = useRef<HTMLInputElement>(null);
    const [end, setEnd] = useState(false);
    const { t } = useTranslation();

    const handleNextClick = () => {
        const k = totalPages % 2 === 0 ? 1 : 0;
        if (!full) {
            if (current + 1 > totalPages - k) return;
            setCurrent(current + 1);
        } else {
            if (current > totalPages - 2) {
                setEnd(true);
                return;
            } else setCurrent(current + 1);
        }
    };

    const curRef = useCurrentRef(ref, current);

    const handlePrevClick = () => {
        if (current === 0) return;
        setCurrent(current - 1);
    };
    useEffect(() => {
        if (curRef.current) {
            curRef.current.style.transition = "all 0.2s ease-in-out";
            curRef.current.style.transform = `translateX(-${current}00%)`;
        }
    }, [current]);

    return (
        <>
            {arrows && (
                <div className="flex items-center self-end m-4 cursor-pointer ">
                    <button
                        onClick={handlePrevClick}
                        className={clsx(
                            "mr-2 text-4xl hover:text-secondary",
                            current > totalPages - 2 &&
                                "text-secondary color-secondary",
                        )}
                        style={end ? { color: "red" } : {}}
                    >
                        <HiArrowNarrowLeft />
                    </button>

                    <button
                        onClick={handleNextClick}
                        className={clsx(
                            "text-4xl hover:text-secondary",
                            end && "!text-secondary color-secondary",
                        )}
                    >
                        <HiArrowNarrowRight />
                    </button>
                </div>
            )}
            {pagination && (
                <div className="flex items-center justify-end  md:justify-center w-full mt-9 mb-7 md:mb-0 md:mt-0 md:justify-between md:w-64">
                    <button
                        onClick={handlePrevClick}
                        className="mx-2 mb-[2px] text-lg hover:text-secondary flex items-center"
                    >
                        {t("other.buttons.prev")}
                        <HiArrowNarrowLeft className="ml-2 w-[24px] h-[24px]" />
                    </button>
                    <button
                        onClick={handleNextClick}
                        className="mx-2 mb-[2px] text-lg hover:text-secondary flex items-center"
                    >
                        <HiArrowNarrowRight className="mr-2 w-[24px] h-[24px]" />
                        {t("other.buttons.next")}
                    </button>
                </div>
            )}
            <div className="overflow-x-auto md:overflow-hidden">
                <div
                    className={clsx(
                        "flex",
                        pagination && "md:w-[400px] lg:w-[440px] xl:w-[880px]",
                    )}
                    ref={ref}
                >
                    {children}
                </div>
            </div>
            {full && (
                <div
                    id="arr"
                    className="absolute -order-1 self-end w-fit flex items-center m-4 cursor-pointer top-[-10px] right-[1rem] lg:left-0 xl:left-12 lg:bottom-[-20rem] lg:bottom-[-30rem]"
                >
                    <button
                        onClick={handlePrevClick}
                        className={clsx(
                            "mr-2 text-4xl hover:text-secondary",
                            current === 0 && "text-secondary color-secondary",
                        )}
                    >
                        <HiArrowNarrowLeft />
                    </button>

                    <button
                        onClick={handleNextClick}
                        className={clsx(
                            "text-4xl hover:text-secondary",
                            current > totalPages - 2 &&
                                "text-secondary color-secondary",
                        )}
                    >
                        <HiArrowNarrowRight />
                    </button>
                </div>
            )}
        </>
    );
};
