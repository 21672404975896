import { ITableForm } from "@types";
import React from "react";
import {
    BailiffData,
    CheckedList,
    DataTable,
    DownloadList,
    SimpleTable,
} from "@components/components";
import { useTranslation } from "react-i18next";

export const TableClaimantForm5: React.FC<ITableForm> = ({ id, myRef }) => {
    const { t } = useTranslation();
    const claimantName = localStorage.getItem("claimantName");
    const claimantId = localStorage.getItem("claimantId");
    const claimantAddress = localStorage.getItem("claimantAddress");
    const claimantPost = localStorage.getItem("claimantPost");

    const claimantPhone = `+${localStorage.getItem(
        "pre_claimant_phone",
    )} ${localStorage.getItem("claimant_phone")}`;

    const payment_period = localStorage.getItem("paymentPeriod");
    const payment_sum = localStorage.getItem("paymentSum");
    const payment_full = localStorage.getItem("paymentFull");

    const agree = localStorage.getItem("agree");

    const current_date = localStorage.getItem("currentDate");
    const case_number = localStorage.getItem("caseNumber");
    return (
        <div className="print-container a4" id={id} ref={myRef}>
            <img
                src={require(`@assets/images/logo_new.png`)}
                alt="Logo"
                className="logo_print block ml-auto lg:w-52 w-36"
            />
            <div className="printElement1">
                <BailiffData />
                <p className="mb-6 font-[500] text-center text-[16px] fontWeight">
                    {t("tableClaimantForm5.title")}
                </p>
                <div className="flex flex-col self-start mb-6">
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.current_date")}:
                        </p>
                        <p className="text-[16px]">{current_date}</p>
                    </div>
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.case_number")}:
                        </p>
                        <p className="text-[16px]">
                            {case_number === "undefined" ? "-" : case_number}
                        </p>
                    </div>
                </div>

                <DataTable
                    title={t("tableClaimantForm5.submitter_title")}
                    name={claimantName || ""}
                    id={claimantId || ""}
                    address={claimantAddress || ""}
                    phone={claimantPhone || ""}
                    email={claimantPost || ""}
                />

                <SimpleTable
                    className="mb-4"
                    title={t("tableClaimantForm5.unpaid_part.title")}
                    thead_1={t("tableClaimantForm5.unpaid_part.thead_1")}
                    thead_2={t("tableClaimantForm5.unpaid_part.thead_2")}
                    thead_3={t("tableClaimantForm5.unpaid_part.thead_3")}
                    tbody_1_1={payment_period || ""}
                    tbody_2_1={payment_sum || ""}
                    tbody_3_1={payment_full || ""}
                    row={1}
                />
                <DownloadList keyEl="downloadForm_5" className="mb-4" />
                <CheckedList
                    title=""
                    className="mb-4"
                    point_1={t("sharedClaimantForm.checklist")}
                    sub_1={agree === "true"}
                />
                <p className="font-[500] text-[16px] text-[#1A1A1F] mb-2 fontWeight">
                    {t("sharedClaimantForm.applicant")}
                    <span className="font-normal text-[16px] text-[#1A1A1F] ml-2">
                        {claimantName === "undefined" ? "-" : claimantName}
                    </span>
                </p>
                <p className="font-[500] text-[16px] text-[#1A1A1F] flex items-end fontWeight">
                    {t("sharedClaimantForm.signature")}
                    <span className="ml-2 h-[1px] w-[150px] bg-black" />
                </p>
            </div>
        </div>
    );
};
