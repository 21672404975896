import React from "react";
import { newsData } from "@config/home";
import { NewsCard } from "@components/components";
import { useTranslation } from "react-i18next";

export const News = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col mb-10">
            <div className="flex flex-col justify-between mx-8 md:mx-12 md:items-center md:flex-row">
                <div className="flex flex-col ">
                    <h2 className="mb-4 font-semibold text-left">
                        {t("news.title")}
                    </h2>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-3 mx-4 md:mx-8 md:grid-cols-2">
                {newsData(t).map((item) => (
                    <NewsCard
                        status={item.status}
                        title={item.title}
                        date={item.date}
                        key={item.id}
                        path={item.path}
                    />
                ))}
            </div>
        </div>
    );
};
