import React from "react";
import clsx from "clsx";

interface IButton {
    children?: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    type?: "button" | "submit";
    outline?: boolean;
    ghost?: boolean;
    white?: boolean;
    small?: boolean;
}

export const Button: React.FC<IButton> = ({
    onClick,
    disabled,
    children,
    className,
    type = "button",
    outline = false,
    ghost = false,
    white = false,
    small = false,
}) => {
    return (
        <button
            type={type}
            disabled={disabled}
            onClick={onClick}
            className={clsx(
                className,
                "lg:mr-4 md:px-8 py-2 px-8 font-semibold uppercase rounded-md md:text-[14px] text-[16px] w-fit bg-secondary hover:bg-neutral text-white border-2 border-secondary hover:border-neutral",
                outline &&
                    "bg-transparent hover:bg-secondary  hover:!border-secondary border-2 border-secondary !text-secondary hover:!text-white ",
                disabled &&
                    !outline &&
                    "bg-secondary/50 cursor-default hover:bg-secondary/50 border-0",
                disabled &&
                    outline &&
                    "!bg-transparent cursor-default  hover:!text-lightGray hover:!bg-transparent !border-lightGray hover:border-gray !text-lightGray ",
                ghost &&
                    "bg-transparent  hover:bg-transparent hover:!text-neutral hover:border-secondary border-2 border-transparent !text-lightGray",

                white &&
                    " bg-white !text-neutral hover:bg-transparent hover:border-2 hover:border-white hover:!text-white hover:border-secondary",
                small && "!px-4 !py-2 capitalize font-normal",
            )}
        >
            {children}
        </button>
    );
};