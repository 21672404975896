import { TFunction } from "i18next";

export const claimantForm3Data = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("claimantForm3Data.title")}`,
        datepicker: true,
        sections: [
            {
                id: 1,
                title: `${t("claimantForm3Data.sections.0.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "claimant_name",
                        label: `${t(
                            "claimantForm3Data.sections.0.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "claimant_id",
                        label: `${t(
                            "claimantForm3Data.sections.0.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 11,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 3,
                        name: "claimant_address",
                        label: `${t(
                            "claimantForm3Data.sections.0.fields.2.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                ],
                note: "",
                add_more: false,
                divided_fields: [],
            },
            {
                id: 2,
                title: `${t("claimantForm3Data.sections.1.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "re_claimant_name",
                        label: `${t(
                            "claimantForm3Data.sections.1.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "re_claimant_id",
                        label: `${t(
                            "claimantForm3Data.sections.1.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 11,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 3,
                        name: "re_claimant_address",
                        label: `${t(
                            "claimantForm3Data.sections.1.fields.2.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 4,
                        name: "re_claimant_phone",
                        label: `${t(
                            "claimantForm3Data.sections.1.fields.3.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 5,
                        name: "re_claimant_email",
                        label: `${t(
                            "claimantForm3Data.sections.1.fields.4.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                ],
                note: "",
                add_more: false,
            },
            {
                id: 3,
                title: `${t("claimantForm3Data.sections.2.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "debtor_name",
                        label: `${t(
                            "claimantForm3Data.sections.2.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "debtor_id",
                        label: `${t(
                            "claimantForm3Data.sections.2.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 11,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                ],
                note: "",
                add_more: false,
            },
        ],
        download: {
            title: `${t("claimantForm3Data.download.title")}`,
            divided_fields: [
                {
                    id: 1,
                    elem: `${t(
                        "claimantForm3Data.download.divided_fields.0.elem",
                    )}`,
                    multiple: true,
                    type: "file",
                    name: "download_1",
                    placeholder: `${t(
                        "claimantForm3Data.download.divided_fields.0.placeholder",
                    )}`,
                },
            ],
        },
        permits_suspensions: {
            title: `${t("claimantForm3Data.permits_suspensions.title")}`,
            points: [
                {
                    id: 1,
                    value: `${t(
                        "claimantForm3Data.permits_suspensions.points.0.value",
                    )}`,
                    name: "point1",
                },
                {
                    id: 2,
                    value: `${t(
                        "claimantForm3Data.permits_suspensions.points.1.value",
                    )}`,
                    name: "point2",
                },
                {
                    id: 3,
                    value: `${t(
                        "claimantForm3Data.permits_suspensions.points.2.value",
                    )}`,
                    name: "point3",
                },
                {
                    id: 4,
                    value: `${t(
                        "claimantForm3Data.permits_suspensions.points.3.value",
                    )}`,
                    name: "point4",
                },
                {
                    id: 5,
                    value: `${t(
                        "claimantForm3Data.permits_suspensions.points.4.value",
                    )}`,
                    name: "point5",
                },
            ],
        },
        invalidate_suspensions: {
            title: `${t("claimantForm3Data.invalidate_suspensions.title")}`,
            points: [
                {
                    id: 6,
                    value: `${t(
                        "claimantForm3Data.invalidate_suspensions.points.0.value",
                    )}`,
                    name: "voice1",
                },
                {
                    id: 7,
                    value: `${t(
                        "claimantForm3Data.invalidate_suspensions.points.1.value",
                    )}`,
                    name: "voice2",
                },
                {
                    id: 8,
                    value: `${t(
                        "claimantForm3Data.invalidate_suspensions.points.2.value",
                    )}`,
                    name: "voice3",
                },
                {
                    id: 9,
                    value: `${t(
                        "claimantForm3Data.invalidate_suspensions.points.3.value",
                    )}`,
                    name: "voice4",
                },
                {
                    id: 10,
                    value: `${t(
                        "claimantForm3Data.invalidate_suspensions.points.4.value",
                    )}`,
                    name: "voice5",
                },
                {
                    id: 11,
                    value: `${t(
                        "claimantForm3Data.invalidate_suspensions.points.5.value",
                    )}`,
                    name: "voice6",
                },
                {
                    id: 12,
                    value: `${t(
                        "claimantForm3Data.invalidate_suspensions.points.6.value",
                    )}`,
                    name: "voice7",
                },
            ],
        },

        debt_amount: {
            id: 1,
            title: `${t("claimantForm3Data.debt_amount.title")}`,
            elem: "",
            placeholder: "",
            // placeholder: "€",
            type: "text",
            name: "debtor_sum",
            number: true,
        },
        awared: {
            title: `${t("claimantForm3Data.awared.title")}`,
            points: [
                {
                    id: 1,
                    content: `${t(
                        "claimantForm3Data.awared.points.0.content",
                    )}`,
                },
            ],
        },
        agreement: {
            title: "",
            points: [
                {
                    id: 1,
                    value: `${t(
                        "claimantForm3Data.agreement.points.0.content",
                    )}`,
                    name: "agree",
                },
            ],
        },
    };
};
