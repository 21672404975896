import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const servicesListData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 2,
            title: `${t("servicesList.servicesListData.0.title")}`,
            pretitle: `${t("servicesList.servicesListData.0.pretitle")}`,
            content: `${t("servicesList.servicesListData.0.content")}`,
        },
        {
            id: 3,
            pretitle: `${t("servicesList.servicesListData.1.pretitle")}`,
            content: `${t("servicesList.servicesListData.1.content")}`,
        },
        {
            id: 4,
            pretitle: `${t("servicesList.servicesListData.2.pretitle")}`,
            content: `${t("servicesList.servicesListData.2.content")}`,
            content_2: `${t("servicesList.servicesListData.2.content_2")}`,
            link_2: "https://www.oksjonikeskus.ee",
            endOfContent_2: ".",
        },
        {
            id: 5,
            pretitle: `${t("servicesList.servicesListData.3.pretitle")}`,
            content: `${t("servicesList.servicesListData.3.content")}`,
            content_4: `${t("servicesList.servicesListData.3.content_4")}`,
            path_4: AppRoute.CONTACTS,
            path_url_4: "/contacts",
            name_4: "",
            path_name_4: `${t("servicesList.servicesListData.3.path_name_4")}`,
            endOfContent_4: ".",
        },
    ];
};
