import { TFunction } from "i18next";

export const cookiesData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("cookiesData.title")}`,
        items: [
            {
                id: 1,
                title: `${t("cookiesData.items.0.title")}`,
                desc: `${t("cookiesData.items.0.desc")}`,
                desc_2: `${t("cookiesData.items.0.desc_2")}`,
                desc_3: `${t("cookiesData.items.0.desc_3")}`,
            },
            {
                id: 2,
                title: `${t("cookiesData.items.1.title")}`,
                desc: `${t("cookiesData.items.1.desc")}`,
                desc_2: `${t("cookiesData.items.1.desc_2")}`,
                desc_3: `${t("cookiesData.items.1.desc_3")}`,
                desc_4: `${t("cookiesData.items.1.desc_4")}`,
                desc_5: `${t("cookiesData.items.1.desc_5")}`,
            },
            {
                id: 3,
                title: "",
                desc: `${t("cookiesData.items.2.desc")}`,
                desc_2: `${t("cookiesData.items.2.desc_2")}`,
                desc_3: `${t("cookiesData.items.2.desc_3")}`,
                desc_4: `${t("cookiesData.items.2.desc_4")}`,
            },
            {
                id: 4,
                title: `${t("cookiesData.items.3.title")}`,
                desc: `${t("cookiesData.items.3.desc")}`,
            },
            {
                id: 5,
                title: `${t("cookiesData.items.4.title")}`,
                desc: `${t("cookiesData.items.4.desc")}`,
                desc_2: `${t("cookiesData.items.4.desc_2")}`,
            },
            {
                id: 6,
                title: `${t("cookiesData.items.5.title")}`,
                desc: `${t("cookiesData.items.5.desc")}`,
            },
            {
                id: 7,
                title: `${t("cookiesData.items.6.title")}`,
                desc: `${t("cookiesData.items.6.desc")}`,
                desc_2: `${t("cookiesData.items.6.desc_2")}`,
            },
            {
                id: 8,
                title: `${t("cookiesData.items.7.title")}`,
                desc: `${t("cookiesData.items.7.desc")}`,
            },
        ],
    };
};
