import React from "react";

export const ClaimIcon5 = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.13788 0H12.8534C16.0668 0 17.9913 1.95143 18 5.20971V14.7903C18 18.0486 16.0842 20 12.8621 20H10.0058C9.6395 19.959 9.36224 19.6452 9.36224 19.2715C9.36224 18.8978 9.6395 18.584 10.0058 18.543H12.8534C15.3091 18.543 16.5457 17.2804 16.5457 14.7903V5.20971C16.5457 2.71965 15.3091 1.45695 12.8534 1.45695H5.13788C2.68215 1.45695 1.43686 2.71965 1.43686 5.20971V14.7903C1.43686 17.2804 2.68215 18.543 5.13788 18.543C5.50419 18.584 5.78145 18.8978 5.78145 19.2715C5.78145 19.6452 5.50419 19.959 5.13788 20C1.92453 20 0 18.0486 0 14.7903V5.20971C0 1.9426 1.92453 0 5.13788 0ZM5.39042 6.4989H8.13353C8.49983 6.45794 8.7771 6.1441 8.7771 5.77042C8.7771 5.39674 8.49983 5.0829 8.13353 5.04194H5.39042C5.02411 5.0829 4.74685 5.39674 4.74685 5.77042C4.74685 6.1441 5.02411 6.45794 5.39042 6.4989ZM5.39032 10.7285H12.5921C12.8676 10.7593 13.1363 10.6276 13.2836 10.3894C13.4309 10.1513 13.4309 9.8487 13.2836 9.61057C13.1363 9.37243 12.8676 9.24071 12.5921 9.27152H5.38161C5.0153 9.31247 4.73804 9.62632 4.73804 10C4.73804 10.3737 5.0153 10.6875 5.38161 10.7285H5.39032Z"
                fill="white"
            />
        </svg>
    );
};
