import { TFunction } from "i18next";

export const contactFormData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            label: `${t("contactForm.contactFormData.0.label")}`,
            placeholder: " ",
            name: "name_form",
            type: "text",
            required: true,
            minLength: 3,
        },
        {
            id: 2,
            label: "Teema",
            placeholder: " ",
            name: "topic_form",
            required: true,
            options: [
                {
                    id: 1,
                    value: `${t(
                        "contactForm.contactFormData.1.options.0.value",
                    )}`,
                },
                {
                    id: 2,
                    value: `${t(
                        "contactForm.contactFormData.1.options.1.value",
                    )}`,
                },
                {
                    id: 3,
                    value: `${t(
                        "contactForm.contactFormData.1.options.2.value",
                    )}`,
                },
                {
                    id: 4,
                    value: `${t(
                        "contactForm.contactFormData.1.options.3.value",
                    )}`,
                },
                {
                    id: 5,
                    value: `${t(
                        "contactForm.contactFormData.1.options.4.value",
                    )}`,
                },
                {
                    id: 6,
                    value: `${t(
                        "contactForm.contactFormData.1.options.5.value",
                    )}`,
                },
                {
                    id: 7,
                    value: `${t(
                        "contactForm.contactFormData.1.options.6.value",
                    )}`,
                },
            ],
        },
        {
            id: 3,
            label: `${t("contactForm.contactFormData.2.label")}`,
            placeholder: " ",
            name: "email_form",
            type: "email",
            required: true,
        },
        {
            id: 4,
            label: `${t("contactForm.contactFormData.3.label")}`,
            placeholder: "",
            name: "phone_form",
            type: "tel",
            required: true,
            minLength: 12,
        },
        {
            id: 5,
            label: `${t("contactForm.contactFormData.4.label")}`,
            placeholder: " ",
            name: "message_form",
            type: "textarea",
        },
    ];
};
export const checkRoleData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            value: `${t("contactForm.checkRoleData.0.value")}`,
            name: "role_form",
        },
        {
            id: 2,
            value: `${t("contactForm.checkRoleData.1.value")}`,
            name: "role_form",
        },
        {
            id: 3,
            value: `${t("contactForm.checkRoleData.2.value")}`,
            name: "role_form",
        },
    ];
};
