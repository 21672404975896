import React from "react";
import { BailiffCard } from "@components/home";
import { bailiffsListData } from "@config/home";
import { useTranslation } from "react-i18next";

export const Team = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col mt-0 ml-6 mr-6 md:mt-6 md:ml-0 md:mr-0">
            <p className="mb-4 md:mb-6 text-xl font-semibold">
                {t("bailiffsListData.title")}
            </p>
            <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2 lg:w-3/4 lg:grid-cols-1 ">
                {bailiffsListData(t).map((item) => (
                    <div
                        className="my-2 border rounded border-neutral/10"
                        key={item.id}
                    >
                        <BailiffCard
                            id={item.id}
                            key={item.id}
                            name={item.name}
                            image={item.image}
                            status={item.status}
                            handedTo={item.handedTo}
                            position={item.position}
                            contacts={item.contacts}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
