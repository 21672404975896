import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { checkedState, initialState } from "state";
import { useFullForm } from "@hooks";

import { AppRoute } from "@enums";
import {
    Awared,
    Checkbox,
    CheckedContainer,
    DebtAmount,
    Download,
    FormFooter,
    FormsMain,
    Section,
} from "@components/components";
import { claimantForm3Data } from "@config/forms";

export const ClaimantForm3 = () => {
    const { t } = useTranslation();
    const [validForm, setValidForm] = useState(false);
    const [name, setName] = useState<string>("");
    const {
        title,
        sections,
        permits_suspensions,
        invalidate_suspensions,
        debt_amount,
        awared,
        download,
    } = claimantForm3Data(t);
    const { onChange, onSubmit, values, onCheckboxChange, checked } =
        useFullForm(formCallback, initialState, checkedState);
    const [checkedArr, setCheckedArr] = useState({ agree: true });
    const onHandelCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCheckedArr({
            ...checkedArr,
            [event.target.name]: event.target.checked,
        });
    };

    useEffect(() => {
        localStorage.setItem("selected_titles_arr", JSON.stringify([title]));
        localStorage.setItem("selectTitleItem", title);
    }, []);

    async function formCallback() {
        console.log("values");
        console.log("checked");
    }

    useEffect(() => {
        localStorage.setItem("claimantName", Object(values).claimant_name);
        localStorage.setItem("claimantId", Object(values).claimant_id);
        localStorage.setItem(
            "claimantAddress",
            Object(values).claimant_address,
        );

        localStorage.setItem("reClaimantName", Object(values).re_claimant_name);
        localStorage.setItem("reClaimantId", Object(values).re_claimant_id);
        localStorage.setItem(
            "reClaimantAddress",
            Object(values).re_claimant_address,
        );
        localStorage.setItem(
            "reClaimantEmail",
            Object(values).re_claimant_email,
        );

        localStorage.setItem(
            "re_claimant_phone",
            Object(values).re_claimant_phone,
        );
        localStorage.setItem(
            "pre_re_claimant_phone",
            Object(values).pre_re_claimant_phone,
        );

        localStorage.setItem("reDebtorName", Object(values).re_debtor_name);
        localStorage.setItem("reDebtorId", Object(values).re_debtor_id);
        localStorage.setItem(
            "reDebtorAddress",
            Object(values).re_debtor_address,
        );
        localStorage.setItem("reDebtorEmail", Object(values).re_debtor_email);
        setName(Object(values).debtor_name);
        localStorage.setItem("debtorName", Object(values).debtor_name);
        localStorage.setItem("debtorId", Object(values).debtor_id);

        localStorage.setItem("debtorSum", Object(values).debtor_sum);
        localStorage.setItem("caseNumber", Object(values).case_number);
    }, [values]);

    useEffect(() => {
        localStorage.setItem("point1", Object(checked).point1);
        localStorage.setItem("point2", Object(checked).point2);
        localStorage.setItem("point3", Object(checked).point3);
        localStorage.setItem("point4", Object(checked).point4);
        localStorage.setItem("point5", Object(checked).point5);
        localStorage.setItem("voice1", Object(checked).voice1);
        localStorage.setItem("voice2", Object(checked).voice2);
        localStorage.setItem("voice3", Object(checked).voice3);
        localStorage.setItem("voice4", Object(checked).voice4);
        localStorage.setItem("voice5", Object(checked).voice5);
        localStorage.setItem("voice6", Object(checked).voice6);
        localStorage.setItem("voice7", Object(checked).voice7);
    }, [checked]);
    useEffect(() => {
        localStorage.setItem("agree", JSON.stringify(checkedArr.agree));
    }, [checkedArr]);

    const validationSchema = Yup.object().shape(
        {
            claimantName: Yup.string().required("Name is required"),
            claimantId: Yup.string().min(11).max(11).required("Id is required"),
            claimantAddress: Yup.string().required("Address is required"),
            reClaimantName: Yup.string().required("Name is required"),
            reClaimantId: Yup.string()
                .min(11)
                .max(11)
                .required("Id is required"),
            reClaimantAddress: Yup.string().required("Address is required"),
            reClaimantEmail: Yup.string().email().required("Email is required"),
            debtorName: Yup.string().required("Name is required"),
            debtorId: Yup.string().min(11).max(11).required("Id is required"),
        },
        [],
    );
    useEffect(() => {
        validationSchema
            .isValid({
                claimantName: Object(values).claimant_name,
                claimantId: Object(values).claimant_id,
                claimantAddress: Object(values).claimant_address,
                reClaimantName: Object(values).re_claimant_name,
                reClaimantId: Object(values).re_claimant_id,
                reClaimantAddress: Object(values).re_claimant_address,
                reClaimantEmail: Object(values).re_claimant_email,
                debtorName: Object(values).debtor_name,
                debtorId: Object(values).debtor_id,
            })
            .then(() => {
                setValidForm(true);
            })
            .catch((err) => {
                console.log(err.name);
                console.log(err.errors);
            });
    });
    return (
        <FormsMain title={title} onChange={onChange} validForm={validForm}>
            <form onSubmit={onSubmit}>
                {sections.map((section) => (
                    <Section
                        data={section}
                        key={section.id}
                        onChange={onChange}
                        className="grid grid-cols-1 gap-4 md:grid-cols-2"
                    />
                ))}

                <DebtAmount data={debt_amount} onChange={onChange} />
                <CheckedContainer
                    data={permits_suspensions}
                    onCheckboxChange={onCheckboxChange}
                    checked={Object(checked)}
                />
                <CheckedContainer
                    data={invalidate_suspensions}
                    onCheckboxChange={onCheckboxChange}
                    checked={Object(checked)}
                />
                <Awared data={awared} dark upper />

                <Download data={download} nameLocal="downloadForm_3" />

                <Checkbox
                    name="agree"
                    id="agree"
                    label={t("claimantForm3Data.checkbox")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="agree"
                    checked={checkedArr.agree}
                />

                {/*<CheckedContainer*/}
                {/*    data={agreement}*/}
                {/*    onCheckboxChange={onCheckboxChange}*/}
                {/*    checked={Object(checked)}*/}
                {/*/>*/}
            </form>

            <FormFooter
                pdf={AppRoute.PDF_DOCUMENT_3}
                title={title}
                validForm={validForm}
                params={{
                    name,
                }}
            />
        </FormsMain>
    );
};
