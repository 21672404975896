import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import Backend from "i18next-chained-backend";
import { initReactI18next } from "react-i18next";

import ee from "./locales/ee.json";
import en from "./locales/en.json";
import ru from "./locales/ru.json";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "ee",
        debug: false,
        fallbackLng: "ee",
        resources: {
            ee: { translation: ee },
            en: { translation: en },
            ru: { translation: ru },
        },
        react: {
            useSuspense: false,
        },
        backend: {
            backends: [HttpApi],
            backendOptions: [
                {
                    loadPath: "/locales/{{lng}}.json",
                },
            ],
        },
    });

// i18next
//     .use(Backend)
//     .use(LanguageDetector)
//     .use(initReactI18next)
//     .init({
//         lng: "ee",
//         debug: false,
//         fallbackLng: "ee",
//         resources: { ee: { translation: ee }, en: { translation: en } },
//         react: {
//             useSuspense: false,
//         },
//     });

export default i18next;
