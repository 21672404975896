import React from "react";
import { AuctionPanel, AuctionObjectCard } from "@components/components";

export const Auction = () => {
    return (
        <div className="flex flex-col items-stretch justify-between w-full pl-4 pr-4 md:pl-0 md:pr-0">
            <AuctionPanel />
            <AuctionObjectCard />
        </div>
    );
};
