import React from "react";

interface SimpleTableProps {
    title: string;
    thead_1: string;
    thead_2?: string | null;
    thead_3?: string | null;
    thead_4?: string | null;
    thead_5?: string | null;
    thead_6?: string;
    thead_7?: string;
    tbody_1_1: string;
    tbody_2_1?: string;
    tbody_3_1?: string;
    tbody_4_1?: string;
    tbody_1_2?: string;
    tbody_2_2?: string;
    tbody_3_2?: string;
    tbody_4_2?: string;
    tbody_1_3?: string;
    tbody_2_3?: string;
    tbody_3_3?: string;
    tbody_4_3?: string;
    tbody_1_4?: string;
    tbody_2_4?: string;
    tbody_3_4?: string;
    tbody_4_4?: string;
    tbody_1_5?: string;
    tbody_2_5?: string;
    tbody_3_5?: string;
    tbody_4_5?: string;
    tbody_5_1?: string;
    tbody_6_1?: string;
    tbody_7_1?: string;
    row?: number;
    className?: string;
}

export const SimpleTable: React.FC<SimpleTableProps> = ({
    title,
    tbody_1_1,
    tbody_2_1,
    tbody_3_1,
    tbody_4_1,
    tbody_5_1,
    tbody_1_2,
    tbody_2_2,
    tbody_3_2,
    tbody_4_2,
    tbody_1_3,
    tbody_2_3,
    tbody_3_3,
    tbody_4_3,
    tbody_1_4,
    tbody_2_4,
    tbody_3_4,
    tbody_4_4,
    tbody_1_5,
    tbody_2_5,
    tbody_3_5,
    tbody_4_5,

    thead_1,
    thead_2,
    thead_3,
    thead_4,
    thead_5,
    row,
    className,
}) => {
    const tbody_4_1_correct = tbody_4_1?.includes("@")
        ? tbody_4_1?.split("@").join("@ ")
        : tbody_4_1;
    return (
        <div className={className}>
            {title && (
                <p className="font-[500] text-[16px] fontWeight mb-2">
                    {title}
                </p>
            )}

            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                <span className="text-[16px]">{thead_1}</span>
                <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                    {tbody_1_1 === "undefined" || tbody_1_1 === "undefined €"
                        ? "-"
                        : tbody_1_1}
                </span>
            </p>

            {thead_2 && (
                <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                    <span className="text-[16px]">{thead_2}</span>
                    <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                        {tbody_2_1 === "undefined" ? "-" : tbody_2_1}
                    </span>
                </p>
            )}

            {thead_3 && (
                <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                    <span className="text-[16px]">{thead_3}</span>
                    <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                        {tbody_3_1 === "undefined" ? "-" : tbody_3_1}
                    </span>
                </p>
            )}

            {thead_4 && (
                <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                    <span className="text-[16px]">{thead_4}</span>
                    <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal tracking-wide">
                        {tbody_4_1_correct === "undefined"
                            ? "-"
                            : tbody_4_1_correct}
                    </span>
                </p>
            )}
            {thead_5 && (
                <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                    <span className="text-[16px]">{thead_5}</span>
                    <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal tracking-wide">
                        {tbody_5_1 === "undefined" ||
                        tbody_5_1 === "+undefined undefined"
                            ? "-"
                            : tbody_5_1}
                    </span>
                </p>
            )}

            {tbody_1_2 !== "undefined" && !row && (
                <>
                    <p className="font-[500] text-[16px] text-[#1A1A1F] mt-4 fontWeight flex items-center">
                        <span className="text-[16px]">{thead_1}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_1_2}
                        </span>
                    </p>
                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_2}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_2_2}
                        </span>
                    </p>

                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_3}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_3_2}
                        </span>
                    </p>

                    {thead_4 && (
                        <>
                            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                                <span className="text-[16px]">{thead_4}</span>
                                <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                                    {tbody_4_2}
                                </span>
                            </p>
                        </>
                    )}
                </>
            )}

            {tbody_1_3 !== "undefined" && !row && (
                <>
                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_1}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_1_3}
                        </span>
                    </p>

                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_2}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_2_3}
                        </span>
                    </p>

                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_3}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_3_3}
                        </span>
                    </p>

                    {thead_4 && (
                        <>
                            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                                <span className="text-[16px]">{thead_4}</span>
                                <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                                    {tbody_4_3}
                                </span>
                            </p>
                        </>
                    )}
                </>
            )}

            {tbody_1_4 !== "undefined" && !row && (
                <>
                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_1}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_1_4}
                        </span>
                    </p>

                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_2}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_2_4}
                        </span>
                    </p>

                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_3}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_3_4}
                        </span>
                    </p>

                    {thead_4 && (
                        <>
                            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                                <span className="text-[16px]">{thead_4}</span>
                                <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                                    {tbody_4_4}
                                </span>
                            </p>
                        </>
                    )}
                </>
            )}

            {tbody_1_5 !== "undefined" && !row && (
                <>
                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_1}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_1_5}
                        </span>
                    </p>

                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_2}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_2_5}
                        </span>
                    </p>

                    <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                        <span className="text-[16px]">{thead_3}</span>
                        <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                            {tbody_3_5}
                        </span>
                    </p>

                    {thead_4 && (
                        <>
                            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                                <span className="text-[16px]">{thead_4}</span>
                                <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                                    {tbody_4_5}
                                </span>
                            </p>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
