import { DividedInput } from "@components/common";
import React from "react";

interface IData {
    id: number;
    elem: string;
    placeholder: string;
    type: string;
    name: string;
    number?: boolean;
    title?: string;
}

interface IDebtAmount {
    data: IData;
    onChange?: (arg0: any) => void;
}
export const DebtAmount: React.FC<IDebtAmount> = ({ data, onChange }) => {
    return (
        <div className="my-8 base-border">
            <p className="mb-2 text-[#4b4b4b] text-lg">{data.title}</p>
            <DividedInput
                name={data.name}
                elem={data.elem}
                onChange={onChange}
                number={data.number}
                placeholder={data.placeholder}
                units="€"
            />
        </div>
    );
};
