import React from "react";
import { useTranslation } from "react-i18next";
import { Case, UserCard } from ".";

interface IFormsMain {
    title: string;
    children: React.ReactNode;
    onChange?: (arg0: any) => void;
    withoutCase?: boolean;
    validForm?: boolean;
}

export const FormsMain: React.FC<IFormsMain> = ({
    title,
    children,
    onChange,
    withoutCase,
    validForm,
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col mt-0 ml-6 md:mt-8 md:ml-0 " id="error">
            <p className="mb-6 text-xl font-semibold ">{title}</p>
            {!validForm && (
                <p className="text-[#f70303] text-center font-semibold mb-4">
                    {t("other.formMain.valid_info")}
                </p>
            )}
            <UserCard />
            {!withoutCase && <Case isCase onChange={onChange} />}
            <div className="pr-6 md:pr-0 ">{children}</div>
        </div>
    );
};
