import React from "react";
import { SimpleCard } from "@components/components";
import { debtorCardData } from "@config/debtor";
import { useTranslation } from "react-i18next";

export const DebtorActions = () => {
    const { t } = useTranslation();
    return (
        <div className="relative z-20 grid gap-4 px-12 pb-20 bg-white mg:gap-12 xl:gap-24 grid-col-1 md:grid-cols-2 ">
            {debtorCardData(t).map((item) => (
                <SimpleCard
                    key={item.id}
                    pay={item.pay}
                    path={item.path}
                    name={item.name}
                >
                    <div className="flex flex-col items-center justify-center px-4 pt-8 pb-12 bg-white">
                        <p className="py-2 font-semibold text-lg">
                            {item.title}
                        </p>
                        <p className="text-center text-lg">{item.desc_1}</p>
                        {/* <p className="text-center">{item.desc_2}</p> */}
                    </div>
                </SimpleCard>
            ))}
        </div>
    );
};
