import React from "react";
// import { auctionPanelData } from "@config/";
import { useTranslation } from "react-i18next";

export const AuctionPanel = () => {
    const { t } = useTranslation();

    // const handleSearchClick = () => {
    //     localStorage.setItem("filterValue", filterValue);
    //     localStorage.setItem("dateFromSearch", dateFromValue);
    //     localStorage.setItem("dateToSearch", dateToValue);
    //     window.location.reload();
    // };
    // const filterData = String(localStorage.getItem("filterValue") || "");
    // const dateFromSearch = String(localStorage.getItem("dateFromSearch"));
    // const dateToSearch = String(localStorage.getItem("dateToSearch"));
    // const [filterValue, setFilterValue] = useState(filterData || "");
    // const [dateFromValue, setFromDateValue] = useState(dateFromSearch || "");
    // const [dateToValue, setToDateValue] = useState(dateToSearch || "");

    return (
        <div className="flex flex-col w-full mb-4 md:mb-20 md:flex-row justify-berween">
            <div className="flex flex-col w-full mt-6 ">
                <p className="mb-4 md:mb-6 text-xl font-semibold">
                    {t("auctionPanelData.title")}
                </p>
                <p className="mb-6 text-lg leading-8 ">
                    {t("auctionPanelData.desc")}{" "}
                    <a
                        className="text-lg leading-8 text-secondary"
                        href="http://www.oksjonikeskus.ee"
                    >
                        www.oksjonikeskus.ee
                    </a>
                </p>
                {/*<a*/}
                {/*    href="https://www.oksjonikeskus.ee"*/}
                {/*    target="_blank"*/}
                {/*    rel="noopener noreferrer"*/}
                {/*>*/}
                {/*    <p className="text-lg font-semibold text-secondary">*/}
                {/*        {path}*/}
                {/*    </p>*/}
                {/*</a>*/}
            </div>
            {/* <div className="mt-4 ml-0 md:ml-8 lg:w-3/4 md:w-3/5 base-border">
                <p className="mb-4 font-semibold">{form.title}</p>
                <Input
                    value={filterValue || ""}
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    className="mb-2"
                    onChange={(e) => setFilterValue(e.target.value)}
                />
                <div className="grid grid-cols-1 gap-0 mb-2 md:gap-4 md:grid-cols-2">
                    <DividedInput
                        value={dateFromValue}
                        type={divide_field[0].type}
                        name={divide_field[0].name}
                        placeholder={divide_field[0].placeholder}
                        onChange={(e) => setFromDateValue(e.target.value)}
                    />
                    <DividedInput
                        value={dateToValue}
                        type={divide_field[1].type}
                        name={divide_field[1].name}
                        placeholder={divide_field[1].placeholder}
                        onChange={(e) => setToDateValue(e.target.value)}
                    />
                </div>

                <Button
                    onClick={handleSearchClick}
                    className="self-center !w-full md:!w-fit"
                >
                    otsi
                </Button>
            </div> */}
        </div>
    );
};
