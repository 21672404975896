import { TFunction } from "i18next";

export const monthlyMinimumData = (t: TFunction<"translation", undefined>) => {
    return {
        banks: {
            title: `${t("monthlyMinimumData.banks.title")}`,
            points: [
                {
                    id: 1,
                    value: `${t("monthlyMinimumData.banks.points.0.value")}`,
                    name: "bank2",
                },
                {
                    id: 2,
                    value: `${t("monthlyMinimumData.banks.points.1.value")}`,
                    name: "bank2",
                },
                {
                    id: 3,
                    value: `${t("monthlyMinimumData.banks.points.2.value")}`,
                    name: "bank2",
                },
                {
                    id: 4,
                    value: `${t("monthlyMinimumData.banks.points.3.value")}`,
                    name: "bank2",
                },

                {
                    id: 5,
                    value: `${t("monthlyMinimumData.banks.points.4.value")}`,
                    name: "bank2",
                },
                {
                    id: 6,
                    value: `${t("monthlyMinimumData.banks.points.5.value")}`,
                    name: "bank2",
                },
                {
                    id: 7,
                    value: `${t("monthlyMinimumData.banks.points.6.value")}`,
                    name: "bank2",
                },
                {
                    id: 8,
                    value: `${t("monthlyMinimumData.banks.points.7.value")}`,
                    name: "bank2",
                },
                {
                    id: 9,
                    value: `${t("monthlyMinimumData.banks.points.8.value")}`,
                    name: "bank2",
                },
            ],
        },
        field: {
            id: 1,
            placeholder: `${t("monthlyMinimumData.field.placeholder")}`,
            name: "add_more",
        },

        nb: {
            title: `${t("monthlyMinimumData.nb.title")}`,
            points: [
                {
                    id: 1,
                    content: `${t("monthlyMinimumData.nb.points.0.content")}`,
                    name: "nb2_1",
                    path: "",
                },
                {
                    id: 2,
                    content: `${t("monthlyMinimumData.nb.points.1.content")}`,
                    path: `${t("monthlyMinimumData.nb.points.1.path")}`,
                    path_link: `/debtor/questions#${t(
                        "questionsData.items.4.title",
                    )}`,
                    name: "defaultOpen5",
                },
                {
                    id: 3,
                    content: `${t("monthlyMinimumData.nb.points.2.content")}`,
                    path: "",
                },
            ],
        },
        otherData: {
            id: 1,
            label: `${t("monthlyMinimumData.otherData.label")}`,
            placeholder: "",
            name: "additional2",
        },
    };
};
