import React from "react";
import { Accordion } from "@components/components";
import { servicesData } from "@config/home";
import { useTranslation } from "react-i18next";

export const Services = () => {
    const { t } = useTranslation();
    return (
        <div className="pt-8 pb-12 ">
            <div className="container flex flex-col pt-4">
                {/*<div className="flex items-center mb-8 ">*/}
                {/*    <div className="w-8 ml-0 mr-4 border-t-2 lg:mx-16 border-neutral "></div>*/}
                {/*    <div className="w-8 ml-0 mr-4 border-t-2 lg:mx-16 border-neutral "></div>*/}
                {/*    <p className="lg:ml-40 font-semibold uppercase">Ametiteenused</p>*/}
                {/*</div>*/}
                <div className="flex flex-col ml-0 ">
                    <h3 className="text-2xl mb-16 font-semibold">
                        {t("servicesData.title")}
                    </h3>
                    <Accordion data={servicesData(t)} />
                </div>
            </div>
        </div>
    );
};
