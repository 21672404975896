import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const resourcesData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            title: `${t("resourcesData.debtor.title")}`,
            path: AppRoute.DEBTOR_QUESTIONS,
            items: [
                {
                    id: 1,
                    content: `${t("resourcesData.debtor.items.0.content")}`,
                    path: AppRoute.DEBTOR_QUESTIONS,
                    name: "defaultOpen1",
                },
                {
                    id: 2,
                    content: `${t("resourcesData.debtor.items.1.content")}`,
                    path: AppRoute.DEBTOR_RIGHTS,
                },
                {
                    id: 3,
                    content: `${t("resourcesData.debtor.items.2.content")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "schedule",
                },
                {
                    id: 4,
                    content: `${t("resourcesData.debtor.items.3.content")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "minimum",
                },
                {
                    id: 5,
                    content: `${t("resourcesData.debtor.items.4.content")}`,
                    path: AppRoute.DEBTOR_QUESTIONS,
                },
            ],
        },
        {
            id: 2,
            title: `${t("resourcesData.claimant.title")}`,
            path: AppRoute.FORMS_DEBTOR_FORM_6,
            items: [
                {
                    id: 1,
                    content: `${t("resourcesData.claimant.items.0.content")}`,
                    path: AppRoute.FORMS_CLAIMANT_FORM_4,
                },
                {
                    id: 2,
                    content: `${t("resourcesData.claimant.items.1.content")}`,
                    path: AppRoute.CLAIMANT_HOTLINE,
                },
                {
                    id: 3,
                    content: `${t("resourcesData.claimant.items.2.content")}`,
                    path: AppRoute.CLAIMANT_FORMS,
                },
                // { id: 4, content: "Täitemenetlus", path: "/" },
                {
                    id: 5,
                    content: `${t("resourcesData.claimant.items.3.content")}`,
                    path: AppRoute.CLAIMANT_APPLYING,
                },
            ],
        },
        {
            id: 3,
            title: `${t("resourcesData.blanks.title")}`,
            path: AppRoute.FORMS,
            items: [
                {
                    id: 1,
                    content: `${t("resourcesData.blanks.items.0.content")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "schedule",
                },
                {
                    id: 2,
                    content: `${t("resourcesData.blanks.items.1.content")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "minimum",
                },
                {
                    id: 3,
                    content: `${t("resourcesData.blanks.items.2.content")}`,
                    path: AppRoute.FORMS_CLAIMANT_FORM_4,
                },
                {
                    id: 4,
                    content: `${t("resourcesData.blanks.items.3.content")}`,
                    path: AppRoute.FORMS_CLAIMANT_FORM_5,
                },
            ],
        },
        {
            id: 4,
            title: `${t("resourcesData.calculators.title")}`,
            path: AppRoute.CALCULATOR_SALARY,
            items: [
                {
                    id: 1,
                    content: `${t(
                        "resourcesData.calculators.items.0.content",
                    )}`,
                    path: AppRoute.CALCULATOR_SALARY,
                },
                // {
                //     id: 2,
                //     content: "Võlgniku blanketid",
                //     path: AppRoute.CALCULATOR_ASSETS,
                // },
            ],
        },
    ];
};
