import React from "react";
import clsx from "clsx";
import { ContactForm } from ".";

interface IContacts {
    className?: string;
}

export const Contacts: React.FC<IContacts> = ({ className }) => {
    return (
        <div
            className={clsx(
                className,
                "flex flex-col justify-between w-full md:flex-row",
            )}
        >
            {/* <ContactInfo /> */}
            <ContactForm />
        </div>
    );
};
