import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useDeviceWidhtDetect } from "@hooks";
import { LanguageSelect, Logo, Navbar, UserData } from "@components/components";
import { CloseHamburgerIcon, OpenHamburgerIcon } from "@assets";

interface IHeader {
    className?: string;
    bailiff_img?: string;
    bailiff_name?: string;
}

export const Header: React.FC<IHeader> = ({ bailiff_img, bailiff_name }) => {
    const isMobile = useDeviceWidhtDetect(767);
    const isSmallMobile = useDeviceWidhtDetect(425);

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        !isMobile ? setIsOpen(true) : setIsOpen(false);
    }, [isMobile]);

    return (
        <>
            <div
                className={clsx(
                    "h-[62px] bg-white shadow-sm lg:h-[154px] z-50 w-full mx-auto max-w-[1440px] ",
                    isMobile && "fixed top-[68px] left-0 right-0 z-30 mb-12",
                )}
            >
                <div className="bg-white container relative flex-row items-center justify-between z-50">
                    <div className="flex items-center justify-between">
                        <Logo />
                        <div className="flex items-center">
                            {bailiff_name && bailiff_img && (
                                <UserData
                                    img={bailiff_img}
                                    name={bailiff_name}
                                />
                            )}
                            {!isMobile && (
                                <div className="z-50">
                                    <LanguageSelect />
                                </div>
                            )}
                            {isMobile && (
                                <div className="flex items-center -mr-2">
                                    <LanguageSelect isMobile={isSmallMobile} />
                                    <button
                                        onClick={() => setIsOpen(!isOpen)}
                                        type="button"
                                        className="inline-flex items-center z-50 justify-center p-2 rounded-md text-neutral hover:text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                        aria-expanded="false"
                                    >
                                        {!isOpen ? (
                                            <OpenHamburgerIcon />
                                        ) : (
                                            <CloseHamburgerIcon />
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    {/*{!isMobile && summa}*/}
                    {/*<Link to={AppRoute.AUTHORIZATION}>*/}
                    {/*    <Button className="hidden md:block whitespace-nowrap">*/}
                    {/*        Logi sisse*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}
                    <div
                        className={clsx(
                            "",
                            !isOpen ? "hidden" : "",
                            isMobile &&
                                "bg-white flex flex-col w-screen items-center px-8 pb-4 absolute transition-all duration-300",
                            !isMobile && isOpen && "block",
                            isMobile && !isOpen && "hidden",
                            isMobile && isOpen ? "h-fit opacity-100" : "h-0 ",
                            !isOpen
                                ? "opacity-0 h-0 overflow-hidden z-0"
                                : "z-30",
                        )}
                    >
                        <Navbar
                            isMobile={isMobile}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
