import React from "react";
import { DebtorMain } from "@components/debtor";
import { paymentData } from "@config/debtor";
import { useTranslation } from "react-i18next";

export const PaymentSchedule = () => {
    const { t } = useTranslation();
    const { title, desc_1, desc_2, desc_3, desc_4, path } = paymentData(t);
    return (
        <DebtorMain
            title={title}
            desc_1={desc_1}
            desc_2={desc_2}
            desc_3={desc_3}
            desc_4={desc_4}
            path={path}
        />
    );
};
