import React from "react";
import { Textarea, Awared, Input } from "@components/components";
import { limitationData } from "@config/forms";
import { useTranslation } from "react-i18next";

interface ILimitation {
    onChange?: (arg0: any) => void;
}
export const Limitation: React.FC<ILimitation> = ({ onChange }) => {
    const { t } = useTranslation();
    const { claimer_details, nb } = limitationData(t);
    return (
        <>
            <div className=" base-border">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    {claimer_details.fields.map((item) => (
                        <div className="my-4 base-bord" key={item.id}>
                            <Input
                                label={item.label}
                                placeholder={item.placeholder}
                                name={item.name}
                                onChange={onChange}
                                number={item.number}
                                minLength={item.minLength}
                                maxLength={item.maxLength}
                            />
                        </div>
                    ))}
                </div>
                <div className="base-bord">
                    <Textarea
                        label={claimer_details.divided_field.elem}
                        name={claimer_details.divided_field.name}
                        onChange={onChange}
                        rows={8}
                        tip={claimer_details.divided_field.tip}
                    />
                </div>
            </div>
            <Awared data={nb} upper base dark />
        </>
    );
};
