import React from "react";
import { useTranslation } from "react-i18next";

export const Claimant = () => {
    const { t } = useTranslation();
    return (
        <div className="pt-0 pl-6 pr-6 md:pt-4">
            <h2 className="my-4 md:mt-0 md:mb-6 text-xl font-semibold leading-6 md:leading-9">
                {t("claimant.title")}
            </h2>
            <p className="mb-4 text-lg leading-6 md:leading-9">
                {t("claimant.desk_1")}
            </p>
            <p className="mb-4 text-lg leading-6 md:leading-9">
                {t("claimant.desk_2")}
            </p>
            <h3 className="text-lg font-semibold leading-6 md:leading-9">
                {t("claimant.listTitle")}
            </h3>
            <ul className="list-disc mb-4 pl-5">
                <li className="text-lg w-full my-4">{t("claimant.list.0")}</li>
                <li className="text-lg w-full my-4">{t("claimant.list.1")}</li>
                <li className="text-lg w-full my-4">{t("claimant.list.2")}</li>
                <li className="text-lg w-full my-4">{t("claimant.list.3")}</li>
            </ul>
            <p className="text-lg leading-6 md:leading-9">
                {t("claimant.desk_3")}
                <a
                    target="_blank"
                    rel="noreferrer"
                    className="inline text-lg font-semibold text-secondary ml-2"
                    href="https://www.riigiteataja.ee/akt/114032011022?leiaKehtiv#para2"
                >
                    {t("claimant.desk_3_link")}
                </a>
                .
            </p>
            <p className="mb-4 text-lg leading-6 md:leading-9">
                {t("claimant.desk_4")}
            </p>
            <p className="mb-4 text-lg leading-6 md:leading-9">
                {t("claimant.desk_5")}
            </p>
        </div>
    );
};
