import { cookiesData } from "@config/cookies";
import React from "react";
import { useTranslation } from "react-i18next";

export const CookiesPage = () => {
    const { t } = useTranslation();
    const { title, items } = cookiesData(t);
    localStorage.removeItem("url");
    return (
        <div className="pt-4">
            <h2 className="font-semibold text-xl mb-4">{title}</h2>
            <ul>
                {items.map((item) => {
                    return (
                        <li className="" key={item.id}>
                            {item.title && (
                                <h3 className="font-semibold text-lg mt-4">
                                    {item.title}
                                </h3>
                            )}
                            <p className="text-lg mt-4">{item.desc}</p>
                            {item.desc_2 && (
                                <p className="text-lg mt-4">{item.desc_2}</p>
                            )}
                            {item.desc_3 && (
                                <p className="text-lg mt-4">{item.desc_3}</p>
                            )}
                            {item.desc_4 && (
                                <p className="text-lg mt-4">{item.desc_4}</p>
                            )}
                            {item.desc_5 && (
                                <p className="text-lg mt-4">{item.desc_5}</p>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
