import React, { useState } from "react";
import clsx from "clsx";

interface ITooltip {
    hint: string;
    right?: boolean;
}

export const Tooltip: React.FC<ITooltip> = ({ hint }) => {
    const [hover, setHover] = useState(false);
    return (
        <span className="inline-block mx-6 z-30">
            <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className="relative flex items-center justify-center w-3 h-3 text-[9px] font-semibold m-2 -ml-4 border rounded-full cursor-pointer justify-self-end border-neutral/50 text-neutral/50 top-[-1px]"
            >
                i
                <div className={clsx(hover ? "flex items-center " : "hidden")}>
                    <div className="absolute z-50 w-4 h-4 rotate-45 bg-white border border-transparent border-l-[#e53d3e] border-t-[#e53d3e] right-[-2px] top-[18px]" />
                    <div className="absolute z-40 p-2 rounded-md w-[250px] md:w-[350px] bg-white border border-[#e53d3e] top-[25px] right-[-25px]">
                        <p className="text-neutral text-center bg-white text-[12px] px-1">
                            {hint}
                        </p>
                    </div>
                </div>
            </div>
        </span>
    );
};
