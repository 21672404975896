import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {useFullForm} from "@hooks";
import {checkedState, initialState} from "state";
import {
    Assets,
    Checkbox,
    Download,
    FormFooter,
    FormsMain,
    Section,
} from "@components/components";
import {AppRoute} from "@enums";
import {debtorForm5Data} from "@config/forms";

export const DebtorForm5 = () => {
    const {t} = useTranslation();
    const {title, sections, assetsLiabilities, agreement_note, download} =
        debtorForm5Data(t);
    const {onChange, onSubmit, values} = useFullForm(
        formCallback,
        initialState,
        checkedState,
    );
    const [name, setName] = useState<string>("");
    const [checkedArr, setCheckedArr] = useState({
        agree: true,
        point1: true,
        point2: true,
        point3: true,
    });
    const onHandelCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCheckedArr({
            ...checkedArr,
            [event.target.name]: event.target.checked,
        });
    };

    async function formCallback() {
        console.log("values");
        console.log("checked");
    }

    useEffect(() => {
        localStorage.setItem("selected_titles_arr", JSON.stringify([title]));
        localStorage.setItem("selectTitleItem", title);
    }, []);

    const [validForm, setValidForm] = useState(false);
    const [, setIsTopError] = useState(false);
    useEffect(() => {
        if (validForm) setIsTopError(false);
    }, [validForm]);
    useEffect(() => {
        localStorage.setItem("caseNumber", Object(values).case_number);
        setName(Object(values).debtor_name);
        localStorage.setItem("debtorName", Object(values).debtor_name);
        localStorage.setItem("debtorId", Object(values).debtor_id);
        localStorage.setItem("debtorAddress", Object(values).debtor_address);
        localStorage.setItem("debtorEmail", Object(values).debtor_email);
        localStorage.setItem("debtor_phone", Object(values).debtor_phone);
        localStorage.setItem(
            "pre_debtor_phone",
            Object(values).pre_debtor_phone,
        );

        localStorage.setItem("job", Object(values).job);
        localStorage.setItem("claims", Object(values).claims);
        localStorage.setItem("balance", Object(values).balance);
        localStorage.setItem("cars", Object(values).cars);
        localStorage.setItem("tech", Object(values).tech);
        localStorage.setItem("relative", Object(values).relative);
        localStorage.setItem("donate", Object(values).donate);
        localStorage.setItem("liabilities", Object(values).liabilities);
    }, [values]);
    useEffect(() => {
        localStorage.setItem("point1", JSON.stringify(checkedArr.point1));
        localStorage.setItem("point2", JSON.stringify(checkedArr.point2));
        localStorage.setItem("point3", JSON.stringify(checkedArr.point3));
        localStorage.setItem("agree", JSON.stringify(checkedArr.agree));
    }, [checkedArr]);

    const validationSchema = Yup.object().shape(
        {
            debtorName: Yup.string().required("Name is required"),
            debtorId: Yup.string().min(11).max(11).required("Id is required"),
            debtorAddress: Yup.string().required("Address is required"),
            debtorEmail: Yup.string().email().required("Email is required"),
        },
        [],
    );
    useEffect(() => {
        validationSchema
            .isValid({
                debtorName: Object(values).debtor_name,
                debtorId: Object(values).debtor_id,
                debtorAddress: Object(values).debtor_address,
                debtorEmail: Object(values).debtor_email,
            })
            .then((valid) => {
                setValidForm(true);
                setIsTopError(!valid);
            })
            .catch((err) => {
                console.log(err.name);
                console.log(err.errors);
            });
    });

    const allTrue = Object.values(checkedArr).every((value) => value);

    return (
        <FormsMain title={title} onChange={onChange} validForm={validForm}>
            <form onSubmit={onSubmit}>
                <Section
                    data={sections[0]}
                    key={sections[0].id}
                    onChange={onChange}
                    className="grid grid-cols-1 gap-4 md:grid-cols-2"
                />
                <h3 className="font-semibold  text-neutral w-11/12 mb-2 text-base !text-lg uppercase mt-6">
                    Vara ja kohustuste nimekiri
                </h3>
                {assetsLiabilities.map((item) => (
                    <Assets
                        items={item.items}
                        title={item.title}
                        key={item.id}
                        onChange={onChange}
                    />
                ))}
                <p className="mb-4 text-lg font-semibold text-neutral">
                    {t("debtorForm5Data.added_info")}
                </p>
                <p className="text-middleGray">{agreement_note}</p>
                <Download data={download} nameLocal="downloadForm_6"/>
                <Checkbox
                    name="agree"
                    id="agree"
                    label={t("debtorForm5Data.checkbox_1")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="agree"
                    checked={checkedArr.agree}
                />

                <Checkbox
                    name="point1"
                    id="point1"
                    label={t("debtorForm5Data.checkbox_2")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="point1"
                    checked={checkedArr.point1}
                />
                <Checkbox
                    name="point2"
                    id="point2"
                    label={t("debtorForm5Data.checkbox_3")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="point2"
                    checked={checkedArr.point2}
                />
                <Checkbox
                    name="point3"
                    id="point3"
                    label={t("debtorForm5Data.checkbox_4")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="point3"
                    checked={checkedArr.point3}
                />
                {!allTrue && (
                    <p className="text-[#f70303] text-center font-semibold my-6">
                        {t("debtorForm5Data.warning")}
                    </p>
                )}
            </form>
            <FormFooter
                pdf={AppRoute.PDF_DOCUMENT_5}
                title={title}
                validForm={validForm}
                setIsTopError={setIsTopError}
                params={{
                    name,
                }}
            />
        </FormsMain>
    );
};
