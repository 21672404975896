import React, { useRef } from "react";
import clsx from "clsx";
import { Tooltip } from "./Tooltip";
import NumberFormat from "react-number-format";

interface IInput {
    disabled?: boolean;
    id?: string;
    label?: string;
    placeholder?: string;
    type?: string;
    min?: number | string;
    max?: number | string;
    name: string;
    className?: string;
    required?: boolean;
    checked?: boolean;
    hint?: string;
    light?: boolean;
    gray?: boolean;
    value?: string | number | readonly string[] | undefined;
    form?: boolean;
    onChange?: (arg0: React.ChangeEvent<HTMLInputElement>) => void;
    right?: boolean;
    units?: string;
    minLength?: number;
    maxLength?: number;
    number?: boolean;
    onkey?: boolean;
    contact?: boolean;
    pattern?: boolean;
    defaultValue?: string;
}

export const Input: React.FC<IInput> = ({
    id,
    label,
    placeholder,
    type = "text",
    min,
    name,
    className,
    hint,
    light,
    gray,
    onChange,
    required,
    right = false,
    units,
    minLength,
    maxLength,
    number,
    value,
    onkey,
    contact,
    pattern = false,
    defaultValue,
    disabled,
    max,
}) => {
    const ref = useRef<HTMLInputElement>(null);

    const handleError = (e: { target: { value: string } }): void => {
        if (!e.target.value || !ref?.current?.checkValidity()) {
            ref?.current?.setAttribute("style", "border:1px solid #e53d3e");
        } else {
            ref?.current?.setAttribute("style", "border:1px solid #4b4b4b");
        }
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode >= 65 && e.keyCode <= 185) {
            !/\D/g.test(e.key) && console.log(e, "key");
            e.preventDefault();
        }
    };
    return (
        <div className={clsx(className, "")}>
            <div className="relative flex items-center">
                {label && (
                    <label
                        htmlFor={name}
                        className={clsx(
                            contact ? "text-lg" : "text-lg",
                            light ? "text-[#595959]/80" : "text-[#4b4b4b]",
                            " mb-1 text-lg",
                        )}
                    >
                        {label}
                    </label>
                )}
                {hint && <Tooltip hint={hint} right={right} />}
            </div>
            <div className="flex items-center relative">
                {pattern ? (
                    <NumberFormat
                        format="###/####/#####"
                        name={name}
                        placeholder="___/____/_____"
                        onChange={onChange}
                        mask="_"
                        className="focus:outline-none focus:ring-secondary  focus:ring-1 border mb-1 focus:border-secondary border-[#4b4b4b] text-lg block rounded-md py-2 placeholder:opacity-50 placeholder:text-neutral w-full placeholder:text-lg p-2 text-lg"
                    />
                ) : (
                    <input
                        className={clsx(
                            "focus:outline-none focus:ring-secondary  focus:ring-1 border mb-1 focus:border-secondary border-[#4b4b4b] text-lg block rounded-md py-2 placeholder:opacity-50 placeholder:text-neutral w-full placeholder:text-lg p-2 text-lg",
                            // form && "form-input placeholder:text-lightGray",
                            gray ? "bg-lightGray/5" : "bg-transparent",
                        )}
                        minLength={minLength}
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        value={value}
                        id={id}
                        onChange={onChange}
                        min={min}
                        max={max}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        required={required}
                        maxLength={maxLength}
                        onKeyDown={(e) => onkey && handleKeyDown(e)}
                        onKeyPress={(event: {
                            key: string;
                            preventDefault: () => void;
                        }) => {
                            number &&
                                !/[0-9.,]/.test(event.key) &&
                                event.preventDefault();
                        }}
                        ref={ref}
                        onBlur={(e) => handleError(e)}
                    />
                )}

                {units && (
                    <p className="absolute right-2 px-2 text-lg text-gray ">
                        {units}
                    </p>
                )}
            </div>
        </div>
    );
};
