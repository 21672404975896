import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { BaseLayout } from "@components/components";
import { calcSidebarData } from "@config/";

export const CalculatorBase = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const basePath = location.pathname.endsWith("salary");
    return <BaseLayout data={calcSidebarData(t)} calc={basePath} />;
};
