import React from "react";

interface ListTableProps {
    row_title_1: string;
    row_title_2?: string | null;
    row_title_3?: string | null;
    row_title_4?: string | null;
    row_title_5?: string | null;
    row_title_6?: string | null;
    row_title_7?: string | null;
    row_title_8?: string;
    row_1: string;
    row_2?: string;
    row_3?: string;
    row_4?: string;
    row_5?: string;
    row_6?: string;
    row_7?: string;
    row_8?: string;
    title: string;
    className?: string;
}

export const ListTable: React.FC<ListTableProps> = ({
    title,
    row_title_1,
    row_title_2,
    row_title_3,
    row_title_4,
    row_title_5,
    row_title_6,
    row_title_7,
    row_title_8,
    row_1,
    row_2,
    row_3,
    row_4,
    row_5,
    row_6,
    row_7,
    row_8,
    className,
}) => {
    const rows = [row_1, row_2, row_3, row_4, row_5, row_6, row_7, row_8];
    const titles = [
        row_title_1,
        row_title_2,
        row_title_3,
        row_title_4,
        row_title_5,
        row_title_6,
        row_title_7,
        row_title_8,
    ];
    return (
        <div className={className}>
            {title && (
                <p className="mb-2 font-[500] text-[16px] fontWeight">
                    {title}
                </p>
            )}

            {rows.map((item, index) => (
                <>
                    {rows[index] && (
                        <>
                            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight flex items-center">
                                <span className="text-[16px]">
                                    {titles[index]}
                                </span>
                                <span className="font-normal text-[16px] text-[#1A1A1F] ml-2">
                                    {rows[index] === "undefined" ||
                                    rows[index] === "+undefined "
                                        ? "-"
                                        : rows[index]}
                                </span>
                            </p>
                        </>
                    )}
                </>
            ))}
        </div>
    );
};
