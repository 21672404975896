import React from "react";

interface ICheckbox {
    onClick?: any;
    label?: string | null;
    value?: string;
    name: string;
    length?: number;
    id?: number | string;
    onCheckboxChange?: (arg0: any) => void;
    checked?: boolean;
}

export const Checkbox: React.FC<ICheckbox> = ({
    onClick,
    label,
    value,
    name,
    id,
    onCheckboxChange,
    checked,
}) => {
    return (
        <div className="relative flex items-center w-fit">
            <input
                onClick={onClick}
                id={String(id)}
                className="checkbox hidden"
                type="checkbox"
                value={value}
                checked={checked}
                name={name}
                onChange={onCheckboxChange}
            />
            <label
                htmlFor={String(id)}
                className="pb-2 text-lg text-middleGray"
            >
                {label}
            </label>
        </div>
    );
};
