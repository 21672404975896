import { TFunction } from "i18next";

export const limitationData = (t: TFunction<"translation", undefined>) => {
    return {
        claimer_details: {
            title: `${t("limitationData.claimer_details.title")}`,
            fields: [
                {
                    id: 1,
                    label: `${t(
                        "limitationData.claimer_details.fields.0.label",
                    )}`,
                    placeholder: "",
                    name: "claimant_name1",
                },
                {
                    id: 2,
                    label: `${t(
                        "limitationData.claimer_details.fields.1.label",
                    )}`,
                    placeholder: "",
                    name: "claimant_id1",
                    minLength: 8,
                    maxLength: 11,
                    number: true,
                },
            ],
            divided_field: {
                id: 1,
                elem: `${t(
                    "limitationData.claimer_details.divided_field.elem",
                )}`,
                name: "claimant_content1",
                tip: `${t("limitationData.claimer_details.divided_field.tip")}`,
            },
        },
        nb: {
            title: `${t("limitationData.nb.title")}`,
            points: [
                {
                    id: 1,
                    content: `${t("limitationData.nb.points.0.content")}`,
                    orange: `${t("limitationData.nb.points.0.orange")}`,
                    link: "https://kt.hathor.ee/contacts#isTop",
                    endContent: `${t("limitationData.nb.points.0.endContent")}`,
                },
                {
                    id: 2,
                    content: `${t("limitationData.nb.points.1.content")}`,
                    orange: `${t("limitationData.nb.points.1.orange")}`,
                    endContent: ".",
                    link: "https://kt.hathor.ee/debtor/questions#top",
                },
            ],
        },
    };
};
