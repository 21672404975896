import React from "react";
import { bailiffsListData } from "@config/home";
import { BailiffCard } from "@components/components";
import { useTranslation } from "react-i18next";

interface IBailiffs {
    isHome?: boolean;
}

export const Bailiffs: React.FC<IBailiffs> = ({ isHome }) => {
    const { t } = useTranslation();
    return (
        <div className="pt-6 pb-6 sm:pt-20 px-4 bg-white sm:bg-primary">
            {isHome && (
                <h4 className="text-lg text-secondary mb-4 sm:hidden font-semibold">
                    Kohtutäiturid
                </h4>
            )}
            <div className="sm:container sm:grid sm:grid-cols-1 sm:gap-6 md:grid-cols-2 min-h-max mx-auto">
                {bailiffsListData(t).map((item) => (
                    <BailiffCard
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        contacts={item.contacts}
                        image={item.image}
                        status={item.status}
                        handedTo={item.handedTo}
                        position={item.position}
                        isHome={isHome}
                    />
                ))}
            </div>
        </div>
    );
};
