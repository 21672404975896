import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { HiArrowNarrowDown } from "react-icons/hi";
import { NavHashLink } from "react-router-hash-link";

interface IItem {
    id: number;
    title: string;
    desc_1?: string;
    desc_2?: string;
    desc_3?: string;
}
interface IAccordion {
    desc?: string;
    desc_bold?: string;
    list_1?: string[];
    link_1?: string;
    link_name_1?: string;
    path?: string;
    desc_mid?: string;
    path_name_1?: string;
    endOfDesc_1?: string;
    name_1?: string;
    desc_2?: string;
    path_2?: string;
    path_name_2?: string;
    endOfDesc_2?: string;
    name_2?: string;
    list_2?: string[];
    list_3?: string[];
    desc_3?: string;
    path_3?: string;
    link_3?: string;
    link_name_3?: string;
    name_3?: string;
    desc_bold_3?: string;
    path_name_3?: string;
    endOfDesc_3?: string;
    desc_4?: string;
    desc_bold_4?: string;
    desc_5?: string;
    list?: string[];
    list_4?: string[];
    items?: IItem[];
    title: string;
    line?: boolean;
    big?: boolean;
    children?: React.ReactElement;
    form?: boolean;
    defaultOpen?: boolean;
    defaultOpenQuestion?: string;
    middle?: boolean;
    upper?: boolean;
    path_url_1?: string;
    path_url_2?: string;
    path_url_3?: string;
    path_url_4?: string;
    path_4?: string;
    name_4?: string;
    path_name_4?: string;
    endOfDesc_4?: string;
}

export const AccordionLayout: React.FC<IAccordion> = ({
    desc,
    path,
    path_name_1,
    name_1,
    link_1,
    link_name_1,
    desc_mid,
    desc_bold,
    endOfDesc_1,
    desc_2,
    list_2,
    path_name_2,
    name_2,
    endOfDesc_2,
    path_2,
    desc_3,
    link_3,
    link_name_3,
    desc_bold_3,
    name_3,
    path_3,
    path_name_3,
    endOfDesc_3,
    desc_4,
    desc_5,
    list,
    list_1,
    list_3,
    list_4,
    items,
    title,
    line,
    big,
    children,
    form,
    defaultOpen,
    middle,
    upper,
    path_url_1,
    path_url_2,
    path_url_3,
    path_url_4,
    name_4,
    path_4,
    desc_bold_4,
    path_name_4,
    endOfDesc_4,
    defaultOpenQuestion,
}) => {
    const [open, setOpen] = useState(
        (localStorage.getItem("selectQuestion") || [""]).includes(
            defaultOpenQuestion || "all",
        ) || false,
    );

    const selectItemClick = (
        name: string | undefined,
        path: string | undefined,
    ) => {
        localStorage.setItem("url", String(path));
        localStorage.setItem("selectQuestion", String(name));
        localStorage.setItem("selectMenuItem", String(name));
    };
    useEffect(() => {
        setTimeout(() => {
            localStorage.removeItem("selectQuestion");
        }, 5000);
    }, [open]);

    return (
        <div
            id={title}
            className={clsx(
                "accordion-item  w-full md:pr-4 pr-0 ",
                (defaultOpen || open) && "accordion-item--opened relative z-50",
            )}
        >
            <div
                className="relative z-10 flex items-center justify-start pr-0 cursor-pointer md:pr-4"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <p
                    className={clsx(
                        "font-semibold  text-neutral w-11/12 mb-2",
                        big ? "text-lg md:text-2xl" : "text-base",
                        middle ? "!text-lg" : "!text-lg",
                        upper ? "uppercase" : "",
                    )}
                >
                    {title}
                </p>
                {form ? (
                    <></>
                ) : (
                    // <IoIosArrowDown
                    //     className={
                    //         defaultOpen || open
                    //             ? "rotate-180 transition-all duration-200  text-2xl text-secondary"
                    //             : "transition-all text-neutral  text-2xl hover:text-secondary"
                    //     }
                    // />
                    <HiArrowNarrowDown
                        className={
                            open
                                ? "rotate-180 transition-all duration-200  text-2xl text-secondary"
                                : "transition-all text-neutral  text-2xl hover:text-secondary"
                        }
                    />
                )}
            </div>
            {line && <div className="w-full my-2 md:my-4 bord"></div>}
            <div className="relative z-0 accordion-item__inner max-h-0">
                <div className="opacity-0 accordion-item__content">
                    {items ? (
                        items.map((item) => (
                            <div className="flex flex-col" key={item.id}>
                                <p className="w-11/12 text-lg font-semibold text-neutral">
                                    {item.title}
                                </p>
                                <p className="mt-2 mb-6 text-lg">
                                    {item.desc_1}
                                </p>
                                {item.desc_2 && (
                                    <p className="mt-2 mb-6 text-lg">
                                        {item.desc_2}
                                    </p>
                                )}
                                {item.desc_3 && (
                                    <p className="mt-2 mb-6 text-lg">
                                        {item.desc_3}
                                    </p>
                                )}
                            </div>
                        ))
                    ) : (
                        <>
                            <p className="mb-6 text-lg">
                                {desc}
                                {desc_bold && (
                                    <span className="text-lg">{desc_bold}</span>
                                )}
                                {desc_mid && desc_mid}
                                {path && (
                                    <NavHashLink
                                        onClick={() =>
                                            selectItemClick(name_1, path_url_1)
                                        }
                                        to={`${path}#isTop`}
                                        className="font-semibold text-secondary"
                                    >
                                        {path_name_1}
                                    </NavHashLink>
                                )}
                                {link_1 && (
                                    <a
                                        href={link_1}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="font-semibold text-secondary"
                                    >
                                        {link_name_1}
                                    </a>
                                )}
                                {endOfDesc_1 && endOfDesc_1}
                                {list_1 && (
                                    <ul className="mt-6">
                                        {list_1.map((item) => (
                                            <li
                                                key={item}
                                                className="mb-2 text-lg"
                                            >
                                                • {item}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </p>
                            {desc_2 && (
                                <p className="mt-2 mb-6 text-lg">
                                    {desc_2}
                                    {path_2 && (
                                        <NavHashLink
                                            onClick={() =>
                                                selectItemClick(
                                                    name_2,
                                                    path_url_2,
                                                )
                                            }
                                            to={`${path_2}#isTop`}
                                            className="font-semibold text-secondary"
                                        >
                                            {path_name_2}
                                        </NavHashLink>
                                    )}
                                    {endOfDesc_2 && endOfDesc_2}
                                    {list_2 && (
                                        <ul className="mt-6">
                                            {list_2.map((item) => (
                                                <li
                                                    key={item}
                                                    className="mb-2 text-lg"
                                                >
                                                    • {item}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </p>
                            )}
                            {desc_3 && (
                                <p className="mt-2 mb-6 text-lg">
                                    {desc_3}
                                    {desc_bold_3 && (
                                        <span className="text-lg font-semibold">
                                            {desc_bold_3}
                                        </span>
                                    )}
                                    {path_3 && (
                                        <NavHashLink
                                            onClick={() =>
                                                selectItemClick(
                                                    name_3,
                                                    path_url_3,
                                                )
                                            }
                                            to={`${path_3}#isTop`}
                                            className="font-semibold text-secondary "
                                        >
                                            {path_name_3}
                                        </NavHashLink>
                                    )}
                                    {endOfDesc_3 && endOfDesc_3}
                                    {link_3 && (
                                        <a
                                            href={link_3}
                                            rel="noreferrer"
                                            target="_blank"
                                            className="font-semibold text-secondary "
                                        >
                                            {link_name_3}
                                        </a>
                                    )}
                                </p>
                            )}
                            {list && (
                                <ul className="my-6">
                                    {list.map((item) => (
                                        <li key={item} className="mb-2 text-lg">
                                            • {item}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {list_3 && (
                                <ul className="my-6">
                                    {list_3.map((item) => (
                                        <li key={item} className="mb-2 text-lg">
                                            • {item}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {desc_4 && (
                                <p className="mt-2 mb-6 text-lg">
                                    {desc_4}
                                    {desc_bold_4 && (
                                        <span className="text-lg font-semibold">
                                            {desc_bold_4}
                                        </span>
                                    )}
                                    {path_4 && (
                                        <NavHashLink
                                            onClick={() =>
                                                selectItemClick(
                                                    name_4,
                                                    path_url_4,
                                                )
                                            }
                                            to={`${path_4}#isTop`}
                                            className="font-semibold text-secondary "
                                        >
                                            {path_name_4}
                                        </NavHashLink>
                                    )}
                                    {endOfDesc_4 && endOfDesc_4}
                                </p>
                            )}

                            {list_4 && (
                                <ul className="my-6">
                                    {list_4.map((item) => (
                                        <li key={item} className="mb-2 text-lg">
                                            • {item}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {list && (
                                <ul className="my-6">
                                    {list.map((item) => (
                                        <li key={item} className="mb-2 text-lg">
                                            • {item}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {desc_5 && (
                                <p className="mt-2 mb-2 text-lg">{desc_5}</p>
                            )}
                        </>
                    )}

                    {children}
                </div>
            </div>
        </div>
    );
};
