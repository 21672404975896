import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { NavHashLink } from "react-router-hash-link";

interface IData {
    id: number;
    title: string;
    desc_1: string;
    desc_1_bold?: string;
    endOfDesc_1?: string;
    desc_2: string;
    desc_3?: string;
    desc_3_bold?: string;
    endOfDesc_3?: string;
    desc_4?: string;
    title_arrow?: boolean;
}
interface IDebtor {
    title: string;
    desc?: string;
    name_3?: string;
    name_4?: string;
    desc_1?: string;
    desc_2?: string;
    desc_3?: string;
    desc_4?: string;
    desc_5?: string;
    desc_6?: string;
    endOfDesc_3?: string;
    endOfDesc_4?: string;
    endOfDesc_5?: string;
    endOfDesc_6?: string;
    items?: IData[];
    path?: string;
    path_3?: string;
    path_4?: string;
    path_5?: string;
    path_name?: string;
    path_name_3?: string;
    path_name_4?: string;
    path_name_5?: string;
    path_url?: string;
    path_url_3?: string;
    path_url_5?: string;
    name?: string;
    name_5?: string;
}

export const DebtorMain: React.FC<IDebtor> = ({
    title,
    desc,
    desc_1,
    desc_2,
    desc_3,
    desc_4,
    desc_5,
    desc_6,
    endOfDesc_3,
    endOfDesc_6,
    items,
    path,
    path_3,
    path_4,
    path_name,
    path_name_3,
    path_name_4,
    endOfDesc_5,
    endOfDesc_4,
    path_name_5,
    path_5,
    name_3,
    name_4,
    name,
    name_5,
    path_url,
    path_url_5,
}) => {
    const selectItemClick = (
        name: string | undefined,
        path: string | undefined,
    ) => {
        localStorage.setItem("url", String(path));
        localStorage.setItem("selectQuestion", String(name));
        localStorage.setItem("selectMenuItem", String(name));
    };
    return (
        <div className="overflow-auto">
            {items ? (
                <div className="pt-4 mx-4 md:mx-0">
                    <p className="py-2 text-xl font-semibold ">{title}</p>
                    {desc && (
                        <p className="text-lg leading-6 md:leading-9">{desc}</p>
                    )}
                    {items.map((item) => (
                        <div className="pr-4 pl-5 text-justify" key={item.id}>
                            <ul className="mb-2 mt-4 md:mt-6 mb-6 text-lg font-semibold text-left list-disc">
                                <p className="text-lg font-semibold text-left flex items-center">
                                    <HiArrowNarrowRight className="text-2xl text-secondary mr-2" />{" "}
                                    {item.title}
                                </p>
                                <li className="pt-4 text-lg font-normal leading-6 md:leading-8">
                                    {item.desc_1}
                                    {item.desc_1_bold && (
                                        <span className="text-lg leading-6 md:leading-8">
                                            {item.desc_1_bold}
                                        </span>
                                    )}
                                    {item.endOfDesc_1 && item.endOfDesc_1}
                                </li>
                                <li className="text-lg font-normal leading-6 md:leading-8">
                                    {item.desc_2}
                                </li>
                                <li className="text-lg font-normal leading-6 md:leading-8">
                                    {item.desc_3}
                                    {item.desc_3_bold && (
                                        <span className="text-lg leading-6 md:leading-8">
                                            {item.desc_3_bold}
                                        </span>
                                    )}
                                    {item.endOfDesc_3 && item.endOfDesc_3}
                                </li>
                                {item.desc_4 && (
                                    <li className="text-lg font-normal leading-6 md:leading-8">
                                        {item.desc_4}
                                    </li>
                                )}
                            </ul>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="pt-0 pl-6 pr-6 md:pt-4">
                    <p className="my-2 text-xl font-semibold">{title}</p>
                    <p className="py-4 text-lg leading-6 md:leading-9">
                        {desc_1}
                    </p>
                    <p className="py-4 text-lg leading-6 md:leading-9">
                        {desc_2}
                    </p>
                    <p className="py-4 text-lg leading-6 md:leading-9">
                        {desc_3}{" "}
                        {path_3 && (
                            <NavHashLink
                                onClick={() => selectItemClick(name_3, path_3)}
                                to={`${path_3}#top`}
                                className="font-semibold text-secondary "
                            >
                                {path_name_3}
                            </NavHashLink>
                        )}{" "}
                        {endOfDesc_3}
                    </p>

                    <p className="py-1 text-lg">
                        {desc_4}
                        {path_4 && (
                            <NavHashLink
                                onClick={() => selectItemClick(name_4, path_4)}
                                to={`${path_4}`}
                                className="font-semibold uppercase text-secondary "
                            >
                                {path_name_4}
                            </NavHashLink>
                        )}
                        {endOfDesc_4 && endOfDesc_4}
                    </p>
                    {desc_5 && (
                        <p className="py-1 text-lg">
                            {desc_5}
                            {path_5 && (
                                <NavHashLink
                                    onClick={() =>
                                        selectItemClick(name_5, path_url_5)
                                    }
                                    to={`${path_5}#isTop`}
                                    className="font-semibold uppercase text-secondary "
                                >
                                    {path_name_5}
                                </NavHashLink>
                            )}
                            {endOfDesc_5 && endOfDesc_5}
                        </p>
                    )}
                    {desc_6 && (
                        <p className="py-1 text-lg">
                            {desc_6}
                            <NavHashLink
                                onClick={() => selectItemClick(name, path_url)}
                                to={`${path}#isTop`}
                                className="font-semibold uppercase text-secondary "
                            >
                                {path_name}
                            </NavHashLink>

                            {endOfDesc_6}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};
