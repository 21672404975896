import { TFunction } from "i18next";

export const assetsListData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("assetsListData.title")}`,
        desc_1: `${t("assetsListData.desc_1")}`,
        desc_2: `${t("assetsListData.desc_2")}`,
        desc_3: `${t("assetsListData.desc_3")}`,
    };
};
