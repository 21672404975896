import { Tooltip } from "@components/common/Tooltip";
import React from "react";
import clsx from "clsx";

interface ITextarea extends React.TextareaHTMLAttributes<string> {
    label?: string;
    placeholder?: string;
    name: string;
    className?: string;
    required?: boolean;
    value?: string;
    onChange?: (arg0: any) => void;
    rows?: number;
    tip?: string;
}

export const Textarea: React.FC<ITextarea> = ({
    label,
    placeholder,
    name,
    className,
    onChange,
    value,
    rows,
    tip,
}) => (
    <div className={clsx(className)}>
        {label && (
            <label htmlFor={name} className="mb-1 text-lg text-[#4b4b4b]">
                {label}
                {tip && <Tooltip hint={tip} />}
            </label>
        )}

        <textarea
            className="focus:outline-none focus:ring-secondary focus:ring-1 border mt-1 mb-1 focus:border-secondary border-[#4b4b4b] block rounded-md py-2 placeholder:opacity-50 placeholder:text-neutral w-full placeholder:text-[14px] text-lg p-2 "
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            rows={rows}
        />
    </div>
);
