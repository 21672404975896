import React from "react";
import { noticeData } from "@config/forms";
import { Case, CheckedContainer } from "@components/components";
import { useTranslation } from "react-i18next";

interface INotice {
    onCheckboxChange?: (arg0: any) => void;
    checked: boolean[];
    onRadioChange?: (arg0: any) => void;
    radioChecked: boolean[];
}
export const Notice: React.FC<INotice> = ({ checked, onCheckboxChange }) => {
    const { t } = useTranslation();
    const { agreement, additionalInfo } = noticeData(t);

    return (
        <>
            <div className="mb-8 base-bord">
                <Case />
            </div>

            <CheckedContainer
                data={agreement}
                onCheckboxChange={onCheckboxChange}
                checked={Object(checked)}
            />
            {/*<div className="my-8">*/}
            {/*    <HasData*/}
            {/*        data={hasAdditional}*/}
            {/*        radioChecked={radioChecked}*/}
            {/*        onRadioChange={onRadioChange}*/}
            {/*    />*/}
            {/*</div>*/}
            <CheckedContainer
                data={additionalInfo}
                onCheckboxChange={onCheckboxChange}
                checked={Object(checked)}
            />
        </>
    );
};
