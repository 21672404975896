export const checkedState = {
    agree: false,
    agree_1: false,

    agree2_1: false,
    agree2_2: false,
    agree2_3: false,

    point1: false,
    point2: false,
    point3: false,
    point4: false,
    point5: false,
    point6: false,
    point7: false,
    point8: false,
};
