import React, { useState } from "react";
import { Input } from "@components/common";
import { useTranslation } from "react-i18next";
import DatePicker from "react-multi-date-picker";
import type { Value } from "react-multi-date-picker";
import { useLocation } from "react-router-dom";

import { caseData } from "@config/forms";

interface ICase {
    isCase?: boolean;
    onChange?: (arg0: any) => void;
}

export const Case: React.FC<ICase> = ({ isCase, onChange }) => {
    const { t } = useTranslation();
    const { label, type, name, number } = caseData(t);
    const date = new Date();
    const [value, setValue] = useState<Value>(new Date());
    const currentDate = date.toISOString().split("T")[0];
    const current = value?.toString().split("T")[0];
    const location = useLocation();

    const pathWithoutCase = location.pathname.includes("claimant/form_4");
    localStorage.setItem("currentDate", current || currentDate);

    return (
        <div className="mr-6 base-border md:mr-0 ">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="flex flex-col ">
                    <p className="mb-1 text-lg text-[#4B4B4B]">
                        {t("other.caseData.title")}
                    </p>
                    {/*<div className="w-full text-lg p-2 border rounded-md border-borderColor">*/}
                    {/*    {currentDate}*/}
                    {/*</div>*/}
                    <DatePicker
                        value={value}
                        onChange={setValue}
                        inputClass="w-full text-lg p-2 border rounded-md border-[#4b4b4b] focus:border-secondary focus:outline-none focus:ring-secondary  focus:ring-1"
                    />
                </div>
                {isCase && !pathWithoutCase && (
                    <Input
                        label={label}
                        type={type}
                        name={name}
                        number={number}
                        form
                        onChange={onChange}
                        pattern={true}
                    />
                )}
            </div>
        </div>
    );
};
