import React from "react";

export const SideIcon6 = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.2916 5.55623L12.2411 0.244639C12.0995 0.0956408 11.9051 0.0120809 11.7017 0.0120809H11.4974C11.4774 0.0100675 11.4605 0 11.4395 0C11.4196 0 11.4027 0.0100675 11.3817 0.0120809H5.07002C2.5926 0.0120809 0.5 2.07692 0.5 4.52129V8.81607C0.5 9.23286 0.834975 9.57113 1.24771 9.57113C1.66045 9.57113 1.99543 9.23286 1.99543 8.81607V4.52129C1.99543 2.8954 3.40312 1.5222 5.07002 1.5222H10.6918V3.68569C10.6918 5.52703 12.1763 7.02809 13.9997 7.03211H14.0007C14.4134 7.03211 14.7474 6.69486 14.7484 6.27907C14.7494 5.86127 14.4154 5.523 14.0027 5.522C13.0018 5.51998 12.1873 4.69647 12.1873 3.68569V2.36887L16.0046 6.38276V15.4435C16.0046 17.1227 14.6726 18.4899 13.0337 18.4899H5.07002C3.28847 18.4899 1.99543 17.2083 1.99543 15.4435V12.9206C1.99543 12.5038 1.66045 12.1655 1.24771 12.1655C0.834975 12.1655 0.5 12.5038 0.5 12.9206V15.4435C0.5 18.0409 2.46399 20 5.07002 20H13.0337C15.4971 20 17.5 17.9553 17.5 15.4435V6.07973C17.5 5.88443 17.4252 5.69717 17.2916 5.55623Z"
                fill="white"
            />
            <path
                d="M8.2467 11.8801C8.04846 11.8801 7.88693 11.8381 7.76211 11.7541C7.64464 11.6632 7.58957 11.5337 7.59692 11.3658C7.60426 11.044 7.67401 10.7676 7.80617 10.5367C7.94567 10.3058 8.1072 10.1344 8.29075 10.0225C8.48164 9.90355 8.66887 9.7986 8.85242 9.70765C9.03598 9.61669 9.19016 9.51524 9.31498 9.4033C9.44714 9.28436 9.51322 9.14443 9.51322 8.98351C9.51322 8.74563 9.42144 8.56022 9.23789 8.42729C9.06167 8.28736 8.81204 8.21739 8.48899 8.21739C8.06314 8.21739 7.72907 8.33633 7.48678 8.57422C7.25184 8.8051 7.10866 9.08846 7.05727 9.42429C7.04993 9.44528 6.95815 9.44878 6.78194 9.43478C6.61307 9.41379 6.44053 9.34383 6.26432 9.22489C6.08811 9.09895 6 8.92054 6 8.68966C6 8.26987 6.22394 7.88506 6.67181 7.53524C7.11968 7.17841 7.73275 7 8.51101 7C9.28194 7 9.88767 7.17142 10.3282 7.51424C10.7761 7.85707 11 8.31185 11 8.87856C11 9.2004 10.9302 9.47676 10.7907 9.70765C10.6512 9.93153 10.4787 10.1099 10.2731 10.2429C10.0749 10.3688 9.87298 10.4913 9.6674 10.6102C9.46916 10.7221 9.29295 10.8586 9.13877 11.0195C8.98458 11.1804 8.90015 11.3728 8.88546 11.5967C8.83407 11.7856 8.62115 11.8801 8.2467 11.8801ZM8.15859 14C7.67401 14 7.43172 13.7691 7.43172 13.3073V13.1604C7.43172 12.6987 7.67401 12.4678 8.15859 12.4678H8.35683C8.85609 12.4678 9.10573 12.6987 9.10573 13.1604V13.3073C9.10573 13.7691 8.85609 14 8.35683 14H8.15859Z"
                fill="white"
            />
        </svg>
    );
};
