import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import { getNumber } from "../../services";

export const StickyHeader = () => {
    const { t } = useTranslation();
    const [current, setCurrent] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    function changeNumber(
        from: number,
        to: number,
        delay: number,
        count: number,
    ) {
        let currentNum = from;
        const timerId = setInterval(function () {
            currentNum += count;
            setCurrent(currentNum);
            if (currentNum >= to) {
                clearInterval(timerId);
            }
        }, delay);
    }

    useLayoutEffect(() => {
        setLoading(true);
        getNumber()
            .then(({ data }) => {
                changeNumber(data || 43062, 100000000, 2000, 1);
                setLoading(false);
            })
            .catch((error) => console.log(error.message));
    }, []);

    function commify(n: number) {
        const parts = n.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return (
            numberPart.replace(thousands, " ") +
            (decimalPart ? "." + decimalPart : "")
        );
    }

    return (
        <div className="bg-[#FEF8F3] fixed z-50 top-0 left-0 right-0 md:static ">
            <div className="h-[69px] container relative flex flex-row items-center md:justify-end justify-between 2xl:pl-[100px] 2xl:pr-[100px]">
                <p className="font-bold lg:text-[24px] text-[14px] leading-[16px] text-[#000000]">
                    {t("header.sticky")}
                </p>
                <p className="flex md:ml-[112px] ml-[15px] items-center">
                    {current && (
                        <>
                            <span className="font-bold lg:text-[32px] lg:leading-[52px] text-[16px] leading-[20px] text-[#000000] mr-1">
                                €
                            </span>
                            <span className="font-bold lg:text-[32px] lg:leading-[52px] min-w-[70px] text-[16px] leading-[20px] text-[#000000] bg-[#FEF8F3]">
                                {commify(current)}
                            </span>
                            {/*<NumericFormat*/}
                            {/*    value={current}*/}
                            {/*    thousandsGroupStyle="thousand"*/}
                            {/*    thousandSeparator=" "*/}
                            {/*    className="cursor-default md:max-w-[160px] max-w-[80px] font-bold lg:text-[32px] lg:leading-[52px] text-[16px] leading-[20px] text-[#000000] bg-[#FEF8F3]"*/}
                            {/*/>*/}
                        </>
                    )}
                    {loading && (
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="40"
                            visible={true}
                        />
                    )}
                </p>
            </div>
        </div>
    );
};
