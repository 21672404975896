import { AppRoute } from "@enums";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HiOutlineArrowCircleRight } from "react-icons/hi";
import { navListData, socialLinksData } from "@config/common";
import clsx from "clsx";
import { Button } from "@components/components";
import { useCookies } from "react-cookie";

interface IFooter {
    className?: string;
}

export const Footer: React.FC<IFooter> = ({ className }) => {
    const [info, setInfo] = useState(true);
    const [cookies, setCookie] = useCookies(["user"]);
    const { t } = useTranslation();
    const handleClick = () => {
        setInfo(false);
    };
    const handleCookie = () => {
        setCookie("user", "JohnDoe", {
            path: "/",
        });
        setInfo(false);
    };

    useEffect(() => {
        localStorage.getItem("url");
    });
    const url = localStorage.getItem("url");

    const handleFooterClick = (path: string) => {
        localStorage.setItem("url", path);
        // window.location.reload();
    };

    return (
        <div
            className={clsx(
                className,
                "flex flex-col items-center justify-center px-6 pt-20 mt-8 font-semibold text-white md:mt-0 md:pt-16 bg-neutral",
            )}
        >
            <div className="container flex flex-col flex-wrap items-center justify-center md:flex-row">
                {navListData(t).map((item) => (
                    <NavHashLink
                        onClick={() => {
                            handleFooterClick(item.path);
                        }}
                        smooth
                        to={`${item.path}#isTop`}
                        scroll={(el) =>
                            el.scrollIntoView({
                                behavior: "auto",
                                block: "end",
                            })
                        }
                        className={clsx(
                            "p-2 text-base uppercase md:p-4 hover:text-secondary ",
                            url?.includes(item.path) ? "activeFooterItem" : "",
                        )}
                        key={item.id}
                    >
                        {item.content}
                    </NavHashLink>
                ))}
            </div>

            <div
                className={clsx(
                    "flex items-center justify-center pt-10 py-10",
                    info && "",
                )}
            >
                {socialLinksData.map((item) => (
                    <a
                        target="_blank"
                        href={item.path}
                        className="flex items-center justify-center w-10 h-10 mx-2 rounded-full bg-secondary "
                        key={item.id}
                        rel="noreferrer"
                    >
                        {<item.icon fill="white" width="20" height="20" />}
                    </a>
                ))}
            </div>
            {info && !cookies.user && (
                <>
                    <div className="fixed bottom-0 z-20 w-full px-8 py-8 border-t lg:px-32 bg-neutral border-neutral-400 ">
                        <div className="flex flex-col justify-between w-3/4 xl:w-full lg:w-3/4 md:flex-row">
                            <div className="flex flex-col w-full">
                                <p className="pb-2 text-sm font-light text-neutral-400">
                                    {t("cookiesData.desc")}
                                </p>

                                <Link
                                    to={`${AppRoute.COOKIES_INFO}#isTop`}
                                    target="_blank"
                                    className="flex items-center"
                                >
                                    <p className="mr-4 text-sm font-semibold text-secondary">
                                        {t("cookiesData.link_name")}
                                    </p>
                                    <HiOutlineArrowCircleRight className="text-2xl text-secondary" />
                                </Link>
                            </div>
                            <div className="flex mt-8 md:mt-0">
                                <Button
                                    small
                                    white
                                    onClick={handleClick}
                                    className="mr-4 min-w-[90px]"
                                >
                                    {t("cookiesData.btn_disagree")}
                                </Button>
                                <Button small white onClick={handleCookie}>
                                    {t("cookiesData.btn_agree")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
