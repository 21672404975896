import React from "react";
import { debtorData } from "@config/debtor";
import { DebtorMain } from "@components/components";
import { useTranslation } from "react-i18next";

export const Debtor = () => {
    const { t } = useTranslation();
    const {
        title,
        desc_1,
        desc_2,
        desc_3,
        desc_4,
        desc_5,
        endOfDesc_3,
        path_3,
        path_name_3,
    } = debtorData(t);
    return (
        <div className="flex flex-col">
            <DebtorMain
                title={title}
                desc_1={desc_1}
                desc_2={desc_2}
                desc_3={desc_3}
                desc_4={desc_4}
                desc_5={desc_5}
                endOfDesc_3={endOfDesc_3}
                path_3={path_3}
                path_name_3={path_name_3}
            />
        </div>
    );
};
