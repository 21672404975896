export const radioState = {
    period: "",
    percent: "",
    active: "",

    role: "",

    paycheck_true: "",
    paycheck_false: "",

    bank: "",

    bank2: "",

    re_claimant: "",
    re_debtor: "",
};
