import { TFunction } from "i18next";

export const debtorData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("debtorData.title")}`,
        desc_1: `${t("debtorData.desc_1")}`,
        desc_2: `${t("debtorData.desc_2")}`,
        desc_3: `${t("debtorData.desc_3")}`,
        path_3: "https://www.eesti.ee/",
        path_name_3: "www.eesti.ee",
        endOfDesc_3: `${t("debtorData.endOfDesc_3")}`,
        desc_4: `${t("debtorData.desc_4")}`,
        desc_5: `${t("debtorData.desc_5")}`,
    };
};
