import React from "react";
import { DividedInput, Input } from "@components/components";
import { SectionContainer } from ".";

interface IFields {
    id: number;
    name: string;
    label: string;
    type: string;
    number?: boolean;
}
interface IDividedFields {
    id: number;
    elem: string;
    placeholder: string;
    type: string;
    name: string;
    number?: boolean;
}
interface IData {
    title: string;
    fields: IFields[];
    divided_fields?: IDividedFields[];
}
interface IDetails {
    data: IData;
    onChange?: (arg0: any) => void;
}

export const Details: React.FC<IDetails> = ({ data, onChange }) => {
    const { title, divided_fields, fields } = data;
    return (
        <SectionContainer title={title}>
            <div className="base-border">
                <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                    {divided_fields?.map((item) => (
                        <DividedInput
                            onChange={onChange}
                            type={item.type}
                            name={item.name}
                            elem={item.elem}
                            placeholder={item.placeholder}
                            key={item.id}
                            number={item.number}
                        />
                    ))}
                </div>
                <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                    {fields.map((item) => (
                        <Input
                            onChange={onChange}
                            type={item.type}
                            name={item.name}
                            key={item.id}
                            label={item.label}
                            form
                            number={item.number}
                        />
                    ))}
                </div>
            </div>
        </SectionContainer>
    );
};
