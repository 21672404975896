import { ITableForm } from "@types";
import React from "react";
import {
    BailiffData,
    CheckedList,
    DownloadList,
    ListTable,
    Radio,
    SimpleTable,
} from "@components/components";
import { monthlyMinimumData, scheduleData } from "@config/forms";
import { useTranslation } from "react-i18next";

export const TableDebtorForm6: React.FC<ITableForm> = ({ id, myRef }) => {
    const { t } = useTranslation();
    const { values } = scheduleData(t).banks;
    const {
        claimantHasRepresentative,
        debtorHasRepresentative,
        debtorHasDependent,
    } = scheduleData(t);

    const debtorName = localStorage.getItem("debtorName");
    const debtorId = localStorage.getItem("debtorId");
    const debtorAddress = localStorage.getItem("debtorAddress");
    const debtorEmail = localStorage.getItem("debtorEmail");
    const debtorPhone = `+${localStorage.getItem(
        "pre_debtor_phone",
    )} ${localStorage.getItem("debtor_phone")}`;

    const addBank = localStorage.getItem("addBank");
    const addBank2 = localStorage.getItem("addBank2");
    const amount = localStorage.getItem("amount");
    const date = localStorage.getItem("date");
    const firstPayment = localStorage.getItem("firstPayment");
    const limit1 = localStorage.getItem("limit1");

    const amount3 = localStorage.getItem("amount3");
    const current3 = localStorage.getItem("current3");
    const summa = localStorage.getItem("summa");

    const claimantName1 = localStorage.getItem("claimantName1");
    const claimantId1 = localStorage.getItem("claimantId1");
    const claimantContent1 = localStorage.getItem("claimantContent1");

    const placeOwner = localStorage.getItem("placeOwner");
    const placeAddress = localStorage.getItem("placeAddress");
    const placeBroker = localStorage.getItem("placeBroker");
    const placePost = localStorage.getItem("placePost");
    const placePhone = `+${localStorage.getItem(
        "pre_placePhone",
    )} ${localStorage.getItem("placePhone")}`;
    const placePrice = localStorage.getItem("placePrice");
    const placeDate = localStorage.getItem("placeDate");
    const notary = localStorage.getItem("notary");

    const additional = localStorage.getItem("additional");
    const additional1 = localStorage.getItem("additional1");
    const additional2 = localStorage.getItem("additional2");
    const additional3 = localStorage.getItem("additional3");
    const bank = localStorage.getItem("bank");
    const bank2 = localStorage.getItem("bank2");

    const re_dependent_name_1 = localStorage.getItem("re_dependent_name_1");
    const re_dependent_id_1 = localStorage.getItem("re_dependent_id_1");
    const re_dependent_name_2 = localStorage.getItem("re_dependent_name_2");
    const re_dependent_id_2 = localStorage.getItem("re_dependent_id_2");
    const re_dependent_name_3 = localStorage.getItem("re_dependent_name_3");
    const re_dependent_id_3 = localStorage.getItem("re_dependent_id_3");
    const re_dependent_name_4 = localStorage.getItem("re_dependent_name_4");
    const re_dependent_id_4 = localStorage.getItem("re_dependent_id_4");
    const re_dependent_name_5 = localStorage.getItem("re_dependent_name_5");
    const re_dependent_id_5 = localStorage.getItem("re_dependent_id_5");

    const reDebtor = localStorage.getItem("re_debtor");
    const reClaimant = localStorage.getItem("re_claimant");
    const reDependent = localStorage.getItem("re_dependent");

    const agree1 = localStorage.getItem("agree1");
    const agree2_1 = localStorage.getItem("agree2_1");
    const agree2_2 = localStorage.getItem("agree2_2");
    const agree2_3 = localStorage.getItem("agree2_3");

    const job = localStorage.getItem("job");
    const claims = localStorage.getItem("claims");
    const balance = localStorage.getItem("balance");
    const cars = localStorage.getItem("cars");
    const tech = localStorage.getItem("tech");
    const relative = localStorage.getItem("relative");
    const donate = localStorage.getItem("donate");
    const liabilities = localStorage.getItem("liabilities");

    const selectMenuItemFromStorage = JSON.parse(
        localStorage.getItem("selected_menu_arr") || "[]",
    );
    const current_date = localStorage.getItem("currentDate");
    const current_date1 = localStorage.getItem("currentDate1");
    const case_number = localStorage.getItem("caseNumber");
    return (
        <div className="print-container a4" id={id} ref={myRef}>
            <img
                src={require(`@assets/images/logo_new.png`)}
                alt="Logo"
                className="logo_print block ml-auto lg:w-52 w-36"
            />
            <div className="printElement1">
                <BailiffData />
                <p className="mb-6 font-[500] text-center text-[16px] fontWeight">
                    {t("tableDebtorForm6.title")}
                </p>
                <div className="mb-6">
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight ">
                            {t("sharedClaimantForm.current_date")}:
                        </p>
                        <p className="text-[16px] tracking-wide">
                            {current_date}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.case_number")}:
                        </p>
                        <p className="text-[16px]">
                            {case_number === "undefined" ? "-" : case_number}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight ">
                            Kinnitan, et sain täitmisteate kätte:{" "}
                        </p>
                        <p className="text-[16px]">{current_date1}</p>
                    </div>
                </div>
                <SimpleTable
                    className="mb-4"
                    title={t("tableDebtorForm6.debtor.title")}
                    thead_1={t("tableDebtorForm6.debtor.thead_1")}
                    thead_2={t("tableDebtorForm6.debtor.thead_2")}
                    thead_3={t("tableDebtorForm6.debtor.thead_3")}
                    thead_4={t("tableDebtorForm6.debtor.thead_4")}
                    thead_5={t("tableDebtorForm6.debtor.thead_5")}
                    tbody_1_1={debtorName || ""}
                    tbody_2_1={debtorId || ""}
                    tbody_3_1={debtorAddress || ""}
                    tbody_4_1={debtorEmail || ""}
                    tbody_5_1={debtorPhone || ""}
                    row={1}
                />

                {selectMenuItemFromStorage.includes("schedule") && (
                    <div className="mb-4" id="schedule">
                        <p className="mb-2 font-[500] text-center text-[16px] fontWeight ">
                            {t("tableDebtorForm6.schedule.title")}
                        </p>
                        <SimpleTable
                            className="mb-4"
                            title={t(
                                "tableDebtorForm6.schedule.pay_debt.title",
                            )}
                            thead_1={t(
                                "tableDebtorForm6.schedule.pay_debt.thead_1",
                            )}
                            thead_2={t(
                                "tableDebtorForm6.schedule.pay_debt.thead_2",
                            )}
                            thead_3={t(
                                "tableDebtorForm6.schedule.pay_debt.thead_3",
                            )}
                            tbody_1_1={`${amount} €` || ""}
                            tbody_2_1={date || ""}
                            tbody_3_1={firstPayment || ""}
                            row={1}
                        />
                        <Radio
                            className="mb-4"
                            col
                            bank
                            noUpper
                            title={t(
                                "tableDebtorForm6.schedule.non_revenue_title",
                            )}
                            rad1={values[0].value}
                            rad2={values[1].value}
                            rad3={values[2].value}
                            rad4={values[3].value}
                            rad5={values[4].value}
                            rad6={values[5].value}
                            rad7={values[6].value}
                            rad8={values[7].value}
                            rad9={values[8].value}
                            val1={bank === values[0].value}
                            val2={bank === values[1].value}
                            val3={bank === values[2].value}
                            val4={bank === values[3].value}
                            val5={bank === values[4].value}
                            val6={bank === values[5].value}
                            val7={bank === values[6].value}
                            val8={bank === values[7].value}
                            val9={bank === values[8].value}
                        />

                        {bank === t("scheduleData.banks.values.8.value") && (
                            <div className="flex items-center mb-4 w-fit">
                                <p className="mr-2 text-[16px] font-[500] fontWeight">
                                    {t("tableDebtorForm6.schedule.add_bank")}
                                </p>
                                <p className="text-[16px]">
                                    {addBank === "undefined" ? "-" : addBank}
                                </p>
                            </div>
                        )}
                        <div className="mb-4">
                            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight">
                                {t("tableDebtorForm6.schedule.limit1")}
                            </p>
                            <div className="flex items-center">
                                <p className="text-[16px] text-[#1A1A1F]">
                                    {limit1 === "undefined" ? " " : limit1}
                                </p>
                                <p className="ml-2 text-[16px] text-[#1A1A1F]">
                                    €
                                </p>
                            </div>
                        </div>

                        <Radio
                            className="mb-4"
                            noUpper
                            title={claimantHasRepresentative.title}
                            rad1={claimantHasRepresentative.values[0].value}
                            rad2={claimantHasRepresentative.values[1].value}
                            val1={
                                reClaimant ===
                                claimantHasRepresentative.values[0].value
                            }
                            val2={
                                reClaimant ===
                                claimantHasRepresentative.values[1].value
                            }
                        />

                        <Radio
                            className="mb-4"
                            noUpper
                            title={debtorHasRepresentative.title}
                            rad1={debtorHasRepresentative.values[0].value}
                            rad2={debtorHasRepresentative.values[1].value}
                            val1={
                                reDebtor ===
                                debtorHasRepresentative.values[0].value
                            }
                            val2={
                                reDebtor ===
                                debtorHasRepresentative.values[1].value
                            }
                        />
                        <Radio
                            className="mb-4 marginBot"
                            noUpper
                            title={debtorHasDependent.title}
                            rad1={debtorHasDependent.values[0].value}
                            rad2={debtorHasDependent.values[1].value}
                            val1={
                                reDependent ===
                                debtorHasDependent.values[0].value
                            }
                            val2={
                                reDependent ===
                                debtorHasDependent.values[1].value
                            }
                        />
                        {reDependent ===
                            t(
                                "scheduleData.debtorHasDependent.values.0.value",
                            ) && (
                            <SimpleTable
                                className="mb-4"
                                title={""}
                                thead_1={t("tableDebtorForm6.schedule.thead_1")}
                                thead_2={t("tableDebtorForm6.schedule.thead_2")}
                                tbody_1_1={re_dependent_name_1 || ""}
                                tbody_2_1={re_dependent_id_1 || ""}
                                tbody_1_2={re_dependent_name_2 || ""}
                                tbody_2_2={re_dependent_id_2 || ""}
                                tbody_1_3={re_dependent_name_3 || ""}
                                tbody_2_3={re_dependent_id_3 || ""}
                                tbody_1_4={re_dependent_name_4 || ""}
                                tbody_2_4={re_dependent_id_4 || ""}
                                tbody_1_5={re_dependent_name_5 || ""}
                                tbody_2_5={re_dependent_id_5 || ""}
                            />
                        )}

                        <p className="font-[500] text-[16px] mb-2 fontWeight">
                            {t("tableDebtorForm6.schedule.additional")}
                        </p>
                        <p className="text-[16px] max-w-[1200px] w-full">
                            {additional1 === "undefined" ? "-" : additional1}
                        </p>
                    </div>
                )}

                {selectMenuItemFromStorage.includes("minimum") && (
                    <div className="mb-4" id="minimum">
                        <p className="mb-2 font-[500] text-center text-[16px] fontWeight">
                            {t("tableDebtorForm6.minimum.title")}
                        </p>
                        <Radio
                            bank
                            noUpper
                            col
                            className="mb-4"
                            title={t("tableDebtorForm6.minimum.bank_title")}
                            rad1={monthlyMinimumData(t).banks.points[0].value}
                            rad2={monthlyMinimumData(t).banks.points[1].value}
                            rad3={monthlyMinimumData(t).banks.points[2].value}
                            rad4={monthlyMinimumData(t).banks.points[3].value}
                            rad5={monthlyMinimumData(t).banks.points[4].value}
                            rad6={monthlyMinimumData(t).banks.points[5].value}
                            rad7={monthlyMinimumData(t).banks.points[6].value}
                            rad8={monthlyMinimumData(t).banks.points[7].value}
                            rad9={monthlyMinimumData(t).banks.points[8].value}
                            val1={
                                bank2 ===
                                monthlyMinimumData(t).banks.points[0].value
                            }
                            val2={
                                bank2 ===
                                monthlyMinimumData(t).banks.points[1].value
                            }
                            val3={
                                bank2 ===
                                monthlyMinimumData(t).banks.points[2].value
                            }
                            val4={
                                bank2 ===
                                monthlyMinimumData(t).banks.points[3].value
                            }
                            val5={
                                bank2 ===
                                monthlyMinimumData(t).banks.points[4].value
                            }
                            val6={
                                bank2 ===
                                monthlyMinimumData(t).banks.points[5].value
                            }
                            val7={
                                bank2 ===
                                monthlyMinimumData(t).banks.points[6].value
                            }
                            val8={
                                bank2 ===
                                monthlyMinimumData(t).banks.points[7].value
                            }
                            val9={
                                bank2 ===
                                monthlyMinimumData(t).banks.points[8].value
                            }
                        />

                        {bank2 ===
                            t("monthlyMinimumData.banks.points.8.value") && (
                            <div className="flex items-center mb-4 w-fit ">
                                <p className="text-[16px] font-[500] fontWeight">
                                    {t("tableDebtorForm6.minimum.add_bank")}
                                </p>
                                <p className="ml-2 text-[16px]">
                                    {addBank2 === "undefined" ? "-" : addBank2}
                                </p>
                            </div>
                        )}
                        <Radio
                            className="mb-4"
                            noUpper
                            title={debtorHasDependent.title}
                            rad1={debtorHasDependent.values[0].value}
                            rad2={debtorHasDependent.values[1].value}
                            val1={
                                reDependent ===
                                debtorHasDependent.values[0].value
                            }
                            val2={
                                reDependent ===
                                debtorHasDependent.values[1].value
                            }
                        />
                        {reDependent ===
                            t(
                                "scheduleData.debtorHasDependent.values.0.value",
                            ) && (
                            <SimpleTable
                                title={""}
                                thead_1={t("tableDebtorForm6.minimum.thead_1")}
                                thead_2={t("tableDebtorForm6.minimum.thead_2")}
                                tbody_1_1={re_dependent_name_1 || ""}
                                tbody_2_1={re_dependent_id_1 || ""}
                                tbody_1_2={re_dependent_name_2 || ""}
                                tbody_2_2={re_dependent_id_2 || ""}
                                tbody_1_3={re_dependent_name_3 || ""}
                                tbody_2_3={re_dependent_id_3 || ""}
                                tbody_1_4={re_dependent_name_4 || ""}
                                tbody_2_4={re_dependent_id_4 || ""}
                                tbody_1_5={re_dependent_name_5 || ""}
                                tbody_2_5={re_dependent_id_5 || ""}
                                className="mb-4"
                            />
                        )}

                        <p className="font-[500] text-[16px] fontWeight mb-2">
                            {t("tableDebtorForm6.minimum.additional")}
                        </p>
                        <p className="h-24 max-w-[1200px] w-full">
                            {additional2 === "undefined" ? "-" : additional2}
                        </p>
                    </div>
                )}

                {selectMenuItemFromStorage.includes("refund") && (
                    <div className="mb-4" id="refund">
                        <p className="mb-2 font-[500] text-center text-[16px] fontWeight">
                            {t("tableDebtorForm6.refund.title")}
                        </p>
                        <p className="mb-4 text-[16px] font-[500] fontWeight">
                            {t("tableDebtorForm6.refund.non_deductible")}
                        </p>

                        <div className="flex items-center mb-4 ">
                            <p className="mr-6 font-[500] fontWeight">
                                {t("tableDebtorForm6.refund.current_number")}
                            </p>
                            <div className="flex items-center justify-between p-1">
                                <p className="">
                                    {" "}
                                    {current3 === "undefined" ? "-" : current3}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center mb-4 ">
                            <p className="mr-6 font-[500] fontWeight">
                                {t("tableDebtorForm6.refund.amount")}
                            </p>
                            <div className="flex items-center justify-between p-1">
                                <p className="">
                                    {" "}
                                    {amount3 === "undefined" ? "-" : amount3}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center mb-4">
                            <p className="mr-6 font-[500] fontWeight">
                                {t("tableDebtorForm6.refund.summa")}
                            </p>
                            <div className="flex items-center justify-between p-1">
                                <p className="">
                                    {" "}
                                    {summa === "undefined" ? "-" : summa}
                                </p>{" "}
                                <p className="ml-2"> €</p>
                            </div>
                        </div>
                        <Radio
                            className="mb-4"
                            noUpper
                            title={debtorHasDependent.title}
                            rad1={debtorHasDependent.values[0].value}
                            rad2={debtorHasDependent.values[1].value}
                            val1={
                                reDependent ===
                                debtorHasDependent.values[0].value
                            }
                            val2={
                                reDependent ===
                                debtorHasDependent.values[1].value
                            }
                        />
                        {reDependent ===
                            t(
                                "scheduleData.debtorHasDependent.values.0.value",
                            ) && (
                            <SimpleTable
                                title={""}
                                thead_1={t("tableDebtorForm6.refund.thead_1")}
                                thead_2={t("tableDebtorForm6.refund.thead_2")}
                                tbody_1_1={re_dependent_name_1 || ""}
                                tbody_2_1={re_dependent_id_1 || ""}
                                tbody_1_2={re_dependent_name_2 || ""}
                                tbody_2_2={re_dependent_id_2 || ""}
                                tbody_1_3={re_dependent_name_3 || ""}
                                tbody_2_3={re_dependent_id_3 || ""}
                                tbody_1_4={re_dependent_name_4 || ""}
                                tbody_2_4={re_dependent_id_4 || ""}
                                tbody_1_5={re_dependent_name_5 || ""}
                                tbody_2_5={re_dependent_id_5 || ""}
                                className="mb-4"
                            />
                        )}
                        <p className="font-[500] text-[16px] mb-2 fontWeight">
                            {t("tableDebtorForm6.refund.additional")}
                        </p>
                        <p className="h-24 mb-4 max-w-[1200px] w-full">
                            {additional3 === "undefined" ? "-" : additional3}
                        </p>
                    </div>
                )}

                {selectMenuItemFromStorage.includes("notice") && (
                    <div className="mb-4" id="notice">
                        <p className="mb-2 font-[500] text-center text-[16px] fontWeight">
                            {t("tableDebtorForm6.notice.title")}
                        </p>
                        <ul className="mb-4">
                            <li className="my-1">
                                <p className="mb-1 font-[500] text-[16px] fontWeight">
                                    {t(
                                        "debtorForm5Data.assetsLiabilities.0.items.0.desc_1",
                                    )}
                                    :
                                </p>
                                <p className="h-12 text-[16px]">
                                    {job === "undefined" ? "-" : job}
                                </p>
                            </li>
                            <li className="my-1">
                                <p className="mb-1 font-[500] text-[16px] fontWeight">
                                    {t(
                                        "debtorForm5Data.assetsLiabilities.0.items.1.desc_1",
                                    )}
                                    :
                                </p>
                                <p className="h-12 text-[16px]">
                                    {claims === "undefined" ? "-" : claims}
                                </p>
                            </li>
                            <li className="my-1">
                                <p className="mb-1 font-[500] text-[16px] fontWeight">
                                    {t(
                                        "debtorForm5Data.assetsLiabilities.0.items.2.desc_1",
                                    )}
                                    :
                                </p>
                                <p className="h-12 text-[16px]">
                                    {balance === "undefined" ? "-" : balance}
                                </p>
                            </li>
                            <li className="my-1">
                                <p className="mb-1 font-[500] text-[16px] fontWeight">
                                    {t(
                                        "debtorForm5Data.assetsLiabilities.1.items.0.desc_1",
                                    )}
                                    :
                                </p>
                                <p className="h-12 text-[16px]">
                                    {cars === "undefined" ? "-" : cars}
                                </p>
                            </li>
                            <li className="my-1">
                                <p className="mb-1 font-[500] text-[16px] fontWeight">
                                    {t(
                                        "debtorForm5Data.assetsLiabilities.1.items.1.desc_1",
                                    )}
                                    :
                                </p>
                                <p className="h-12 text-[16px]">
                                    {tech === "undefined" ? "-" : tech}
                                </p>
                            </li>
                            <li className="my-1">
                                <p className="mb-1 font-[500] text-[16px] fontWeight">
                                    V
                                    {t(
                                        "debtorForm5Data.assetsLiabilities.1.items.2.desc_1",
                                    )}
                                    :
                                </p>
                                <p className="h-12 text-[16px]">
                                    {relative === "undefined" ? "-" : relative}
                                </p>
                            </li>
                            <li className="my-1">
                                <p className="mb-1 font-[500] text-[16px] fontWeight">
                                    {t(
                                        "debtorForm5Data.assetsLiabilities.1.items.3.desc_1",
                                    )}
                                    :
                                </p>
                                <p className="h-12 text-[16px]">
                                    {donate === "undefined" ? "-" : donate}
                                </p>
                            </li>
                            <li className="my-1">
                                <p className="mb-1 font-[500] text-[16px] fontWeight">
                                    {t(
                                        "debtorForm5Data.assetsLiabilities.1.items.4.desc_1",
                                    )}
                                    :
                                </p>
                                <p className="h-12 text-[16px]">
                                    {liabilities === "undefined"
                                        ? "-"
                                        : liabilities}
                                </p>
                            </li>
                        </ul>
                        <p className="mb-2 font-[500] fontWeight">
                            {t("tableDebtorForm5.info_1")}
                        </p>
                        <p className="font-[500] fontWeight">
                            {t("tableDebtorForm5.info_2")}
                        </p>
                    </div>
                )}

                {selectMenuItemFromStorage.includes("limitation") && (
                    <div className="mb-4" id="limitation">
                        <p className="mb-2 font-[500] text-center text-[16px] fontWeight">
                            {t("tableDebtorForm6.limitation.title")}
                        </p>
                        <SimpleTable
                            className="mb-4 "
                            title={""}
                            thead_1={t("tableDebtorForm6.limitation.thead_1")}
                            thead_2={t("tableDebtorForm6.limitation.thead_2")}
                            tbody_1_1={claimantName1 || ""}
                            tbody_2_1={claimantId1 || ""}
                            row={1}
                        />
                        <p className="font-[500] text-[16px] mb-2 fontWeight">
                            {t("tableDebtorForm6.limitation.claimantContent")}
                        </p>
                        <p className="h-24 mb-4">
                            {claimantContent1 === "undefined"
                                ? "-"
                                : claimantContent1}
                        </p>
                    </div>
                )}

                {selectMenuItemFromStorage.includes("sale") && (
                    <div className="mb-4" id="sale">
                        <p className="mb-2 font-[500] text-center text-[16px] fontWeight">
                            {t("tableDebtorForm6.sale.title")}
                        </p>
                        <SimpleTable
                            className="mb-4"
                            title={t("tableDebtorForm6.sale.place.title")}
                            thead_1={t("tableDebtorForm6.sale.place.thead_1")}
                            thead_2={t("tableDebtorForm6.sale.place.thead_2")}
                            tbody_1_1={placeOwner || ""}
                            tbody_2_1={placeAddress || ""}
                            row={1}
                        />
                        <ListTable
                            className="mb-4"
                            title=""
                            row_1={notary || ""}
                            row_title_1={t("tableDebtorForm6.sale.notary")}
                        />
                        <SimpleTable
                            className="mb-4"
                            title=""
                            thead_1={t(
                                "tableDebtorForm6.sale.paceBroker.thead_1",
                            )}
                            thead_2={t(
                                "tableDebtorForm6.sale.paceBroker.thead_2",
                            )}
                            thead_3={t(
                                "tableDebtorForm6.sale.paceBroker.thead_3",
                            )}
                            tbody_1_1={placeBroker || ""}
                            tbody_2_1={placePost || ""}
                            tbody_3_1={placePhone || ""}
                            row={1}
                        />
                        <ListTable
                            className="mb-4"
                            title=""
                            row_1={placePrice || ""}
                            row_2={placeDate || ""}
                            row_title_1={t(
                                "tableDebtorForm6.sale.placePrice.row_title_1",
                            )}
                            row_title_2={t(
                                "tableDebtorForm6.sale.placePrice.row_title_2",
                            )}
                        />
                    </div>
                )}

                {selectMenuItemFromStorage.includes("other") && (
                    <div id="other" className="mb-4">
                        <p className="mb-2 font-[500] text-center text-[16px] fontWeight">
                            {t("tableDebtorForm6.other.title")}
                        </p>
                        <p className="mb-4 text-[16px] font-[500] fontWeight">
                            {t("tableDebtorForm6.other.additional")}
                        </p>
                        <p className="text-[16px] max-w-[1200px] w-full">
                            {additional === "undefined" ? "-" : additional}
                        </p>
                    </div>
                )}
                <DownloadList keyEl="downloadForm_7" className="mb-4" />

                <CheckedList
                    className="mb-4"
                    title=""
                    point_1={t("tableDebtorForm6.checkbox_1")}
                    point_2={t("tableDebtorForm6.checkbox_2")}
                    point_3={t("tableDebtorForm6.checkbox_3")}
                    point_4={t("tableDebtorForm6.checkbox_4")}
                    sub_1={agree1 === "true"}
                    sub_2={agree2_1 === "true"}
                    sub_3={agree2_2 === "true"}
                    sub_4={agree2_3 === "true"}
                />
                <p className="font-[500] text-[16px] text-[#1A1A1F] mb-2 fontWeight">
                    {t("sharedClaimantForm.applicant")}
                    <span className="font-normal text-[16px] text-[#1A1A1F] ml-2">
                        {debtorName === "undefined" ? "-" : debtorName}
                    </span>
                </p>
                <p className="font-[500] text-[16px] text-[#1A1A1F] flex items-end fontWeight">
                    {t("sharedClaimantForm.signature")}
                    <span className="ml-2 h-[1px] w-[150px] bg-black" />
                </p>
            </div>
        </div>
    );
};
