import { SalaryIcon } from "@assets";
import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const calcSidebarData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            icon: SalaryIcon,
            title: `${t("calcSidebarData.0.title")}`,
            desc: `${t("calcSidebarData.0.desc")}`,
            // desc: "Arvutus ei arvesta võimaliku korteriühistu pandiõiguse nõuet.",
            path: AppRoute.CALCULATOR_SALARY,
        },
        // {
        //     id: 2,
        //     icon: AssetsIcon,
        //     title: "Võlgniku blanketid",
        //     desc: "Võlgnikul on võimalus esitada kohtutäiturile maksegraafiku avaldus ...",
        //     path: AppRoute.CALCULATOR_ASSETS,
        // },
    ];
};
