import React from "react";
import { formsSidebarData } from "@config/index";
import { SimpleFormsCard } from "@components/pages";
import { useTranslation } from "react-i18next";

export const Forms = () => {
    const { t } = useTranslation();
    const { title, children } = formsSidebarData(t)[1];
    return (
        <div className="mt-0 ml-6 mr-6 md:ml-0">
            <SimpleFormsCard title={title} items={children} />
        </div>
    );
};
