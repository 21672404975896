import React, { useEffect } from "react";
import { bailiffsListData } from "@config/home";
import { useTranslation } from "react-i18next";

export const BailiffData = () => {
    const { t } = useTranslation();
    const bailiff = Number(localStorage.getItem("bailiff")) || null;
    useEffect(() => {
        localStorage.getItem("bailiff");
    }, [bailiff]);
    return (
        <div className="flex flex-col mb-6">
            {bailiff && (
                <>
                    <div className="mb-4">
                        <p className="font-[500] text-[16px] fontWeight">
                            {
                                bailiffsListData(t)[bailiff - 1].contacts[3]
                                    .content
                            }
                        </p>
                        <p className="font-[500] text-[16px] mb-2 fontWeight">
                            {bailiffsListData(t)[bailiff - 1].name}
                        </p>
                        <p className="text-[16px]">
                            {t("bailiffData.address")}:{" "}
                            {
                                bailiffsListData(t)[bailiff - 1].contacts[0]
                                    .content
                            }
                        </p>
                        <p className="text-[16px] mb-2">
                            {
                                bailiffsListData(t)[bailiff - 1]
                                    .contacts_full[1].content
                            }
                        </p>
                        <p className="text-[16px]">
                            {t("bailiffData.phone")}:{" "}
                            {
                                bailiffsListData(t)[bailiff - 1].contacts[2]
                                    .content
                            }
                        </p>
                        <p className="text-[16px]">
                            {t("bailiffData.email")}:{" "}
                            {
                                bailiffsListData(t)[bailiff - 1].contacts[1]
                                    .content
                            }
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};
