import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosGlobe } from "react-icons/io";
import { ReactComponent as GbIcon } from "@assets/icons/flags/ukFlag.svg";
import { ReactComponent as RuIcon } from "@assets/icons/flags/rusFlag.svg";
import { ReactComponent as EstIcon } from "../../assets/icons/flags/estoniaFlag.svg";
import i18n from "../../i18n";
import clsx from "clsx";

interface ILanguageSelect {
    isMobile?: boolean;
}
export const LanguageSelect: React.FC<ILanguageSelect> = ({ isMobile }) => {
    const [open, setOpen] = useState(false);
    const [language, setLanguage] = useState(
        localStorage.getItem("language") || "ee",
    );
    const handleSelectClick = (lng: string) => {
        setLanguage(lng);
    };
    useEffect(() => {
        localStorage.setItem("language", language);
        i18n.changeLanguage(language);
    }, [language]);
    return (
        <div
            className="cursor-pointer relative z-50"
            onClick={() => setOpen(!open)}
        >
            {language ? (
                <>
                    <div className="relative flex">
                        {language && language === "ee" && (
                            <div className="flex items-center justify-center">
                                <IoIosGlobe className="text-2xl" />
                                <p className="px-2 uppercase w-[30px]">EE</p>

                                <IoIosArrowForward className="rotate-90 " />
                            </div>
                        )}
                    </div>
                    <div className="relative flex">
                        {language && language === "ru" && (
                            <div className="flex items-center justify-center">
                                <IoIosGlobe className="text-2xl" />
                                <p className="px-2 uppercase w-[30px]">ru</p>

                                <IoIosArrowForward className="rotate-90 " />
                            </div>
                        )}
                    </div>
                    <div className="relative flex">
                        {language && language === "en" && (
                            <div className="flex items-center justify-center">
                                <IoIosGlobe className="text-2xl" />
                                <p className="px-2 uppercase w-[30px]">en</p>

                                <IoIosArrowForward className="rotate-90 " />
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="flex items-center">
                    <IoIosGlobe
                        className={clsx(isMobile ? "text-4xl" : "text-2xl")}
                    />

                    {!isMobile && <p className="px-2 text-sm uppercase ">EE</p>}
                    <IoIosArrowForward className="rotate-90 " />
                </div>
            )}

            <div
                className={clsx(
                    "cursor-pointer z-20 absolute top-10 right-[-41px] w-72",
                    open ? "block" : "hidden",
                )}
            >
                <div className="relative pt-2 pb-4 bg-white border rounded-md border-neutral/10 ">
                    <>
                        <div
                            className="flex  items-center hover:bg-[#FEF8F3] "
                            onClick={() => handleSelectClick("ee")}
                        >
                            {/*<img*/}
                            {/*    src={require("@assets/images/countries/estonia.webp")}*/}
                            {/*    alt="country-image"*/}
                            {/*    className="mx-4"*/}
                            {/*/>*/}
                            <EstIcon width="24" height="16" className="mx-4" />
                            <div className="w-full py-2 border-b-[0.5px] border-neutral/10 ">
                                <p className="text-sm ">Eesti</p>
                            </div>
                        </div>
                        <div
                            className="flex  items-center hover:bg-[#FEF8F3] "
                            onClick={() => handleSelectClick("ru")}
                        >
                            <RuIcon width="24" height="16" className="mx-4" />

                            <div className="w-full py-2 border-b-[0.5px] border-neutral/10 ">
                                <p className="text-sm ">Русский</p>
                            </div>
                        </div>
                        <div
                            className="flex  items-center hover:bg-[#FEF8F3] "
                            onClick={() => handleSelectClick("en")}
                        >
                            <GbIcon width="24" height="16" className="mx-4" />

                            <div className="w-full py-2 border-b-[0.5px] border-neutral/10 ">
                                <p className="text-sm ">English</p>
                            </div>
                        </div>
                    </>
                    {/* <div
                        onClick={() => {
                            console.log("view");
                        }}
                        className="flex items-center justify-end mt-4 mr-4 cursor-pointer text-secondary"
                    >
                        <p className="">View all </p>
                        <IoIosArrowForward className="" />
                    </div> */}
                </div>
                <div className="absolute  w-4 h-4 z-10 rotate-45  right-10 bg-white border-l-neutral/10 border-t-neutral/10 bg-white border-r-transparent border-b-transparent border -top-[8px] "></div>
            </div>
        </div>
    );
};
