import React from "react";
import { NavLink } from "react-router-dom";
import { CircleArrow } from "@assets";

interface IItems {
    id: number;
    title: string;
    path: string;
}

interface ISimpleForms {
    items: IItems[];
    title: string;
}

export const SimpleFormsCard: React.FC<ISimpleForms> = ({ title, items }) => {
    return (
        <div className="flex flex-col w-full mt-4 md:mt-6 cursor-pointer md:w-5/6">
            <p className="mb-4 md:mb-6 text-xl font-semibold">{title}</p>
            {items.map((item) => (
                <NavLink
                    to={item.path}
                    className="flex items-center justify-between p-4 mb-4 bg-white border rounded-md md:px-6 md:py-8 hover:bg-primary border-neutral/10"
                    key={item.id}
                >
                    <p className="text-base font-semibold md:text-lg">
                        {item.title}
                    </p>
                    <div className="flex items-center justify-center">
                        <CircleArrow />
                    </div>
                </NavLink>
            ))}
        </div>
    );
};
