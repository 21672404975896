import React from "react";

export const FormIcon1 = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.7162 16.2236H8.49622"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7162 12.0371H8.49622"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.2513 7.86035H8.49634"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9086 2.75C15.9086 2.75 8.23161 2.754 8.21961 2.754C5.45961 2.771 3.75061 4.587 3.75061 7.357V16.553C3.75061 19.337 5.47261 21.16 8.25661 21.16C8.25661 21.16 15.9326 21.157 15.9456 21.157C18.7056 21.14 20.4156 19.323 20.4156 16.553V7.357C20.4156 4.573 18.6926 2.75 15.9086 2.75Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
