import React from "react";
import { Link } from "react-router-dom";

export const Logo = () => {
    const handleFooterClick = () => {
        localStorage.setItem("url", "/contacts");
        // window.location.reload();
    };
    return (
        <Link
            to="/"
            className="flex items-center justify-center "
            onClick={handleFooterClick}
        >
            <img
                src={require(`@assets/images/logo_new.png`)}
                alt="Logo"
                className="lg:w-52 w-36 relative left-[-14px]"
            />
        </Link>
    );
};
