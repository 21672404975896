import React from "react";
import { useTranslation } from "react-i18next";

interface IAuctionCard {
    img: string;
    date?: string;
    cost: string;
    desc?: string;
    url?: string;
}

export const AuctionCard: React.FC<IAuctionCard> = ({
    img,
    date,
    cost,
    desc,
    url,
}) => {
    const { t } = useTranslation();
    return (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center w-screen p-0  cursor-pointer md:p-4  md:w-[400px] lg:w-[440px]"
        >
            <p className="mb-6 text-xl font-semibold text-center hidden md:block">
                {t("auctionObjectCard.price")}: {cost}
            </p>
            <img
                src={img}
                alt="Object"
                className="w-full hover:scale-105 h-[352px] object-cover"
            />

            <div className="flex flex-col items-center w-full px-4 mt-4 font-semibold opacity-70">
                <p className="text-neutral">
                    {t("auctionObjectCard.auction")} {date?.slice(0, 10)}
                </p>
                {/*<p className="text-secondary">*/}
                {/*    {t("auctionObjectCard.desc")} {date?.slice(19, 29)}*/}
                {/*</p>*/}
            </div>
            <p className="mt-6  text-xl font-semibold text-center  md:hidden">
                {t("auctionObjectCard.price")}: {cost}
            </p>
            <div className="flex flex-col px-4 ">
                <div className="my-4 border-t border-slate-300" />
                <p className="text-justify text-neutral opacity-70">{desc}</p>
            </div>
        </a>
    );
};
