import { ITableForm } from "@types";
import React from "react";
import {
    BailiffData,
    CheckedList,
    DownloadList,
    ListTable,
    Radio,
} from "@components/components";
import { claimantForm4Data } from "@config/forms";
import { useTranslation } from "react-i18next";

export const TableClaimantForm4: React.FC<ITableForm> = ({ id, myRef }) => {
    const { t } = useTranslation();
    const {
        debtorHasRepresentative,
        claim_clarification,
        claimantHasRepresentative,
    } = claimantForm4Data(t);
    const claimantName = localStorage.getItem("claimantName4");
    const claimantId = localStorage.getItem("claimantId4");
    const claimantAddress = localStorage.getItem("claimantAddress4");
    const claimantPost = localStorage.getItem("claimantPost4");
    const claimantPhone = `+${localStorage.getItem(
        "pre_claimant_phone",
    )} ${localStorage.getItem("claimant_phone")}`;
    const bankAccount = localStorage.getItem("bankAccount");
    const bankName = localStorage.getItem("bankName");

    const reClaimantName = localStorage.getItem("reClaimantName4");
    const reClaimantId = localStorage.getItem("reClaimantId4");
    const reClaimantAddress = localStorage.getItem("reClaimantAddress4");
    const reClaimantPost = localStorage.getItem("reClaimantPost4");
    const reClaimantPhone = `+${localStorage.getItem(
        "pre_reClaimantPhone4",
    )} ${localStorage.getItem("reClaimantPhone4")}`;
    const reClaimantAccount = localStorage.getItem("reClaimantAccount");
    const reClaimantBank = localStorage.getItem("reClaimantBank");

    const debtorName = localStorage.getItem("debtorName4");
    const debtorId = localStorage.getItem("debtorId4");
    const debtorAddress = localStorage.getItem("debtorAddress4");
    const debtorPost = localStorage.getItem("debtorPost4");
    const debtorPhone = `+${localStorage.getItem(
        "pre_debtor_phone",
    )} ${localStorage.getItem("debtor_phone")}`;

    const reDebtorName = localStorage.getItem("reDebtorName4");
    const reDebtorId = localStorage.getItem("reDebtorId4");
    const reDebtorAddress = localStorage.getItem("reDebtorAddress4");
    const reDebtorPost = localStorage.getItem("reDebtorPost4");
    const reDebtorPhone = `+${localStorage.getItem(
        "pre_reDebtorPhone4",
    )} ${localStorage.getItem("reDebtorPhone4")}`;

    const requestName = localStorage.getItem("requestName");
    const requestDate = localStorage.getItem("requestDate");
    const requestResult = localStorage.getItem("requestResult");
    const requestNumber = localStorage.getItem("requestNumber");
    const requestContent = localStorage.getItem("requestContent");
    const requestSum = localStorage.getItem("requestSum");

    const agree = localStorage.getItem("agree");

    const period = localStorage.getItem("period");
    const percent = localStorage.getItem("percent");
    const active = localStorage.getItem("active");
    const reDebtor = localStorage.getItem("re_debtor");
    const reClaimant = localStorage.getItem("re_claimant");

    const debtor_assets = localStorage.getItem("debtor_assets");
    const current_date = localStorage.getItem("currentDate");

    return (
        <div className="print-container a4" id={id} ref={myRef}>
            <img
                src={require(`@assets/images/logo_new.png`)}
                alt="Logo"
                className="logo_print block ml-auto lg:w-52 w-36"
            />
            <div className="printElement1">
                <BailiffData />

                <p className="mb-6 font-[500] text-center text-[16px] fontWeight">
                    {t("tableClaimantForm4.title")}
                </p>
                <div className="flex flex-col self-start mb-6">
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.current_date")}:
                        </p>
                        <p className="text-[16px]">{current_date}</p>
                    </div>
                </div>
                <ListTable
                    className="mb-4"
                    title={t("tableClaimantForm4.receiver.title")}
                    row_1={claimantName || ""}
                    row_2={claimantId || ""}
                    row_3={claimantAddress || ""}
                    row_4={claimantPhone || ""}
                    row_5={claimantPost || ""}
                    row_6={bankAccount || ""}
                    row_7={bankName || ""}
                    row_title_1={t("tableClaimantForm4.receiver.row_title_1")}
                    row_title_2={t("tableClaimantForm4.receiver.row_title_2")}
                    row_title_3={t("tableClaimantForm4.receiver.row_title_3")}
                    row_title_4={t("tableClaimantForm4.receiver.row_title_4")}
                    row_title_5={t("tableClaimantForm4.receiver.row_title_5")}
                    row_title_6={t("tableClaimantForm4.receiver.row_title_6")}
                    row_title_7={t("tableClaimantForm4.receiver.row_title_7")}
                />
                <Radio
                    className="mb-4"
                    title={claimantHasRepresentative.title}
                    rad1={claimantHasRepresentative.values[0].value}
                    rad2={claimantHasRepresentative.values[1].value}
                    val1={
                        reClaimant === claimantHasRepresentative.values[0].value
                    }
                    val2={
                        reClaimant === claimantHasRepresentative.values[1].value
                    }
                    col
                />

                {reClaimant ===
                    t(
                        "claimantForm4Data.claimantHasRepresentative.values.0.value",
                    ) && (
                    <>
                        <ListTable
                            className="mb-4"
                            title={t("tableClaimantForm4.representative.title")}
                            row_1={reClaimantName || ""}
                            row_2={reClaimantId || ""}
                            row_3={reClaimantAddress || ""}
                            row_4={reClaimantPhone || ""}
                            row_5={reClaimantPost || ""}
                            row_6={reClaimantAccount || ""}
                            row_7={reClaimantBank || ""}
                            row_title_1={t(
                                "tableClaimantForm4.representative.row_title_1",
                            )}
                            row_title_2={t(
                                "tableClaimantForm4.representative.row_title_2",
                            )}
                            row_title_3={t(
                                "tableClaimantForm4.representative.row_title_3",
                            )}
                            row_title_4={t(
                                "tableClaimantForm4.representative.row_title_4",
                            )}
                            row_title_5={t(
                                "tableClaimantForm4.representative.row_title_5",
                            )}
                            row_title_6={t(
                                "tableClaimantForm4.representative.row_title_6",
                            )}
                            row_title_7={t(
                                "tableClaimantForm4.representative.row_title_7",
                            )}
                        />
                    </>
                )}
                <ListTable
                    className="mb-4"
                    title={t("tableClaimantForm4.debtor.title")}
                    row_1={debtorName || ""}
                    row_2={debtorId || ""}
                    row_3={debtorAddress || ""}
                    row_4={debtorPhone || ""}
                    row_5={debtorPost || ""}
                    row_title_1={t("tableClaimantForm4.debtor.row_title_1")}
                    row_title_2={t("tableClaimantForm4.debtor.row_title_2")}
                    row_title_3={t("tableClaimantForm4.debtor.row_title_3")}
                    row_title_4={t("tableClaimantForm4.debtor.row_title_4")}
                    row_title_5={t("tableClaimantForm4.debtor.row_title_5")}
                />

                <Radio
                    className="mb-4"
                    title={debtorHasRepresentative.title}
                    rad1={debtorHasRepresentative.values[0].value}
                    rad2={debtorHasRepresentative.values[1].value}
                    val1={reDebtor === debtorHasRepresentative.values[0].value}
                    val2={reDebtor === debtorHasRepresentative.values[1].value}
                    col
                />

                {reDebtor ===
                    t(
                        "claimantForm4Data.debtorHasRepresentative.values.0.value",
                    ) && (
                    <ListTable
                        className="mb-4"
                        title={t(
                            "tableClaimantForm4.debtor_representative.title",
                        )}
                        row_1={reDebtorName || ""}
                        row_2={reDebtorId || ""}
                        row_3={reDebtorAddress || ""}
                        row_4={reDebtorPhone || ""}
                        row_5={reDebtorPost || ""}
                        row_title_1={t(
                            "tableClaimantForm4.debtor_representative.row_title_1",
                        )}
                        row_title_2={t(
                            "tableClaimantForm4.debtor_representative.row_title_2",
                        )}
                        row_title_3={t(
                            "tableClaimantForm4.debtor_representative.row_title_3",
                        )}
                        row_title_4={t(
                            "tableClaimantForm4.debtor_representative.row_title_4",
                        )}
                        row_title_5={t(
                            "tableClaimantForm4.debtor_representative.row_title_5",
                        )}
                    />
                )}

                <p className="mb-2 text-[16px] font-[500] fontWeight">
                    {t("tableClaimantForm4.enforcement.info")}
                </p>
                <ListTable
                    className="mb-4"
                    title=""
                    row_1={requestName || ""}
                    row_2={requestDate || ""}
                    row_3={requestResult || ""}
                    row_4={requestNumber || ""}
                    row_5={requestContent || ""}
                    row_6={requestSum || ""}
                    row_title_1={t(
                        "tableClaimantForm4.enforcement.row_title_1",
                    )}
                    row_title_2={t(
                        "tableClaimantForm4.enforcement.row_title_2",
                    )}
                    row_title_3={t(
                        "tableClaimantForm4.enforcement.row_title_3",
                    )}
                    row_title_4={t(
                        "tableClaimantForm4.enforcement.row_title_4",
                    )}
                    row_title_5={t(
                        "tableClaimantForm4.enforcement.row_title_5",
                    )}
                    row_title_6={t(
                        "tableClaimantForm4.enforcement.row_title_6",
                    )}
                />

                <Radio
                    className="mb-4"
                    title={claim_clarification[0].title}
                    rad1={claim_clarification[0].values[0].value}
                    rad2={claim_clarification[0].values[1].value}
                    val1={period === claim_clarification[0].values[0].value}
                    val2={period === claim_clarification[0].values[1].value}
                    col
                />
                <Radio
                    className="mb-4"
                    title={claim_clarification[1].title}
                    rad1={claim_clarification[1].values[0].value}
                    rad2={claim_clarification[1].values[1].value}
                    val1={percent === claim_clarification[1].values[0].value}
                    val2={percent === claim_clarification[1].values[1].value}
                    col
                />
                <Radio
                    className="mb-4"
                    title={claim_clarification[2].title}
                    rad1={claim_clarification[2].values[0].value}
                    rad2={claim_clarification[2].values[1].value}
                    val1={active === claim_clarification[2].values[0].value}
                    val2={active === claim_clarification[2].values[1].value}
                    col
                />
                <div className="flex items-center mb-4">
                    <p className="text-[16px] font-[500] fontWeight">
                        {t("tableClaimantForm4.owned_debtor")}:
                    </p>
                    <p className="text-[16px] ml-2"> {debtor_assets}</p>
                </div>
                <DownloadList keyEl="downloadForm_4" className="mb-4" />
                <CheckedList
                    className="mb-4"
                    title=""
                    point_1={t("sharedClaimantForm.checklist")}
                    sub_1={agree === "true"}
                />
                <p className="font-[500] text-[16px] text-[#1A1A1F] flex items-end fontWeight">
                    {t("sharedClaimantForm.applicant")}
                    <span className="ml-2 h-[1px] w-[150px] bg-black" />
                </p>
            </div>
        </div>
    );
};
