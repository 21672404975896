import React from "react";
import { questionsData } from "@config/debtor";
import { AccordionLayout } from "@components/common";
import { useTranslation } from "react-i18next";

export const Questions = () => {
    const { t } = useTranslation();
    const { items, title } = questionsData(t);
    return (
        <div className="flex flex-col px-4 mt-4 md:mt-6">
            <p className="my-4 md:mt-0 md:mb-6 text-xl font-semibold md:mb-4">
                {title}
            </p>
            {/*<p className="text-xl font-semibold">*/}
            {/*    {subtitle_text}*/}
            {/*    <NavHashLink*/}
            {/*        to={`${AppRoute.FORMS_DEBTOR}#top`}*/}
            {/*        className="uppercase text-secondary"*/}
            {/*    >*/}
            {/*        {subtitle_path}*/}
            {/*    </NavHashLink>*/}
            {/*</p>*/}

            <ul className="w-full overflow-hidden">
                {items.map((item) => {
                    return (
                        <li
                            className="w-full px-4 py-8 my-4 border rounded-md border-neutral/10"
                            key={item.id}
                        >
                            <AccordionLayout
                                path={item.path}
                                path_name_1={item.path_name_1}
                                name_1={item.name_1}
                                desc={item.desc}
                                list_1={item.list_1}
                                link_1={item.link_1}
                                link_name_1={item.link_name_1}
                                endOfDesc_1={item.endOfDesc_1}
                                desc_2={item.desc_2}
                                path_2={item.path_2}
                                endOfDesc_2={item.endOfDesc_2}
                                path_name_2={item.path_name_2}
                                name_2={item.name_2}
                                desc_3={item.desc_3}
                                link_3={item.link_3}
                                link_name_3={item.link_name_3}
                                desc_bold_3={item.desc_bold_3}
                                name_3={item.name_3}
                                path_3={item.path_3}
                                path_name_3={item.path_name_3}
                                endOfDesc_3={item.endOfDesc_3}
                                desc_4={item.desc_4}
                                path_name_4={item.path_name_4}
                                path_4={item.path_4}
                                path_url_4={item.path_4}
                                desc_bold_4={item.desc_bold_4}
                                endOfDesc_4={item.endOfDesc_4}
                                desc_5={item.desc_5}
                                title={item.title}
                                list_4={item.list_4}
                                list_3={item.list_3}
                                path_url_1={item.path_url_1}
                                path_url_2={item.path_url_2}
                                path_url_3={item.path_url_3}
                                defaultOpenQuestion={item.defaultOpenQuestion}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
