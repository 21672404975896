import { Services } from "@components/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";

export const Activity = () => {
    const [tai, setTai] = useState(false);
    const [ame, setAme] = useState(false);
    const { t } = useTranslation();

    const onHideTai = (): void => {
        setTai((prev) => !prev);
        if (ame) setAme(false);
    };
    const onHideAme = (): void => {
        if (tai) setTai(false);
        setAme((prev) => !prev);
    };
    return (
        <div className="pt-[14rem] md:pt-96 bg-primary md:-mt-96">
            <div className="container flex justify-around flex-col pt-16 md:pt-28 lg:pt-10 md:flex-1 md:flex-row">
                <div className="w-full md:w-1/2">
                    {/*<h2 className="w-full  font-semibold md:w-1/3">*/}
                    {/*    Kohtutäituri ametitegevus*/}
                    {/*</h2>*/}
                    {/*<p className="mt-8 text-2xl font-semibold uppercase text-neutral/60">*/}
                    {/*    TÄITEMENETLUS | AMETITEENUSED*/}
                    {/*</p>*/}
                </div>

                <div className="flex flex-col justify-around w-full pb-8 pl-0 mt-0 xl:mt-8 xl:mr-12 md:w-1/2">
                    <div className="mb-4 border-t border-neutral/90" />
                    <h4
                        className="w-full flex justify-between items-center  font-semibold cursor-pointer "
                        onClick={onHideTai}
                    >
                        <span className="text-lg lg:text-3xl">
                            {t("activityData.proceed.title")}
                        </span>
                        {tai ? (
                            <AiOutlineDown className="text-secondary text-xl" />
                        ) : (
                            <AiOutlineRight className="hover:text-secondary text-xl" />
                        )}
                    </h4>
                    <h4
                        className="w-full flex justify-between items-center  font-semibold cursor-pointer "
                        onClick={onHideAme}
                    >
                        <span className="text-lg lg:text-3xl">
                            {t("activityData.official.title")}
                        </span>
                        {ame ? (
                            <AiOutlineDown className="text-secondary text-xl" />
                        ) : (
                            <AiOutlineRight className="hover:text-secondary text-xl" />
                        )}
                    </h4>
                </div>
            </div>
            {tai && (
                <p className="w-auto mx-5 lg:mx-14 lg:mt-10 lg:leading-8 leading-8 md:w-7/8 w-full text-lg">
                    {t("activityData.proceed.desc")}
                </p>
            )}
            {ame && (
                <>
                    <p className="w-auto mx-5 lg:mx-14 lg:mt-10 lg:leading-8 leading-8 md:w-7/8 w-full text-lg">
                        {t("activityData.official.desc")}
                    </p>
                    <Services />
                </>
            )}
        </div>
    );
};
