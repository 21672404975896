import { TFunction } from "i18next";

export const noticeData = (t: TFunction<"translation", undefined>) => {
    return {
        section: {
            title: `${t("noticeData.section.title")}`,
            fields: [
                {
                    id: 1,
                    name: "debtor_name",
                    label: `${t("noticeData.section.fields.0.label")}`,
                    type: "text",
                    required: true,
                    placeholder: " ",
                },
                {
                    id: 2,
                    name: "debtor_id",
                    label: `${t("noticeData.section.fields.1.label")}`,
                    type: "text",
                    minLength: 11,
                    maxLength: 11,
                    required: true,
                    placeholder: " ",
                    number: true,
                },
                {
                    id: 3,
                    name: "debtor_address",
                    label: `${t("noticeData.section.fields.2.label")}`,
                    type: "text",
                    required: true,
                    placeholder: " ",
                },
                {
                    id: 4,
                    name: "debtor_email",
                    label: `${t("noticeData.section.fields.3.label")}`,
                    type: "email",
                    required: true,
                    placeholder: " ",
                },
                {
                    id: 5,
                    name: "debtor_phone",
                    label: `${t("noticeData.section.fields.4.label")}`,
                    type: "tel",
                    required: true,
                    placeholder: " ",
                },
            ],
            fields_2: [
                {
                    id: 6,
                    name: "debtor_name",
                    label: `${t("noticeData.section.fields_2.0.label")}`,
                    type: "text",
                    required: true,
                    placeholder: " ",
                },
                {
                    id: 7,
                    name: "debtor_id",
                    label: `${t("noticeData.section.fields_2.1.label")}`,
                    type: "text",
                    minLength: 11,
                    maxLength: 11,
                    required: true,
                    placeholder: " ",
                    number: true,
                },
                {
                    id: 8,
                    name: "debtor_address",
                    label: `${t("noticeData.section.fields_2.2.label")}`,
                    type: "text",
                    required: true,
                    placeholder: " ",
                },
                {
                    id: 9,
                    name: "debtor_email",
                    label: `${t("noticeData.section.fields_2.3.label")}`,
                    type: "email",
                    required: true,
                    placeholder: " ",
                },
                {
                    id: 10,
                    name: "debtor_phone",
                    label: `${t("noticeData.section.fields_2.4.label")}`,
                    type: "tel",
                    required: true,
                    placeholder: " ",
                },
            ],
        },
        agreement: {
            title: "",
            points: [
                {
                    id: 1,
                    value: `${t("noticeData.agreement.points.0.value")}`,
                    name: "agree_1",
                    checked: true,
                },
            ],
        },
        hasAdditional: {
            title: `${t("noticeData.hasAdditional.title")}`,
            values: [
                {
                    id: 1,
                    value: `${t("noticeData.hasAdditional.values.0.value")}`,
                    name: "",
                },
                {
                    id: 2,
                    value: `${t("noticeData.hasAdditional.values.1.value")}`,
                    name: "",
                },
            ],
        },
        additionalInfo: {
            title: "",
            points: [
                {
                    id: 1,
                    value: `${t("noticeData.additionalInfo.points.0.value")}`,
                    name: "schedule",
                },
                {
                    id: 2,
                    value: `${t("noticeData.additionalInfo.points.1.value")}`,
                    name: "minimum",
                },
                {
                    id: 3,
                    value: `${t("noticeData.additionalInfo.points.2.value")}`,
                    name: "refund",
                },
                {
                    id: 4,
                    value: `${t("noticeData.additionalInfo.points.3.value")}`,
                    name: "notice",
                },

                {
                    id: 5,
                    value: `${t("noticeData.additionalInfo.points.4.value")}`,
                    name: "limitation",
                },
                {
                    id: 6,
                    value: `${t("noticeData.additionalInfo.points.5.value")}`,
                    name: "sale",
                },
                {
                    id: 7,
                    value: `${t("noticeData.additionalInfo.points.6.value")}`,
                    name: "other",
                },
            ],
        },
    };
};
