import React, { FC } from "react";
import { useFile } from "@providers/FileProvider";

const SigningPage: FC = () => {
    const { signingToken, signerToken } = useFile();
    if (!signingToken || !signerToken) return <div>Loading</div>;

    return (
        <iframe
            id="isign-gateway"
            width="100%"
            height="100%"
            src={`
                https://gateway-sandbox.dokobit.com/signing/${signingToken}?access_token=${signerToken}
            `}
        ></iframe>
    );
};

export default SigningPage;
