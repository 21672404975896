import { ITableForm } from "@types";
import React from "react";
import {
    BailiffData,
    CheckedList,
    DataTable,
    DownloadList,
    SimpleTable,
} from "@components/components";
import { useTranslation } from "react-i18next";

export const TableClaimantForm3: React.FC<ITableForm> = ({ id, myRef }) => {
    const { t } = useTranslation();
    const claimantName = localStorage.getItem("claimantName");
    const claimantId = localStorage.getItem("claimantId");
    const claimantAddress = localStorage.getItem("claimantAddress");

    const reClaimantName = localStorage.getItem("reClaimantName");
    const reClaimantId = localStorage.getItem("reClaimantId");
    const reClaimantAddress = localStorage.getItem("reClaimantAddress");
    const reClaimantEmail = localStorage.getItem("reClaimantEmail");
    const reClaimantPhone = `+${localStorage.getItem(
        "pre_re_claimant_phone",
    )} ${localStorage.getItem("re_claimant_phone")}`;

    const debtorName = localStorage.getItem("debtorName");
    const debtorId = localStorage.getItem("debtorId");
    const debtorSum = localStorage.getItem("debtorSum");

    const point_1 = localStorage.getItem("point1");
    const point_2 = localStorage.getItem("point2");
    const point_3 = localStorage.getItem("point3");
    const point_4 = localStorage.getItem("point4");
    const point_5 = localStorage.getItem("point5");
    const voice_1 = localStorage.getItem("voice1");
    const voice_2 = localStorage.getItem("voice2");
    const voice_3 = localStorage.getItem("voice3");
    const voice_4 = localStorage.getItem("voice4");
    const voice_5 = localStorage.getItem("voice5");
    const voice_6 = localStorage.getItem("voice6");
    const voice_7 = localStorage.getItem("voice7");
    const agree = localStorage.getItem("agree");
    const current_date = localStorage.getItem("currentDate");
    const case_number = localStorage.getItem("caseNumber");
    return (
        <div className="print-container a4" id={id} ref={myRef}>
            <img
                src={require(`@assets/images/logo_new.png`)}
                alt="Logo"
                className="logo_print block ml-auto lg:w-52 w-36"
            />
            <div className="printElement1">
                <BailiffData />

                <p className="mb-6 font-[500] text-center text-[16px] fontWeight">
                    {t("tableClaimantForm3.title")}
                </p>

                <div className="flex flex-col self-start mb-6">
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.current_date")}:
                        </p>
                        <p className="text-[18px]">{current_date}</p>
                    </div>
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.case_number")}:
                        </p>
                        <p className="text-[16px]">
                            {case_number === "undefined" ? "-" : case_number}
                        </p>
                    </div>
                </div>

                <DataTable
                    title={t("tableClaimantForm3.receiver_title")}
                    name={claimantName || ""}
                    id={claimantId || ""}
                    address={claimantAddress || ""}
                    noEmail
                    noTelefon
                />
                <DataTable
                    title={t("tableClaimantForm3.next_title")}
                    name={reClaimantName || ""}
                    id={reClaimantId || ""}
                    address={reClaimantAddress || ""}
                    email={reClaimantEmail || ""}
                    phone={reClaimantPhone || ""}
                />
                <SimpleTable
                    className="mb-4"
                    title={t("tableClaimantForm3.debtor.title")}
                    thead_1={t("tableClaimantForm3.debtor.thead_1")}
                    thead_2={t("tableClaimantForm3.debtor.thead_2")}
                    thead_3={t("tableClaimantForm3.debtor.thead_3")}
                    tbody_1_1={debtorName || ""}
                    tbody_2_1={debtorId || ""}
                    tbody_3_1={`${debtorSum} EUR` || ""}
                    row={1}
                />
                <CheckedList
                    className="pr-6 mb-4"
                    title={t("tableClaimantForm3.submit.title")}
                    point_1={t("tableClaimantForm3.submit.point_1")}
                    point_2={t("tableClaimantForm3.submit.point_2")}
                    point_3={t("tableClaimantForm3.submit.point_3")}
                    point_4={t("tableClaimantForm3.submit.point_4")}
                    point_5={t("tableClaimantForm3.submit.point_5")}
                    sub_1={point_1 === "true"}
                    sub_2={point_2 === "true"}
                    sub_3={point_3 === "true"}
                    sub_4={point_4 === "true"}
                    sub_5={point_5 === "true"}
                />

                <CheckedList
                    className="mb-4"
                    title={t("tableClaimantForm3.repealing.title")}
                    point_1={t("tableClaimantForm3.repealing.point_1")}
                    point_2={t("tableClaimantForm3.repealing.point_2")}
                    point_3={t("tableClaimantForm3.repealing.point_3")}
                    point_4={t("tableClaimantForm3.repealing.point_4")}
                    point_5={t("tableClaimantForm3.repealing.point_5")}
                    point_6={t("tableClaimantForm3.repealing.point_6")}
                    point_7={t("tableClaimantForm3.repealing.point_7")}
                    sub_1={voice_1 === "true"}
                    sub_2={voice_2 === "true"}
                    sub_3={voice_3 === "true"}
                    sub_4={voice_4 === "true"}
                    sub_5={voice_5 === "true"}
                    sub_6={voice_6 === "true"}
                    sub_7={voice_7 === "true"}
                />
                <DownloadList keyEl="downloadForm_3" className="mb-4" />
                <CheckedList
                    className="mb-4"
                    title=""
                    point_1={t("sharedClaimantForm.checklist")}
                    sub_1={agree === "true"}
                />
                <p className="font-[500] text-[16px] text-[#1A1A1F] mb-2 fontWeight">
                    {t("sharedClaimantForm.applicant")}
                    <span className="font-normal text-[16px] text-[#1A1A1F] ml-2">
                        {reClaimantName === "undefined" ? "-" : reClaimantName}
                    </span>
                </p>
                <p className="font-[500] text-[16px] text-[#1A1A1F] flex items-end fontWeight">
                    {t("sharedClaimantForm.signature")}
                    <span className="ml-2 h-[1px] w-[150px] bg-black" />
                </p>
            </div>
        </div>
    );
};
