import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { checkedState, initialState } from "state";
import { useFullForm } from "@hooks";
import { AppRoute } from "@enums";
import {
    Checkbox,
    Download,
    FormFooter,
    FormsMain,
    Input,
    Section,
} from "@components/components";
import { claimantForm4Data, claimantForm5Data } from "@config/forms";
import { getFile } from "../../../services/DownloadFileService";

export const ClaimantForm5 = () => {
    const { t } = useTranslation();
    const [validForm, setValidForm] = useState(false);
    const { title, sections, paymentData } = claimantForm5Data(t);
    const { download } = claimantForm4Data(t);
    const { onChange, onSubmit, values } = useFullForm(
        formCallback,
        initialState,
        checkedState,
    );
    const [name, setName] = useState<string>("");
    const [checkedArr, setCheckedArr] = useState({ agree: true });
    const onHandelCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCheckedArr({
            ...checkedArr,
            [event.target.name]: event.target.checked,
        });
    };

    useEffect(() => {
        localStorage.setItem("selected_titles_arr", JSON.stringify([title]));
        localStorage.setItem("selectTitleItem", title);
    }, []);

    async function formCallback() {
        console.log("values");
        console.log("checked");
    }

    useEffect(() => {
        setName(Object(values).claimant_name);
        localStorage.setItem("claimantName", Object(values).claimant_name);
        localStorage.setItem("claimantId", Object(values).claimant_id);
        localStorage.setItem(
            "claimantAddress",
            Object(values).claimant_address,
        );
        localStorage.setItem("claimantPost", Object(values).claimant_email);
        localStorage.setItem("claimant_phone", Object(values).claimant_phone);
        localStorage.setItem(
            "pre_claimant_phone",
            Object(values).pre_claimant_phone,
        );

        localStorage.setItem("paymentPeriod", Object(values).payment_period);
        localStorage.setItem("paymentSum", Object(values).payment_sum);
        localStorage.setItem("paymentFull", Object(values).payment_full);

        localStorage.setItem("caseNumber", Object(values).case_number);
    }, [values]);

    useEffect(() => {
        localStorage.setItem("agree", JSON.stringify(checkedArr.agree));
    }, [checkedArr]);

    const validationSchema = Yup.object().shape(
        {
            claimantName: Yup.string().required("Name is required"),
            claimantId: Yup.string().min(11).max(11).required("Id is required"),
            claimantAddress: Yup.string().required("Address is required"),
            claimantPost: Yup.string().email().required("Email is required"),
        },
        [],
    );
    useEffect(() => {
        validationSchema
            .isValid({
                claimantName: Object(values).claimant_name,
                claimantId: Object(values).claimant_id,
                claimantAddress: Object(values).claimant_address,
                claimantPost: Object(values).claimant_email,
            })
            .then(() => {
                setValidForm(true);
            })
            .catch((err) => {
                console.log(err.name);
                console.log(err.errors);
            });
    });

    const handleDownload = () => {
        getFile("get_file").then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Elatise võlgnevuse arvestus.xlsx");
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <FormsMain title={title} onChange={onChange} validForm={validForm}>
            <form onSubmit={onSubmit}>
                {sections.map((section) => (
                    <Section
                        data={section}
                        key={section.id}
                        onChange={onChange}
                        className="grid grid-cols-1 gap-4 md:grid-cols-2"
                    />
                ))}
                <p className="mb-6 mt-8 text-lg font-semibold">
                    {paymentData.title}
                </p>
                <div className="base-border">
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                        {paymentData.fields.map((field) => (
                            <Input
                                type={field.type}
                                label={field.label}
                                name={field.name}
                                key={field.id}
                                hint={field.hint}
                                number={field.number}
                                units={field.units}
                                right={field.right}
                                onChange={onChange}
                            />
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-6">
                    <h3 className="mb-2 text-lg  font-semibold text-neutral uppercase font-semibold">
                        {t("claimantForm5Data.awared.title")}
                    </h3>
                    <ul className="mb-4 ml-6 lg:text-justify list-disc">
                        <li className="leading-9 text-neutral text-lg mb-4">
                            <p className="text-lg">
                                {t("claimantForm5Data.awared.points.0.content")}
                                <a
                                    onClick={handleDownload}
                                    className="text-lg text-secondary cursor-pointer ml-2"
                                >
                                    {t(
                                        "claimantForm5Data.awared.points.0.orange",
                                    )}
                                </a>
                                .
                            </p>
                        </li>
                        <li className="leading-9 text-neutral text-lg mb-4">
                            <p className="text-lg">
                                {t("claimantForm5Data.awared.points.1.content")}
                            </p>
                        </li>
                    </ul>
                </div>
                <Download data={download} nameLocal="downloadForm_5" />

                <Checkbox
                    name="agree"
                    id="agree"
                    label={t("claimantForm5Data.checkbox")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="agree"
                    checked={checkedArr.agree}
                />
            </form>
            <FormFooter
                pdf={AppRoute.PDF_DOCUMENT_7}
                title={title}
                validForm={validForm}
                params={{
                    name,
                }}
            />
        </FormsMain>
    );
};
