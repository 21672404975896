import { TFunction } from "i18next";

export const scheduleData = (t: TFunction<"translation", undefined>) => {
    return {
        conditionals: {
            title: `${t("scheduleData.conditionals.title")}`,
            tip: `${t("scheduleData.conditionals.tip")}`,
            fields: [
                {
                    id: 1,
                    label: `${t("scheduleData.conditionals.fields.0.label")}`,
                    name: "amount",
                    number: true,
                    units: "€",
                },
                {
                    id: 2,
                    label: `${t("scheduleData.conditionals.fields.1.label")}`,
                    name: "date",
                    placeholder: `${t(
                        "scheduleData.conditionals.fields.1.placeholder",
                    )}`,
                    type: "number",
                    min: 1,
                    max: 30,
                    number: false,
                },
                {
                    id: 3,
                    label: `${t("scheduleData.conditionals.fields.2.label")}`,
                    name: "first_payment",
                    number: false,
                    type: "date",
                },
            ],
        },
        banks: {
            title: `${t("scheduleData.banks.title")}`,
            values: [
                {
                    id: 1,
                    value: `${t("scheduleData.banks.values.0.value")}`,
                    name: "bank",
                },
                {
                    id: 2,
                    value: `${t("scheduleData.banks.values.1.value")}`,
                    name: "bank",
                },
                {
                    id: 3,
                    value: `${t("scheduleData.banks.values.2.value")}`,
                    name: "bank",
                },
                {
                    id: 4,
                    value: `${t("scheduleData.banks.values.3.value")}`,
                    name: "bank",
                },

                {
                    id: 5,
                    value: `${t("scheduleData.banks.values.4.value")}`,
                    name: "bank",
                },
                {
                    id: 6,
                    value: `${t("scheduleData.banks.values.5.value")}`,
                    name: "bank",
                },
                {
                    id: 7,
                    value: `${t("scheduleData.banks.values.6.value")}`,
                    name: "bank",
                },
                {
                    id: 8,
                    value: `${t("scheduleData.banks.values.7.value")}`,
                    name: "bank",
                },
                {
                    id: 9,
                    value: `${t("scheduleData.banks.values.8.value")}`,
                    name: "bank",
                },
            ],
        },
        field: {
            id: 1,
            placeholder: `${t("scheduleData.field.placeholder")}`,
            name: "add_bank",
        },

        claimantHasRepresentative: {
            title: `${t("scheduleData.claimantHasRepresentative.title")}`,
            tip: `${t("scheduleData.claimantHasRepresentative.tip")}`,
            values: [
                {
                    id: 1,
                    value: `${t(
                        "scheduleData.claimantHasRepresentative.values.0.value",
                    )}`,
                    name: "re_claimant",
                },
                {
                    id: 2,
                    value: `${t(
                        "scheduleData.claimantHasRepresentative.values.1.value",
                    )}`,
                    name: "re_claimant",
                },
            ],
        },
        debtorHasRepresentative: {
            title: `${t("scheduleData.debtorHasRepresentative.title")}`,
            tip: `${t("scheduleData.debtorHasRepresentative.tip")}`,
            values: [
                {
                    id: 1,
                    value: `${t(
                        "scheduleData.debtorHasRepresentative.values.0.value",
                    )}`,
                    name: "re_debtor",
                },
                {
                    id: 2,
                    value: `${t(
                        "scheduleData.debtorHasRepresentative.values.1.value",
                    )}`,
                    name: "re_debtor",
                },
            ],
        },
        debtorHasDependent: {
            title: `${t("scheduleData.debtorHasDependent.title")}`,
            values: [
                {
                    id: 1,
                    value: `${t(
                        "scheduleData.debtorHasDependent.values.0.value",
                    )}`,
                    name: "re_dependent",
                },
                {
                    id: 2,
                    value: `${t(
                        "scheduleData.debtorHasDependent.values.1.value",
                    )}`,
                    name: "re_dependent",
                },
            ],
        },

        sections: [
            {
                id: 1,
                title: "",
                fields: [],
                fields_more: [
                    {
                        id: 1,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_1",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_1",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 2,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_2",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_2",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 3,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_3",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_3",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 4,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_4",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_4",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 5,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_5",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_5",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 6,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_6",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_6",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 7,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_7",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_7",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 8,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_8",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_8",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 9,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_9",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_9",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 10,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_10",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_10",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 11,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_11",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_11",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 12,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_12",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_12",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 13,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_13",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_13",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 14,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_14",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_14",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 15,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_15",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_15",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 16,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_16",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_16",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 17,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_17",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_17",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 18,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_18",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_18",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 19,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_19",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_19",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 20,
                        child: [
                            {
                                id: 1,
                                name: "re_dependent_name_20",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "re_dependent_id_20",
                                label: `${t(
                                    "scheduleData.sections.0.fields_more.0.child.1.label",
                                )}`,
                                maxLength: 11,
                                required: true,
                                number: true,
                                placeholder: " ",
                            },
                        ],
                    },
                ],
                note: "",
                add_more: true,
            },
        ],
        schedulePayments: {
            title: `${t("scheduleData.schedulePayments.title")}`,
            field: {
                id: 1,
                placeholder: "",
                name: "month_limit",
                number: true,
                units: "€",
            },
            points: [
                {
                    id: 1,
                    value: `${t(
                        "scheduleData.schedulePayments.points.0.value",
                    )}`,
                    name: "point1",
                },
                {
                    id: 2,
                    value: `${t(
                        "scheduleData.schedulePayments.points.1.value",
                    )}`,
                    name: "point2",
                },
                {
                    id: 3,
                    value: `${t(
                        "scheduleData.schedulePayments.points.2.value",
                    )}`,
                    name: "point3",
                },
            ],
        },
        note: {
            title: `${t("scheduleData.note.title")}`,
            points: [
                {
                    id: 1,
                    content: `${t("scheduleData.note.points.0.content")}`,
                },
                {
                    id: 2,
                    content: `${t("scheduleData.note.points.1.content")}`,
                },
                {
                    id: 3,
                    content: `${t("scheduleData.note.points.2.content")}`,
                },
            ],
        },
        textArea: {
            id: 1,
            label: `${t("scheduleData.textArea.label")}`,
            placeholder: "",
            name: "additional1",
        },
    };
};
