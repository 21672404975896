import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import { useDeviceWidhtDetect } from "@hooks";
import { bailiffsListData } from "@config/home";
import {
    CalcNotes,
    Contacts,
    DebtorActions,
    Footer,
    Header,
} from "@components/components";
import { Breadcrumbs, Sidebar } from ".";

interface IBaseLayout {
    calc?: boolean;
    debtor?: boolean;
    data?: any[];
    full?: boolean;
}

export const BaseLayout: React.FC<IBaseLayout> = ({
    debtor,
    data,
    calc,
    full,
}) => {
    const isMobile = useDeviceWidhtDetect(770);
    const isSmMobile = useDeviceWidhtDetect(425);
    const bailiff = Number(localStorage.getItem("bailiff")) || null;
    const [contactVisible, setContactVisible] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();
    useEffect(() => {
        const basePathDebtor = location.pathname.includes("debtor");
        const basePathClaimant = location.pathname.includes("claimant");
        const basePathServices = location.pathname.includes("services");
        const basePathForms = location.pathname.includes("forms");
        const basePathForm = location.pathname.includes("form_");
        basePathDebtor || basePathClaimant || basePathServices || basePathForms
            ? setContactVisible(true)
            : setContactVisible(false);
        if (basePathForm) setContactVisible(false);
    }, [location]);

    useEffect(() => {
        localStorage.getItem("bailiff");
    }, [bailiff]);
    return (
        <>
            {bailiff ? (
                <Header
                    bailiff_img={bailiffsListData(t)[bailiff - 1].image}
                    bailiff_name={bailiffsListData(t)[bailiff - 1].name}
                />
            ) : (
                <Header />
            )}
            <div className="flex flex-col max-w-[1440px] mx-auto overflow-hidden relative ">
                <div className="z-0 flex w-full px-0 mt-24 mb-0 md:px-8 h-fit lg:mt-0 xl:px-12 md:mb-0 gap-4 pb-6">
                    {isMobile || full ? null : (
                        <div className="lg:w-1/4 md:w-1/3 flex>">
                            {data && <Sidebar data={data} />}
                        </div>
                    )}
                    <div
                        className={clsx(
                            "flex flex-col lg:pl-6 pl-0 mt-14 md:mt-6 ml-0 h-fit   ",
                            full ? "w-full " : "lg:w-3/4 md:2/3 w-full",
                        )}
                    >
                        {!isSmMobile && <Breadcrumbs />}
                        <Outlet />
                    </div>
                </div>
                {calc ? <CalcNotes /> : null}
                {debtor ? <DebtorActions /> : null}
                {contactVisible && <Contacts className="relative z-20" />}
                <Footer className="relative z-20" />
            </div>
        </>
    );
};
