import React from "react";
import {
    Input,
    Awared,
    Textarea,
    Tooltip,
    HasData,
    Section,
} from "@components/components";
import { otherData, refundData, scheduleData } from "@config/forms";
import { useTranslation } from "react-i18next";

interface IRefund {
    onChange?: (arg0: any) => void;
    radioChecked?: any;
    onRadioChange?: (arg0: any) => void;
}
export const Refund: React.FC<IRefund> = ({
    onChange,
    onRadioChange,
    radioChecked,
}) => {
    const { t } = useTranslation();
    const { fields, nb, subtitle, download, tip } = refundData(t);
    const { label, placeholder } = otherData(t);
    const { debtorHasDependent, sections } = scheduleData(t);

    return (
        <>
            <div className="my-4 base-bord">
                <div className="flex items-center">
                    <p className="mb-2 font-semibold text-lg">{subtitle}</p>
                    <Tooltip hint={tip} />
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 border rounded-lg p-4 border-zinc-100">
                    {fields.map((field) => (
                        <Input
                            key={field.id}
                            label={field.label}
                            placeholder={field.placeholder}
                            units={field.units}
                            name={field.name}
                            onChange={onChange}
                            number={field.number}
                            minLength={field.minLength}
                            maxLength={field.maxLength}
                        />
                    ))}
                </div>
            </div>
            <HasData
                data={debtorHasDependent}
                radioChecked={radioChecked}
                onRadioChange={onRadioChange}
            />
            {Object(radioChecked).re_dependent ===
                t("scheduleData.debtorHasDependent.values.0.value") && (
                <Section
                    data={sections[0]}
                    key={sections[0].id}
                    onChange={onChange}
                />
            )}
            <div className="base-border">
                <p className="mb-2 text-lg text-[#333333] text-lg font-semibold">
                    {t("refundData.add-info_title")}
                </p>
                <Textarea
                    label={label}
                    placeholder={placeholder}
                    name="additional3"
                    onChange={onChange}
                    rows={8}
                />
            </div>

            <Awared data={nb} download={download} upper dark base />
        </>
    );
};
