import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { BaseLayout } from "@components/components";
import { debtorSidebarData } from "@config/debtor";

export const DebtorBase = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const basePath = location.pathname.endsWith("debtor");
    return <BaseLayout debtor={basePath} data={debtorSidebarData(t)} />;
};
