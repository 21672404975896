import React from "react";
import {
    Input,
    Awared,
    RadioGroup,
    Textarea,
    Tooltip,
    HasData,
    Section,
} from "@components/components";
import { monthlyMinimumData, scheduleData } from "@config/forms";
import { useTranslation } from "react-i18next";

interface IMinimim {
    onChange?: (arg0: any) => void;
    addBank2?: boolean;
    radioChecked?: any;
    onRadioChange?: (arg0: any) => void;
}
export const Minimum: React.FC<IMinimim> = ({
    onChange,
    addBank2,
    radioChecked,
    onRadioChange,
}) => {
    const { t } = useTranslation();
    const { banks, field, nb, otherData } = monthlyMinimumData(t);
    const { label, placeholder, name } = otherData;
    const { debtorHasDependent, sections } = scheduleData(t);
    return (
        <>
            <div className="flex items-center">
                <p className="mb-2 text-lg font-semibold">
                    {t("minimum.title")}
                </p>
                <Tooltip hint={t("minimum.hint")} />
            </div>
            <RadioGroup
                square
                col
                data={banks.points}
                upper={false}
                radioChecked={radioChecked}
                onRadioChange={onRadioChange}
            />

            {addBank2 && (
                <div className="my-4 base-bord">
                    <Input
                        placeholder={field.placeholder}
                        name={field.name}
                        onChange={onChange}
                        className="w-1/2"
                    />
                </div>
            )}
            <HasData
                data={debtorHasDependent}
                radioChecked={radioChecked}
                onRadioChange={onRadioChange}
            />
            {Object(radioChecked).re_dependent ===
                t("scheduleData.debtorHasDependent.values.0.value") && (
                <Section
                    data={sections[0]}
                    key={sections[0].id}
                    onChange={onChange}
                />
            )}
            <div className="base-border">
                <p className="mb-2 text-lg text-[#333333] text-lg font-semibold">
                    {t("minimum.other_info")}
                </p>
                <Textarea
                    label={label}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    rows={8}
                />
            </div>
            <Awared data={nb} upper base dark />
        </>
    );
};
