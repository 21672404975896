import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

export interface IModal {
    isShown: boolean;
    hide: () => void;
    modalContent: JSX.Element;
    headerText: string;
    timer?: boolean;
    delay?: number;
}

export const Modal: React.FC<IModal> = ({
    isShown,
    hide,
    modalContent,
    headerText,
    timer,
    delay,
}) => {
    const [timerShow, setTimerShow] = useState<boolean>(true);
    useEffect(() => {
        if (timer && delay) {
            setTimeout(() => {
                setTimerShow(() => false);
            }, delay);
        }
    }, [timer, delay]);

    const modal = (
        <>
            <div className=" backdrop" />
            <div className=" wrapper md:m-0">
                <div className="w-full modal md:w-2/3">
                    <div className="header">
                        <p className="text-lg font-semibold">{headerText}</p>
                        <button
                            className="text-5xl font-semibold closeBtn"
                            onClick={hide}
                        >
                            X
                        </button>
                    </div>
                    <div className="content">{modalContent}</div>
                </div>
            </div>
        </>
    );

    return (timer ? timerShow : isShown)
        ? ReactDOM.createPortal(modal, document.body)
        : null;
};
