import { ContactForm } from "@components/pages";
import React from "react";
import { BailiffCard } from "@components/home";
import { bailiffsListData } from "@config/home";
import { useTranslation } from "react-i18next";

export const Contacts = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col ml-4 mr-4 md:ml-0 md:mr-0 lg:ml-40 pt-4 md:pt-6">
                <h2 className="text-xl font-semibold mb-4 md:mb-6">
                    {t("contacts.title")}
                </h2>
                <p className="text-lg leading-9 md:w-3/4 mb-6">
                    {t("contacts.desc")}
                </p>
                {/*<h3 className="pl-4 my-4 text-xl font-semibold md:pl-0 ">*/}
                {/*    Kohtutäiturite ametitegevus*/}
                {/*</h3>*/}
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-1">
                    {bailiffsListData(t).map((item) => (
                        <div
                            className="my-2 base-border !p-0 lg:w-3/4 w-full"
                            key={item.id}
                        >
                            <BailiffCard
                                id={item.id}
                                key={item.id}
                                name={item.name}
                                image={item.image}
                                status={item.status}
                                handedTo={item.handedTo}
                                position={item.position}
                                contacts={item.contacts}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <ContactForm />
        </>
    );
};
