import React, { useEffect } from "react";

import { bailiffsListData } from "@config/home";
import {
    Activity,
    AuctionObjects,
    Bailiffs,
    Footer,
    Header,
    News,
    OfferSlider,
    Resources,
} from "@components/components";
import { useTranslation } from "react-i18next";

export const Home = () => {
    const { t } = useTranslation();

    const bailiff = Number(localStorage.getItem("bailiff")) || null;

    useEffect(() => {
        localStorage.getItem("bailiff");
    }, [bailiff]);
    return (
        <>
            {bailiff ? (
                <>
                    <Header
                        bailiff_img={bailiffsListData(t)[bailiff - 1].image}
                        bailiff_name={bailiffsListData(t)[bailiff - 1].name}
                    />
                </>
            ) : (
                <Header />
            )}
            <div
                className="w-full mx-auto max-w-[1440px] "
                onScroll={(e) => console.log("scrolling", e)}
            >
                <OfferSlider />
                <Activity />
                <Bailiffs isHome />
                {/*{isMobile ? <BailiffSlider /> : <Bailiffs />}*/}
                {/*<Services />*/}
                <Resources />
                {/* <Achievements /> */}
                <AuctionObjects />
                <News />
                {/* <Contacts /> */}
                <Footer />
            </div>
        </>
    );
};
