import React, { useState, useEffect } from "react";
import { BailiffFullCard, BaseSlider } from "@components/components";
import { bailiffsListData } from "@config/home";
import { useTranslation } from "react-i18next";

export const WorkArea = () => {
    let value = 0;
    const { t } = useTranslation();
    const [current, setCurrent] = useState(value);
    const totalPages = bailiffsListData(t).length;
    useEffect(() => {
        value = Number(localStorage.getItem("bailiff_id"));
    }, []);

    useEffect(() => {
        setCurrent(value - 1);
    }, [value]);

    return (
        <div className="flex flex-col ">
            <div className="flex flex-col overflow-y-hidden overflow-x-auto md:overflow-x-hidden relative">
                <BaseSlider
                    full
                    current={current}
                    setCurrent={setCurrent}
                    totalPages={totalPages}
                >
                    {bailiffsListData(t).map((item) => (
                        <div
                            key={item.id}
                            className="flex item:start min-w-full "
                        >
                            <BailiffFullCard
                                id={item.id}
                                name={item.name}
                                image={item.image_full}
                                contacts={item.contacts_full}
                                desc_1={item.desc_1}
                                desc_2={item.desc_2}
                                desc_3={item.desc_3}
                                region={item.contacts[3].content}
                            />
                        </div>
                    ))}
                </BaseSlider>
            </div>
        </div>
    );
};
