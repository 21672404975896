import React from "react";
import { servicesListData } from "@config/services";
import { useTranslation } from "react-i18next";
import { HiArrowNarrowRight } from "react-icons/hi";
import { NavHashLink } from "react-router-hash-link";

export const ServicesList = () => {
    const { t } = useTranslation();
    const selectItemClick = (
        name: string | undefined,
        path: string | undefined,
    ) => {
        localStorage.setItem("url", String(path));
        localStorage.setItem("selectMenuItem", String(name));
        localStorage.setItem("selectQuestion", String(name));
    };
    return (
        <div className="w-full mt-4 mx-auto lg:max-w-[990px]">
            <div className="lg:!px-12 lg:!py-8 !px-6 !py-4 my-2 base-border">
                <h2 className="mb-4 text-lg font-semibold">
                    {t("servicesList.title")}
                </h2>
                <p className="text-lg leading-9 md:text-justify">
                    {t("servicesList.desc")}
                </p>
            </div>
            <div className="lg:!px-12 lg:!py-8 !px-6 !py-4 my-2 base-border">
                {servicesListData(t).map((item) => (
                    <div key={item.content}>
                        {item.title && (
                            <h2 className="text-lg font-semibold mb-6">
                                {item.title}
                            </h2>
                        )}
                        {item.pretitle && (
                            <h3 className="text-lg font-semibold flex items-center mb-6">
                                <HiArrowNarrowRight className="text-2xl text-secondary mr-2" />
                                {item.pretitle}
                            </h3>
                        )}
                        <p className="text-lg leading-9 md:text-justify mb-6">
                            {item.content}
                        </p>
                        {item.content_2 && (
                            <p className="text-lg leading-9 md:text-justify mb-6">
                                {item.content_2}
                                {item.link_2 && (
                                    <a
                                        href={item.link_2}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="inline font-semibold text-secondary "
                                    >
                                        {item.link_2}
                                    </a>
                                )}
                                {item.endOfContent_2 && item.endOfContent_2}
                            </p>
                        )}

                        {item.content_4 && (
                            <p className="text-lg leading-9 md:text-justify">
                                {item.content_4}
                                {item.path_4 && (
                                    <NavHashLink
                                        onClick={() =>
                                            selectItemClick(
                                                item.name_4,
                                                item.path_url_4,
                                            )
                                        }
                                        to={`${item.path_4}#isTop`}
                                        className="inline font-semibold text-secondary text-lg leading-9"
                                    >
                                        {item.path_name_4}
                                    </NavHashLink>
                                )}
                                {item.endOfContent_4 && item.endOfContent_4}
                            </p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
