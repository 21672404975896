import { AppRoute } from "@enums";
import { TFunction } from "i18next";

// export const applyingData = {
//     title: "Elatisabi taotlemine",
//     desc: "Täitemenetlusaegset elatisabi saab taotleda alates 1. jaanuarist 2017. \n Täitemenetlusaegsele elatisabile on üldjuhul õigus alaealisel lapsel, kelle kasuks on kohtuotsusega välja mõistetud elatis ning kellele ülalpidamist maksma kohustatud isik ka täitemenetluse ajal elatist ei maksa. Täitemenetlusaegse elatisabi taotlemiseks tuleb täita avalduse ning edastada kohtutäiturile. Taotlus täitemenetlusaegse elatisabi saamiseks on võimalik esitada ka e-posti teel. Taotlus tuleb digitaalselt allkirjastada, lisadokumendid saab esitada posti teel.",
// };
// export const applyingFormData = {
//     formTitle: "Avalduse blankett on kättesaadav ",
//     formPath: "SIIT.",
//     formDesc:
//         "Avalduse täitmise abi saamiseks pöörduge kohtutäituri büroosse. Kohtutäituri kontaktandmed leiate ",
//     formDesc_1: "siit.",
// };
export const applyingQuestionsData = (
    t: TFunction<"translation", undefined>,
) => {
    return [
        {
            id: 1,
            title: `${t("applyingQuestionsData.0.title")}`,
            desc: `${t("applyingQuestionsData.0.desc")}`,
            desc_2: `${t("applyingQuestionsData.0.desc_2")}`,
            list_2: [
                `${t("applyingQuestionsData.0.list_2.0")}`,
                `${t("applyingQuestionsData.0.list_2.1")}`,
                `${t("applyingQuestionsData.0.list_2.2")}`,
                `${t("applyingQuestionsData.0.list_2.3")}`,
            ],
            desc_3: `${t("applyingQuestionsData.0.desc_3")}`,
            path_3: AppRoute.CONTACTS,
            path_url_3: "/contacts",
            path_name_3: `${t("applyingQuestionsData.0.path_name_3")}`,
            endOfDesc_3: ".",
            name_3: "contact",
            desc_4: `${t("applyingQuestionsData.0.desc_4")}`,
        },
        {
            id: 2,
            title: `${t("applyingQuestionsData.1.title")}`,
            desc: `${t("applyingQuestionsData.1.desc")}`,
            desc_2: `${t("applyingQuestionsData.1.desc_2")}`,
            list_2: [
                `${t("applyingQuestionsData.1.list_2.0")}`,
                `${t("applyingQuestionsData.1.list_2.1")}`,
                `${t("applyingQuestionsData.1.list_2.2")}`,
                `${t("applyingQuestionsData.1.list_2.3")}`,
            ],
            desc_3: `${t("applyingQuestionsData.1.desc_3")}`,
            link_3: "https://www.riigiteataja.ee/akt/114032011022?leiaKehtiv#para2 ",
            link_name_3: "www.riigiteataja.ee",
        },
        {
            id: 3,
            title: `${t("applyingQuestionsData.2.title")}`,
            desc: `${t("applyingQuestionsData.2.desc")}`,
            desc_bold: `${t("applyingQuestionsData.2.desc_bold")}`,
            endOfDesc_1: ".",
            desc_2: `${t("applyingQuestionsData.2.desc_2")}`,
            desc_3: `${t("applyingQuestionsData.2.desc_3")}`,
            path_3: AppRoute.FORMS_CLAIMANT_FORM_4,
            path_name_3: "SIIN",
            path_url_3: "/forms",
            endOfDesc_3: `${t("applyingQuestionsData.2.endOfDesc_3")}`,
        },
        {
            id: 4,
            title: `${t("applyingQuestionsData.3.title")}`,
            desc: `${t("applyingQuestionsData.3.desc")}`,
            desc_2: `${t("applyingQuestionsData.3.desc_2")}`,
            desc_3: `${t("applyingQuestionsData.3.desc_3")}`,
        },
        {
            id: 5,
            title: `${t("applyingQuestionsData.4.title")}`,
            desc: `${t("applyingQuestionsData.4.desc")}`,
        },
        {
            id: 6,
            title: `${t("applyingQuestionsData.5.title")}`,
            desc: `${t("applyingQuestionsData.5.desc")}`,
            desc_2: `${t("applyingQuestionsData.5.desc_2")}`,
            desc_3: `${t("applyingQuestionsData.5.desc_3")}`,
            desc_4: `${t("applyingQuestionsData.5.desc_4")}`,
            path_4: AppRoute.FORMS_CLAIMANT_FORM_2,
            path_name_4: `${t("applyingQuestionsData.5.path_name_4")}`,
            path_url_4: "/forms",
            endOfDesc_4: ".",
        },
        {
            id: 7,
            title: `${t("applyingQuestionsData.6.title")}`,
            desc: `${t("applyingQuestionsData.6.desc")}`,
            desc_2: `${t("applyingQuestionsData.6.desc_2")}`,
            desc_3: `${t("applyingQuestionsData.6.desc_3")}`,
            desc_bold_3: `${t("applyingQuestionsData.6.desc_bold_3")}`,
            endOfDesc_3: `${t("applyingQuestionsData.6.endOfDesc_3")}`,
            link_3: "https://kpkoda.ee/kohtutaiturid/nouete-aegumisest/ ",
            link_name_3: "kpkoda.ee",
        },
        {
            id: 8,
            title: `${t("applyingQuestionsData.7.title")}`,
            desc: `${t("applyingQuestionsData.7.desc")}`,
            desc_3: `${t("applyingQuestionsData.7.desc_3")}`,
            path_3: AppRoute.SERVICES,
            path_url_3: "/services",
            path_name_3: `${t("applyingQuestionsData.7.path_name_3")}`,
            endOfDesc_3: ".",
        },
    ];
};
