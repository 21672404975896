import { TFunction } from "i18next";

export const refundData = (t: TFunction<"translation", undefined>) => {
    return {
        fields: [
            {
                id: 1,
                placeholder: "",
                label: `${t("refundData.fields.0.label")}`,
                name: "current3",
                number: false,
            },

            {
                id: 2,
                label: `${t("refundData.fields.1.label")}`,
                placeholder: "",
                name: "amount3",
                minLength: undefined,
                maxLength: undefined,
                number: false,
            },
            {
                id: 3,
                label: `${t("refundData.fields.2.label")}`,
                placeholder: "",
                name: "summa",
                number: true,
                units: "€",
            },
        ],
        subtitle: `${t("refundData.subtitle")}`,
        tip: `${t("refundData.tip")}`,
        nb: {
            title: `${t("refundData.nb.title")}`,
            points: [
                {
                    id: 1,
                    content: `${t("refundData.nb.points.0.content")}`,
                },
                {
                    id: 2,
                    content: `${t("refundData.nb.points.1.content")}`,
                    underLineContent: `${t(
                        "refundData.nb.points.1.underLineContent",
                    )}`,
                    endContent: `${t("refundData.nb.points.1.endContent")}`,
                },
                {
                    id: 3,
                    content: `${t("refundData.nb.points.2.content")}`,
                },
                {
                    id: 4,
                    content: `${t("refundData.nb.points.3.content")}`,
                },
                {
                    id: 5,
                    content: `${t("refundData.nb.points.4.content")}`,
                },
                {
                    id: 6,
                    content: `${t("refundData.nb.points.5.content")}`,
                    bold: true,
                },
            ],
        },
        download: {
            title: `${t("refundData.download.title")}`,
            divided_fields: [
                {
                    id: 1,
                    elem: `${t("refundData.download.divided_fields.0.elem")}`,
                    type: "file",
                    name: "download_1",
                    placeholder: `${t(
                        "refundData.download.divided_fields.0.placeholder",
                    )}`,
                },
            ],
            note: `${t("refundData.download.note")}`,
            path: `${t("refundData.download.path")}`,
        },
    };
};
