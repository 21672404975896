import React, { useRef, useState } from "react";
import {
    Button,
    Input,
    RadioGroup,
    Textarea,
    Tooltip,
} from "@components/components";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import NumberFormat from "react-number-format";
import { checkRoleData, contactFormData } from "@config/common";
import * as Yup from "yup";
import clsx from "clsx";
import { sendContactForm } from "../../services";

const initialState = {
    code_phone_form: "372",
    message_form: "",
    name_form: "",
    phone_form: "",
    email_form: "",
    topic_form: "Üldine infopäring",
    role_form: " ",
};

export const ContactForm = () => {
    const form = useRef();
    const [validForm, setValidForm] = useState(false);
    const [visible, setVisible] = useState(false);
    const [formValues, setFormValues] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(false);
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape(
        {
            formName: Yup.string().min(3).required("Name is required"),
            formPost: Yup.string()
                .email("Post is invalid")
                .required("Post is required"),
            formPhone: Yup.string().trim().min(8).required("Phone is required"),
            formCodePhone: Yup.string()
                .trim()
                .min(1)
                .required("Code phone is required"),
            formMessage: Yup.string()
                .trim()
                .min(3)
                .required("Message is required"),
        },
        [],
    );

    const onChange = () => {
        setError(null);
        setWarning(true);
        validationSchema
            .isValid({
                formName: name_form,
                formPhone: phone_form,
                formPost: email_form,
                formCodePhone: code_phone_form,
                formMessage: message_form,
            })
            .then((valid) => {
                setValidForm(valid);
            })
            .catch((err) => {
                console.log(err.name);
            });
        setFormValues((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value,
            };
        });
    };

    const {
        name_form,
        email_form,
        phone_form,
        message_form,
        code_phone_form,
        topic_form,
    } = formValues;

    const sendEmail = () => {
        setLoading(true);
        setError(null);
        sendContactForm(formValues)
            .then((result) => {
                console.log(result);
                setVisible(true);
                setTimeout(() => {
                    setVisible(false);
                    setWarning(false);
                }, 10000);
            })
            .catch((error) => {
                console.log(error);
                setError(error.message);
            })
            .finally(() => {
                setLoading(false);
                setFormValues(initialState);
            });
    };
    return (
        <div className="flex flex-col w-full px-6 py-12 m-0 bg-white md:px-12">
            <div className="flex flex-col w-full ">
                <p className="pb-6 text-xl font-semibold tracking-wide uppercase ">
                    {t("contactForm.title")}
                </p>
                <form className="flex flex-col w-full mt-2" ref={form}>
                    <div className="grid grid-cols-1 gap-2 lg:gap-x-6 lg:gap-y-2 lg:grid-cols-2">
                        {contactFormData(t).map((item) => (
                            <div key={item.id}>
                                {item.type === "email" && (
                                    <Input
                                        contact
                                        value={email_form}
                                        type={item.type}
                                        label={item.label}
                                        placeholder={item.placeholder}
                                        name={item.name}
                                        required={item.required}
                                        onChange={onChange}
                                        minLength={item.minLength}
                                    />
                                )}
                                {item.type === "text" && (
                                    <Input
                                        contact
                                        value={name_form}
                                        type={item.type}
                                        label={item.label}
                                        placeholder={item.placeholder}
                                        name={item.name}
                                        required={item.required}
                                        onChange={onChange}
                                        minLength={item.minLength}
                                    />
                                )}
                                {item.type === "tel" && (
                                    <div>
                                        <div className="relative flex items-center justify-between">
                                            <label
                                                htmlFor={item.name}
                                                className="text-lg text-[#4B4B4B] mb-1 text-lg"
                                            >
                                                {item.label}
                                                {item.tip && (
                                                    <Tooltip
                                                        hint={item.tip}
                                                        right={false}
                                                    />
                                                )}
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <p className="text-lg text-neutral px-3">
                                                +
                                            </p>
                                            <NumberFormat
                                                value={code_phone_form}
                                                className="focus:outline-none focus:ring-secondary w-[50px]  focus:ring-1 border focus:border-secondary border-[#4b4b4b] text-lg block rounded-md py-2 placeholder:opacity-50 placeholder:text-neutral placeholder:text-lg pl-3 text-lg mr-3"
                                                key={item.id}
                                                name="code_phone_form"
                                                format="###"
                                                mask=" "
                                                onValueChange={() => onChange()}
                                            />
                                            <NumberFormat
                                                className="focus:outline-none focus:ring-secondary  focus:ring-1 border focus:border-secondary border-[#4b4b4b] text-lg block rounded-md py-2 placeholder:opacity-50 placeholder:text-neutral w-full placeholder:text-lg p-2 text-lg"
                                                key={item.id}
                                                value={phone_form}
                                                name={item.name}
                                                format="### ### ### ###"
                                                mask=" "
                                                onValueChange={() => onChange()}
                                            />
                                        </div>
                                    </div>
                                )}
                                {item.label === "Teema" && (
                                    <>
                                        <p className="mb-1 text-lg text-[#4b4b4b]">
                                            {t(
                                                "contactForm.contactFormData.1.label",
                                            )}
                                        </p>
                                        <select
                                            value={topic_form}
                                            name="topic_form"
                                            onChange={() => onChange()}
                                            className="h-[46px] focus:outline-none focus:ring-secondary  focus:ring-1 border mb-1 focus:border-secondary border-[#4b4b4b] text-lg block rounded-md py-2 placeholder:opacity-50 placeholder:text-neutral w-full placeholder:text-lg p-2 text-lg bg-transparent"
                                        >
                                            {item.options.map((opt) => (
                                                <option
                                                    key={opt.id}
                                                    value={opt.value}
                                                >
                                                    {opt.value}
                                                </option>
                                            ))}
                                        </select>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="w-full mb-6 mr-4">
                        <Textarea
                            value={message_form}
                            label={contactFormData(t)[4].label}
                            placeholder={contactFormData(t)[4].placeholder}
                            name={contactFormData(t)[4].name}
                            required
                            onChange={onChange}
                            rows={5}
                        />
                    </div>
                    <div className="flex items-center ">
                        <div className="mr-2 lg:mr-4 xl:16">
                            <RadioGroup
                                data={checkRoleData(t)}
                                onRadioChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center lg:flex-row">
                        <Button
                            disabled={!validForm}
                            onClick={sendEmail}
                            className="self-center !w-full  md:!w-auto mt-12 "
                        >
                            {t("contactForm.sendBtn")}
                        </Button>
                        <p
                            className={clsx(
                                "text-[#f70303] lg:mt-12 mt-4 ml-4",
                                !validForm && warning && !visible
                                    ? "block"
                                    : "hidden",
                            )}
                        >
                            {t("contactForm.warning")}
                        </p>
                    </div>
                    {error && (
                        <div className="text-center text-[#f70303] my-4">
                            {error}
                        </div>
                    )}
                    {loading && (
                        <div className="mx-auto my-4">
                            <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"
                                visible={true}
                            />
                        </div>
                    )}
                    {visible && (
                        <div className="mt-4">
                            <p className="text-center text-lg font-semibold">
                                {t("contactForm.sentText")}
                            </p>
                            <p className="mt-4 text-center text-lg">
                                {t("contactForm.answer")}
                            </p>
                            <p className="mt-4 text-center text-lg">
                                {t("contactForm.answer_1")}
                            </p>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};
