import React from "react";

export const SideIcon1 = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.3345 0.75H5.66549C2.64449 0.75 0.750488 2.889 0.750488 5.916V14.084C0.750488 17.111 2.63449 19.25 5.66549 19.25H14.3335C17.3645 19.25 19.2505 17.111 19.2505 14.084V5.916C19.2505 2.889 17.3645 0.75 14.3345 0.75Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.52075 11.1972C4.85975 11.1972 4.32275 10.6602 4.32275 10.0002C4.32275 9.34025 4.85975 8.80225 5.52075 8.80225C6.18175 8.80225 6.71875 9.34025 6.71875 10.0002C6.71875 10.6602 6.18175 11.1972 5.52075 11.1972"
                fill="white"
            />
            <path
                d="M9.99976 11.1972C9.33876 11.1972 8.80176 10.6602 8.80176 10.0002C8.80176 9.34025 9.33876 8.80225 9.99976 8.80225C10.6608 8.80225 11.1978 9.34025 11.1978 10.0002C11.1978 10.6602 10.6608 11.1972 9.99976 11.1972"
                fill="white"
            />
            <path
                d="M14.4788 11.1972C13.8178 11.1972 13.2808 10.6602 13.2808 10.0002C13.2808 9.34025 13.8178 8.80225 14.4788 8.80225C15.1398 8.80225 15.6768 9.34025 15.6768 10.0002C15.6768 10.6602 15.1398 11.1972 14.4788 11.1972"
                fill="white"
            />
        </svg>
    );
};
