import React from "react";

export const SideIcon4 = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 8.822C20 9.239 19.661 9.578 19.244 9.578H19.235V9.56C18.813 9.56 18.471 9.219 18.47 8.797V8.795V6.253C18.47 3.2 16.8 1.53 13.756 1.53H6.256C3.21 1.53 1.53 3.21 1.53 6.253V13.753C1.53 16.787 3.21 18.467 6.253 18.467H13.753C16.796 18.467 18.467 16.787 18.467 13.753C18.467 13.331 18.809 12.988 19.232 12.988C19.655 12.988 19.997 13.331 19.997 13.753C20 17.608 17.608 20 13.756 20H6.253C2.392 20 0 17.608 0 13.756V6.256C0 2.392 2.392 0 6.253 0H13.753C17.59 0 20 2.392 20 6.253V8.822ZM8.8136 11.3135L13.0296 7.0965C13.3226 6.8035 13.7976 6.8035 14.0906 7.0965C14.3836 7.3895 14.3836 7.8645 14.0906 8.1575L9.3436 12.9045C9.2026 13.0445 9.0116 13.1235 8.8136 13.1235C8.6136 13.1235 8.4236 13.0445 8.2826 12.9045L5.9096 10.5305C5.6166 10.2375 5.6166 9.7625 5.9096 9.4695C6.2026 9.1765 6.6776 9.1765 6.9706 9.4695L8.8136 11.3135Z"
                fill="white"
            />
        </svg>
    );
};
