import React from "react";
import clsx from "clsx";

interface ISlide {
    img: string;
    className?: string;
}

export const Slide: React.FC<ISlide> = ({ img, className }) => {
    return (
        <div
            className={clsx(
                className,
                "w-full xl:w-[802px] md:w-3/4 lg:w-[700px] xl:-mr-12 lg:-mr-48 md:ml-0 ",
            )}
        >
            <img
                src={require(`assets/images/${img}`)}
                alt="BailiffPhoto"
                className="object-cover w-full h-full"
                // className="w-full xl:w-11/12 lg:w-3/4 xl:h-[660px] h-[290px] min-h-max"
            />
        </div>
    );
};
