import {TFunction} from "i18next";

export interface PdfForm {
    name_blanked: string;
    name_form: string;
    case_number: string;
    identification_code: string;
    blanks: string[];
}

export const get_pdf_form = (): PdfForm => {
    const selectMenuItemFromStorage = JSON.parse(
        localStorage.getItem("selected_titles_arr") || "[]",
    );
    const debtorName = localStorage.getItem("debtorName");
    const selectMenuItem = localStorage.getItem("selectTitleItem");
    const case_number = localStorage.getItem("caseNumber");
    const debtorId = localStorage.getItem("debtorId");
    return {
        name_blanked: selectMenuItem || "",
        name_form: debtorName || "",
        case_number: case_number || "",
        identification_code: debtorId || "",
        blanks: selectMenuItemFromStorage,
    };
};

export interface Blank {
    personal_code: string;
    name: string;
    process_nr: string;
    todays_date: string | null;
    process_start_date: string | null;
    contact_residence_address: string;
    contact_email: string;
    contact_phone: string;
    form_type_1: {
        name: string;
        partial_payment: string;
        payment_date: string;
        first_payment_date: string;
        bank_1: string;
        bank_account_limit_request: string;
    };
    form_type_2: {
        name: string;
        bank_2: string;
    };
    form_type_3: {
        name: string;
        bank_3: string;
        minimum_summa_request: string;
    };
    dependants: string;
    partial_payment_request: boolean;
    minimum_request: boolean;
    minimum_back_request: boolean;
    assets_list: boolean;
    expiration_request: boolean;
    sales_under_control: boolean;
    other_statement: boolean;
}

export const get_blank_data = (
    t: TFunction<"translation", undefined>,
    pdf_type: string,
): Blank => {
    const name = localStorage.getItem("debtorName") || "";
    const personal_code = localStorage.getItem("debtorId") || "";
    const process_nr = localStorage.getItem("caseNumber") || "";
    const todays_date = localStorage.getItem("currentDate");
    const process_start_date = localStorage.getItem("currentDate1");
    const contact_residence_address =
        localStorage.getItem("debtorAddress") || "";
    let contact_email = localStorage.getItem("debtorEmail") || "";
    const pre_phone = localStorage.getItem("pre_debtor_phone") || "";
    const phone_number = localStorage.getItem("debtor_phone") || "";
    const dependants =
        localStorage.getItem("re_dependent") ===
        t("scheduleData.debtorHasDependent.values.0.value")
            ? localStorage.getItem("add_more_count")
            : "0";
    const selectMenuItemFromStorage = JSON.parse(
        localStorage.getItem("selected_menu_arr") || "[]",
    );

    switch (pdf_type) {
        case "/pdf_1":
            contact_email = localStorage.getItem("parentEmail") || "";
            break;
        case "/pdf_3":
            contact_email = localStorage.getItem("reClaimantEmail") || "";
            break;
        case "/pdf_4":
            contact_email = localStorage.getItem("claimantPost4") || "";
            break;
        case "/pdf_5":
            contact_email = localStorage.getItem("claimantPost") || "";
            break;
        case "/pdf_7":
            contact_email = localStorage.getItem("claimantPost") || "";
            break;
        default:
            break;
    }

    return {
        name,
        personal_code,
        process_nr,
        todays_date,
        process_start_date,
        contact_residence_address,
        contact_email,
        contact_phone: `${pre_phone} ${phone_number}`,
        form_type_1: {
            name: "Maksegraafiku avaldus",
            partial_payment: localStorage.getItem("amount") || "",
            payment_date: localStorage.getItem("date") || "",
            first_payment_date: localStorage.getItem("firstPayment") || "",
            bank_1: localStorage.getItem("bank") || "",
            bank_account_limit_request: localStorage.getItem("limit1") || "",
        },
        form_type_2: {
            name: "Mittearestitava sissetuleku-kasutamine",
            bank_2: localStorage.getItem("bank2") || "",
        },
        form_type_3: {
            name: "Mittearestitava sissetuleku tagastamine",
            bank_3: localStorage.getItem("current3") || "",
            minimum_summa_request: localStorage.getItem("summa") || "",
        },
        dependants: dependants || "",
        partial_payment_request: selectMenuItemFromStorage.includes("schedule"),
        minimum_request: selectMenuItemFromStorage.includes("minimum"),
        minimum_back_request: selectMenuItemFromStorage.includes("refund"),
        assets_list: selectMenuItemFromStorage.includes("notice"),
        expiration_request: selectMenuItemFromStorage.includes("sale"),
        sales_under_control: selectMenuItemFromStorage.includes("other"),
        other_statement: selectMenuItemFromStorage.includes("other"),
    };
};

export interface FormBody {
    blank: string;
    name_form: string;
    identification_code: string;
    claimant_email: string;
}

export const get_form_data = (): FormBody => {
    const blank = JSON.parse(
        localStorage.getItem("selected_titles_arr") || "[]",
    ).toString() as string;
    const name_form = localStorage.getItem("debtorName") || "";
    const identification_code = localStorage.getItem("debtorId") || "";
    const claimant_email = localStorage.getItem("parentEmail") || "";
    return {
        blank,
        name_form,
        identification_code,
        claimant_email,
    };
};
