import { Button } from "@components/common";
import React, { useEffect, useState } from "react";
import { ReactComponent as File } from "@assets/icons/forms/file.svg";
import { DokobitApi } from "@api/Dokobit";
import { useTranslation } from "react-i18next";

interface IDividedFields {
    id: number;
    elem: string;
    type: string;
    name: string;
    placeholder: string;
    multiple?: boolean;
}

interface IData {
    title: string;
    divided_fields: IDividedFields[];
}

interface IDownload {
    data: IData;
    nameLocal: string;
}

interface IFile {
    name: string;
    size: number;
    type: string;
    url: string;
}

// type: string; url: string;
export const Download: React.FC<IDownload> = ({ data, nameLocal }) => {
    const { t } = useTranslation();
    const { title } = data;
    const [files, setFiles] = useState<IFile[]>([]);

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = (): void => {
        if (!hiddenFileInput.current) return;
        hiddenFileInput.current.click();
    };

    const uploadHandler = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ): Promise<void> => {
        event.preventDefault();
        if (!event.currentTarget.files) return;
        const files = event.currentTarget.files;
        const data: IFile[] = [];
        for (let i = 0; i < files.length; i++) {
            const fileObj = await DokobitApi.upload(files[i], true);
            data.push({
                name: files[i].name,
                size: files[i].size,
                type: files[i].type.split("/")[1],
                url: fileObj.fileurl,
            });
        }
        setFiles((prevState) => prevState.concat(data));
    };

    const removeItem = (arg: string) => {
        setFiles((prevState) => prevState.filter((el) => el.name !== arg));
    };

    const elements = files.map(({ name, size }) => (
        <li key={name} className="w-[486px] flex items-center mt-2">
            <File width="20" height="20" className="mr-3" />
            <span className="text-lg text-middleGray w-[70%]">{name}</span>
            <span className="text-lg text-middleGray">
                {Math.ceil(size / 1000)}
            </span>
            <span className="text-lg text-middleGray">kb</span>
            <p
                className="ml-auto text-2xl font-semibold cursor-pointer"
                onClick={() => removeItem(name)}
            >
                x
            </p>
        </li>
    ));
    useEffect(() => {
        if (!nameLocal) return;
        localStorage.setItem(nameLocal, JSON.stringify(files));
    }, [files]);
    return (
        <div className="flex flex-col mb-10  mt-6">
            <p className="mb-4 text-lg font-semibold text-neutral">{title}</p>
            <Button type="button" onClick={handleClick} outline>
                {t("other.download.btn")}
            </Button>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={uploadHandler}
                style={{ display: "none" }}
                multiple
            />
            <ul>{elements}</ul>
        </div>
    );
};
