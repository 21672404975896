import { IError, IRSign, IRToken, IValuesSignForm } from "@types";
import axios, { AxiosError, Method } from "axios";
import { API_URL, Blank, FormBody } from "@helpers";

const getError = <E>(error: Error | AxiosError<E>): E => {
    if (axios.isAxiosError(error) && error.response) {
        return error.response.data as E;
    } else {
        return {
            status: "stock-error",
            message: error.message,
        } as unknown as E;
    }
};

const fetcher = async <TResponse, TError, TData = any>(
    method: Method,
    url: string,
    data?: TData,
): Promise<TResponse> => {
    return new Promise<TResponse>((resolve, reject) =>
        axios(url, {
            method,
            data,
        })
            .then((response) => resolve(response.data))
            .catch((error: AxiosError | Error) =>
                reject(getError<TError>(error)),
            ),
    );
};

interface IUploadFile {
    filename: string;
    fileurl: string;
    digest: string;
}

export class DokobitApi {
    static URL = API_URL;

    static async upload(file: File, attache?: boolean): Promise<IUploadFile> {
        const fd = new FormData();
        fd.append("file", file);
        return fetcher<IUploadFile, IError>(
            "post",
            `${this.URL}/uploadfile?attache=${attache ? "True" : "False"}`,
            fd,
        );
    }

    static async sign(
        file_name: string,
        file_url: string,
        file_digest: string,
        user_id: string,
        user_name: string,
        user_surname: string,
    ): Promise<IRSign> {
        return fetcher<IRSign, IError>("post", `${this.URL}/sign`, {
            file_name,
            file_url,
            file_digest,
            user_id,
            user_name,
            user_surname,
        });
    }

    static async delete(token: string): Promise<IRToken> {
        return fetcher<IRToken, IError>(
            "post",
            `${this.URL}/delete?token=${token}`,
        );
    }

    static async signature(
        documents: string[],
        obj: IValuesSignForm,
        blanks: Blank,
    ): Promise<null> {
        return fetcher<null, IError>("post", `${this.URL}/signature`, {
            file: {
                url_file: documents,
            },
            form: obj,
            blanks,
        });
    }

    static async csv(blank: Blank, form: FormBody): Promise<null> {
        return fetcher<null, IError>("post", `${this.URL}/csv`, {
            blank,
            form,
        });
    }

    static async claimant(documents: string[], form: FormBody): Promise<null> {
        return fetcher<null, IError>("post", `${this.URL}/claimant`, {
            file: {
                url_file: documents,
            },
            form,
        });
    }

    static async debtor(
        blank: Blank,
        form: FormBody,
        document: string,
    ): Promise<null> {
        return fetcher<null, IError>("post", `${this.URL}/debtor`, {
            blank,
            form,
            file: {
                url_file: [document],
            },
        });
    }
}
