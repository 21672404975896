import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCurrentRef } from "@hooks";
import { navListData } from "@config/common";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { NavHashLink } from "react-router-hash-link";

interface INavbar {
    isMobile: boolean;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Navbar: React.FC<INavbar> = ({ isMobile, isOpen, setIsOpen }) => {
    const [submenu, setSubmenu] = useState(false);
    const [currentSubMenu, setCurrentSubMenu] = useState(0);
    const [active, setActive] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const navRef = useRef<HTMLAnchorElement>(null);
    const curRef = useCurrentRef(ref);
    const { t } = useTranslation();

    const handleClick = (e: React.MouseEvent, index: number) => {
        e.persist();
        setActive(index);
        openCurrent(index);
    };
    const openCurrent = (index: number) => {
        if (curRef.current) {
            active === index
                ? curRef.current.children[index].classList.add(
                      "accordion-item--opened",
                  )
                : curRef.current.children[index].classList.remove(
                      "accordion-item--opened",
                  );
        }
    };
    const openSubMenu = (id: number, path: string) => {
        !submenu ? setSubmenu(true) : setSubmenu(false);
        setCurrentSubMenu(id);
        localStorage.setItem("url", path);
    };

    const onClickNavMenu = (e: React.MouseEvent, path: string) => {
        localStorage.setItem("url", String(path));
    };
    const url = localStorage.getItem("url");
    return (
        <div
            ref={ref}
            className={
                isMobile
                    ? "fixed z-50 top-16 left-0 right-0 bg-white justify-around pt-[80px] pb-[120px] flex flex-col bottom-0"
                    : "flex justify-between"
            }
        >
            {!submenu ? (
                <>
                    {navListData(t).map((item, index) => (
                        <div key={item.id} className="text-center">
                            {item.children && isMobile ? (
                                <div
                                    onClick={(e) => handleClick(e, index)}
                                    className="flex flex-col items-center "
                                >
                                    <p
                                        className="cursor-pointer px-2 font-semibold hover:!text-secondary uppercase text-center  lg:text-base md:text-[12.5px] text-lg"
                                        onClick={() =>
                                            openSubMenu(index, item.path)
                                        }
                                    >
                                        {item.content}
                                    </p>
                                </div>
                            ) : (
                                <NavHashLink
                                    onClick={(e) =>
                                        onClickNavMenu(e, item.path)
                                    }
                                    ref={navRef}
                                    to={`${item.path}#isTop`}
                                    style={() =>
                                        url?.includes(item.path)
                                            ? {
                                                  color: "#FF5800",
                                                  textDecoration: "underline",
                                                  textDecorationColor:
                                                      "#FF5800",
                                                  textDecorationThickness:
                                                      "3px",
                                                  textUnderlineOffset: !isMobile
                                                      ? "27px"
                                                      : "5px",
                                              }
                                            : { color: "#1A1A1F" }
                                    }
                                    className="xl:p-0 p-2 cursor-pointer font-semibold hover:!text-secondary uppercase text-center lg:text-base md:text-[12.5px] text-lg"
                                >
                                    {item.content}
                                </NavHashLink>
                            )}
                        </div>
                    ))}
                </>
            ) : (
                <div className="flex flex-col items-center ">
                    {navListData(t)[currentSubMenu]?.children?.map((i) => (
                        <Link
                            to={i.path}
                            key={i.path}
                            onClick={() => setIsOpen(!isOpen)}
                            className=" py-3 hover:text-secondary   lg:text-base md:text-[12.5px] text-base font-semibold uppercase cursor-pointer"
                        >
                            {i.content}
                        </Link>
                    ))}
                    <button className="mt-8" onClick={() => setSubmenu(false)}>
                        <HiArrowNarrowLeft className="text-3xl font-semibold cursor-pointer hover: text-secondary " />
                    </button>
                </div>
            )}
        </div>
    );
};
