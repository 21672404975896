import React from "react";
import { calcData } from "@config/";
import { useTranslation } from "react-i18next";
import { HiArrowNarrowRight } from "react-icons/hi";

export const CalcNotes = () => {
    const { t } = useTranslation();
    const { items, desc, path, path_name, endOfDesc } = calcData(t);
    return (
        <div className="px-12 py-8 lg:py-12">
            <ul className="relative z-10 gap-16 bg-white lg:columns-2 columns-1">
                {items.map((item) => (
                    <li
                        className="text-lg leading-9 text-justify mb-4"
                        key={item.id}
                    >
                        <HiArrowNarrowRight className="inline text-2xl text-secondary mr-2" />
                        {item.content}
                    </li>
                ))}
            </ul>
            <div className="h-[1px] w-[100%] bg-gray my-4" />
            <p className="text-lg leading-9">
                {desc}
                <a
                    href={path}
                    rel="noreferrer"
                    target="_blank"
                    className="font-semibold text-secondary inline"
                >
                    {path_name}
                </a>
                {endOfDesc}
            </p>
        </div>
    );
};
