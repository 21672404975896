import React from "react";

interface CheckedListProps {
    title: string;
    point_1: string;
    point_2?: string | null;
    point_3?: string | null;
    point_4?: string | null;
    point_5?: string | null;
    point_6?: string | null;
    point_7?: string | null;
    point_8?: string;
    point_9?: string;

    sub_1: boolean;
    sub_2?: boolean;
    sub_3?: boolean;
    sub_4?: boolean;
    sub_5?: boolean;
    sub_6?: boolean;
    sub_7?: boolean;
    sub_8?: boolean;
    sub_9?: boolean;
    className?: string;
}

export const CheckedList: React.FC<CheckedListProps> = ({
    title,
    point_1,
    point_2,
    point_3,
    point_4,
    point_5,
    point_6,
    point_7,
    point_8,
    point_9,

    sub_1,
    sub_2,
    sub_3,
    sub_4,
    sub_5,
    sub_6,
    sub_7,
    sub_8,
    sub_9,
    className,
}) => {
    const points = [
        point_1,
        point_2,
        point_3,
        point_4,
        point_5,
        point_6,
        point_7,
        point_8,
        point_9,
    ];
    const subs = [
        sub_1,
        sub_2,
        sub_3,
        sub_4,
        sub_5,
        sub_6,
        sub_7,
        sub_8,
        sub_9,
    ];
    return (
        <div className={className}>
            <p className="mb-2 font-[500] text-[16px] fontWeight">{title}</p>
            <ul className="">
                {points.map(
                    (item, index) =>
                        subs[index] && (
                            <li
                                className="flex items-center mb-4 list-none "
                                key={index}
                            >
                                <div className="border-secondary border rounded-[3px] h-4 w-4 mr-2 relative flex items-center justify-center text-[#f35429] text-[16px] font-[500]">
                                    <img
                                        src={require(`@assets/icons/check.png`)}
                                        alt="✔"
                                    />
                                </div>
                                <p className="w-[90%]"> {item}</p>
                            </li>
                        ),
                )}
            </ul>
        </div>
    );
};
