import { TFunction } from "i18next";

export const claimantForm4Data = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("claimantForm4Data.title")}`,
        datepicker: true,
        sections: [
            {
                id: 1,
                title: `${t("claimantForm4Data.sections.0.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "claimant_name",
                        label: `${t(
                            "claimantForm4Data.sections.0.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "claimant_id",
                        label: `${t(
                            "claimantForm4Data.sections.0.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 8,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 3,
                        name: "claimant_address",
                        label: `${t(
                            "claimantForm4Data.sections.0.fields.2.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 4,
                        name: "claimant_phone",
                        label: `${t(
                            "claimantForm4Data.sections.0.fields.3.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 5,
                        name: "claimant_post",
                        label: `${t(
                            "claimantForm4Data.sections.0.fields.4.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 6,
                        label: `${t(
                            "claimantForm4Data.sections.0.fields.5.label",
                        )}`,
                        type: "text",
                        name: "bank_account",
                        number: false,
                        placeholder: " ",
                    },
                    {
                        id: 7,
                        label: `${t(
                            "claimantForm4Data.sections.0.fields.6.label",
                        )}`,
                        placeholder: " ",
                        type: "text",
                        name: "bank_name",
                    },
                ],
                note: "",
                add_more: false,
            },
            {
                id: 2,
                title: `${t("claimantForm4Data.sections.1.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "re_claimant_name",
                        label: `${t(
                            "claimantForm4Data.sections.1.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "re_claimant_id",
                        label: `${t(
                            "claimantForm4Data.sections.1.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 8,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 3,
                        name: "re_claimant_address",
                        label: `${t(
                            "claimantForm4Data.sections.1.fields.2.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 4,
                        name: "re_claimant_phone",
                        label: `${t(
                            "claimantForm4Data.sections.1.fields.3.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 5,
                        name: "re_claimant_post",
                        label: `${t(
                            "claimantForm4Data.sections.1.fields.4.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 6,
                        label: `${t(
                            "claimantForm4Data.sections.1.fields.5.label",
                        )}`,
                        type: "text",
                        name: "re_claimant_account",
                        number: false,
                        placeholder: " ",
                    },
                    {
                        id: 7,
                        label: `${t(
                            "claimantForm4Data.sections.1.fields.6.label",
                        )}`,
                        placeholder: " ",
                        type: "text",
                        name: "re_claimant_bank",
                    },
                ],
                note: "",
                add_more: false,
            },
            {
                id: 3,
                title: `${t("claimantForm4Data.sections.2.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "debtor_name",
                        label: `${t(
                            "claimantForm4Data.sections.2.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "debtor_id",
                        label: `${t(
                            "claimantForm4Data.sections.2.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 8,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 3,
                        name: "debtor_address",
                        label: `${t(
                            "claimantForm4Data.sections.2.fields.2.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 4,
                        name: "debtor_phone",
                        label: `${t(
                            "claimantForm4Data.sections.2.fields.3.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 5,
                        name: "debtor_post",
                        label: `${t(
                            "claimantForm4Data.sections.2.fields.4.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                ],
                note: "",
                add_more: false,
            },
            {
                id: 4,
                title: `${t("claimantForm4Data.sections.3.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "re_debtor_name",
                        label: `${t(
                            "claimantForm4Data.sections.3.fields.0.label",
                        )}`,
                        type: "text",
                        placeholder: " ",
                        required: true,
                    },
                    {
                        id: 2,
                        name: "re_debtor_id",
                        label: `${t(
                            "claimantForm4Data.sections.3.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 8,
                        maxLength: 11,
                        placeholder: " ",
                        number: true,
                        required: true,
                    },
                    {
                        id: 3,
                        name: "re_debtor_address",
                        label: `${t(
                            "claimantForm4Data.sections.3.fields.2.label",
                        )}`,
                        type: "text",
                        placeholder: " ",
                        required: true,
                    },
                    {
                        id: 4,
                        name: "re_debtor_phone",
                        label: `${t(
                            "claimantForm4Data.sections.3.fields.3.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 5,
                        name: "re_debtor_post",
                        label: `${t(
                            "claimantForm4Data.sections.3.fields.4.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                ],
                note: "",
                add_more: false,
            },
        ],

        claimantHasRepresentative: {
            title: `${t("claimantForm4Data.claimantHasRepresentative.title")}`,
            values: [
                {
                    id: 1,
                    value: `${t(
                        "claimantForm4Data.claimantHasRepresentative.values.0.value",
                    )}`,
                    name: "re_claimant",
                },
                {
                    id: 2,
                    value: `${t(
                        "claimantForm4Data.claimantHasRepresentative.values.1.value",
                    )}`,
                    name: "re_claimant",
                },
            ],
            tip: `${t("claimantForm4Data.claimantHasRepresentative.tip")}`,
        },
        debtorHasRepresentative: {
            title: `${t("claimantForm4Data.debtorHasRepresentative.title")}`,
            values: [
                {
                    id: 1,
                    value: `${t(
                        "claimantForm4Data.debtorHasRepresentative.values.0.value",
                    )}`,
                    name: "re_debtor",
                },
                {
                    id: 2,
                    value: `${t(
                        "claimantForm4Data.debtorHasRepresentative.values.1.value",
                    )}`,
                    name: "re_debtor",
                },
            ],
            tip: `${t("claimantForm4Data.debtorHasRepresentative.tip")}`,
        },

        request: {
            title: `${t("claimantForm4Data.request.title")}`,
            fields: [
                {
                    id: 1,
                    label: `${t("claimantForm4Data.request.fields.0.label")}`,
                    name: "request_name",
                    type: "text",
                    placeholder: " ",
                },
                {
                    id: 2,
                    label: `${t("claimantForm4Data.request.fields.1.label")}`,
                    name: "request_date",
                    type: "date",
                    placeholder: " ",
                },
                {
                    id: 3,
                    label: `${t("claimantForm4Data.request.fields.2.label")}`,
                    name: "request_result",
                    type: "text",
                    placeholder: " ",
                },
                {
                    id: 4,
                    label: `${t("claimantForm4Data.request.fields.3.label")}`,
                    name: "request_number",
                    type: "text",
                    placeholder: " ",
                    onkey: false,
                },
                {
                    id: 5,
                    label: `${t("claimantForm4Data.request.fields.4.label")}`,
                    name: "request_content",
                    type: "text",
                    placeholder: " ",
                    tip: `${t("claimantForm4Data.request.fields.4.top")}`,
                },
                {
                    id: 6,
                    label: `${t("claimantForm4Data.request.fields.5.label")}`,
                    name: "request_sum",
                    type: "text",
                    placeholder: " ",
                    number: true,
                    units: "€",
                    tip: `${t("claimantForm4Data.request.fields.5.tip")}`,
                },
            ],
            tip: `${t("claimantForm4Data.request.tip")}`,
        },
        claim_clarification: [
            {
                id: 1,
                title: `${t("claimantForm4Data.claim_clarification.0.title")}`,
                values: [
                    {
                        id: 1,
                        value: `${t(
                            "claimantForm4Data.claim_clarification.0.values.0.value",
                        )}`,
                        name: "period",
                    },
                    {
                        id: 2,
                        value: `${t(
                            "claimantForm4Data.claim_clarification.0.values.1.value",
                        )}`,
                        name: "period",
                    },
                ],
                tip: `${t("claimantForm4Data.claim_clarification.0.tip")}`,
            },
            {
                id: 2,
                title: `${t("claimantForm4Data.claim_clarification.1.title")}`,
                values: [
                    {
                        id: 1,
                        value: `${t(
                            "claimantForm4Data.claim_clarification.1.values.0.value",
                        )}`,
                        name: "percent",
                    },
                    {
                        id: 2,
                        value: `${t(
                            "claimantForm4Data.claim_clarification.1.values.1.value",
                        )}`,
                        name: "percent",
                    },
                ],
                tip: `${t("claimantForm4Data.claim_clarification.1.tip")}`,
            },
            {
                id: 3,
                title: `${t("claimantForm4Data.claim_clarification.2.title")}`,
                values: [
                    {
                        id: 1,
                        value: `${t(
                            "claimantForm4Data.claim_clarification.2.values.0.value",
                        )}`,
                        name: "active",
                    },
                    {
                        id: 2,
                        value: `${t(
                            "claimantForm4Data.claim_clarification.2.values.1.value",
                        )}`,
                        name: "active",
                    },
                ],
                tip: `${t("claimantForm4Data.claim_clarification.2.tip")}`,
            },
        ],
        download: {
            title: `${t("claimantForm4Data.download.title")}`,
            divided_fields: [
                {
                    id: 1,
                    elem: `${t(
                        "claimantForm4Data.download.divided_fields.0.elem",
                    )}`,
                    multiple: true,
                    type: "file",
                    name: "download_1",
                    placeholder: `${t(
                        "claimantForm4Data.download.divided_fields.0.placeholder",
                    )}`,
                },
            ],
        },
        awared: {
            title: `${t("claimantForm4Data.awared.title")}`,
            points: [
                {
                    id: 1,
                    content: `${t(
                        "claimantForm4Data.awared.points.0.content",
                    )}`,
                },
                {
                    id: 2,
                    content: `${t(
                        "claimantForm4Data.awared.points.1.content",
                    )}`,
                },
                {
                    id: 3,
                    content: `${t(
                        "claimantForm4Data.awared.points.2.content",
                    )}`,
                },
            ],
        },
        agreement: {
            title: "",
            points: [
                {
                    id: 1,
                    value: `${t("claimantForm4Data.agreement.points.0.value")}`,
                    name: "agree",
                },
            ],
        },
    };
};
