import { TFunction } from "i18next";

export const propertySaleData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("propertySaleData.title")}`,
        fields: [
            {
                id: 1,
                label: `${t("propertySaleData.fields.0.label")}`,
                placeholder: "",
                name: "place_owner",
            },
            {
                id: 2,
                label: `${t("propertySaleData.fields.1.label")}`,
                placeholder: "",
                name: "place_address",
            },
            {
                id: 3,
                label: `${t("propertySaleData.fields.2.label")}`,
                placeholder: "",
                name: "notary",
            },
            {
                id: 4,
                label: `${t("propertySaleData.fields.3.label")}`,
                placeholder: "",
                name: "place_broker",
            },
            {
                id: 5,
                label: `${t("propertySaleData.fields.4.label")}`,
                placeholder: "",
                name: "place_post",
                type: "email",
            },
            {
                id: 6,
                label: `${t("propertySaleData.fields.5.label")}`,
                placeholder: "",
                name: "place_phone",
                type: "tel",
            },
            {
                id: 7,
                label: `${t("propertySaleData.fields.6.label")}`,
                placeholder: "",
                name: "place_price",
                number: true,
            },
            {
                id: 8,
                label: `${t("propertySaleData.fields.7.label")}`,
                placeholder: "",
                name: "place_date",
                type: "date",
            },
        ],
        // divided_fields: [
        //     {
        //         id: 1,
        //         elem: "Vara müügihind *",
        //         placeholder: "",
        //         name: "place_price",
        //         number: true,
        //     },
        //     {
        //         id: 2,
        //         elem: "Tehingu kuupäev",
        //         placeholder: "",
        //         name: "place_date",
        //         type: "date",
        //     },
        // ],
        nb: {
            title: `${t("propertySaleData.nb.title")}`,
            points: [
                {
                    id: 1,
                    content: `${t("propertySaleData.nb.points.0.content")}`,
                    name: "nb2_1",
                    path: "",
                },
            ],
        },
    };
};
