import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ClickContacts } from "@components/components";
import { bailiffsListData } from "@config/home";
import { useModal } from "@hooks";
import { AppRoute } from "@enums";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const UserCard = () => {
    const { isShown, toggle, setIsShown } = useModal();
    const [bailiff, setBailiff] = useState(
        Number(localStorage.getItem("bailiff")),
    );
    // const bailiff = Number(localStorage.getItem("bailiff"));
    const { t } = useTranslation();
    const handleClick = (key: number) => {
        localStorage.setItem("bailiff", String(key));
        setBailiff(key);
        setIsShown(!isShown);
    };
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        localStorage.getItem("bailiff");
    }, [bailiff]);
    const handleModalClick = (key: number) => {
        localStorage.setItem("bailiff_id", String(key));
    };
    const handelClickOut = (): void => {
        localStorage.removeItem("bailiff_id");
        localStorage.removeItem("bailiff");
        setBailiff(0);
        // window.location.reload();
    };
    const content = (
        <>
            {bailiffsListData(t).map((item) => (
                <div
                    className="flex items-center justify-between my-2 border rounded-md shadow-sm border-1 border-neutral/10"
                    key={item.id}
                >
                    <div className="flex p-4">
                        <img
                            src={require(`@assets/images/${item.image}`)}
                            alt="UserPhoto"
                            className="w-10 h-10 mr-4 rounded-full"
                        />
                        <div className="flex-flex-col">
                            <p className="font-semibold ">{item.name}</p>
                            <p className="text-[#13254A] ">
                                {item.contacts[3].content}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center h-20 px-4 border-l md:px-8 border-neutral/10 border-1">
                        <button
                            id={String(item.id)}
                            onClick={() => handleClick(item.id)}
                            className="font-semibold cursor-pointer text-secondary "
                        >
                            {t("other.bailiffModalList.btn")}
                        </button>
                    </div>
                </div>
            ))}
        </>
    );
    return (
        <div className="flex justify-between pr-6 md:pr-0">
            {bailiff ? (
                <>
                    <div className="w-full mb-6 base-border">
                        <div className="flex flex-col items-start justify-between md:items-center md:flex-row">
                            <div className="flex" ref={ref}>
                                <img
                                    src={require(`@assets/images/${
                                        bailiffsListData(t)[Number(bailiff - 1)]
                                            .image
                                    }`)}
                                    alt="UserPhoto"
                                    className="mr-4 rounded-full w-14 h-14"
                                />
                                <div className="flex-flex-col">
                                    <p className="text-lg font-semibold">
                                        {
                                            bailiffsListData(t)[
                                                Number(bailiff - 1)
                                            ].name
                                        }
                                    </p>
                                    <p className="text-sm text-lightGray">
                                        {
                                            bailiffsListData(t)[
                                                Number(bailiff - 1)
                                            ].contacts[3].content
                                        }
                                    </p>
                                </div>
                            </div>
                            <div>
                                <Button
                                    onClick={handelClickOut}
                                    className="h-12 py-2 px-4 mr-2 font-semibold uppercase rounded-md md:text-[14px] text-[16px] w-fit bg-secondary hover:bg-neutral text-white border-2 border-secondary hover:border-neutral bg-transparent hover:bg-secondary  hover:!border-secondary border-2 border-secondary !text-secondary hover:!text-white "
                                >
                                    {t("other.userData.deselect")}
                                </Button>
                                <Button
                                    onClick={() => handleModalClick(bailiff)}
                                    className="h-12 my-4 md:my-0"
                                >
                                    <Link to={AppRoute.WORK_AREA}>
                                        {t("other.additional.link")}
                                    </Link>
                                </Button>
                            </div>
                        </div>

                        <div className="w-full my-4 border-t border-lightGray/20"></div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                            {bailiffsListData(t)[
                                Number(bailiff - 1)
                            ].contacts.map(
                                (item) =>
                                    item.item !== "Region" && (
                                        <div
                                            key={item.id}
                                            className="flex flex-row items-start justify-center flex-1 pt-4 pb-2 pl-4 rounded-md md:flex-col xl:flex-row bg-beige md:items-start"
                                        >
                                            <div className="flex  w-12 items-center mr-3  mt-1 md:mt-0 justify-center bg-[#FEE5D5] rounded-full">
                                                <img
                                                    src={require(`@assets/icons/forms/${item.icon}`)}
                                                    alt={item.item}
                                                    className="w-full m-[8px]"
                                                />
                                            </div>

                                            <ClickContacts
                                                item={item.item}
                                                content={item.content}
                                            />
                                        </div>
                                    ),
                            )}
                        </div>
                    </div>
                    <Modal
                        isShown={isShown}
                        hide={toggle}
                        modalContent={content}
                        headerText={t("other.userData.headerText")}
                    />
                </>
            ) : (
                <div className="w-full mb-6 base-border">
                    <div className="flex flex-col items-center justify-between md:flex-row">
                        <div className="flex items-center w-full mb-4 md:mb-0">
                            <div className="flex items-center justify-center p-2 mr-4 rounded-full w-14 h-14 bg-slate">
                                <img
                                    src={require("@assets/images/avatar.png")}
                                    alt="UserPhoto"
                                    className=""
                                />
                            </div>
                            <p className="text-lg font-semibold">
                                {t("other.userData.join")}
                            </p>
                        </div>

                        <Button
                            onClick={toggle}
                            className="self-start h-10 my-4 md:my-0 whitespace-nowrap md:self-center"
                        >
                            {t("other.userData.btn")}
                        </Button>
                    </div>

                    <Modal
                        isShown={isShown}
                        hide={toggle}
                        modalContent={content}
                        headerText={t("other.userData.headerText")}
                    />
                </div>
            )}
        </div>
    );
};
