import React from "react";
import { RadioGroup } from "@components/common";
import { SectionContainer } from ".";

interface IValues {
    id: number;
    value: string;
    name: string;
}
interface IData {
    id?: number;
    title: string;
    values: IValues[];
    tip?: string;
}
interface IHasData {
    data: IData;
    radioChecked?: any;
    onRadioChange?: (arg0: any) => void;
}

export const HasData: React.FC<IHasData> = ({
    data,
    radioChecked,
    onRadioChange,
}) => {
    const { title, values, tip } = data;
    return (
        <SectionContainer title={title} tip={tip}>
            <div className="mb-4 border-t border-neutral/10 "></div>
            <div className="flex items-center ">
                <RadioGroup
                    data={values}
                    radioChecked={radioChecked}
                    onRadioChange={onRadioChange}
                />
            </div>
        </SectionContainer>
    );
};
