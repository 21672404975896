import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/* eslint-disable */
export const useQueryParams = (): Record<string, unknown> => {
  const { search } = useLocation();
  return useMemo(() => {
    if (!search) return {};
    const p = search
      .replace("?", "")
      .split("&")
      .map((param) => {
        const [key, value] = param.split("=");
        if (!value) return [key, true];
        return [key, value];
      });
    return Object.fromEntries(p);
  }, [search]);
};
