import clsx from "clsx";
import React from "react";

interface IRadio {
    className?: string;
    title: string;
    rad1: string;
    rad2: string;
    rad3?: string;
    rad4?: string;
    rad5?: string;
    rad6?: string;
    rad7?: string;
    rad8?: string;
    rad9?: string;
    rad10?: string;

    val1: boolean;
    val2: boolean;
    val3?: boolean;
    val4?: boolean;
    val5?: boolean;
    val6?: boolean;
    val7?: boolean;
    val8?: boolean;
    val9?: boolean;
    val10?: boolean;
    col?: boolean;
    bank?: boolean;
    noUpper?: boolean;
}

export const Radio: React.FC<IRadio> = ({
    title,
    rad1,
    rad2,
    rad3,
    rad4,
    rad5,
    rad6,
    rad7,
    rad8,
    rad9,
    rad10,
    val1,
    val2,
    val3,
    val4,
    val5,
    val6,
    val7,
    val8,
    val9,
    val10,
    col,
    bank,
    noUpper,
    className,
}) => {
    const values = [
        val1,
        val2,
        val3,
        val4,
        val5,
        val6,
        val7,
        val8,
        val9,
        val10,
    ];
    const radio = [rad1, rad2, rad3, rad4, rad5, rad6, rad7, rad8, rad9, rad10];
    return (
        <div className={className}>
            <div
                className={clsx(
                    "relative flex",
                    col ? "flex-col items-start mt-2" : "flex-row items-center",
                )}
            >
                <p
                    className={clsx(
                        "mr-4 font-[500] text-[16px] fontWeight",
                        noUpper ? "" : "uppercase",
                    )}
                >
                    {title}
                </p>
                <div
                    className={clsx(
                        "flex",
                        bank ? "flex-col items-start" : "items-center",
                    )}
                >
                    {values.map((item, index) => (
                        <>
                            {item && (
                                <div
                                    className={clsx(
                                        "flex items-center mr-2",
                                        col ? "my-1" : "my-0",
                                    )}
                                    key={index}
                                >
                                    <p
                                        className={clsx(
                                            "text-middleGray",
                                            item
                                                ? "underline underline-offset-2 decoration-2"
                                                : "no-underline",
                                        )}
                                    >
                                        {radio[index]}
                                    </p>
                                </div>
                            )}
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};
