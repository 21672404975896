import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useDeviceWidhtDetect, useQuery } from "@hooks";
import { AuctionCard, BaseSlider } from "@components/components";
import { HiArrowNarrowRight } from "react-icons/hi";
import { AppRoute } from "@enums";

export const AuctionObjects = () => {
    const responseData = useQuery("/items");
    const isMobile = useDeviceWidhtDetect(425);
    const isTablet = useDeviceWidhtDetect(1280);
    const [current, setCurrent] = useState(0);
    const totalItems = responseData.data?.length;
    const { t } = useTranslation();

    let totalPages;
    isTablet ? (totalPages = totalItems) : (totalPages = totalItems / 2);

    return (
        <div className="flex flex-col px-8 pt-8 pb-20 md:py-16 md:px-[15px] lg:flex-row">
            <div className="flex flex-col w-full pt-0 md:pt-24 md:w-1/4">
                <h3 className="pb-3 font-semibold md:text-[46px] xl:w-[600px]">
                    {t("auctionObjects.title")}
                </h3>
                <p className="mb-6 md:mb-[62px] text-[18px] leading-8 xl:w-[650px]">
                    {t("auctionObjects.desc")}{" "}
                    <a
                        href="http://www.oksjonikeskus.ee"
                        className="ml-2 text-[18px] leading-8 text-secondary"
                    >
                        www.oksjonikeskus.ee
                    </a>
                </p>

                <Link
                    to={AppRoute.AUCTION}
                    className="flex items-center self-end justify-between w-32 hover:w-36 md:self-start hover:text-secondary"
                >
                    <p className="uppercase">{t("auctionObjects.link")}</p>
                    <HiArrowNarrowRight className="text-2xl text-secondary" />
                </Link>
            </div>
            <div
                className={clsx(
                    "flex w-full ml-0 xl:ml-20 lg:ml-12 md:w-3/4 md:flex-row",
                    isMobile ? " justify-end" : "flex-row",
                )}
            >
                <div
                    className={clsx(
                        "flex overflow-hidden flex-col lg:flex-row",
                    )}
                >
                    <BaseSlider
                        totalPages={totalPages}
                        totalItems={totalItems}
                        current={current}
                        setCurrent={setCurrent}
                        pagination
                    >
                        {responseData.data?.map(
                            (item: {
                                url: string;
                                image: string;
                                dates: string;
                                description: string;
                                cost: string;
                            }) => (
                                <div
                                    key={item.url}
                                    className={clsx(
                                        "flex flex-col items-center ",
                                        isMobile &&
                                            "min-w-full overflow-hidden ",
                                    )}
                                >
                                    <AuctionCard
                                        key={item.url}
                                        img={item.image}
                                        date={item?.dates || ""}
                                        desc={item?.description || ""}
                                        cost={item.cost}
                                        url={item.url}
                                    />
                                </div>
                            ),
                        )}
                    </BaseSlider>
                </div>
            </div>
        </div>
    );
};
