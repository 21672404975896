import { FormIcon1, FormIcon2 } from "@assets";
import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const formsSidebarData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            icon: FormIcon1,
            title: `${t("formsSidebarData.0.title")}`,
            // desc: "Sissenõudja võib taotleda võlgniku suhtes sundtäitemenetluse alustamist...",
            path: AppRoute.FORMS_DEBTOR,
            children: [
                {
                    id: 1,
                    title: `${t("formsSidebarData.0.children.0.title")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "schedule",
                },
                {
                    id: 2,
                    title: `${t("formsSidebarData.0.children.1.title")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "minimum",
                },
                {
                    id: 3,
                    title: `${t("formsSidebarData.0.children.2.title")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "refund",
                },
                {
                    id: 4,
                    title: `${t("formsSidebarData.0.children.3.title")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "notice",
                },
                {
                    id: 5,
                    title: `${t("formsSidebarData.0.children.4.title")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "limitation",
                },
                {
                    id: 6,
                    title: `${t("formsSidebarData.0.children.5.title")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "sale",
                },
                {
                    id: 7,
                    title: `${t("formsSidebarData.0.children.6.title")}`,
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    name: "other",
                },
            ],
        },
        {
            id: 2,
            icon: FormIcon2,
            title: `${t("formsSidebarData.1.title")}`,
            // desc: "Võlgnikul on võimalus esitada kohtutäiturile maksegraafiku avaldus ...",
            path: AppRoute.FORMS_CLAIMANT,
            children: [
                {
                    id: 1,
                    title: `${t("formsSidebarData.1.children.0.title")}`,
                    path: AppRoute.FORMS_CLAIMANT_FORM_4,
                    name: "",
                },

                {
                    id: 2,
                    title: `${t("formsSidebarData.1.children.1.title")}`,
                    path: AppRoute.FORMS_CLAIMANT_FORM_2,
                    name: "",
                },
                {
                    id: 3,
                    title: `${t("formsSidebarData.1.children.2.title")}`,
                    path: AppRoute.FORMS_CLAIMANT_FORM_1,
                    name: "",
                },
                {
                    id: 4,
                    title: `${t("formsSidebarData.1.children.3.title")}`,
                    path: AppRoute.FORMS_CLAIMANT_FORM_5,
                    name: "",
                },
                {
                    id: 5,
                    title: `${t("formsSidebarData.1.children.4.title")}`,
                    path: AppRoute.FORMS_CLAIMANT_FORM_3,
                    name: "",
                },
            ],
        },
    ];
};
