import axios from "axios";
import { API_URL } from "@helpers";

export const DataService = () => {
    const getAll = (url: string) => {
        return axios.get(`${API_URL}${url}`);
    };
    const getById = (url: string, id: number) => {
        return axios.get(`${API_URL}${url}/${id}`);
    };
    return { getAll, getById };
};
