import React from "react";
import { assetsListData } from "@config/debtor";
import { DebtorMain } from "@components/debtor";
import { useTranslation } from "react-i18next";

export const AssetsList = () => {
    const { t } = useTranslation();
    const { title, desc_1, desc_2, desc_3 } = assetsListData(t);
    return (
        <DebtorMain
            title={title}
            desc_1={desc_1}
            desc_2={desc_2}
            desc_3={desc_3}
        />
    );
};
