import React from "react";
import { useTranslation } from "react-i18next";

export const Maintenance = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col mt-0 ml-6 mr-6 mt-4 md:mt-6 md:ml-0">
            <div className="flex flex-col ">
                <p className="my-4 md:mt-0 md:mb-6 text-xl font-semibold">
                    {t("maintenance.title")}
                </p>
                <p className="text-lg text-justify whitespace-pre-line font-semibold mb-4 md:mb-6">
                    {t("maintenance.pretitle")}
                </p>
                <p className="text-lg text-justify whitespace-pre-line">
                    {t("maintenance.desc")}
                </p>
            </div>
        </div>
    );
};
