import React, { useState } from "react";

export const useFullForm = (
    callback: any,
    initialState = {},
    checkedState = {},
    radioState = {},
) => {
    const [values, setValues] = useState(initialState);
    const [checked, setChecked] = useState(checkedState);
    const [radioChecked, setRadioChecked] = useState(radioState);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked({ ...checked, [event.target.name]: event.target.checked });
    };

    const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioChecked({
            ...radioChecked,
            [event.target.name]: event.target.value,
        });
    };
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await callback();
    };
    return {
        onChange,
        onSubmit,
        values,
        checked,
        onCheckboxChange,
        onRadioChange,
        radioChecked,
    };
};
