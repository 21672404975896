import React from "react";

export const SideIcon3 = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.2348 9.57784C19.437 9.58019 19.6316 9.50154 19.7754 9.35943C19.9191 9.21733 20 9.0236 20 8.82146V6.2533C20 2.39226 17.5901 0 13.7555 0H6.2533C2.39226 0 0 2.39226 0 6.2533V13.7555C0 17.6077 2.39226 20 6.2533 20H13.7555C17.6077 20 20 17.6077 20 13.7555C20 13.3329 19.6574 12.9903 19.2348 12.9903C18.8122 12.9903 18.4697 13.3329 18.4697 13.7555C18.4697 16.7898 16.7986 18.4697 13.7555 18.4697H6.2533C3.2102 18.4697 1.53034 16.7898 1.53034 13.7555V6.2533C1.53034 3.2102 3.2102 1.53034 6.2533 1.53034H13.7555C16.7986 1.53034 18.4697 3.20141 18.4697 6.2533V8.79507C18.4697 9.21767 18.8122 9.56025 19.2348 9.56025V9.57784ZM9.27879 12.4715V13.351C9.26879 13.7719 9.59702 14.1235 10.0176 14.1426C10.2205 14.1497 10.418 14.0758 10.5665 13.9372C10.715 13.7987 10.8023 13.6068 10.8091 13.4038V12.4363C10.8187 12.2327 10.7456 12.0339 10.6065 11.8849C10.4675 11.7358 10.2741 11.6493 10.0703 11.6448C9.86737 11.6376 9.6699 11.7115 9.52143 11.8501C9.37296 11.9887 9.28567 12.1806 9.27879 12.3835V12.4715ZM13.9737 9.23635C14.1177 9.38036 14.1976 9.57637 14.1952 9.78003H14.16C14.16 9.9845 14.0782 10.1805 13.9328 10.3242C13.7874 10.468 13.5905 10.5476 13.3861 10.5452H6.62264C6.20547 10.5357 5.8709 10.1973 5.86626 9.78003C5.86391 9.57791 5.94256 9.38325 6.08467 9.2395C6.22677 9.09574 6.4205 9.01485 6.62264 9.01486H9.26116V6.6138C9.27062 6.19178 9.61302 5.85327 10.0351 5.84863C10.2388 5.84858 10.4339 5.93068 10.5763 6.07635C10.7186 6.22203 10.7962 6.41897 10.7915 6.6226V9.01486H13.43C13.6337 9.01248 13.8297 9.09233 13.9737 9.23635Z"
                fill="white"
            />
        </svg>
    );
};
