import React from "react";
import { Input } from "@components/components";

interface IData {
    id: number;
    name: string;
    label: string;
    required?: boolean;
    placeholder?: string;
    minLength?: number;
    maxLength?: number;
    type?: string;
    number?: boolean;
}
interface IAddMore {
    data: IData[];
    onChange?: (arg0: any) => void;
}
export const AddMore: React.FC<IAddMore> = ({ data, onChange }) => {
    return (
        <div className="mb-2 base-border">
            {data.map((item) => (
                <Input
                    type={item.type}
                    name={item.name}
                    key={item.id}
                    label={item.label}
                    onChange={onChange}
                    minLength={item.minLength}
                    maxLength={item.maxLength}
                    form
                    required={item.required}
                    placeholder={item.placeholder}
                    number={item.number}
                />
            ))}
        </div>
    );
};