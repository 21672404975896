import React from "react";
import { useQuery } from "@hooks";
import { getFullDate } from "@helpers";
import { useTranslation } from "react-i18next";

export const AuctionObjectCard = () => {
    const { t } = useTranslation();
    const auctionData = useQuery("/items");
    const filterValue = String(localStorage.getItem("filterValue") || "");
    const dateFromSearch = String(localStorage.getItem("dateFromSearch") || "");
    const dateToSearch = String(localStorage.getItem("dateToSearch") || "");

    const data = auctionData.data?.filter((item: { description: string }) =>
        item.description.toLowerCase().includes(filterValue),
    );
    const dateFromData = data?.filter(
        (item: { dates: string }) =>
            data &&
            getFullDate(item.dates.slice(38, 48)) > new Date(dateFromSearch),
    );

    const dateToData = dateFromData?.filter(
        (item: { dates: string }) =>
            data &&
            getFullDate(item.dates.slice(57, 68)) < new Date(dateToSearch),
    );

    return (
        <>
            {(filterValue !== "null" && filterValue) ||
            (dateFromSearch !== "null" && dateFromSearch) ||
            (dateToSearch !== "null" && dateToSearch) ? (
                <>
                    {dateToData && dateFromData ? (
                        dateToData?.map(
                            (item: {
                                url: string;
                                image: string;
                                cost: string;
                                description: string;
                                dates: string;
                            }) => (
                                <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={item.url}
                                >
                                    <div className="relative w-full mb-6 cursor-pointer xl:mb-12 lg:mb-6 min-h-max">
                                        <div
                                            // style={{
                                            //     backgroundImage: `url(${item.image})`,
                                            // }}
                                            className="absolute  !w-64 rounded-md !h-72 lg:bottom-12 md:bottom-8 mt-4 md:mt-0  left-8"
                                        >
                                            <img
                                                id="auction-image"
                                                src={item.image}
                                                alt={item.description}
                                                className="object-cover !w-full !h-full   "
                                            />
                                        </div>

                                        <div className="flex flex-col w-full xl:py-16 py-6 my-6  bg-white border-slate  border rounded-md md:pl-[350px] px-8 lg:pt-40 pt-80 md:pt-8 hover:bg-beige">
                                            <p className="text-xl font-semibold ">
                                                {t("auctionObjectCard.price")}:{" "}
                                                {item.cost}
                                            </p>

                                            <p className="pb-8 pr-0 leading-6 text-justify lg:pr-12 lg:w-3/4 md:w-full text-neutral/90">
                                                {item.description}
                                            </p>

                                            <div className="flex flex-col w-full lg:flex-row">
                                                <div className="flex flex-col w-full mb-4 mr-0 lg:mr-12 lg:mb-0">
                                                    <div className="flex flex-wrap w-full lg:flex-nowrap">
                                                        <p className="mb-2 mr-2 text-sm font-semibold uppercase lg:mb-4 whitespace-nowrap text-secondary">
                                                            {t(
                                                                "auctionObjectCard.registration",
                                                            )}
                                                            {t(
                                                                "auctionObjectCard.beginning",
                                                            )}{" "}
                                                            •{" "}
                                                            {t(
                                                                "auctionObjectCard.end",
                                                            )}
                                                        </p>
                                                        <div className="w-full mt-0 border-t lg:mt-2 border-neutral/10"></div>
                                                    </div>

                                                    <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                        <p className="mr-2">
                                                            {t(
                                                                "auctionObjectCard.beginning",
                                                            )}
                                                        </p>
                                                        <p className="">
                                                            {item.dates?.slice(
                                                                0,
                                                                10,
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                        <p className="mr-2">
                                                            {t(
                                                                "auctionObjectCard.end",
                                                            )}
                                                        </p>
                                                        <p className="">
                                                            {item.dates?.slice(
                                                                19,
                                                                29,
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full mb-4 mr-0 lg:mr-12 lg:mb-0">
                                                    <div className="flex flex-wrap w-full lg:flex-nowrap">
                                                        <p className="mb-2 mr-2 text-sm font-semibold uppercase lg:mb-4 whitespace-nowrap text-secondary">
                                                            {t(
                                                                "auctionObjectCard.start_auction",
                                                            )}
                                                            •{" "}
                                                            {t(
                                                                "auctionObjectCard.end",
                                                            )}
                                                        </p>
                                                        <div className="w-full mt-0 border-t lg:mt-2 border-neutral/10"></div>
                                                    </div>

                                                    <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                        <p className="mr-2">
                                                            {t(
                                                                "auctionObjectCard.beginning",
                                                            )}
                                                        </p>
                                                        <p className="">
                                                            {item.dates.slice(
                                                                38,
                                                                48,
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                        <p className="mr-2">
                                                            {" "}
                                                            {t(
                                                                "auctionObjectCard.end",
                                                            )}
                                                        </p>
                                                        <p className="">
                                                            {item.dates.slice(
                                                                57,
                                                                68,
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            ),
                        )
                    ) : (
                        <p className="text-lg font-semibold text-center text-neutral">
                            {t("auctionObjectCard.error")}
                        </p>
                    )}
                    {dateToSearch === "null" ||
                    !dateToData ||
                    dateFromSearch === "null" ||
                    !dateFromData ||
                    data?.length !== 0 ? (
                        data?.length !== 0 ? (
                            data?.map(
                                (item: {
                                    url: string;
                                    image: string;
                                    cost: string;
                                    description: string;
                                    dates: string;
                                }) => (
                                    <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        key={item.url}
                                    >
                                        <div className="relative w-full mb-6 cursor-pointer xl:mb-12 lg:mb-6 min-h-max">
                                            <div
                                                // style={{
                                                //     backgroundImage: `url(${item.image})`,
                                                // }}
                                                className="absolute  !w-64 rounded-md !h-72 lg:bottom-12 md:bottom-8 mt-4 md:mt-0  left-8"
                                            >
                                                <img
                                                    id="auction-image"
                                                    src={item.image}
                                                    alt={item.description}
                                                    className="object-cover !w-full !h-full   "
                                                />
                                            </div>

                                            <div className="flex flex-col w-full xl:py-16 py-6 my-6  bg-white border-slate  border rounded-md md:pl-[350px] px-8 lg:pt-40 pt-80 md:pt-8 hover:bg-beige">
                                                <p className="text-xl font-semibold ">
                                                    {t(
                                                        "auctionObjectCard.price",
                                                    )}
                                                    :{item.cost}
                                                </p>

                                                <p className="pb-8 pr-0 leading-6 text-justify lg:pr-12 lg:w-3/4 md:w-full text-neutral/90">
                                                    {item.description}
                                                </p>

                                                <div className="flex flex-col w-full lg:flex-row">
                                                    <div className="flex flex-col w-full mb-4 mr-0 lg:mr-12 lg:mb-0">
                                                        <div className="flex flex-wrap w-full lg:flex-nowrap">
                                                            <p className="mb-2 mr-2 text-sm font-semibold uppercase lg:mb-4 whitespace-nowrap text-secondary">
                                                                {t(
                                                                    "auctionObjectCard.registration",
                                                                )}
                                                                {t(
                                                                    "auctionObjectCard.beginning",
                                                                )}{" "}
                                                                •{" "}
                                                                {t(
                                                                    "auctionObjectCard.end",
                                                                )}
                                                            </p>
                                                            <div className="w-full mt-0 border-t lg:mt-2 border-neutral/10"></div>
                                                        </div>

                                                        <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                            <p className="mr-2">
                                                                {t(
                                                                    "auctionObjectCard.beginning",
                                                                )}
                                                            </p>
                                                            <p className="">
                                                                {item.dates?.slice(
                                                                    0,
                                                                    10,
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                            <p className="mr-2">
                                                                {t(
                                                                    "auctionObjectCard.end",
                                                                )}
                                                            </p>
                                                            <p className="">
                                                                {item.dates?.slice(
                                                                    19,
                                                                    29,
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col w-full mb-4 mr-0 lg:mr-12 lg:mb-0">
                                                        <div className="flex flex-wrap w-full lg:flex-nowrap">
                                                            <p className="mb-2 mr-2 text-sm font-semibold uppercase lg:mb-4 whitespace-nowrap text-secondary">
                                                                {t(
                                                                    "auctionObjectCard.auction",
                                                                )}
                                                                {t(
                                                                    "auctionObjectCard.beginning",
                                                                )}{" "}
                                                                •{" "}
                                                                {t(
                                                                    "auctionObjectCard.end",
                                                                )}
                                                            </p>
                                                            <div className="w-full mt-0 border-t lg:mt-2 border-neutral/10"></div>
                                                        </div>

                                                        <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                            <p className="mr-2">
                                                                {t(
                                                                    "auctionObjectCard.beginning",
                                                                )}
                                                            </p>
                                                            <p className="">
                                                                {item.dates.slice(
                                                                    38,
                                                                    48,
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                            <p className="mr-2">
                                                                {" "}
                                                                {t(
                                                                    "auctionObjectCard.end",
                                                                )}
                                                            </p>
                                                            <p className="">
                                                                {item.dates.slice(
                                                                    57,
                                                                    68,
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ),
                            )
                        ) : (
                            <p className="text-lg font-semibold text-center text-neutral">
                                {t("auctionObjectCard.error")}
                            </p>
                        )
                    ) : (
                        <p className="text-lg font-semibold text-center text-neutral">
                            {t("auctionObjectCard.error")}
                        </p>
                    )}
                </>
            ) : (
                <>
                    {auctionData.data?.map(
                        (item: {
                            url: string;
                            image: string;
                            cost: string;
                            description: string;
                            dates: string;
                        }) => (
                            <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={item.url}
                                className="flex items-center flex-col md:flex-row justify-between flex-1 w-full px-8 py-4 mt-8 bg-white border rounded-md cursor-pointer border-slate hover:bg-beige min-h-max"
                            >
                                <div className=" rounded-md md:mr-8 w-full md:!w-[263px] !h-[259px]  shrink-0">
                                    <img
                                        id="auction-image"
                                        src={item.image}
                                        alt={item.description}
                                        className="object-cover w-full h-full   "
                                    />
                                </div>

                                <div className="flex flex-col w-full my-6">
                                    <p className="text-xl font-semibold ">
                                        {t("auctionObjectCard.price")}:{" "}
                                        {item.cost}
                                    </p>

                                    <p className="pb-8 text-lg pr-0 leading-6 text-justify lg:pr-12 lg:w-3/4 md:w-full text-neutral/90">
                                        {item.description}
                                    </p>

                                    <div className="flex flex-col w-full lg:flex-row">
                                        <div className="flex flex-col w-full mb-4 mr-0 lg:mr-12 lg:mb-0">
                                            <div className="flex flex-wrap w-full lg:flex-nowrap">
                                                <p className="mb-2 mr-2 text-lg font-semibold uppercase lg:mb-4 whitespace-nowrap text-secondary">
                                                    {t(
                                                        "auctionObjectCard.registration",
                                                    )}
                                                    {t(
                                                        "auctionObjectCard.beginning",
                                                    )}{" "}
                                                    •{" "}
                                                    {t("auctionObjectCard.end")}
                                                </p>
                                                <div className="w-full mt-0 border-t lg:mt-[10px] border-neutral/10"></div>
                                            </div>

                                            <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                <p className="mr-2">
                                                    {t(
                                                        "auctionObjectCard.beginning",
                                                    )}
                                                </p>
                                                <p className="text-lg">
                                                    {item.dates?.slice(0, 10)}
                                                </p>
                                            </div>
                                            <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                <p className="mr-2">
                                                    {t("auctionObjectCard.end")}
                                                </p>
                                                <p className="text-lg">
                                                    {item.dates?.slice(19, 29)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full mb-4 mr-0 lg:mr-12 lg:mb-0">
                                            <div className="flex flex-wrap w-full lg:flex-nowrap">
                                                <p className="mb-2 mr-2 text-lg font-semibold uppercase lg:mb-4 whitespace-nowrap text-secondary">
                                                    {t(
                                                        "auctionObjectCard.auction",
                                                    )}{" "}
                                                    {t(
                                                        "auctionObjectCard.beginning",
                                                    )}{" "}
                                                    •{" "}
                                                    {t("auctionObjectCard.end")}
                                                </p>
                                                <div className="w-full mt-0 border-t lg:mt-[10px] border-neutral/10"></div>
                                            </div>

                                            <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                <p className="mr-2">
                                                    {t(
                                                        "auctionObjectCard.beginning",
                                                    )}
                                                </p>
                                                <p className="text-lg">
                                                    {item.dates.slice(38, 48)}
                                                </p>
                                            </div>
                                            <div className="flex items-center xl:mb-4 lg:mb-2 text-neutral/90">
                                                <p className="mr-2">
                                                    {t("auctionObjectCard.end")}
                                                </p>
                                                <p className="text-lg">
                                                    {item.dates.slice(57, 68)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ),
                    )}
                </>
            )}
        </>
    );
};
