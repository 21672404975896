import React, {
    createContext,
    useContext,
    useMemo,
    useReducer,
    useState,
} from "react";
import { defaultState, reducer } from "./FileProvider.reducer";
import { ActionTypes, IState } from "./FileProvider.types";
import { IError } from "@types";
import { DokobitApi } from "@api/Dokobit";

interface IFileProvider extends IState {
    error: IError | null;
    loading: boolean;
    upload: (file: File, name: string, surname: string) => Promise<void>;
}

const FileContext = createContext<IFileProvider>({} as IFileProvider);

export const FileProvider: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const [error, setError] = useState<IError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const upload = async (
        file: File,
        name: string,
        surname: string,
    ): Promise<void> => {
        setLoading(() => true);
        try {
            const { filename, fileurl, digest } = await DokobitApi.upload(file);
            const res = await DokobitApi.sign(
                filename,
                `https://ktapi.hathor.ee/${fileurl
                    .split("/")
                    .slice(3)
                    .join("/")}`,
                digest,
                "unF4X26Toj6MU1Ay",
                name,
                surname,
            );

            if (res.status === "error") {
                setError(() => res as unknown as IError);
                return;
            }

            const signingToken = res.token;
            const signerToken = res.signers["unF4X26Toj6MU1Ay"];
            dispatch({
                type: ActionTypes.SET_SIGN_TOKENS,
                payload: {
                    signingToken,
                    signerToken,
                },
            });
        } catch (e) {
            setError(() => e as IError);
        } finally {
            setLoading(() => false);
        }
    };

    const values = useMemo(
        () => ({
            ...state,
            error,
            loading,
            upload,
        }),
        [state, error, loading],
    );
    return (
        <FileContext.Provider value={values}>{children}</FileContext.Provider>
    );
};

export const useFile = (): IFileProvider => {
    return useContext<IFileProvider>(FileContext);
};
