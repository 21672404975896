import React from "react";
import { applyingQuestionsData } from "@config/claimant";
import { AccordionLayout } from "@components/common";
import { useTranslation } from "react-i18next";
export const Applying = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col justify-center mt-0 ml-6 mr-6 mt-4 md:mt-6 md:ml-0 ">
            <ul className="w-full overflow-hidden">
                {applyingQuestionsData(t).map((item) => {
                    return (
                        <li
                            className="w-full px-4 py-8 my-4 border rounded-md border-neutral/10"
                            key={item.id}
                        >
                            <AccordionLayout
                                desc={item.desc}
                                endOfDesc_1={item.endOfDesc_1}
                                desc_2={item.desc_2}
                                list_2={item.list_2}
                                desc_3={item.desc_3}
                                name_3={item.name_3}
                                link_name_3={item.link_name_3}
                                link_3={item.link_3}
                                path_3={item.path_3}
                                path_name_3={item.path_name_3}
                                endOfDesc_3={item.endOfDesc_3}
                                desc_4={item.desc_4}
                                path_4={item.path_4}
                                path_name_4={item.path_name_4}
                                endOfDesc_4={item.endOfDesc_4}
                                title={item.title}
                                desc_bold_3={item.desc_bold_3}
                                path_url_3={item.path_url_3}
                                path_url_4={item.path_url_4}
                                desc_bold={item.desc_bold}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
