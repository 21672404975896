import React from "react";
import { SectionContainer } from ".";
import { Checkbox } from "@components/common";

interface IField {
    name: string;
    label?: string;
    type?: string;
    id?: number;
    placeholder?: string;
    number?: boolean;
    units?: string;
}
interface IPoints {
    id: number;
    value: string;
    name: string;
}
interface IData {
    title: string;
    points: IPoints[];
    field?: IField;
}
interface IChecked {
    data: IData;
    onChange?: (arg0: any) => void;
    onCheckboxChange?: (arg0: any) => void;
    checked: boolean[];
    dark?: boolean;
}

export const CheckedContainer: React.FC<IChecked> = ({
    data,
    onCheckboxChange,
    checked,
    dark,
    onChange,
}) => {
    const { title, points, field } = data;
    return (
        <SectionContainer title={title} field={field} dark={dark} onChange={onChange}>
            <ul className="text-justify list-none ">
                {points.map(({id, value, name}, index) => (
                    <li className="" key={id}>
                        <Checkbox
                            onCheckboxChange={onCheckboxChange}
                            label={value}
                            name={name}
                            value={value}
                            id={id}
                            checked={checked[index]}
                        />
                    </li>
                ))}
            </ul>
        </SectionContainer>
    );
};
