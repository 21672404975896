import React from "react";
import { useTranslation } from "react-i18next";

interface IDownloadList {
    className?: string;
    keyEl: string;
}

interface IFile {
    name: string;
    size: number;
    type: string;
    url: string;
}

export const DownloadList: React.FC<IDownloadList> = ({ keyEl, className }) => {
    const { t } = useTranslation();
    const download = JSON.parse(localStorage.getItem(keyEl) || "[]") as IFile[];
    return (
        <div className={className}>
            <p className="mb-2 font-[500] text-[16px] fontWeight uppercase">
                {t("other.download.title")}:
            </p>
            {download.length !== 0
                ? download.map((item) => (
                      <li
                          key={item.name}
                          className="w-[486px] flex items-center mt-2"
                      >
                          {/*<File width="20" height="20" className="mr-3" />*/}
                          <span className="text-[16px] text-middleGray w-full">
                              {item.name}
                          </span>
                          {/*<span className="text-lg text-middleGray">*/}
                          {/*    {item.type}*/}
                          {/*</span>*/}
                      </li>
                  ))
                : null}
        </div>
    );
};
