import React from "react";
import { useTranslation } from "react-i18next";

interface DataTableProps {
    title: string;
    name: string;
    id: string;
    address: string;
    phone?: string;
    email?: string;
    noEmail?: boolean;
    noTelefon?: boolean;
}
export const DataTable: React.FC<DataTableProps> = ({
    title,
    name,
    id,
    address,
    phone,
    email,
    noTelefon,
    noEmail,
}) => {
    const { t } = useTranslation();

    return (
        <div className="mb-4">
            <p className="mb-2 font-[500] text-[16px] fontWeight">{title}</p>
            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight">
                {t("dataTable.name")}:
                <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                    {name === "undefined" ? "-" : name}
                </span>
            </p>
            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight">
                {t("dataTable.code")}:
                <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                    {id === "undefined" ? "-" : id}
                </span>
            </p>
            <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight">
                {t("dataTable.address")}:
                <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                    {address === "undefined" ? "-" : address}
                </span>
            </p>
            {!noEmail && (
                <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight">
                    {t("dataTable.email")}:
                    <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                        {email === "undefined" ? "-" : email}
                    </span>
                </p>
            )}
            {!noTelefon && (
                <p className="font-[500] text-[16px] text-[#1A1A1F] fontWeight">
                    {t("dataTable.phone")}:
                    <span className="text-[16px] text-[#1A1A1F] ml-2 font-normal">
                        {phone === "+undefined undefined" ||
                        phone === "+undefined "
                            ? "-"
                            : phone}
                    </span>
                </p>
            )}
        </div>
    );
};
