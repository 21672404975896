import { TFunction } from "i18next";

export const servicesData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            title: `${t("servicesData.list.0.title")}`,
            desc: `${t("servicesData.list.0.desc")}`,
        },
        {
            id: 2,
            title: `${t("servicesData.list.1.title")}`,
            desc: `${t("servicesData.list.1.desc")}`,
        },
        {
            id: 3,
            title: `${t("servicesData.list.2.title")}`,
            desc: `${t("servicesData.list.2.desc")}`,
        },
        {
            id: 4,
            title: `${t("servicesData.list.3.title")}`,
            desc: `${t("servicesData.list.3.desc")}`,
        },
    ];
};
