import { StickyHeader } from "@components/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { routes } from "./routes";

const App: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <div id="isTop" />
            <StickyHeader />
            <Routes>
                {routes(t).map((route) =>
                    !route.children ? (
                        <Route
                            path={route.path}
                            element={route.element}
                            key={route.path}
                        ></Route>
                    ) : (
                        <Route
                            path={route.path}
                            element={route.element}
                            key={route.path}
                        >
                            {route.children.map((r) => (
                                <Route
                                    path={r.path}
                                    element={r.element}
                                    key={r.path}
                                />
                            ))}
                        </Route>
                    ),
                )}
            </Routes>
        </>
    );
};

export default React.memo(App);
