import clsx from "clsx";
import React from "react";
import { Button } from "@components/components";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";
import { AppRoute } from "@enums";
import { ClickContacts } from "@components/common";

interface IContacts {
    id: number;
    item: string;
    content: string;
}
interface IBailiffCard {
    id: number;
    key: number;
    name: string;
    contacts: IContacts[];
    image: string;
    status: string;
    handedTo: string;
    position: string;
    isHome?: boolean;
}

export const BailiffCard: React.FC<IBailiffCard> = ({
    name,
    contacts,
    image,
    status,
    handedTo,
    position,
    id,
    isHome,
}) => {
    const { t } = useTranslation();
    const handleClick = (key: number) => {
        localStorage.setItem("bailiff_id", String(key));
    };
    return (
        <div className="w-full md:pl-0 md:pr-0 md:w-auto ">
            <div className="flex flex-col flex-1 h-auto bg-white rounded-md shrink-0 lg:flex-row min-h-fit screen:h-auto">
                <img
                    src={require(`@assets/images/${image}`)}
                    alt="BailiffPhoto"
                    className={clsx(
                        "w-full lg:rounded-l-md xl:w-fit lg:w-[47%] h-full",
                        isHome ? "hidden sm:block" : "",
                    )}
                />

                <div
                    className={clsx(
                        "flex justify-between items-center md:items-start flex-1 py-2 md:px-0",
                        isHome
                            ? "sm:flex-col sm:px-4 py-2 md:px-0"
                            : "px-4 flex-col",
                    )}
                >
                    <div className="flex flex-col flex-1 ml-0 lg:ml-8 md:ml-4 items-start">
                        <p
                            className={clsx(
                                "text-lg",
                                isHome
                                    ? "text-secondary sm:text-gray"
                                    : "text-gray",
                            )}
                        >
                            {position}
                        </p>
                        <p className="mb-1 font-semibold text-neutral text-lg">
                            {name}
                        </p>
                        {status ? (
                            <div
                                className={clsx(
                                    "flex-col items-center md:items-start",
                                    isHome ? "hidden sm:flex" : "flex",
                                )}
                            >
                                <p className="text-lg">{status}</p>
                                <p className="mt-4 text-lg">{handedTo}</p>
                            </div>
                        ) : (
                            <>
                                {contacts.map((item) => (
                                    <ClickContacts
                                        isHome={isHome}
                                        item={item.item}
                                        content={item.content}
                                        key={item.id}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                    <NavHashLink
                        to={`${AppRoute.WORK_AREA}#isTop`}
                        className={clsx(
                            "mx-0 md:my-auto md:w-fit md:mx-4 md:self-end md:mb-0",
                            isHome
                                ? "sm:self-start sm:w-full sm:mt-1"
                                : "self-start w-full mt-1",
                        )}
                    >
                        <Button
                            onClick={() => handleClick(id)}
                            className={clsx(
                                "!w-full md:w-fit",
                                isHome ? "text-xs sm:text-lg" : "",
                            )}
                        >
                            {t("other.additional.link")}
                        </Button>
                    </NavHashLink>
                </div>
            </div>
        </div>
    );
};
