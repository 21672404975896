import { ITableForm } from "@types";
import React from "react";
import {
    BailiffData,
    CheckedList,
    DataTable,
    DownloadList,
    SimpleTable,
} from "@components/components";
import { useTranslation } from "react-i18next";

export const TableClaimantForm2: React.FC<ITableForm> = ({ id, myRef }) => {
    const { t } = useTranslation();
    const debtorName = localStorage.getItem("debtorName");
    const debtorId = localStorage.getItem("debtorId");
    const debtorAddress = localStorage.getItem("debtorAddress");
    const debtorEmail = localStorage.getItem("debtorEmail");
    const debtorPhone = `+${localStorage.getItem(
        "pre_debtor_phone",
    )} ${localStorage.getItem("debtor_phone")}`;

    const debtorName1 = localStorage.getItem("debtorName1");
    const debtorId1 = localStorage.getItem("debtorId1");

    const agree = localStorage.getItem("agree");

    const current_date = localStorage.getItem("currentDate");
    const case_number = localStorage.getItem("caseNumber");

    return (
        <div className="print-container a4" id={id} ref={myRef}>
            <img
                src={require(`@assets/images/logo_new.png`)}
                alt="Logo"
                className="logo_print block ml-auto lg:w-52 w-36"
            />
            <div className="printElement1">
                <BailiffData />
                <p className="my-6 font-[500] text-center text-lg fontWeight">
                    {t("tableClaimantForm2.title")}
                </p>
                <div className="flex flex-col self-start mb-6">
                    <div className="flex items-center">
                        <p className="mr-2 text-lg font-[500] fontWeight">
                            {t("sharedClaimantForm.current_date")}:
                        </p>
                        <p className="text-[16px]">{current_date}</p>
                    </div>
                    <div className="flex items-center">
                        <p className="mr-2 text-lg font-[500] fontWeight">
                            {t("sharedClaimantForm.case_number")}:
                        </p>
                        <p className="text-[16px]">
                            {case_number === "undefined" ? "-" : case_number}
                        </p>
                    </div>
                </div>

                <DataTable
                    title={t("tableClaimantForm2.receiver_title")}
                    name={debtorName || ""}
                    email={debtorEmail || ""}
                    id={debtorId || ""}
                    address={debtorAddress || ""}
                    phone={debtorPhone || ""}
                />
                <SimpleTable
                    className="mb-4"
                    title=""
                    thead_1={t("tableClaimantForm2.company_name")}
                    thead_2={t("tableClaimantForm2.code")}
                    tbody_1_1={debtorName1 || ""}
                    tbody_2_1={debtorId1 || ""}
                    row={1}
                />

                <p className="mb-2 text-[16px]">
                    {t("tableClaimantForm2.desc_1")}
                </p>
                <p className="mb-4 text-[16px]">
                    {t("tableClaimantForm2.desc_2")}
                </p>
                <div className="page-break" />
                <DownloadList keyEl="downloadForm_2" className="mb-4" />
                <CheckedList
                    className="mb-4"
                    title=""
                    point_1={t("sharedClaimantForm.checklist")}
                    sub_1={agree === "true"}
                />
                <p className="font-[500] text-[16px] text-[#1A1A1F] mb-2 fontWeight">
                    {t("sharedClaimantForm.applicant")}
                    <span className="font-normal text-[16px] text-[#1A1A1F] ml-2">
                        {debtorName === "undefined" ? "-" : debtorName}
                    </span>
                </p>
                <p className="font-[500] text-[16px] text-[#1A1A1F] flex items-end fontWeight">
                    {t("sharedClaimantForm.signature")}
                    <span className="ml-2 h-[1px] w-[150px] bg-black" />
                </p>
            </div>
        </div>
    );
};
