import React from "react";

export const SideIcon7 = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2916 5.55651L12.2421 0.244651C12.1005 0.0956456 11.9061 0.0120816 11.7027 0.0120816H11.4974C11.4884 0.0111764 11.48 0.00864398 11.4717 0.00613002L11.4717 0.00612924C11.4616 0.00305062 11.4515 0 11.4405 0C11.4289 0 11.4184 0.00312576 11.408 0.00624229C11.3998 0.00871902 11.3915 0.0111899 11.3827 0.0120816H5.07002C2.5926 0.0120816 0.5 2.07702 0.5 4.52152V8.68059C0.5 9.09741 0.834975 9.43569 1.24771 9.43569C1.66045 9.43569 1.99543 9.09741 1.99543 8.68059V4.52152C1.99543 2.89554 3.40312 1.52228 5.07002 1.52228H10.6928V3.68487C10.6928 5.52731 12.1763 7.02844 13.9997 7.03247H14.0007C14.4134 7.03247 14.7474 6.69519 14.7484 6.27939C14.7494 5.86157 14.4154 5.52328 14.0027 5.52228C13.0018 5.52026 12.1882 4.6957 12.1882 3.68487V2.36899L16.0046 6.38309V15.3083C16.0046 17.0028 14.6168 18.4898 13.0347 18.4898H5.07002C3.34629 18.4898 1.99543 17.0924 1.99543 15.3083V12.7853C1.99543 12.3685 1.66045 12.0302 1.24771 12.0302C0.834975 12.0302 0.5 12.3685 0.5 12.7853V15.3083C0.5 17.9391 2.50786 20 5.07002 20H13.0347C15.4553 20 17.5 17.8515 17.5 15.3083V6.08004C17.5 5.88472 17.4252 5.69746 17.2916 5.55651ZM5.87007 14.4053H11.2516C11.6644 14.4053 11.9993 14.0671 11.9993 13.6502C11.9993 13.2334 11.6644 12.8951 11.2516 12.8951H5.87007C5.45734 12.8951 5.12236 13.2334 5.12236 13.6502C5.12236 14.0671 5.45734 14.4053 5.87007 14.4053ZM9.21584 9.41948H5.87007C5.45734 9.41948 5.12236 9.0812 5.12236 8.66438C5.12236 8.24757 5.45734 7.90929 5.87007 7.90929H9.21584C9.62858 7.90929 9.96355 8.24757 9.96355 8.66438C9.96355 9.0812 9.62858 9.41948 9.21584 9.41948Z"
                fill="white"
            />
        </svg>
    );
};
