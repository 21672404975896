import React, { MouseEventHandler } from "react";
import { SectionContainer } from ".";
import clsx from "clsx";
import { NavHashLink } from "react-router-hash-link";
import { Download } from "@components/components";

interface IDividedFields {
    id: number;
    elem: string;
    type: string;
    name: string;
    placeholder: string;
}
interface IDownload {
    title: string;
    divided_fields: IDividedFields[];
    note: string;
    path: string;
}
interface IPoints {
    id: number;
    content: string;
    underLineContent?: string;
    endContent?: string;
    path?: string;
    bold?: boolean;
    orange?: string;
    link?: string;
    path_link?: string;
    name?: string;
}
interface IData {
    title: string;
    points: IPoints[];
}
interface IAwared {
    data: IData;
    base?: boolean;
    file?: boolean;
    download?: IDownload;
    upper?: boolean;
    dark?: boolean;
}

export const Awared: React.FC<IAwared> = ({
    data,
    base,
    file,
    download,
    upper,
    dark,
}) => {
    const { title, points } = data;
    const handleClick = (
        name: string | undefined,
    ): MouseEventHandler<HTMLButtonElement> | undefined => {
        if (!name) return;
        localStorage.setItem("selectQuestion", String(name));
    };
    return (
        <SectionContainer title={title} upper={upper} dark={dark}>
            <ul className="mb-4 ml-6 lg:text-justify list-disc">
                {file ? (
                    <>
                        <li
                            className={clsx(
                                "leading-9 text-neutral",
                                base ? "text-base mb-2" : "text-lg mb-4",
                            )}
                        >
                            <p>{points[0].content}</p>
                        </li>
                        {download && (
                            <Download
                                data={download}
                                nameLocal="downloadForm_6"
                            />
                        )}
                        {points.map(
                            (item, index) =>
                                index > 0 && (
                                    <li
                                        className={clsx(
                                            "leading-9  text-neutral",
                                            base
                                                ? "text-base mb-2"
                                                : "text-lg mb-4",
                                        )}
                                        key={item.id}
                                    >
                                        <p
                                            className={clsx(
                                                "text-lg",
                                                item.bold
                                                    ? "font-semibold"
                                                    : "",
                                            )}
                                            key={item.id}
                                        >
                                            {item.content}
                                            {item.underLineContent !==
                                                undefined && (
                                                <span className="text-lg text-secondary">
                                                    {item.underLineContent}
                                                </span>
                                            )}
                                            {item.orange !== undefined && (
                                                <a
                                                    href={item.link}
                                                    className="text-lg text-secondary"
                                                >
                                                    {item.orange}
                                                </a>
                                            )}
                                            {item.endContent}
                                        </p>
                                    </li>
                                ),
                        )}
                    </>
                ) : (
                    <>
                        {points.map((item) => (
                            <li
                                className={clsx(
                                    "leading-9 text-neutral",
                                    base ? "text-lg mb-2" : "text-lg mb-4",
                                )}
                                key={item.id}
                            >
                                {item.path ? (
                                    <p className="text-lg ">
                                        {item.content}
                                        <button
                                            type="button"
                                            onClick={handleClick(item.name)}
                                        >
                                            <NavHashLink
                                                to={`${item.path_link}` || "#"}
                                                className="font-semibold text-secondary"
                                            >
                                                {item.path}
                                            </NavHashLink>
                                        </button>
                                    </p>
                                ) : (
                                    <p
                                        className={clsx(
                                            "text-lg",
                                            item.bold ? "font-semibold" : "",
                                        )}
                                    >
                                        {item.content}
                                        {item.underLineContent !==
                                            undefined && (
                                            <span className="text-lg text-secondary">
                                                {item.underLineContent}
                                            </span>
                                        )}
                                        {item.orange !== undefined && (
                                            <a
                                                href={item.link}
                                                className="text-lg text-secondary"
                                            >
                                                {item.orange}
                                            </a>
                                        )}
                                        {item.endContent}
                                    </p>
                                )}
                            </li>
                        ))}
                    </>
                )}
            </ul>
        </SectionContainer>
    );
};
