import { AppRoute } from "@enums";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";
export const Initiation = () => {
    const { t } = useTranslation();
    return (
        <div className="pt-0 pl-6 pr-6 md:pt-4">
            <h2 className="mt-2 mb-4 text-xl font-semibold">
                {t("initiation.title")}
            </h2>
            <p className="mb-4 text-lg leading-6 md:leading-9">
                {t("initiation.desc")}
            </p>
            <h3 className="text-lg font-semibold leading-6 md:leading-9">
                {t("initiation.titleList")}
            </h3>
            <ul className="list-disc mb-4 pl-5">
                <li className="text-lg w-full my-4">
                    {t("initiation.list.0")}
                </li>
                <li className="text-lg w-full my-4">
                    {t("initiation.list.1")}
                </li>
                <li className="text-lg w-full my-4">
                    {t("initiation.list.2")}
                </li>
                <li className="text-lg w-full my-4">
                    {t("initiation.list.3")}
                </li>
            </ul>
            <p className="text-lg leading-6 md:leading-9 my-4">
                {t("initiation.desc_2")}{" "}
                <NavHashLink
                    to={`${AppRoute.FORMS_CLAIMANT_FORM_4}#isTop`}
                    className="font-semibold text-secondary"
                >
                    {t("initiation.link_name_2")}
                </NavHashLink>
                .
            </p>
            <h3 className="text-lg font-semibold leading-6 md:leading-9 mb-4">
                {t("initiation.title_2")}
            </h3>
            <p className="text-lg leading-6 md:leading-9">
                {t("initiation.desc_3")}
            </p>
            <p className="text-lg leading-6 md:leading-9">
                {t("initiation.desc_4")}
            </p>
            <p className="py-4 text-lg leading-6 md:leading-9">
                {t("initiation.desc_5")}
            </p>
            <p className="text-lg leading-6 md:leading-9">
                {t("initiation.desc_6")}{" "}
                <NavHashLink
                    to={`${AppRoute.FORMS_CLAIMANT_FORM_1}#isTop`}
                    className="font-semibold text-secondary "
                >
                    {t("initiation.link_name_6")}
                </NavHashLink>
                .
            </p>
            <p className="text-lg leading-6 md:leading-9">
                {t("initiation.desc_7")}
                <a
                    target="_blank"
                    rel="noreferrer"
                    className="ml-1 text-lg font-semibold text-secondary"
                    href="https://sotsiaalkindlustusamet.ee/et/taitemenetlusaegse-elatisabi-korduma-kippuvad-kusimused"
                >
                    sotsiaalkindlustusamet.ee
                </a>
                .
            </p>
        </div>
    );
};
