import React, { useState } from "react";
import { AccordionLayout, Tooltip } from "@components/common";
import {
    Assets,
    Limitation,
    Minimum,
    Other,
    Refund,
    Sale,
    Schedule,
    Section,
} from "@components/components";
import { debtorForm5Data, noticeData } from "@config/forms";
import { useTranslation } from "react-i18next";

interface IData {
    id: number;
    title: string;
    element: any;
    name: string;
}

interface IStatements {
    data: IData[];
    onCheckboxChange?: (arg0: any) => void;
    checked: boolean[];
    onChange?: (arg0: any) => void;
    radioChecked?: any;
    onRadioChange?: (arg0: any) => void;
    addBank?: boolean;
    addBank2?: boolean;
}

export const Statements: React.FC<IStatements> = ({
                                                      data,
                                                      onCheckboxChange,
                                                      checked,
                                                      onChange,
                                                      radioChecked,
                                                      onRadioChange,
                                                      addBank,
                                                      addBank2,
                                                  }) => {
    const { t } = useTranslation();
    const { additionalInfo, section } = noticeData(t);
    const selectMenuItemFromStorage = localStorage.getItem("selectMenuItem");
    const selectTitleItem = localStorage.getItem("selectTitleItem");
    const [titles, setTitles] = useState<(string | null)[]>([selectTitleItem]);
    const [selected, setSelected] = useState<(string | null)[]>([
        selectMenuItemFromStorage,
    ]);
    const isRadioSelected = (value: string): boolean =>
        selected.includes(value);
    const { assetsLiabilities, agreement_note } = debtorForm5Data(t);

    const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (
            selected.includes(e.target.value) &&
            titles.includes(e.target.name)
        ) {
            const newArr = selected.filter((item) => item !== e.target.value);
            const arr = titles.filter((item) => item !== e.target.name);

            if (!newArr.length)
                return;

            setTitles(arr);
            setSelected(newArr);
            return;
        }

        setSelected((prevState) => [...prevState, e.target.value]);
        setTitles((prevState) => [...prevState, e.target.name]);
    };
    localStorage.setItem("selected_menu_arr", JSON.stringify(selected));
    localStorage.setItem("selected_titles_arr", JSON.stringify(titles));

    // console.log(data[0].title)
    // console.log(data[0].name)
    return (
        <div className="mt-8">
            <ul className="w-full">
                <li className="w-full mb-8">
                    <div className="flex items-center">
                        <p className="mb-2 text-lg font-semibold">
                            {t("statements.title")}
                        </p>
                        <Tooltip hint={t("statements.hint")} />
                    </div>
                    <div className="relative flex flex-col">
                        {additionalInfo.points.map((item) => (
                            <div
                                key={item.id}
                                className="flex items-center my-2 mr-4 lg:mr-16"
                            >
                                <input
                                    id={item.value}
                                    type="checkbox"
                                    className="square"
                                    name={item.value}
                                    value={item.name}
                                    checked={
                                        isRadioSelected(item.value) ||
                                        selected.includes(item.name)
                                    }
                                    onChange={handleRadioClick}
                                />

                                {item.value && (
                                    <label
                                        htmlFor={item.value}
                                        className="flex items-center cursor-pointer"
                                    >
                                        <p className="text-lg ml-2 text-neutral">
                                            {item.value}
                                        </p>
                                    </label>
                                )}
                            </div>
                        ))}
                    </div>
                    <Section
                        dark
                        data={section}
                        onChange={onChange}
                        className="grid grid-cols-1 gap-4 md:grid-cols-2"
                    />
                </li>

                {(selected.includes(data[1].title) ||
                    selected.includes(data[1].name)) && (
                    <li className="w-full mb-8" id="schedule">
                        <AccordionLayout
                            title={data[1].title}
                            form
                            middle
                            upper
                            defaultOpen
                        >
                            <Schedule
                                onChange={onChange}
                                onCheckboxChange={onCheckboxChange}
                                checked={checked}
                                onRadioChange={onRadioChange}
                                radioChecked={radioChecked}
                                addBank={addBank}
                            />
                        </AccordionLayout>
                    </li>
                )}

                {(selected.includes(data[2].title) ||
                    selected.includes(data[2].name)) && (
                    <li className="w-full mb-8" id="minimum">
                        <AccordionLayout
                            title={data[2].title}
                            form
                            middle
                            upper
                            defaultOpen
                        >
                            <Minimum
                                onChange={onChange}
                                addBank2={addBank2}
                                onRadioChange={onRadioChange}
                                radioChecked={radioChecked}
                            />
                        </AccordionLayout>
                    </li>
                )}

                {(selected.includes(data[3].title) ||
                    selected.includes(data[3].name)) && (
                    <li className="w-full mb-8" id="refund">
                        <AccordionLayout
                            title={data[3].title}
                            form
                            middle
                            upper
                            defaultOpen
                        >
                            <Refund
                                onChange={onChange}
                                onRadioChange={onRadioChange}
                                radioChecked={radioChecked}
                            />
                        </AccordionLayout>
                    </li>
                )}

                {(selected.includes(data[0].title) ||
                    selected.includes(data[0].name)) && (
                    <li className="w-full mb-8" id="notice">
                        <p className="font-semibold  text-neutral w-11/12 mb-2 text-base !text-lg uppercase">
                            {data[0].title}
                        </p>
                        {assetsLiabilities.map((item) => (
                            <Assets
                                items={item.items}
                                title={item.title}
                                key={item.id}
                                onChange={onChange}
                            />
                        ))}
                        <p className="mb-4 text-lg font-semibold text-neutral">
                            {t("statements.agreement_note")}
                        </p>
                        <p className="text-middleGray">{agreement_note}</p>
                    </li>
                )}

                {(selected.includes("Avaldus aegumise kohaldamiseks") ||
                    selected.includes(data[4].name)) && (
                    <li className="w-full mb-8" id="limitation">
                        <AccordionLayout
                            title={data[4].title}
                            form
                            middle
                            defaultOpen
                            upper
                        >
                            <Limitation onChange={onChange} />
                        </AccordionLayout>
                    </li>
                )}

                {(selected.includes(data[5].title) ||
                    selected.includes(data[5].name)) && (
                    <li className="w-full mb-8" id="sale">
                        <AccordionLayout
                            title={data[5].title}
                            form
                            middle
                            defaultOpen
                            upper
                        >
                            <Sale onChange={onChange} />
                        </AccordionLayout>
                    </li>
                )}

                {(selected.includes(data[6].title) ||
                    selected.includes(data[6].name)) && (
                    <li className="w-full mb-8" id="other">
                        <AccordionLayout
                            title={data[6].title}
                            form
                            middle
                            defaultOpen
                            upper
                        >
                            <Other onChange={onChange} />
                        </AccordionLayout>
                    </li>
                )}
            </ul>
        </div>
    );
};
