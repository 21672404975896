import React from "react";

export const ClaimIcon1 = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.88989 3.99976C3.39802 3.99976 2.99927 4.33555 2.99927 4.74976C2.99927 5.16397 3.39802 5.49976 3.88989 5.49976H9.23364C9.72552 5.49976 10.1243 5.16397 10.1243 4.74976C10.1243 4.33555 9.72552 3.99976 9.23364 3.99976H3.88989ZM2.99927 7.74976C2.99927 7.33555 3.39802 6.99976 3.88989 6.99976H6.26489C6.75677 6.99976 7.15552 7.33555 7.15552 7.74976C7.15552 8.16397 6.75677 8.49976 6.26489 8.49976H3.88989C3.39802 8.49976 2.99927 8.16397 2.99927 7.74976ZM16.9809 11.2864C17.3327 10.997 17.3395 10.5221 16.9959 10.2258C16.6522 9.92941 16.0882 9.92378 15.7364 10.2132L10.9559 14.1445L9.27078 12.7204C8.92357 12.427 8.35967 12.4262 8.01124 12.7185C7.66282 13.0109 7.66182 13.4858 8.00901 13.7792L10.3164 15.7292C10.6611 16.0205 11.2202 16.0237 11.5696 15.7364L16.9809 11.2864Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.526 1.6865C2.53348 0.605621 3.96871 0 5.665 0H14.334C16.0343 0 17.4701 0.605298 18.4771 1.68678C19.4785 2.76222 20 4.2476 20 5.916V14.084C20 15.7524 19.4785 17.2378 18.477 18.3133C17.4698 19.3948 16.0337 20 14.333 20H5.665C3.96425 20 2.52857 19.3947 1.52187 18.3131C0.520842 17.2376 0 15.7522 0 14.084V5.916C0 4.24677 0.523963 2.76152 1.526 1.6865ZM2.62325 2.70925C1.92304 3.46048 1.5 4.55823 1.5 5.916V14.084C1.5 15.4428 1.92116 16.5404 2.61988 17.2911C3.31293 18.0358 4.33475 18.5 5.665 18.5H14.333C15.6633 18.5 16.6857 18.0357 17.3793 17.291C18.0785 16.5402 18.5 15.4426 18.5 14.084V5.916C18.5 4.5574 18.0785 3.45978 17.3794 2.70897C16.6859 1.9642 15.6637 1.5 14.334 1.5H5.665C4.34029 1.5 3.31802 1.96388 2.62325 2.70925Z"
                fill="white"
            />
        </svg>
    );
};
