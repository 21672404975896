import React from "react";

export const ClaimIcon4 = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 8.822C20 9.239 19.661 9.578 19.244 9.578H19.235V9.56C18.813 9.56 18.471 9.219 18.47 8.797V8.795V6.253C18.47 3.2 16.8 1.53 13.756 1.53H6.256C3.21 1.53 1.53 3.21 1.53 6.253V13.753C1.53 16.787 3.21 18.467 6.253 18.467H13.753C16.796 18.467 18.467 16.787 18.467 13.753C18.467 13.331 18.809 12.988 19.232 12.988C19.655 12.988 19.997 13.331 19.997 13.753C20 17.608 17.608 20 13.756 20H6.253C2.392 20 0 17.608 0 13.756V6.256C0 2.392 2.392 0 6.253 0H13.753C17.59 0 20 2.392 20 6.253V8.822ZM6.86997 8.41958H10.2157C10.6285 8.41958 10.9635 8.0813 10.9635 7.66449C10.9635 7.24767 10.6285 6.90939 10.2157 6.90939H6.86997C6.45724 6.90939 6.12226 7.24767 6.12226 7.66449C6.12226 8.0813 6.45724 8.41958 6.86997 8.41958ZM6.86997 13.4054H12.2515C12.6643 13.4054 12.9992 13.0672 12.9992 12.6503C12.9992 12.2335 12.6643 11.8952 12.2515 11.8952H6.86997C6.45724 11.8952 6.12226 12.2335 6.12226 12.6503C6.12226 13.0672 6.45724 13.4054 6.86997 13.4054Z"
                fill="white"
            />
        </svg>
    );
};
