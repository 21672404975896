import React, { useState } from "react";
import clsx from "clsx";

interface IData {
    id: number;
    value: string;
    name: string;
}
interface IRadio {
    data: IData[];
    col?: boolean;
    className?: string;
    square?: boolean;
    radioChecked?: any;
    onRadioChange?: (arg0: any) => void;
    upper?: boolean;
}

export const RadioGroup: React.FC<IRadio> = ({
    data,
    col,
    square,
    radioChecked,
    onRadioChange,
    upper = false,
}) => {
    const [selected, setSelected] = useState("");
    const isRadioSelected = (value: string): boolean => selected === value;
    const handleElementClick = (e: any): void => {
        setSelected(e.target.innerText);
        onRadioChange;
    };

    return (
        <div>
            <div
                className={clsx(
                    "relative flex  justify-between flex-wrap",
                    col ? "flex-col items-start" : "items-center flex-row",
                )}
            >
                {data.map((item) => (
                    <div
                        key={item.id}
                        className="flex items-center mb-4 mr-4 lg:mr-16 "
                        onClick={(e) => handleElementClick(e)}
                    >
                        <input
                            id={item.value}
                            type="radio"
                            className={clsx(
                                square ? "square" : "round",
                                "checked:before:!border-secondary",
                            )}
                            name={item.name}
                            value={item.value}
                            checked={Object(radioChecked)?.item?.name}
                            onChange={onRadioChange}
                        />

                        {item.value && (
                            <label
                                htmlFor={item.value}
                                className={clsx(
                                    "flex items-center ml-2   cursor-pointer",
                                    upper ? "uppercase" : "",
                                )}
                            >
                                <p
                                    className={clsx(
                                        square ? "text-lg" : "text-lg",
                                        isRadioSelected(item.value)
                                            ? "text-neutral"
                                            : "text-[#4B4B4B]",
                                    )}
                                >
                                    {item.value}
                                </p>
                            </label>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
