import { TFunction } from "i18next";

export const calcData = (t: TFunction<"translation", undefined>) => {
    return {
        items: [
            {
                id: 1,
                content: `${t("calcData.items.0.content")}`,
            },
            {
                id: 2,
                content: `${t("calcData.items.1.content")}`,
            },
            {
                id: 3,
                content: `${t("calcData.items.2.content")}`,
            },
            {
                id: 4,
                content: `${t("calcData.items.3.content")}`,
            },
            {
                id: 5,
                content: `${t("calcData.items.4.content")}`,
            },
        ],
        desc: `${t("calcData.desc")}`,
        path: "https://www.riigiteataja.ee/akt/112032022009",
        path_name: `${t("calcData.path_name")}`,
        endOfDesc: `${t("calcData.endOfDesc")}`,
    };
};
