import { ActionTypes, IAction, IState } from "./FileProvider.types";

export const defaultState: IState = {
    signingToken: "",
    signerToken: "",
};

export const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
        case ActionTypes.SET_SIGN_TOKENS:
            return { ...action.payload };
        default:
            return state;
    }
};
