import React from "react";
import { notificationData } from "@config/debtor";
import { DebtorMain } from "@components/debtor";
import { useTranslation } from "react-i18next";

export const Notification = () => {
    const { t } = useTranslation();
    const {
        title,
        desc_1,
        desc_2,
        desc_3,
        desc_4,
        desc_5,
        desc_6,
        path,
        path_name,
        endOfDesc_6,
        path_url,
        name,
    } = notificationData(t);
    return (
        <DebtorMain
            title={title}
            name={name}
            path_url={path_url}
            desc_1={desc_1}
            desc_2={desc_2}
            desc_3={desc_3}
            desc_4={desc_4}
            desc_5={desc_5}
            desc_6={desc_6}
            endOfDesc_6={endOfDesc_6}
            path_name={path_name}
            path={path}
        />
    );
};
