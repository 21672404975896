import { TFunction } from "i18next";

export const claimantForm1Data = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("claimantForm1Data.title")}`,
        datepicker: false,
        sections: [
            {
                id: 1,
                title: `${t("claimantForm1Data.sections.0.title")}`,
                fields: [],
                fields_more: [
                    {
                        id: 1,
                        child: [
                            {
                                id: 1,
                                name: "child_name_1",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "child_id_1",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.1.label",
                                )}`,
                                required: true,
                                minLength: 11,
                                maxLength: 11,
                                placeholder: " ",
                                number: true,
                            },
                            {
                                id: 3,
                                name: "child_address_1",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.2.label",
                                )}`,
                                required: true,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 2,
                        child: [
                            {
                                id: 1,
                                name: "child_name_2",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: false,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "child_id_2",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.1.label",
                                )}`,
                                required: false,
                                minLength: 11,
                                maxLength: 11,
                                placeholder: " ",
                                number: true,
                            },
                            {
                                id: 3,
                                name: "child_address_2",
                                label: "Aadress",
                                required: false,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 3,
                        child: [
                            {
                                id: 1,
                                name: "child_name_3",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: false,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "child_id_3",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.1.label",
                                )}`,
                                required: false,
                                minLength: 11,
                                maxLength: 11,
                                placeholder: " ",
                                number: true,
                            },
                            {
                                id: 3,
                                name: "child_address_3",
                                label: "Aadress",
                                required: false,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 4,
                        child: [
                            {
                                id: 1,
                                name: "child_name_4",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: false,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "child_id_4",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.1.label",
                                )}`,
                                required: false,
                                minLength: 11,
                                maxLength: 11,
                                placeholder: " ",
                                number: true,
                            },
                            {
                                id: 3,
                                name: "child_address_4",
                                label: "Aadress",
                                required: false,
                                placeholder: " ",
                            },
                        ],
                    },
                    {
                        id: 5,
                        child: [
                            {
                                id: 1,
                                name: "child_name_5",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.0.label",
                                )}`,
                                required: false,
                                placeholder: " ",
                            },
                            {
                                id: 2,
                                type: "text",
                                name: "child_id_5",
                                label: `${t(
                                    "claimantForm1Data.sections.0.fields_more.0.child.1.label",
                                )}`,
                                required: false,
                                minLength: 11,
                                maxLength: 11,
                                placeholder: " ",
                                number: true,
                            },
                            {
                                id: 3,
                                name: "child_address_5",
                                label: "Aadress",
                                required: false,
                                placeholder: " ",
                            },
                        ],
                    },
                ],
                note: `${t("claimantForm1Data.sections.0.note")}`,
                add_more: true,
                divided_fields: [],
            },
            {
                id: 2,
                title: `${t("claimantForm1Data.sections.1.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "parent_name",
                        label: `${t(
                            "claimantForm1Data.sections.1.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "parent_id",
                        label: `${t(
                            "claimantForm1Data.sections.1.fields.1.label",
                        )}`,
                        type: "text",
                        required: true,
                        minLength: 11,
                        maxLength: 11,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 3,
                        name: "parent_address",
                        label: `${t(
                            "claimantForm1Data.sections.1.fields.2.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 4,
                        name: "parent_email",
                        label: `${t(
                            "claimantForm1Data.sections.1.fields.3.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 5,
                        name: "parent_phone",
                        label: `${t(
                            "claimantForm1Data.sections.1.fields.4.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                    },
                ],
                note: "",
                add_more: false,
            },
            {
                id: 3,
                title: `${t("claimantForm1Data.sections.2.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "debtor_name",
                        label: `${t(
                            "claimantForm1Data.sections.2.fields.0.label",
                        )}`,
                        type: "text",
                        placeholder: " ",
                        required: true,
                    },
                    {
                        id: 2,
                        name: "debtor_id",
                        label: `${t(
                            "claimantForm1Data.sections.2.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 11,
                        maxLength: 11,
                        placeholder: " ",
                        number: true,
                        required: true,
                    },
                    {
                        id: 3,
                        name: "debtor_address",
                        label: `${t(
                            "claimantForm1Data.sections.2.fields.2.label",
                        )}`,
                        type: "text",
                        placeholder: " ",
                    },
                    {
                        id: 4,
                        name: "debtor_email",
                        label: `${t(
                            "claimantForm1Data.sections.2.fields.3.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 5,
                        name: "debtor_phone",
                        label: `${t(
                            "claimantForm1Data.sections.2.fields.4.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                    },
                ],
                note: "",
                add_more: false,
            },
        ],
        attached: {
            title: `${t("claimantForm1Data.attached.title")}`,
            points: [
                {
                    id: 1,
                    value: `${t("claimantForm1Data.attached.points.0.value")}`,
                    name: "point1",
                },
                {
                    id: 2,
                    value: `${t("claimantForm1Data.attached.points.1.value")}`,
                    name: "point2",
                },
                {
                    id: 3,
                    value: `${t("claimantForm1Data.attached.points.2.value")}`,
                    name: "point3",
                },
                {
                    id: 4,
                    value: `${t("claimantForm1Data.attached.points.3.value")}`,
                    name: "point4",
                },
                {
                    id: 5,
                    value: `${t("claimantForm1Data.attached.points.4.value")}`,
                    name: "point5",
                },
                {
                    id: 6,
                    value: `${t("claimantForm1Data.attached.points.5.value")}`,
                    name: "point6",
                },
                {
                    id: 7,
                    value: `${t("claimantForm1Data.attached.points.6.value")}`,
                    name: "point7",
                },
            ],
        },
        details: {
            title: `${t("claimantForm1Data.details.title")}`,
            fields: [
                {
                    id: 1,
                    placeholder: " ",
                    label: `${t("claimantForm1Data.details.fields.0.label")}`,
                    type: "text",
                    name: "judgment_number",
                    number: false,
                },
                {
                    id: 2,
                    name: "court",
                    label: `${t("claimantForm1Data.details.fields.1.label")}`,
                    type: "text",
                    placeholder: " ",
                },
                {
                    id: 3,
                    placeholder: " ",
                    label: `${t("claimantForm1Data.details.fields.2.label")}`,
                    type: "date",
                    name: "judgment_date",
                },
                {
                    id: 4,
                    name: "country",
                    label: `${t("claimantForm1Data.details.fields.3.label")}`,
                    type: "text",
                    placeholder: " ",
                },
            ],
        },
        compensation: {
            title: `${t("claimantForm1Data.compensation.title")}`,
            fields: [
                {
                    id: 1,
                    placeholder: " ",
                    label: `${t(
                        "claimantForm1Data.compensation.fields.0.label",
                    )}`,
                    type: "text",
                    name: "comp_bank",
                    number: false,
                },
                {
                    id: 2,
                    placeholder: " ",
                    label: `${t(
                        "claimantForm1Data.compensation.fields.1.label",
                    )}`,
                    type: "text",
                    name: "comp_holder",
                },
                {
                    id: 3,
                    placeholder: " ",
                    label: `${t(
                        "claimantForm1Data.compensation.fields.2.label",
                    )}`,
                    type: "text",
                    name: "comp_current",
                    number: true,
                },
            ],
        },

        awared: {
            title: `${t("claimantForm1Data.awared.title")}`,
            points: [
                {
                    id: 1,
                    content: `${t(
                        "claimantForm1Data.awared.points.0.content",
                    )}`,
                },
                {
                    id: 2,
                    content: `${t(
                        "claimantForm1Data.awared.points.1.content",
                    )}`,
                },
                {
                    id: 3,
                    content: `${t(
                        "claimantForm1Data.awared.points.2.content",
                    )}`,
                },
                {
                    id: 4,
                    content: `${t(
                        "claimantForm1Data.awared.points.3.content",
                    )}`,
                },
            ],
        },
        agreement: {
            title: "",
            points: [
                {
                    id: 1,
                    value: `${t("claimantForm1Data.agreement.points.0.value")}`,
                    name: "agree",
                },
            ],
        },
        download: {
            title: `${t("claimantForm1Data.download.title")}`,
            divided_fields: [
                {
                    id: 1,
                    elem: `${t(
                        "claimantForm1Data.download.divided_fields.0.elem",
                    )}`,
                    multiple: true,
                    type: "file",
                    name: "download_1",
                    placeholder: `${t(
                        "claimantForm1Data.download.divided_fields.0.placeholder",
                    )}`,
                },
            ],
        },
    };
};
