import { TFunction } from "i18next";

export const paymentData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("paymentData.title")}`,
        desc_1: `${t("paymentData.desc_1")}`,

        desc_2: `${t("paymentData.desc_2")}`,

        desc_3: `${t("paymentData.desc_3")}`,

        desc_4: `${t("paymentData.desc_4")}`,
        path: `${t("paymentData.path")}`,
    };
};
