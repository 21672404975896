import { TFunction } from "i18next";

export const salaryData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("salaryData.title")}`,
        calc: {
            name: `${t("salaryData.calc.name")}`,
            desc: `${t("salaryData.calc.desc")}`,
            fields: [
                {
                    id: 1,
                    name: "minimum",
                    type: "text",
                    label: `${t("salaryData.calc.fields.0.label")}`,
                    number: true,
                },
                {
                    id: 2,
                    type: "text",
                    name: "subsistence",
                    label: `${t("salaryData.calc.fields.1.label")}`,
                    number: true,
                },
                {
                    id: 3,
                    type: "number",
                    name: "percent",
                    label: `${t("salaryData.calc.fields.2.label")}`,
                    number: true,
                },
                {
                    id: 4,
                    type: "text",
                    name: "depend",
                    label: `${t("salaryData.calc.fields.3.label")}`,
                    number: true,
                },
                {
                    id: 5,
                    type: "text",
                    name: "salary",
                    label: `${t("salaryData.calc.fields.4.label")}`,
                    number: true,
                },
                {
                    id: 6,
                    type: "text",
                    name: "non_tax",
                    label: `${t("salaryData.calc.fields.5.label")}`,
                    number: true,
                },
                {
                    id: 7,
                    type: "text",
                    name: "discounts",
                    label: `${t("salaryData.calc.fields.6.label")}`,
                    number: true,
                },
                {
                    id: 8,
                    type: "text",
                    name: "debtor_income",
                    label: `${t("salaryData.calc.fields.7.label")}`,
                    number: true,
                },
                {
                    id: 9,
                    type: "text",
                    name: "debtor_net_income",
                    label: `${t("salaryData.calc.fields.8.label")}`,
                    number: true,
                },
            ],
            sum: `${t("salaryData.calc.sum")}`,
            seized: `${t("salaryData.calc.seized")}`,
        },
    };
};
