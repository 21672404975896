import { TFunction } from "i18next";

export const debtorForm5Data = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("debtorForm5Data.title")}`,
        datepicker: false,
        sections: [
            {
                id: 1,
                title: `${t("debtorForm5Data.sections.0.title")}`,
                fields: [
                    {
                        id: 1,
                        name: "debtor_name",
                        label: `${t(
                            "debtorForm5Data.sections.0.fields.0.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 2,
                        name: "debtor_id",
                        label: `${t(
                            "debtorForm5Data.sections.0.fields.1.label",
                        )}`,
                        type: "text",
                        minLength: 11,
                        maxLength: 11,
                        required: true,
                        placeholder: " ",
                        number: true,
                    },
                    {
                        id: 3,
                        name: "debtor_address",
                        label: `${t(
                            "debtorForm5Data.sections.0.fields.2.label",
                        )}`,
                        type: "text",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 4,
                        name: "debtor_email",
                        label: `${t(
                            "debtorForm5Data.sections.0.fields.3.label",
                        )}`,
                        type: "email",
                        required: true,
                        placeholder: " ",
                    },
                    {
                        id: 5,
                        name: "debtor_phone",
                        label: `${t(
                            "debtorForm5Data.sections.0.fields.4.label",
                        )}`,
                        type: "tel",
                        required: true,
                        placeholder: " ",
                    },
                ],
                note: "",
                add_more: false,
            },
        ],

        assetsLiabilities: [
            {
                id: 1,
                title: `${t("debtorForm5Data.assetsLiabilities.0.title")}`,
                items: [
                    {
                        id: 1,
                        desc_1: `${t(
                            "debtorForm5Data.assetsLiabilities.0.items.0.desc_1",
                        )}`,
                        desc_2: `${t(
                            "debtorForm5Data.assetsLiabilities.0.items.0.desc_2",
                        )}`,
                        name: "job",
                        tip: `${t(
                            "debtorForm5Data.assetsLiabilities.0.items.0.tip",
                        )}`,
                    },
                    {
                        id: 2,
                        desc_1: `${t(
                            "debtorForm5Data.assetsLiabilities.0.items.1.desc_1",
                        )}`,
                        desc_2: `${t(
                            "debtorForm5Data.assetsLiabilities.0.items.1.desc_2",
                        )}`,
                        name: "claims",
                        tip: `${t(
                            "debtorForm5Data.assetsLiabilities.0.items.1.tip",
                        )}`,
                    },
                    {
                        id: 3,
                        desc_1: `${t(
                            "debtorForm5Data.assetsLiabilities.0.items.2.desc_1",
                        )}`,
                        desc_2: `${t(
                            "debtorForm5Data.assetsLiabilities.0.items.2.desc_2",
                        )}`,
                        name: "balance",
                        tip: `${t(
                            "debtorForm5Data.assetsLiabilities.0.items.2.tip",
                        )}`,
                    },
                ],
            },
            {
                id: 2,
                items: [
                    {
                        id: 1,
                        desc_1: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.0.desc_1",
                        )}`,
                        desc_2: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.0.desc_2",
                        )}`,
                        name: "cars",
                        tip: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.0.tip",
                        )}`,
                    },
                    {
                        id: 2,
                        desc_1: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.1.desc_1",
                        )}`,
                        desc_2: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.1.desc_2",
                        )}`,
                        name: "tech",
                        tip: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.1.tip",
                        )}`,
                    },
                    {
                        id: 3,
                        desc_1: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.2.desc_1",
                        )}`,
                        desc_2: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.2.desc_2",
                        )}`,
                        name: "relative",
                        tip: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.2.tip",
                        )}`,
                    },
                    {
                        id: 4,
                        desc_1: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.3.desc_1",
                        )}`,
                        desc_2: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.3.desc_2",
                        )}`,
                        name: "donate",
                        tip: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.3.tip",
                        )}`,
                    },
                    {
                        id: 5,
                        desc_1: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.4.desc_1",
                        )}`,
                        desc_2: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.4.desc_2",
                        )}`,
                        name: "liabilities",
                        tip: `${t(
                            "debtorForm5Data.assetsLiabilities.1.items.4.tip",
                        )}`,
                    },
                ],
            },
        ],
        awared: {
            title: "",
            points: [
                {
                    id: 1,
                    value: `${t("debtorForm5Data.awared.point.0.value")}`,
                    name: "point1",
                },
                {
                    id: 2,
                    value: `${t("debtorForm5Data.awared.point.1.value")}`,
                    name: "point2",
                },
                {
                    id: 3,
                    value: `${t("debtorForm5Data.awared.point.2.value")}`,
                    name: "point3",
                },
            ],
        },
        download: {
            title: `${t("debtorForm5Data.download.title")}`,
            divided_fields: [
                {
                    id: 1,
                    elem: `${t(
                        "debtorForm5Data.download.divided_fields.0.elem",
                    )}`,
                    multiple: true,
                    type: "file",
                    name: "download_1",
                    placeholder: `${t(
                        "debtorForm5Data.download.divided_fields.0.placeholder",
                    )}`,
                },
            ],
        },

        // agreement: {
        //     title: "",
        //     points: [
        //         {
        //             id: 1,
        //             value: "Kinnitan oma au ja südametunnistuse juures, et kohtutäiturile esitatud andmed vara kohta on mulle teadaolevalt õiged. ",
        //             name: "agree",
        //         },
        //     ],
        // },
        agreement_note: `${t("debtorForm5Data.agreement_note")}`,
    };
};
