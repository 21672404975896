import { ITableForm } from "@types";
import React from "react";
import {
    BailiffData,
    CheckedList,
    DataTable,
    DownloadList,
    ListTable,
    SimpleTable,
} from "@components/components";
import { useTranslation } from "react-i18next";

export const TableClaimantForm1: React.FC<ITableForm> = ({ id, myRef }) => {
    const { t } = useTranslation();
    const childName1 = localStorage.getItem("childName1");
    const childId1 = localStorage.getItem("childId1");
    const childAddress1 = localStorage.getItem("childAddress1");

    const childName2 = localStorage.getItem("childName2");
    const childId2 = localStorage.getItem("childId2");
    const childAddress2 = localStorage.getItem("childAddress2");

    const childName3 = localStorage.getItem("childName3");
    const childId3 = localStorage.getItem("childId3");
    const childAddress3 = localStorage.getItem("childAddress3");

    const childName4 = localStorage.getItem("childName4");
    const childId4 = localStorage.getItem("childId4");
    const childAddress4 = localStorage.getItem("childAddress4");

    const childName5 = localStorage.getItem("childName5");
    const childId5 = localStorage.getItem("childId5");
    const childAddress5 = localStorage.getItem("childAddress5");

    const parentName = localStorage.getItem("parentName");
    const parentId = localStorage.getItem("parentId");
    const parentAddress = localStorage.getItem("parentAddress");
    const parentEmail = localStorage.getItem("parentEmail");
    const parentPhone = `+${localStorage.getItem(
        "pre_parent_phone",
    )} ${localStorage.getItem("parent_phone")}`;

    const debtorName = localStorage.getItem("debtorName");
    const debtorId = localStorage.getItem("debtorId");
    const debtorAddress = localStorage.getItem("debtorAddress");
    const debtorPhone = `+${localStorage.getItem(
        "pre_debtor_phone",
    )} ${localStorage.getItem("debtor_phone")}`;
    const debtorEmail = localStorage.getItem("debtorEmail");

    const judgmentNumber = localStorage.getItem("judgmentNumber");
    const judgmentDate = localStorage.getItem("judgmentDate");
    const court = localStorage.getItem("court");
    const country = localStorage.getItem("country");
    const comp_bank = localStorage.getItem("compBank");
    const comp_holder = localStorage.getItem("compHolder");
    const comp_current = localStorage.getItem("compCurrent");

    const point_1 = localStorage.getItem("point1");
    const point_2 = localStorage.getItem("point2");
    const point_3 = localStorage.getItem("point3");
    const point_4 = localStorage.getItem("point4");
    const point_5 = localStorage.getItem("point5");
    const point_6 = localStorage.getItem("point6");
    const point_7 = localStorage.getItem("point7");

    const agree = localStorage.getItem("agree");

    const current_date = localStorage.getItem("currentDate");
    const case_number = localStorage.getItem("caseNumber");
    return (
        <div className="print-container a4" id={id} ref={myRef}>
            <img
                src={require(`@assets/images/logo_new.png`)}
                alt="Logo"
                className="logo_print block ml-auto lg:w-52 w-36"
            />
            <div className="printElement1">
                <BailiffData />
                <p className="mb-6 font-[500] text-center text-[16px] fontWeight">
                    {t("tableClaimantForm1.title")}
                </p>
                <div className="flex flex-col self-start mb-6">
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.current_date")}:
                        </p>
                        <p className="text-[18px]">{current_date}</p>
                    </div>
                    <div className="flex items-center">
                        <p className="mr-2 text-[16px] font-[500] fontWeight">
                            {t("sharedClaimantForm.case_number")}:
                        </p>
                        <p className="text-[16px]">
                            {case_number === "undefined" ? "-" : case_number}
                        </p>
                    </div>
                </div>
                <SimpleTable
                    className="mb-4"
                    title={t("tableClaimantForm1.maintenance.title")}
                    thead_1={t("tableClaimantForm1.maintenance.thead_1")}
                    thead_2={t("tableClaimantForm1.maintenance.thead_2")}
                    thead_3={t("tableClaimantForm1.maintenance.thead_3")}
                    tbody_1_1={childName1 || ""}
                    tbody_2_1={childId1 || ""}
                    tbody_3_1={childAddress1 || ""}
                    tbody_1_2={childName2 || ""}
                    tbody_2_2={childId2 || ""}
                    tbody_3_2={childAddress2 || ""}
                    tbody_1_3={childName3 || ""}
                    tbody_2_3={childId3 || ""}
                    tbody_3_3={childAddress3 || ""}
                    tbody_1_4={childName4 || ""}
                    tbody_2_4={childId4 || ""}
                    tbody_3_4={childAddress4 || ""}
                    tbody_1_5={childName5 || ""}
                    tbody_2_5={childId5 || ""}
                    tbody_3_5={childAddress5 || ""}
                />
                <DataTable
                    title={t("tableClaimantForm1.applicant")}
                    name={parentName || ""}
                    email={parentEmail || ""}
                    id={parentId || ""}
                    address={parentAddress || ""}
                    phone={parentPhone || ""}
                />
                <DataTable
                    title={t("tableClaimantForm1.debtor_maintenance")}
                    name={debtorName || ""}
                    email={debtorEmail || ""}
                    id={debtorId || ""}
                    address={debtorAddress || ""}
                    phone={debtorPhone || ""}
                />
                <SimpleTable
                    title={t("tableClaimantForm1.judgment.title")}
                    thead_1={t("tableClaimantForm1.judgment.thead_1")}
                    thead_2={t("tableClaimantForm1.judgment.thead_2")}
                    thead_3={t("tableClaimantForm1.judgment.thead_3")}
                    thead_4={t("tableClaimantForm1.judgment.thead_4")}
                    tbody_1_1={judgmentNumber || ""}
                    tbody_2_1={judgmentDate || ""}
                    tbody_3_1={court || ""}
                    tbody_4_1={country || ""}
                    row={1}
                />
                <ListTable
                    className="mt-4"
                    title={t("tableClaimantForm1.payment_compensation.title")}
                    row_1={comp_bank || ""}
                    row_2={comp_holder || ""}
                    row_3={comp_current || ""}
                    row_title_1={t(
                        "tableClaimantForm1.payment_compensation.row_title_1",
                    )}
                    row_title_2={t(
                        "tableClaimantForm1.payment_compensation.row_title_2",
                    )}
                    row_title_3={t(
                        "tableClaimantForm1.payment_compensation.row_title_3",
                    )}
                />
                <DownloadList keyEl="downloadForm_1" className="my-4" />

                <CheckedList
                    title={t("tableClaimantForm1.attached.title")}
                    point_1={t("tableClaimantForm1.attached.point_1")}
                    point_2={t("tableClaimantForm1.attached.point_2")}
                    point_3={t("tableClaimantForm1.attached.point_3")}
                    point_4={t("tableClaimantForm1.attached.point_4")}
                    point_5={t("tableClaimantForm1.attached.point_5")}
                    point_6={t("tableClaimantForm1.attached.point_6")}
                    point_7={t("tableClaimantForm1.attached.point_7")}
                    sub_1={point_1 === "true"}
                    sub_2={point_2 === "true"}
                    sub_3={point_3 === "true"}
                    sub_4={point_4 === "true"}
                    sub_5={point_5 === "true"}
                    sub_6={point_6 === "true"}
                    sub_7={point_7 === "true"}
                />
            </div>

            <CheckedList
                title=""
                point_1={t("sharedClaimantForm.checklist")}
                sub_1={agree === "true"}
            />

            <p className="font-[500] text-[16px] text-[#1A1A1F] mb-2 fontWeight">
                {t("sharedClaimantForm.applicant")}
                <span className="font-normal text-[16px] text-[#1A1A1F] ml-2">
                    {parentName === "undefined" ? "-" : parentName}
                </span>
            </p>
            <p className="font-[500] text-[16px] text-[#1A1A1F] flex items-end fontWeight">
                {t("sharedClaimantForm.signature")}
                <span className="ml-2 h-[1px] w-[150px] bg-black" />
            </p>
        </div>
    );
};
