import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { ScrollToTop } from "@components/common/ScrollToTop";
import { FileProvider } from "@providers/FileProvider";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <I18nextProvider i18n={i18n}>
        <Router>
            <ScrollToTop>
                <CookiesProvider>
                    <FileProvider>
                        <App />
                    </FileProvider>
                </CookiesProvider>
            </ScrollToTop>
        </Router>
    </I18nextProvider>,
);
