import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const questionsData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("questionsData.title")}`,
        subtitle_text: `${t("questionsData.subtitle_text")}`,
        subtitle_path: `${t("questionsData.subtitle_path")}`,
        items: [
            {
                id: 1,
                title: `${t("questionsData.items.0.title")}`,
                desc: `${t("questionsData.items.0.desc")}`,
                desc_2: `${t("questionsData.items.0.desc_2")}`,
                desc_3: `${t("questionsData.items.0.desc_3")}`,
                path_3: AppRoute.FORMS_DEBTOR_FORM_6,
                path_url_3: "/forms",
                path_name_3: " SIIN",
                name_3: "minimum",
                endOfDesc_3: ".",
                defaultOpenQuestion: "defaultOpen1",
                desc_4: `${t("questionsData.items.0.desc_4")}`,
                path_4: AppRoute.DEBTOR_RIGHTS,
                path_name_4: `${t("questionsData.items.0.path_name_4")}`,
                endOfDesc_4: ".",
                // desc_5: "Nõude aegunuks tunnistamiseks pead:",
                // list: [
                //     "Esitama kohtusse hagi sundtäitmise lubamatuks tunnistamiseks.",
                //     "Maksta riigilõiv 300 EUR.",
                // ],
            },
            {
                id: 2,
                title: `${t("questionsData.items.1.title")}`,
                desc: `${t("questionsData.items.1.desc")}`,
                desc_2: `${t("questionsData.items.1.desc_2")}`,
                desc_3: `${t("questionsData.items.1.desc_3")}`,
                desc_4: `${t("questionsData.items.1.desc_4")}`,
                desc_bold_4: `${t("questionsData.items.1.desc_bold_4")}`,
                endOfDesc_4: `${t("questionsData.items.1.endOfDesc_4")}`,
                desc_5: `${t("questionsData.items.1.desc_5")}`,
            },
            {
                id: 3,
                title: `${t("questionsData.items.2.title")}`,
                desc: `${t("questionsData.items.2.desc")}`,
                desc_2: `${t("questionsData.items.2.desc_2")}`,
                desc_3: `${t("questionsData.items.2.desc_3")}`,
            },
            {
                id: 4,
                title: `${t("questionsData.items.3.title")}`,
                desc: `${t("questionsData.items.3.desc")}`,
                path: AppRoute.FORMS_DEBTOR_FORM_6,
                path_name_1: `${t("questionsData.items.3.path_name_1")}`,
                path_url_1: "/forms",
                name_1: "minimum",
                endOfDesc_1: ".",
                desc_2: `${t("questionsData.items.3.desc_2")}`,
                desc_3: `${t("questionsData.items.3.desc_3")}`,
                path_3: AppRoute.FORMS_DEBTOR_FORM_6,
                name_3: "refund",
                path_name_3: `${t("questionsData.items.3.path_name_3")}`,
                path_url_3: "/forms",
                endOfDesc_3: ".",
            },
            {
                id: 5,
                title: `${t("questionsData.items.4.title")}`,
                desc: `${t("questionsData.items.4.desc")}`,
                desc_2: `${t("questionsData.items.4.desc_2")}`,
                desc_3: `${t("questionsData.items.4.desc_3")}`,
                list_3: [
                    `${t("questionsData.items.4.list_3.0")}`,

                    `${t("questionsData.items.4.list_3.1")}`,

                    `${t("questionsData.items.4.list_3.2")}`,

                    `${t("questionsData.items.4.list_3.3")}`,

                    `${t("questionsData.items.4.list_3.4")}`,

                    `${t("questionsData.items.4.list_3.5")}`,

                    `${t("questionsData.items.4.list_3.6")}`,

                    `${t("questionsData.items.4.list_3.7")}`,

                    `${t("questionsData.items.4.list_3.8")}`,

                    `${t("questionsData.items.4.list_3.9")}`,

                    `${t("questionsData.items.4.list_3.10")}`,

                    `${t("questionsData.items.4.list_3.11")}`,
                ],
                desc_4: `${t("questionsData.items.4.desc_4")}`,
                desc_bold_4: `${t("questionsData.items.4.desc_bold_4")}`,
                endOfDesc_4: `${t("questionsData.items.4.endOfDesc_4")}`,
                list_4: [
                    `${t("questionsData.items.4.list_4.0")}`,
                    `${t("questionsData.items.4.list_4.1")}`,
                    `${t("questionsData.items.4.list_4.2")}`,
                ],
                desc_5: `${t("questionsData.items.4.desc_5")}`,
                defaultOpenQuestion: "defaultOpen5",
            },
            {
                id: 6,
                title: `${t("questionsData.items.5.title")}`,
                desc: `${t("questionsData.items.5.desc")}`,
                path: AppRoute.FORMS_DEBTOR_FORM_6,
                path_url_1: "/forms",
                name_1: "schedule",
                path_name_1: `${t("questionsData.items.5.path_name_1")}`,
                endOfDesc_1: ".",
                desc_2: `${t("questionsData.items.5.desc_2")}`,
                desc_3: `${t("questionsData.items.5.desc_3")}`,
            },
            {
                id: 7,
                title: `${t("questionsData.items.6.title")}`,
                desc: `${t("questionsData.items.6.desc")}`,
            },
            {
                id: 8,
                title: `${t("questionsData.items.7.title")}`,
                desc: `${t("questionsData.items.7.desc")}`,
                desc_2: `${t("questionsData.items.7.desc_2")}`,
            },
            {
                id: 9,
                title: `${t("questionsData.items.8.title")}`,
                desc: `${t("questionsData.items.8.desc")}`,
                path: "/debtor#",
                path_url_1: "/debtor",
                path_name_1: `${t("questionsData.items.8.path_name_1")}`,
                endOfDesc_1: ".",
            },
            {
                id: 10,
                title: `${t("questionsData.items.9.title")}`,
                desc: `${t("questionsData.items.9.desc")}`,
            },
            {
                id: 11,
                title: `${t("questionsData.items.10.title")}`,
                desc: `${t("questionsData.items.10.desc")}`,
            },
            {
                id: 12,
                title: `${t("questionsData.items.11.title")}`,
                desc: `${t("questionsData.items.11.desc")}`,
                desc_2: `${t("questionsData.items.11.desc_2")}`,
                path_2: AppRoute.FORMS_DEBTOR_FORM_6,
                path_url_2: "/forms",
                name_2: "limitation",
                path_name_2: `${t("questionsData.items.11.path_name_2")}`,
                endOfDesc_2: ".",
                desc_3: `${t("questionsData.items.11.desc_3")}`,
                desc_bold_3: `${t("questionsData.items.11.desc_bold_3")}`,
                link_3: "https://kpkoda.ee/kohtutaiturid/nouete-aegumisest/",
                link_name_3: "kpkoda.ee",
            },
            {
                id: 13,
                title: `${t("questionsData.items.12.title")}`,
                desc: `${t("questionsData.items.12.desc")}`,
                list_1: [
                    `${t("questionsData.items.12.list_1.0")}`,
                    `${t("questionsData.items.12.list_1.1")}`,
                    `${t("questionsData.items.12.list_1.2")}`,
                    `${t("questionsData.items.12.list_1.3")}`,
                    `${t("questionsData.items.12.list_1.4")}`,
                ],
                desc_2: `${t("questionsData.items.12.desc_2")}`,
                desc_3: `${t("questionsData.items.12.desc_3")}`,
                desc_4: `${t("questionsData.items.12.desc_4")}`,
            },
            {
                id: 14,
                title: `${t("questionsData.items.13.title")}`,
                desc: `${t("questionsData.items.13.desc")}`,
                link_1: "https://www.eesti.ee/",
                link_name_1: "www.eesti.ee ",
                endOfDesc_1: `${t("questionsData.items.13.endOfDesc_1")}`,
                desc_3: `${t("questionsData.items.13.desc_3")}`,
            },
        ],
    };
};
