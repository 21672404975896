import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { TableClaimantForm2 } from "@components/components";

export const PDFDocument2 = () => {
    const { t } = useTranslation();
    let componentRef = useRef();
    let myRef = useRef();

    useEffect(() => {
        myRef.current.click();
    }, []);
    return (
        <div className="mb-36 page-hidden">
            <div className="p-8 m-8 ">
                <TableClaimantForm2
                    myRef={(response) => (componentRef = response)}
                />
                <ReactToPrint
                    content={() => componentRef}
                    trigger={() => (
                        <button
                            className="float-right mt-16 cursor-pointer mr-[48%] lg:mr-4 md:px-8 py-2 px-8 font-semibold uppercase rounded-md md:text-[14px] text-[16px] w-fit bg-secondary hover:bg-neutral text-white border-2 border-secondary hover:border-neutral"
                            ref={myRef}
                        >
                            {t("other.buttons.print_pdf")}
                        </button>
                    )}
                    bodyClass={"printElement1"}
                />
            </div>
        </div>
    );
};
