import { useEffect, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { DataService } from "services";

interface IUseQueryOutput<T> {
    data: T | undefined | any;
    error: AxiosError;
    loading: boolean;
}

export const useQuery = <QueryType>(
    url: string,
): IUseQueryOutput<QueryType> => {
    const [error, setError] = useState<AxiosError>({} as AxiosError);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<QueryType>();
    const { getAll } = DataService();

    useEffect(() => {
        setLoading(() => true);
        getAll(url)
            .then((response) => setData(() => response.data))
            .catch((error: AxiosError) => setError(() => error));
        setLoading(() => false);
    }, [url]);

    const value = useMemo(
        () => ({
            data,
            error,
            loading,
        }),
        [data, error, loading],
    );
    return value;
};
