import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const debtorCardData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 2,
            title: `${t("debtorCardData.0.title")}`,
            desc_1: `${t("debtorCardData.0.desc_1")}`,
            pay: false,
            path: AppRoute.DEBTOR_QUESTIONS,
            name: "defaultOpen1",
        },
        {
            id: 3,
            title: `${t("debtorCardData.1.title")}`,
            desc_1: `${t("debtorCardData.1.desc_1")}`,
            pay: false,
            path: AppRoute.FORMS_DEBTOR_FORM_6,
            name: "schedule",
        },
    ];
};
