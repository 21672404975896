import React from "react";
import { useTranslation } from "react-i18next";
import { ResourcesCard } from ".";
import { resourcesData } from "@config/home";

export const Resources = () => {
    const { t } = useTranslation();
    return (
        <div className="pb-12 bg-primary pt-6">
            <div className="container grid flex-1 grid-cols-1 gap-2 lg:grid-cols-4 md:grid-cols-2">
                {/*<p className="block mt-6 md:mt-0 pb-6 text-xl font-semibold text-center md:hidden">*/}
                {/*    Ametlik Teenused*/}
                {/*</p>*/}
                {resourcesData(t).map((item) => (
                    <ResourcesCard
                        key={item.id}
                        title={item.title}
                        items={item.items}
                        path={item.path}
                    />
                ))}
            </div>
        </div>
    );
};
