import { TFunction } from "i18next";

export const caseData = (t: TFunction<"translation", undefined>) => {
    return {
        name: "case_number",
        type: "text",
        label: `${t("other.caseData.label")}`,
        className: "w-5/6 mb-4",
        number: true,
    };
};
