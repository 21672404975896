import React from "react";
import { formsSidebarData } from "@config/forms";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";
import { CircleArrow } from "@assets";

export const ClaimantFormBase = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col mt-4 ml-6 mr-6 mt-4 md:mt-6 md:ml-0">
            <p className="text-xl font-semibold mb-4 md:mb-6">
                {t("formsSidebarData.1.title")}
            </p>
            <div className="flex flex-col">
                {formsSidebarData(t)[1].children.map((item) => (
                    <li
                        key={item.id}
                        className="h-[90px] p-2 w-fill mb-4 hover:bg-neutral hover:text-white base-border flex !flex-row justify-between"
                    >
                        <NavHashLink
                            className="flex items-center justify-between w-[100%]"
                            to={`${item.path}#isTop`}
                        >
                            <span className="text-lg w-[90%]">
                                {item.title}
                            </span>
                            <CircleArrow />
                        </NavHashLink>
                    </li>
                ))}
            </div>
        </div>
    );
};
