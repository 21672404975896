import { Button, ClickContacts } from "@components/common";
import React from "react";
import { useTranslation } from "react-i18next";

interface IContacts {
    id: number;
    item?: string;
    content?: string;
}
interface IBailiffFullCard {
    id: number;
    name: string;
    contacts: IContacts[];
    image?: string;
    desc_1?: string;
    desc_2?: string;
    desc_3?: string;
    region?: string;
}

export const BailiffFullCard: React.FC<IBailiffFullCard> = ({
    id,
    name,
    contacts,
    image,
    desc_1,
    desc_2,
    desc_3,
    region,
}) => {
    const { t } = useTranslation();
    const bailiff = localStorage.getItem("bailiff");

    const handleClick = (key: number) => {
        localStorage.setItem("bailiff", String(key));
        window.location.reload();
    };
    return (
        <div className="w-screen px-8 mt-2 mb-0 md:mb-80 md:px-0 md:w-auto lg:w-full">
            <p className="mb-6 text-xl font-semibold h-[28px] md:h-fit">
                {region}
            </p>

            <div className="relative flex flex-col flex-1 bg-white rounded-md shrink-0 lg:flex-row ">
                {image ? (
                    <img
                        src={require(`@assets/images/${image}`)}
                        alt="BailiffPhoto"
                        className="lg:w-[700px] xl:w-[740px] xl:h-[579px]  w-full md:mb-0 mb-4 lg:object-cover object-top"
                    />
                ) : (
                    <div className=" mb-32 md:mb-0 xl:w-[693px] xl:h-[459px] lg:w-[443px] lg:h-[309px]  bg-[#C4C4C430]" />
                )}

                <div className="flex flex-col justify-between flex-1 w-full p-0 mt-4 md:pl-4 xl:mt-0 lg:mt-0 md:mt-10">
                    <div className="flex flex-col flex-1 w-full ml-0 md:ml-2">
                        <p className="text-lg font-semibold text-secondary">
                            {t("other.bailiffFullCard.name")} {name}
                        </p>
                        <>
                            {contacts.map((item) => (
                                <ClickContacts
                                    full
                                    item={item.item}
                                    content={item.content}
                                    key={item.id}
                                />
                            ))}
                        </>
                    </div>
                </div>
                <div className="min-h-fit  lg:absolute order-1 static mt-16 lg:mt-0 z-10 xl:w-[55%] lg:w-[50%] md:w-full md:top-[40%] md:left-[14%] lg:p-8 p-4 rounded-md top-0 bg-beige lg:top-[104%] lg:left-[16%]">
                    <p className="mb-4 text-xl font-semibold text-secondary">
                        {name}
                    </p>
                    <p className="mb-2 text-lg lg:text-justify text-black">
                        {desc_1}
                    </p>
                    {desc_2 && (
                        <p className="mb-2 text-lg lg:text-justify text-black">
                            {desc_2}
                        </p>
                    )}
                    {desc_3 && (
                        <p className="mb-2 text-lg lg:text-justify text-black">
                            {desc_3}
                        </p>
                    )}
                    <div className="">
                        {Number(bailiff) === id ? (
                            <Button
                                disabled
                                onClick={() => handleClick(id)}
                                className="my-4"
                            >
                                {t("other.bailiffFullCard.btn_join")}
                            </Button>
                        ) : (
                            <Button
                                onClick={() => handleClick(id)}
                                className="my-4 "
                            >
                                {t("other.bailiffFullCard.deselect")}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
