import React from "react";
import clsx from "clsx";
import { Input } from "@components/components";
import { SectionContainer } from ".";

interface IFields {
    id: number;
    label: string;
    placeholder: string;
    type: string;
    name: string;
    number?: boolean;
}
interface IData {
    title: string;
    fields?: IFields[];
}
interface ICompensation {
    data: IData;
    onChange?: (arg0: any) => void;
}

export const Compensation: React.FC<ICompensation> = ({ data, onChange }) => {
    const { title, fields } = data;
    return (
        <SectionContainer title={title}>
            <div className="base-border">
                <div className="w-full md:w-1/2">
                    {fields?.map((item) => (
                        <div
                            key={item.id}
                            className={clsx(item.id < fields.length && "mb-4")}
                        >
                            <Input
                                type={item.type}
                                name={item.name}
                                label={item.label}
                                placeholder={item.placeholder}
                                onChange={onChange}
                                number={item.number}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </SectionContainer>
    );
};