import React from "react";
import { Button } from "@components/common";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";

interface IResources {
    title: string;
    items: IItems[];
    path: string;
}

interface IItems {
    id: number;
    content: string;
    path: string;
    name?: string;
    defaultOpen?: boolean;
}

export const ResourcesCard: React.FC<IResources> = ({ items, title, path }) => {
    const { t } = useTranslation();
    const handleFooterClick = (path: string, name: string | undefined) => {
        let correctPath = `/${path.split("/")[1]}`;

        if (correctPath === "/calculator") correctPath = "/calculator/salary";
        localStorage.setItem("url", correctPath);
        if (!name) return;
        localStorage.setItem("selectMenuItem", String(name));
        localStorage.setItem("selectQuestion", String(name));
    };

    return (
        <div className="flex flex-col items-center justify-between flex-1 px-8 py-12 bg-transparent border rounded-md border-neutral/10 hover:bg-white">
            <div className="flex flex-col ">
                <p className="pb-12 text-xl font-semibold text-center">
                    {title}
                </p>
                {items.map((item) => (
                    <NavHashLink
                        to={`${item.path}#isTop`}
                        className="pb-4 text-center text-xl md:pb-6 hover:text-secondary"
                        key={item.id}
                        onClick={() => handleFooterClick(item.path, item.name)}
                    >
                        {item.content}
                    </NavHashLink>
                ))}
            </div>
            <NavHashLink
                to={`${path}#isTop`}
                className="flex items-center justify-center w-full"
                onClick={() => handleFooterClick(path, "")}
            >
                <Button className="mt-10 !w-full md:!w-auto">
                    {t("other.resourcesCard.btn")}
                </Button>
            </NavHashLink>
        </div>
    );
};
