export const initialState = {
    child_name_1: "",
    child_id_1: "",
    child_address_1: "",

    child_name_2: "",
    child_id_2: "",
    child_address_2: "",

    child_name_3: "",
    child_id_3: "",
    child_address_3: "",

    child_name_4: "",
    child_id_4: "",
    child_address_4: "",

    child_name_5: "",
    child_id_5: "",
    child_address_5: "",

    parent_name: "",
    parent_id: "",
    parent_address: "",
    parent_email: "",
    parent_phone: "",

    debtor_name: "",
    debtor_id: "",
    debtor_address: "",
    debtor_email: "",
    pre_debtor_phone: "",
    debtor_phone: "",
    debtor_assets: "",

    re_debtor_name: "",
    re_debtor_id: "",
    re_debtor_address: "",
    re_debtor_email: "",
    re_debtor_phone: "",
    re_debtor_other: "",
    re_debtor_bank: "",
    re_debtor_account: "",

    judgmentDate: "",
    judgment_number: "",
    court: "",
    country: "",
    comp_bank: "",
    comp_holder: "",
    comp_current: "",

    claimant_name: "",
    claimant_id: "",
    claimant_address: "",
    claimant_email: "",
    claimant_phone: "",

    re_claimant_other: "",
    re_claimant_bank: "",
    re_claimant_account: "",

    reClaimant_name: "",
    reClaimant_id: "",
    reClaimant_address: "",
    reClaimant_email: "",
    reClaimant_phone: "",

    sub_field: "",
    debtor_sum: "",

    request_name: "",
    request_sum: "",
    request_date: "",
    request_result: "",
    request_other: "",
    request_number: "",

    case_number: "",

    debtor_name_1: "",
    debtor_id_1: "",
    email: "",

    download_1: "",
    download_2: "",
    download_3: "",
    download_4: "",
    download_5: "",

    form_name: "",
    form_email: "",
    form_post: "",
    form_phone: "",
    form_message: "",

    amount: "",
    date: "",
    first_payment: "",
    limit: "",
    limit1: "",

    amount3: "",
    current3: "",

    claimant_content1: "",
    claimant_name1: "",
    claimant_id1: "",

    place_owner: "",
    place_address: "",
    place_broker: "",
    place_post: "",
    place_phone: "",
    place_price: "",
    place_date: "",

    additional: "",
    additional1: "",

    payment_period: "",
    payment_sum: "",
    payment_full: "",
    add_bank: "",
    month_limit: "",

    documentForSign: "",
};
