import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const nonSeizableData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("nonSeizableData.title")}`,
        desc_1: `${t("nonSeizableData.desc_1")}`,

        desc_2: `${t("nonSeizableData.desc_2")}`,

        desc_3: `${t("nonSeizableData.desc_3")}`,

        desc_4: `${t("nonSeizableData.desc_4")}`,
        path_4: `/debtor/questions#${t("questionsData.items.4.title")}`,
        name_4: "defaultOpen5",
        path_name_4: `${t("nonSeizableData.path_name_4")}`,
        endOfDesc_4: ".",
        desc_5: `${t("nonSeizableData.desc_5")}`,
        path_url_5: "/forms",
        path_5: AppRoute.FORMS_DEBTOR_FORM_6,
        name_5: "minimum",
        path_name_5: `${t("nonSeizableData.path_name_5")}`,
        endOfDesc_5: ".",
    };
};
