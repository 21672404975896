import React, { useState } from "react";
import { DividedInput, Input, AddMore, Tooltip } from "@components/components";
import { useTranslation } from "react-i18next";
import { SectionContainer } from ".";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import clsx from "clsx";

interface IArrayFields {
    id: number;
    child: IFields[];
}
interface IFields {
    units?: string;
    id: number;
    name: string;
    label: string;
    type?: string;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    placeholder?: string;
    number?: boolean;
    onkey?: boolean;
    tip?: string;
}
interface IDividedFields {
    pattern?: string | RegExpConstructor | any;
    id: number;
    elem: string;
    placeholder: string;
    type?: string;
    name: string;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    number?: boolean;
    onkey?: boolean;
}
interface IData {
    id?: number;
    title: string;
    fields: IFields[];
    fields_2?: IFields[];
    fields_more?: IArrayFields[];
    note?: string;
    add_more?: boolean;
    divided_fields?: IDividedFields[];
    number?: boolean;
    tip?: string;
}
interface ISection {
    data: IData;
    onChange?: (arg0: any) => void;
    className?: string;
    dark?: boolean;
    upper?: boolean;
}

export const Section: React.FC<ISection> = ({
    data,
    onChange,
    className,
    dark,
    upper,
}) => {
    const { t } = useTranslation();
    const [count, setCount] = useState(1);
    const [phoneValue, setPhoneValue] = useState("");
    const {
        title,
        fields,
        fields_2,
        note,
        add_more,
        divided_fields,
        fields_more,
        tip,
    } = data;
    const onPhoneChange = (e: NumberFormatValues, name: string) => {
        setPhoneValue(e.formattedValue);
        localStorage.setItem(name, e.formattedValue);
    };
    localStorage.setItem("add_more_count", count.toString());
    const currentField = () => {
        const selectMenuItemFromStorage = JSON.parse(
            localStorage.getItem("selected_menu_arr") || "[]",
        );
        return selectMenuItemFromStorage.includes("schedule") ||
            selectMenuItemFromStorage.includes("notice") ||
            selectMenuItemFromStorage.includes("limitation") ||
            selectMenuItemFromStorage.includes("sale") ||
            selectMenuItemFromStorage.includes("other")
            ? fields
            : fields_2;
    };
    const rightField = currentField();
    return (
        <SectionContainer title={title} dark={dark} upper={upper} tip={tip}>
            <div className={clsx("", !fields_more && "base-border")}>
                <div className={clsx("mb-2", className)}>
                    {(rightField || fields).map((item) =>
                        item.type !== "tel" ? (
                            <Input
                                type={item.type}
                                name={item.name}
                                key={item.id}
                                label={item.label}
                                onChange={onChange}
                                form
                                required={item.required}
                                maxLength={item.maxLength}
                                minLength={item.minLength}
                                placeholder={item.placeholder}
                                number={item.number}
                                onkey={item.onkey}
                                units={item.units}
                                hint={item.tip}
                            />
                        ) : (
                            <div>
                                <div className="relative flex items-center justify-between">
                                    <label
                                        htmlFor={item.name}
                                        className="text-lg text-[#4B4B4B]  mb-1 text-lg"
                                    >
                                        {item.label}
                                        {item.tip && (
                                            <Tooltip
                                                hint={item.tip}
                                                right={false}
                                            />
                                        )}
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <p className="text-lg text-neutral  px-3">
                                        +
                                    </p>
                                    <NumberFormat
                                        className="focus:outline-none focus:ring-secondary w-[50px]  focus:ring-1 border focus:border-secondary border-[#4b4b4b] text-lg block rounded-md py-2 placeholder:opacity-50 placeholder:text-neutral placeholder:text-lg pl-3 text-lg mr-2"
                                        name={`pre_${item.name}`}
                                        format="###"
                                        onChange={onChange}
                                        mask=" "
                                    />
                                    <NumberFormat
                                        className="focus:outline-none focus:ring-secondary  focus:ring-1 border focus:border-secondary border-[#4b4b4b] text-lg block rounded-md py-2 placeholder:opacity-50 placeholder:text-neutral w-full placeholder:text-lg p-2 text-lg"
                                        name={item.name}
                                        format="### ### ### ### ###"
                                        onChange={onChange}
                                        mask=" "
                                    />
                                </div>
                            </div>
                        ),
                    )}
                </div>
                <div className="mb-2">
                    {fields_more?.map(
                        (item, index) =>
                            index < count && (
                                <AddMore
                                    data={item.child}
                                    key={item.id}
                                    onChange={onChange}
                                />
                            ),
                    )}
                </div>
                <div className="grid grid-cols-1 gap-4 mt-1 md:grid-cols-2">
                    {divided_fields?.map((item) =>
                        item.elem !== "Telefon*" ? (
                            <DividedInput
                                type={item.type}
                                name={item.name}
                                elem={item.elem}
                                placeholder={item.placeholder}
                                key={item.id}
                                onChange={onChange}
                                required={item.required}
                                maxLength={item.maxLength}
                                minLength={item.minLength}
                                number={item.number}
                                onkey={item.onkey}
                            />
                        ) : (
                            <NumberFormat
                                key={item.id}
                                name={item.name}
                                onChange={onChange}
                                format="+### ### ### ##"
                                onValueChange={(e) =>
                                    onPhoneChange(e, item.name)
                                }
                                value={phoneValue}
                                mask=" "
                                customInput={Input}
                                placeholder={item.placeholder}
                            />
                        ),
                    )}
                </div>
            </div>
            {note && <span className="text-sm text-[#333333]">{note}</span>}

            {add_more && (
                <div
                    className="flex items-center self-end cursor-pointer"
                    onClick={() => setCount(count + 1)}
                >
                    <div className="flex items-center justify-center w-3 h-3 mr-2 pb-[2px] font-semibold text-white rounded-full text-[10px] bg-secondary">
                        +
                    </div>
                    <p className="text-sm font-semibold uppercase text-neutral">
                        {t("other.buttons.add_more")}
                    </p>
                </div>
            )}
        </SectionContainer>
    );
};
