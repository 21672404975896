import {
    SideIcon1,
    // SideIcon2,
    SideIcon3,
    SideIcon4,
    // SideIcon5,
    SideIcon6,
    SideIcon7,
} from "@assets";

import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const debtorSidebarData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            icon: null,
            title: "",
            desc: "",
            path: AppRoute.DEBTOR,
        },
        {
            id: 7,
            icon: SideIcon4,
            title: `${t("debtorSidebarData.1.title")}`,
            desc: `${t("debtorSidebarData.1.desc")}`,
            path: AppRoute.DEBTOR_RIGHTS,
        },
        {
            id: 4,
            icon: SideIcon1,
            title: `${t("debtorSidebarData.2.title")}`,
            desc: `${t("debtorSidebarData.2.desc")}`,
            path: AppRoute.DEBTOR_NOTIFICATION,
        },
        {
            id: 6,
            icon: SideIcon3,
            title: `${t("debtorSidebarData.3.title")}`,
            desc: `${t("debtorSidebarData.3.desc")}`,
            path: AppRoute.DEBTOR_NONSEIZABLE,
        },
        {
            id: 2,
            icon: SideIcon7,
            title: `${t("debtorSidebarData.4.title")}`,
            desc: `${t("debtorSidebarData.4.desc")}`,
            path: AppRoute.DEBTOR_FORMS,
        },
        {
            id: 3,
            icon: SideIcon6,
            title: `${t("debtorSidebarData.5.title")}`,
            desc: `${t("debtorSidebarData.5.desc")}`,
            path: AppRoute.DEBTOR_QUESTIONS,
        },
        // {
        //     id: 5,
        //     icon: SideIcon2,
        //     title: "Maksegraafiku taotlemine",
        //     desc: "Võlgnikul on võimalus esitada kohtutäiturile maksegraafiku avaldus ...",
        //     path: AppRoute.DEBTOR_SCHEDULE,
        // },
        // {
        //     id: 8,
        //     icon: SideIcon5,
        //     title: "Vara nimekirja esitamine",
        //     desc: "Kohtutäituril on õigus kohustada võlgnikku esitama temale kuuluva ...",
        //     path: AppRoute.DEBTOR_ASSETS,
        // },
    ];
};
