import { TFunction } from "i18next";
import React from "react";
import {
    Applying,
    AssetsList,
    Auction,
    AuctionBase,
    Authorization,
    AuthorizationBase,
    CalculatorBase,
    Claimant,
    ClaimantBase,
    ClaimantForm1,
    ClaimantForm2,
    ClaimantForm3,
    ClaimantForm4,
    ClaimantForm5,
    ClaimantFormBase,
    Contacts,
    ContactsBase,
    CookiesBase,
    CookiesPage,
    Debtor,
    DebtorForm5,
    DebtorForm6,
    DebtorFormBase,
    DebtorForms,
    DigitalSign,
    DigitalSignBase,
    Forms,
    Home,
    Initiation,
    MainForms,
    Maintenance,
    NonSeizable,
    NotFound,
    Notification,
    PaymentSchedule,
    PDFDocument1,
    PDFDocument2,
    PDFDocument3,
    PDFDocument4,
    PDFDocument5,
    PDFDocument6,
    PDFDocument7,
    Questions,
    RightsObligations,
    Salary,
    ServicesBase,
    ServicesList,
    SigningPage,
    Team,
    WorkArea,
    WorkAreaBase,
} from "@pages";
import { DebtorBase, FormsBase } from "@components/components";

import { AppRoute } from "@enums";

export const routes = (t: TFunction<"translation", undefined>) => {
    return [
        {
            path: "/",
            element: <Home />,
            breadcrumb: `${t("routes.0.breadcrumb")}`,
        },
        {
            path: AppRoute.DEBTOR,
            element: <DebtorBase />,
            breadcrumb: `${t("routes.1.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.DEBTOR,
                    // element: <MainForms />,
                    element: <Debtor />,
                    breadcrumb: `${t("routes.1.children.0.breadcrumb")}`,
                },
                {
                    path: AppRoute.DEBTOR_FORMS,
                    element: <DebtorForms />,
                    breadcrumb: `${t("routes.1.children.1.breadcrumb")}`,
                },
                {
                    path: AppRoute.DEBTOR_QUESTIONS,
                    element: <Questions />,
                    breadcrumb: `${t("routes.1.children.2.breadcrumb")}`,
                },
                {
                    path: AppRoute.DEBTOR_NOTIFICATION,
                    element: <Notification />,
                    breadcrumb: `${t("routes.1.children.3.breadcrumb")}`,
                },
                {
                    path: AppRoute.DEBTOR_SCHEDULE,
                    element: <PaymentSchedule />,
                    breadcrumb: `${t("routes.1.children.4.breadcrumb")}`,
                },
                {
                    path: AppRoute.DEBTOR_NONSEIZABLE,
                    element: <NonSeizable />,
                    breadcrumb: `${t("routes.1.children.5.breadcrumb")}`,
                },
                {
                    path: AppRoute.DEBTOR_RIGHTS,
                    element: <RightsObligations />,
                    breadcrumb: `${t("routes.1.children.6.breadcrumb")}`,
                },
                {
                    path: AppRoute.DEBTOR_ASSETS,
                    element: <AssetsList />,
                    breadcrumb: `${t("routes.1.children.7.breadcrumb")}`,
                },
            ],
        },
        {
            path: AppRoute.CLAIMANT,
            element: <ClaimantBase />,
            breadcrumb: `${t("routes.2.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.CLAIMANT,
                    // element: <MainForms />,
                    element: <Claimant />,
                    breadcrumb: `${t("routes.2.children.0.breadcrumb")}`,
                },
                {
                    path: AppRoute.CLAIMANT_PORTAL,
                    // element: <Portal />,
                    element: <Initiation />,
                    breadcrumb: `${t("routes.2.children.1.breadcrumb")}`,
                },
                {
                    path: AppRoute.CLAIMANT_FORMS,
                    element: <Forms />,
                    breadcrumb: `${t("routes.2.children.2.breadcrumb")}`,
                },
                {
                    path: AppRoute.CLAIMANT_HOTLINE,
                    element: <ServicesList />,
                    // element: <Hotline />,
                    breadcrumb: `${t("routes.2.children.3.breadcrumb")}`,
                },
                {
                    path: AppRoute.CLAIMANT_MAINTENANCE,
                    element: <Maintenance />,
                    breadcrumb: `${t("routes.2.children.4.breadcrumb")}`,
                },
                {
                    path: AppRoute.CLAIMANT_APPLYING,
                    element: <Applying />,
                    breadcrumb: `${t("routes.2.children.5.breadcrumb")}`,
                },
                // {
                //     path: AppRoute.CLAIMANT_QUESTIONS,
                //     element: <ClaimantQuestions />,
                //     breadcrumb: "Korduma kippuvad küsimused",
                // },
                {
                    path: AppRoute.CLAIMANT_TEAM,
                    element: <Team />,
                    breadcrumb: `${t("routes.2.children.6.breadcrumb")}`,
                },
            ],
        },
        {
            path: AppRoute.SERVICES,
            element: <ServicesBase />,
            breadcrumb: `${t("routes.3.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.SERVICES,
                    element: <ServicesList />,
                    breadcrumb: `${t("routes.3.children.0.breadcrumb")}`,
                },
            ],
        },
        {
            path: AppRoute.FORMS,
            element: <FormsBase />,
            breadcrumb: `${t("routes.4.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.FORMS,
                    element: <MainForms />,
                    breadcrumb: `${t("routes.4.children.0.breadcrumb")}`,
                },
                {
                    path: AppRoute.FORMS_DEBTOR,
                    element: <DebtorFormBase />,
                    breadcrumb: `${t("routes.4.children.1.breadcrumb")}`,
                },

                {
                    path: AppRoute.FORMS_CLAIMANT_FORM_1,
                    breadcrumb: `${t("routes.4.children.2.breadcrumb")}`,
                    element: <ClaimantForm1 />,
                },
                {
                    path: AppRoute.FORMS_CLAIMANT_FORM_2,
                    breadcrumb: `${t("routes.4.children.3.breadcrumb")}`,
                    element: <ClaimantForm2 />,
                },
                {
                    path: AppRoute.FORMS_CLAIMANT_FORM_3,
                    breadcrumb: `${t("routes.4.children.4.breadcrumb")}`,
                    element: <ClaimantForm3 />,
                },
                {
                    path: AppRoute.FORMS_CLAIMANT_FORM_4,
                    breadcrumb: `${t("routes.4.children.5.breadcrumb")}`,
                    element: <ClaimantForm4 />,
                },
                {
                    path: AppRoute.FORMS_CLAIMANT_FORM_5,
                    breadcrumb: `${t("routes.4.children.6.breadcrumb")}`,
                    element: <ClaimantForm5 />,
                },
                {
                    path: AppRoute.FORMS_DEBTOR_FORM_5,
                    breadcrumb: `${t("routes.4.children.7.breadcrumb")}`,
                    element: <DebtorForm5 />,
                },
                {
                    path: AppRoute.FORMS_DEBTOR_FORM_6,
                    breadcrumb: `${t("routes.4.children.8.breadcrumb")}`,
                    element: <DebtorForm6 />,
                },
                {
                    path: AppRoute.FORMS_CLAIMANT,
                    element: <ClaimantFormBase />,
                    breadcrumb: `${t("routes.4.children.9.breadcrumb")}`,
                },
            ],
        },
        {
            path: AppRoute.CALCULATOR,
            element: <CalculatorBase />,
            breadcrumb: `${t("routes.5.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.CALCULATOR_SALARY,
                    element: <Salary />,
                    breadcrumb: `${t("routes.5.children.0.breadcrumb")}`,
                },
                // {
                //     path: AppRoute.CALCULATOR_ASSETS,
                //     element: <CalcAssets />,
                //     breadcrumb: "Võlgniku blanketid",
                // },
            ],
        },
        {
            path: AppRoute.AUCTION,
            element: <AuctionBase />,
            breadcrumb: `${t("routes.6.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.AUCTION,
                    element: <Auction />,
                    breadcrumb: `${t("routes.6.children.0.breadcrumb")}`,
                },
            ],
        },

        {
            path: AppRoute.WORK_AREA,
            element: <WorkAreaBase />,
            breadcrumb: `${t("routes.7.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.WORK_AREA,
                    element: <WorkArea />,
                    breadcrumb: `${t("routes.7.children.0.breadcrumb")}`,
                },
            ],
        },
        {
            path: AppRoute.CONTACTS,
            element: <ContactsBase />,
            breadcrumb: `${t("routes.8.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.CONTACTS,
                    element: <Contacts />,
                    breadcrumb: `${t("routes.8.children.0.breadcrumb")}`,
                },
            ],
        },
        {
            path: AppRoute.AUTHORIZATION,
            element: <AuthorizationBase />,
            breadcrumb: `${t("routes.9.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.AUTHORIZATION,
                    element: <Authorization />,
                    breadcrumb: `${t("routes.9.children.0.breadcrumb")}`,
                },
            ],
        },
        {
            path: "*",
            element: <NotFound />,
            breadcrumb: "404",
        },
        {
            path: AppRoute.PDF_DOCUMENT_1,
            element: <PDFDocument1 />,
            breadcrumb: "pdf-1",
        },
        {
            path: AppRoute.PDF_DOCUMENT_2,
            element: <PDFDocument2 />,
            breadcrumb: "pdf-2",
        },
        {
            path: AppRoute.PDF_DOCUMENT_3,
            element: <PDFDocument3 />,
            breadcrumb: "pdf-3",
        },
        {
            path: AppRoute.PDF_DOCUMENT_4,
            element: <PDFDocument4 />,
            breadcrumb: "pdf-4",
        },
        {
            path: AppRoute.PDF_DOCUMENT_5,
            element: <PDFDocument5 />,
            breadcrumb: "pdf-5",
        },
        {
            path: AppRoute.PDF_DOCUMENT_6,
            element: <PDFDocument6 />,
            breadcrumb: "pdf-6",
        },
        {
            path: AppRoute.PDF_DOCUMENT_7,
            element: <PDFDocument7 />,
            breadcrumb: "pdf-7",
        },

        {
            path: AppRoute.DIGITAL_SIGN,
            element: <DigitalSignBase />,
            breadcrumb: `${t("routes.18.breadcrumb")}`,
            children: [
                {
                    path: AppRoute.DIGITAL_SIGN,
                    element: <DigitalSign />,
                    breadcrumb: `${t("routes.18.children.0.breadcrumb")}`,
                },
            ],
        },
        {
            path: AppRoute.SIGNING_PAGE,
            element: <SigningPage />,
            breadcrumb: `${t("routes.19.breadcrumb")}`,
        },
        {
            path: AppRoute.COOKIES_INFO,
            element: <CookiesBase />,
            breadcrumb: "Cookies",
            children: [
                {
                    path: AppRoute.COOKIES_INFO,
                    element: <CookiesPage />,
                    breadcrumb: "Cookies",
                },
            ],
        },
    ];
};
