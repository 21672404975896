// import { LinkedInIcon } from "@assets/icons";
import { ReactComponent as LinkedInIcon } from "@assets/icons/social/linkedin.svg";

export const socialLinksData = [
    // { id: 1, icon: FacebookIcon, path: "/" },
    // { id: 2, icon: InstagramIcon, path: "/" },
    {
        id: 3,
        icon: LinkedInIcon,
        path: "https://ee.linkedin.com/company/%C3%BChinenud-kohtut%C3%A4iturid",
    },
];
