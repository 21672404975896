export interface IState {
    signingToken: string;
    signerToken: string;
}

export enum ActionTypes {
    SET_SIGN_TOKENS = "SET_SIGN_TOKENS",
}

interface IASetSignTokens {
    type: ActionTypes.SET_SIGN_TOKENS;
    payload: {
        signingToken: string;
        signerToken: string;
    };
}

export type IAction = IASetSignTokens;
