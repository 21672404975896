import React from "react";
import { useLocation } from "react-router";
import { NavHashLink } from "react-router-hash-link";
import clsx from "clsx";

interface IData {
    id: number;
    icon: React.ReactElement | null | string | JSX.Element;
    title: string;
    desc: string;
    path: string;
    name?: string;
}

interface ISidebar {
    data: IData[];
}

export const Sidebar: React.FC<ISidebar> = ({ data }) => {
    const location = useLocation();
    const pathName = location.pathname;

    return (
        <div className="sticky py-4 my-8 border rounded-md top-8 border-neutral/10">
            {data.map(
                (item) =>
                    item.icon && (
                        <div
                            key={item.id}
                            id="sidebar"
                            className={clsx(
                                "flex items-center px-4 py-2 cursor-pointer hover:bg-neutral hover:text-white ",
                                pathName === item.path &&
                                    "bg-neutral text-white",
                            )}
                        >
                            <NavHashLink
                                id="icon"
                                to={`${item.path}#top`}
                                className={clsx(
                                    "flex items-center justify-center mr-4 rounded-md w-[50px] h-[40px] ",
                                    pathName === item.path
                                        ? "bg-secondary"
                                        : "bg-neutral",
                                )}
                            >
                                {item.icon}
                            </NavHashLink>
                            <NavHashLink
                                to={`${item.path}#top`}
                                className="flex flex-col w-full"
                            >
                                <>
                                    <p className="font-semibold">
                                        {item.title}
                                    </p>
                                </>
                            </NavHashLink>
                        </div>
                    ),
            )}
        </div>
    );
};
