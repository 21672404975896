import { AppRoute } from "@enums";
import { TFunction } from "i18next";

export const notificationData = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("notificationData.title")}`,
        desc_1: `${t("notificationData.desc_1")}`,
        desc_2: `${t("notificationData.desc_2")}`,
        desc_3: `${t("notificationData.desc_3")}`,
        desc_4: `${t("notificationData.desc_4")}`,
        desc_5: `${t("notificationData.desc_5")}`,
        desc_6: `${t("notificationData.desc_6")}`,
        path_name: `${t("notificationData.path_name")}`,
        path: AppRoute.FORMS_DEBTOR_FORM_6,
        path_url: "/forms",
        name: "schedule",
        endOfDesc_6: ".",
    };
};
