import {
    Limitation,
    Minimum,
    Notice,
    Other,
    Refund,
    Sale,
    Schedule,
} from "@components/forms";
import { TFunction } from "i18next";

export const debtorForm6Data = (t: TFunction<"translation", undefined>) => {
    return {
        title: `${t("debtorForm6Data.title")}`,

        statements: [
            {
                id: 1,
                title: `${t("debtorForm6Data.statements.0.title")}`,
                element: Notice,
                name: "notice",
            },
            {
                id: 2,
                title: `${t("debtorForm6Data.statements.1.title")}`,
                element: Schedule,
                name: "schedule",
            },
            {
                id: 3,
                title: `${t("debtorForm6Data.statements.2.title")}`,
                element: Minimum,
                name: "minimum",
            },
            {
                id: 4,
                title: `${t("debtorForm6Data.statements.3.title")}`,
                element: Refund,
                name: "refund",
            },
            {
                id: 5,
                title: `${t("debtorForm6Data.statements.4.title")}`,
                element: Limitation,
                name: "limitation",
            },

            {
                id: 6,
                title: `${t("debtorForm6Data.statements.5.title")}`,
                element: Sale,
                name: "sale",
            },
            {
                id: 7,
                title: `${t("debtorForm6Data.statements.6.title")}`,
                element: Other,
                name: "other",
            },
        ],
        agreement: {
            title: "",
            points: [
                {
                    id: 1,
                    value: `${t("debtorForm6Data.agreement.points.0.value")}`,
                    name: "agree2_1",
                },
                {
                    id: 2,
                    value: `${t("debtorForm6Data.agreement.points.1.value")}`,
                    name: "agree2_2",
                },
                {
                    id: 3,
                    value: `${t("debtorForm6Data.agreement.points.2.value")}`,
                    name: "agree2_3",
                },
            ],
        },
        download: {
            title: `${t("debtorForm6Data.download.title")}`,
            divided_fields: [
                {
                    id: 1,
                    elem: `${t(
                        "debtorForm6Data.download.divided_fields.0.elem",
                    )}`,
                    multiple: true,
                    type: "file",
                    name: "download_1",
                    placeholder: `${t(
                        "debtorForm6Data.download.divided_fields.0.placeholder",
                    )}`,
                },
            ],
        },
    };
};
